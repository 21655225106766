import { faFileContract, faMagnifyingGlass, faReceipt, faTombstone } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Nav, Sidebar, Sidenav } from "rsuite";

import { permissionsRicercheState } from "@stores/diritti";
import { cimiteriListState } from "@stores/municipality";

import { StatoDiritti } from "@models/Diritti";

import { useCruxNavigate } from "@hooks/useCruxNavigate";
import { useDiritti } from "@hooks/useDiritti";

const CruxSidenav = () => {
    const permissionsRicerche = useRecoilValue(permissionsRicercheState);
    const cimiteriList = useRecoilValue(cimiteriListState);

    const [activeKey, setActiveKey] = React.useState<string | null>(null);
    const [expanded, setExpanded] = React.useState(true);

    const { numberRouteKey, useQuery } = useCruxNavigate();
    const uiPreview = useQuery.get("preview");

    useDiritti();

    useEffect(() => {
        setActiveKey(numberRouteKey);
    }, [numberRouteKey]);

    if (uiPreview) return null;

    return (
        <Sidebar className='h-full flex transition-all' width={expanded ? 240 : 56} collapsible>
            <Sidenav expanded={expanded} appearance='subtle' className='main-sidenav transition-all'>
                <Sidenav.Body>
                    <Nav activeKey={activeKey} onSelect={setActiveKey}>
                        <Nav.Item
                            as={Link}
                            eventKey='1'
                            to={cimiteriList ? `/cimitero/${cimiteriList[0]?.id}` : "/"}
                            icon={<FontAwesomeIcon className='rs-icon' icon={faTombstone} />}
                        >
                            Gestione Cimitero
                        </Nav.Item>
                        <Nav.Item
                            as={Link}
                            eventKey='2'
                            to='/contratti'
                            icon={<FontAwesomeIcon className='rs-icon' icon={faFileContract} />}
                        >
                            Gestione Contratti
                        </Nav.Item>

                        {permissionsRicerche === StatoDiritti.LETTURA && (
                            <Nav.Menu
                                title='Ricerche'
                                eventKey='6'
                                placement='rightStart'
                                trigger='hover'
                                icon={<FontAwesomeIcon className='rs-icon' icon={faMagnifyingGlass} />}
                            >
                                <Nav.Item as={Link} to='/ricerche/defunti'>
                                    Ricerche defunti
                                </Nav.Item>
                                <Nav.Item as={Link} to='/ricerche/tombe'>
                                    Ricerche tombe
                                </Nav.Item>
                                <Nav.Item as={Link} to='/ricerche/contratti'>
                                    Ricerche contratti
                                </Nav.Item>
                            </Nav.Menu>
                        )}

                        <Nav.Menu
                            title='Gestione Canoni'
                            eventKey='3'
                            placement='rightStart'
                            trigger='hover'
                            icon={<FontAwesomeIcon className='rs-icon' icon={faReceipt} />}
                        >
                            <Nav.Item as={Link} to='/canoni'>
                                Lista canoni
                            </Nav.Item>
                            <Nav.Item as={Link} to='/canoni/massive-edit'>
                                Modifica canoni con criteri
                            </Nav.Item>
                            <Nav.Item as={Link} to='/canoni/massive-copy'>
                                Copia canoni
                            </Nav.Item>
                            <Nav.Menu placement='rightStart' title='Gestione fatture' eventKey='5'>
                                <Nav.Item as={Link} to='/fatture'>
                                    Lista fatture
                                </Nav.Item>
                                <Nav.Item as={Link} to='/fatture/massive-create'>
                                    Crea fatture
                                </Nav.Item>
                                <Nav.Item as={Link} to='/fatture/massive-edit'>
                                    Modifica fatture con criteri
                                </Nav.Item>
                            </Nav.Menu>
                        </Nav.Menu>
                    </Nav>
                </Sidenav.Body>
                <Sidenav.Toggle onToggle={setExpanded} className='toggle-main-sidebar' />
            </Sidenav>
        </Sidebar>
    );
};

export default CruxSidenav;
