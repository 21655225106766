import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import { cimiteroMapVisibilityConfigState } from "@stores/graveyard";

import { ELayerToShow } from "@models/Configs";
import { ELayer } from "@models/Grafica";

import { GraphicViewProps } from "@modules/cimiteri/pages/Models/settori";

import { featureLayer } from "@openLayersMap/Helpers/EditMappingHelpers";
import { useMap } from "@openLayersMap/Map/MapContext";
import { vector } from "@openLayersMap/Source";

import VectorLayer from "./VectorLayer";

const VectorLayers = ({
    featuresSettori,
    featuresTombe,
    featuresLinee,
    featuresTesti,
    onSelectSettore,
    onSelectTomba,
    pixel,
    navigationSource,
    setCoordinate,
}: GraphicViewProps): JSX.Element => {
    const visibilityConfig = useRecoilValue(cimiteroMapVisibilityConfigState);
    const { map } = useMap();

    useEffect(() => {
        if (map && pixel) {
            const coordinate = map.getCoordinateFromPixel(pixel.pixel);
            const fts = navigationSource.getFeaturesAtCoordinate(coordinate ?? []);

            if (fts && fts.length > 0) {
                const layer = featureLayer(fts.at(-1) as Feature<Geometry>);

                if (layer === pixel.oldLayer || (layer === ELayer.Tomba && pixel.oldLayer === ELayer.Retino)) {
                    const f = fts.at(-1) as Feature<Geometry>;
                    const targetElement = f.getProperties()?.properties?.element;
                    const targetLayer = f.getProperties()?.properties?.layer;

                    if (targetLayer === ELayer.Settore && setCoordinate) {
                        onSelectSettore(targetElement);
                        setCoordinate(undefined);
                    }
                    if (targetLayer === ELayer.Tomba && setCoordinate) {
                        onSelectTomba(targetElement);
                        setCoordinate(undefined);
                    }
                }
            }

            if (pixel.oldLayer === ELayer.Settore && fts && fts.length === 0) {
                const targetElement = pixel.feature.getProperties()?.properties?.element;
                onSelectSettore(targetElement);
                setCoordinate(undefined);
            }
        }
    }, [map, pixel]);

    return (
        <>
            {visibilityConfig.layers.includes(ELayerToShow.Retino) && <VectorLayer source={vector(featuresLinee)} />}
            {visibilityConfig.layers.includes(ELayerToShow.Settore) && <VectorLayer source={vector(featuresSettori)} />}
            {visibilityConfig.layers.includes(ELayerToShow.Tomba) && <VectorLayer source={vector(featuresTombe)} />}
            {visibilityConfig.layers.includes(ELayerToShow.Testo) && <VectorLayer source={vector(featuresTesti)} />}
        </>
    );
};

export default VectorLayers;
