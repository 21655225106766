import { some } from "lodash";
import OLMap, { MapOptions } from "ol/Map";
import OLView from "ol/View";
import { Extent } from "ol/extent";
import { defaults } from "ol/interaction";
import { Projection } from "ol/proj";
import React, { Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useRecoilValue } from "recoil";

import { colorConfigState } from "@stores/globalUserSettings";

import { mappaEsternaState } from "@openLayersMap/Stores/editControls";

import { useMap } from "./MapContext";
import "./map.css";

interface MapProps {
    children?: React.ReactNode;
    onClick?: (event: any) => void;
    extent: Extent;
    initialViewExtent?: Extent | undefined;
    onLoaded?: (map: any) => void;
    onMoved?: (extent: Extent) => void;
    cruxWebMap?: boolean;
    graficaMap?: boolean;
}

export interface RefMap {
    forceFit: (extent?: Extent | undefined) => void;
}

const Map = forwardRef(
    (
        {
            children,
            onClick,
            extent,
            onLoaded,
            onMoved,
            initialViewExtent,
            cruxWebMap = false,
            graficaMap = false,
        }: MapProps,
        ref: Ref<RefMap>
    ) => {
        const mapRef = useRef<any>();
        const { map, setMap } = useMap();
        const colorConfig = useRecoilValue(colorConfigState);
        const mappaEsterna = useRecoilValue(mappaEsternaState);

        const normalizedExtent = useMemo(() => {
            let newExtent = extent;
            if (some(extent, (e) => e === Infinity)) {
                newExtent = [5, 45, 30, 65]; // fixme: sono dati casuali -> mettere valori di default
            }
            return newExtent;
        }, [extent]);

        const projection = useMemo(() => {
            return new Projection({
                code: "CruxProjection",
                units: "m",
                extent: normalizedExtent,
                worldExtent: [-10000, -10000, 10000, 10000],
                metersPerUnit: 1,
            });
        }, [normalizedExtent]);

        const view = useMemo(() => {
            return new OLView({
                projection,
                // extent: calculateExtent,
                smoothExtentConstraint: true,
                showFullExtent: true,
                maxZoom: 5,
                padding: [20, 20, 20, 20],
            });
        }, [projection]);

        const fitMap = (ext?: Extent | undefined) => {
            map?.getView().fit(ext ?? normalizedExtent, {
                size: map?.getSize(),
                maxZoom: 18,
                padding: [50, 50, 50, 50],
            });
        };

        const bgColorMap = useMemo(() => {
            if (graficaMap) return "rgba(255,255,255,1)";
            if (mappaEsterna || cruxWebMap) return "transparent";

            return colorConfig?.mappa?.sfondo;
        }, [mappaEsterna, graficaMap, cruxWebMap]);

        useEffect(() => {
            const options: MapOptions = {
                target: "map",
                view,
                layers: [],
                controls: [],
                overlays: [],
                interactions: defaults({ doubleClickZoom: false }),
            };

            const mapObject = new OLMap(options);
            mapObject.setTarget(mapRef.current);
            mapObject.on("dblclick", (e) => {
                e.preventDefault();
            });
            mapObject.on("moveend", (e) => {
                onMoved?.(e.map.getView().calculateExtent(e.map.getSize()));
            });
            mapObject.getView().fit(initialViewExtent ?? normalizedExtent);

            setMap(mapObject);
            onLoaded?.(mapObject);

            return () => mapObject.setTarget(undefined);
        }, [view]);

        useImperativeHandle(ref, () => ({ forceFit: (ext?) => fitMap(ext) }));

        return (
            <div ref={mapRef} className='ol-map relative block h-full w-full' style={{ backgroundColor: bgColorMap }}>
                {children}
            </div>
        );
    }
);

export default Map;
