import { useRequest } from "ahooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { getSettoreCimitero, getTombeSettore } from "@services/CimiteriService";

import { PathItem, Tomba } from "@models/Tomba";

import { LoadingExportMap } from "@modules/cimiteri/pages/Models/settori";
import BreadcrumbSettori from "@modules/cimiteri/pages/_components/BreadcrumbSettori";
import GraphicView from "@modules/cimiteri/pages/settori/GraphicView";

import { useCruxRequest } from "@hooks/useCruxRequest";
import { useCruxToaster } from "@hooks/useCruxToaster";

import { MapProvider } from "@openLayersMap/Map/MapContext";
import { mappaEsternaState } from "@openLayersMap/Stores/editControls";

interface IMappaEsterna {
    livello: PathItem;
    idTomba: number;
    isGrave?: boolean;
    nextLivello?: PathItem;
    isCruxWeb?: boolean;
    graficaMap?: boolean;
    lastMap?: boolean;
    loadingMap?: ({ loading, idSettore }: LoadingExportMap) => void;
}

const MappaEsterna = ({
    livello,
    idTomba,
    isGrave,
    nextLivello,
    isCruxWeb = true,
    graficaMap = false,
    lastMap = false,
    loadingMap,
}: IMappaEsterna) => {
    const {
        data: settore,
        loading: loadingSettore,
        run: runSettore,
        error,
    } = useRequest(getSettoreCimitero, { manual: true });
    const navigate = useNavigate();

    const { data: tombe, loading: loadingTombe, run: runTombe } = useCruxRequest(getTombeSettore, { manual: true });
    const { handleApiError } = useCruxToaster();
    const setMappaEsterna = useSetRecoilState(mappaEsternaState);

    useEffect(() => {
        if (livello) runSettore(livello.id, true);
    }, [livello, runSettore]);

    useEffect(() => {
        if (settore) {
            runTombe(settore.id, true);
        }
    }, [settore, runTombe]);

    useEffect(() => {
        if (error) {
            handleApiError({
                error: (error as any).response.data,
            });
        }
    }, [error]);

    useEffect(() => {
        setMappaEsterna(graficaMap);
    }, [graficaMap]);

    const navigateToTomba = (tomba: Tomba) => {
        navigate(`/cimitero/${tomba.idCimitero}/settore/${tomba.idStruttura}/tomba/${tomba.id}`);
    };

    const hiddenMap = () => {
        if (!lastMap && graficaMap) return "hidden";
        return "block";
    };

    if (!settore) return <></>;

    return (
        <div
            className={`border border-primary-500 relative w-full
            ${isCruxWeb ? "min-h-[40rem]" : "h-full"}
            ${hiddenMap()}
            `}
        >
            <p className='absolute left-8 bottom-8'>
                <BreadcrumbSettori settore={settore} />
            </p>

            <MapProvider>
                <GraphicView
                    settore={settore}
                    tombe={tombe ?? []}
                    loadingSettore={loadingSettore}
                    loadingTombe={loadingTombe}
                    onSelectTomba={(tomba) => {
                        if (graficaMap && tomba.id === idTomba) navigateToTomba(tomba);
                    }}
                    onSelectSettore={(id) => {}}
                    cruxWebMap={!graficaMap}
                    graficaMap={graficaMap}
                    livelloRicerca={!isGrave ? nextLivello : undefined}
                    idTomba={!isGrave ? idTomba : undefined}
                    loadingMap={loadingMap}
                />
            </MapProvider>
        </div>
    );
};

export default MappaEsterna;
