import { useEffect, useState } from "react";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import WebFont from "webfontloader";

import history from "@services/HistoryService";

import { AuthProvider } from "@context/AuthContext";

import { useCruxToaster } from "@hooks/useCruxToaster";

import AppRoutes from "./routing/AppRoutes";

const { PUBLIC_URL } = process.env;

const App = () => {
    const [key, setKey] = useState("");
    const { handleApiSuccess, handleApiWarning } = useCruxToaster();

    history.listen((value) => {
        const query = new URLSearchParams(value.location.search);
        const refreshToken = query.get("refreshToken");
        const signUp = query.get("signUp");
        setKey(history.location.key);

        const refreshTokenMessage = "Utente disconnesso per inattività o per accesso da un nuovo dispositivo";
        const signUpMessage = "Utente registrato correttamente";

        if (refreshToken && history.location.key !== key) handleApiWarning({ message: refreshTokenMessage });
        if (signUp && history.location.key !== key) handleApiSuccess({ message: signUpMessage });
    });

    useEffect(() => {
        WebFont.load({
            custom: {
                families: ["Font Awesome 6 Pro"],
            },
        });
    }, []);

    return (
        <RecoilRoot>
            <RecoilNexus />
            <AuthProvider>
                <HistoryRouter basename={PUBLIC_URL} history={history as any}>
                    <AppRoutes />
                </HistoryRouter>
            </AuthProvider>
        </RecoilRoot>
    );
};

export default App;
