import React from "react";
import { Drawer } from "rsuite";
import { ModalSize } from "rsuite/esm/Modal/Modal";

interface RightDrawerProps<T> {
    size?: ModalSize;
    onClose: () => void;
    children?: React.ReactNode;
}

const RightDrawer: <T extends object>(p: RightDrawerProps<T>) => React.ReactElement<RightDrawerProps<T>> = (props) => {
    const { onClose, children, size = "md" } = props;

    return (
        <div>
            <Drawer
                size={size}
                placement='right'
                open
                onClose={onClose}
                backdrop={false}
                keyboard={false}
                className='overflow-y-auto'
                backdropClassName='right-form-drawer-backdrop'
            >
                {children}
            </Drawer>
        </div>
    );
};

export default RightDrawer;
