import classNames from "classnames";

interface ICard {
    isPadding?: boolean;
    isSmall?: boolean;
    isHistoricalData?: boolean;
    isFit?: boolean;
    left: React.ReactNode;
    right: React.ReactNode;
    actions?: React.ReactNode;
}

const Card = ({
    isPadding = false,
    isSmall = false,
    isFit = true,
    isHistoricalData = false,
    left,
    right,
    actions,
}: ICard) => {
    return (
        <div
            className={classNames(
                "w-full border bg-athens-600 relative block transition-all duration-150 cursor-defaul -mb-1",
                { "bg-athens-200 grayscale": isHistoricalData },
                { "h-fit": isFit },
                { "h-full": !isFit }
            )}
        >
            <section
                className={classNames(
                    "w-full border-athens-600 transition-all duration-200 flex overflow-hidden bg-athens-200 h-full",
                    { "p-2": isPadding, "p-0": !isPadding },
                    { "items-center": isSmall }
                )}
            >
                {left}

                <div
                    className={classNames(
                        "w-full items-center flex xl:flex-row flex-col space-y-8 xl:space-x-12 xl:space-y-0",
                        { "px-12 py-8": !isPadding },
                        { "px-12": isPadding }
                    )}
                >
                    <div className='flex-1 w-full'>{right}</div> <div>{actions}</div>
                </div>
            </section>
        </div>
    );
};

export default Card;
