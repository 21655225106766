import React, { FC } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "@context/AuthContext";

interface GateProps {
    children: React.ReactNode;
}

const ProtectedRoute: FC<GateProps> = ({ children }): any => {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return <Navigate to='/login' replace />;
    }

    return children;
};

export default ProtectedRoute;
