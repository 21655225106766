import { Graticule } from "ol/layer";
import { Stroke } from "ol/style";
import { FC, useEffect } from "react";

import { useMap } from "../Map/MapContext";

const GraticuleLayer: FC = () => {
    const { map } = useMap();

    useEffect(() => {
        if (!map) return undefined;

        const graticuleLayer = new Graticule({
            strokeStyle: new Stroke({
                color: "rgba(255,120,0,1)",
                width: 1,
            }),
            targetSize: 15,
            opacity: 0.2,
            properties: {
                name: "graticule",
            },
        });

        map.addLayer(graticuleLayer);

        return () => {
            if (map) {
                map.removeLayer(graticuleLayer);
            }
        };
    }, [map]);

    return <></>;
};

export default GraticuleLayer;
