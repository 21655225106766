import React, { FC } from "react";
import { Stack } from "rsuite";

interface FormDrawerStackProps {
    children?: React.ReactNode;
}

const FormDrawerStack: FC<FormDrawerStackProps> = (props) => {
    return (
        <Stack direction='column' alignItems='stretch' spacing={10} justifyContent='flex-start' className='h-full'>
            {props.children}
        </Stack>
    );
};

export default FormDrawerStack;
