export enum ELayer {
    Settore = 1,
    Tomba = 2,
    Testo = 3,
    Retino = 4,
}

interface GraficaBase {
    id?: number;
    editId?: number;
    geom: string;
    testo?: TestoGrafica;
}

interface GraficaConColore extends GraficaBase {
    colore: ColoreGrafica;
}

interface IGrafica extends GraficaConColore {
    layer: ELayer;
    idStruttura: number;
    testo?: TestoGrafica;
    raggio?: number;
}

interface TestoGrafica {
    val?: string;
    rot?: number;
    align?: number;
    size?: number; // dovrà esistere in quelli aggiunti
}

interface ColoreGrafica {
    bordo?: string;
    sfondo?: string;
}

export type { GraficaBase, IGrafica, TestoGrafica, ColoreGrafica, GraficaConColore };
