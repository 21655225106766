import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "rsuite";

import { Tomba } from "@models/Tomba";

import WithTooltip from "@hoc/withTooltip";

import { iconsFontAwesome } from "@helpers/FileHelper";

const BadgeWithTooltip = WithTooltip(Button);

export interface Icona {
    value: string;
    label: string;
    key: string;
}

const IconaNoteTomba = ({ tomba, showNote }: { tomba: Tomba; showNote?: boolean }) => {
    const findIcona = iconsFontAwesome && tomba?.icona ? iconsFontAwesome.find((d) => d.value === tomba.icona) : "";

    return (
        <>
            {findIcona && (
                <div
                    className={`${
                        showNote
                            ? "flex justify-center items-center"
                            : "border w-fit bg-primary-100 border-primary-400 text-primary-700 px-8 py-4 flex"
                    }`}
                >
                    {!showNote && <span>Icona attuale: </span>}
                    <FontAwesomeIcon icon={findIcona.icon as IconProp} className='w-[1.5rem]' />
                </div>
            )}

            {tomba.note && showNote && (
                <BadgeWithTooltip
                    message={tomba.note}
                    placement='rightStart'
                    appearance='ghost'
                    size='xs'
                    className='cursor-default'
                >
                    <span className='font-bold'>Note</span>
                </BadgeWithTooltip>
            )}
        </>
    );
};

export default IconaNoteTomba;
