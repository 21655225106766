import { Feature } from "ol";
import { Vector as VectorSource } from "ol/source";

function vector(features: Feature[]) {
    return new VectorSource({
        features,
    });
}

export default vector;
