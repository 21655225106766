import { faArrowsLeftRightToLine, faCheck, faEraser, faHashtag, faTextSize } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React, { useMemo } from "react";
import { Button } from "rsuite";
import * as Yup from "yup";

import { listLevels } from "@services/settings/cimiteri/LivelliService";

import { IStrutturaLivelli } from "@models/StrutturaLivelli";

import { useCruxRequest } from "@hooks/useCruxRequest";

import FormInput from "@components/Form/Input/FormInput";
import SelectInput from "@components/Form/Input/SelectInput";
import ToggleInput from "@components/Form/Input/ToggleInput";

import { SettoreDrawType } from "@openLayersMap/Hooks/DrawSettoreHook";

interface AddSettorePanelProps {
    hasDrawn: boolean;
    handleCancel: () => void;
    handleConfirm: (date: Partial<IStrutturaLivelli>) => void;
    drawType: SettoreDrawType;
    setDrawType: (SettoreDrawType: SettoreDrawType) => void;
    idLivelloPadre: number | undefined;
    isSaving: boolean;
}

export const settoreInitialValues = {
    idLivello: undefined,
    codice: "",
    descr: "",
    visualizzaDescr: false,
    visualizzaSuWeb: false,
};

const validationSchema = Yup.object().shape({
    idLivello: Yup.number().required(),
    codice: Yup.string().min(0).max(50).required(),
    descr: Yup.string().min(0).max(50),
    visualizzaDescr: Yup.boolean(),
});

const drawTypes: { id: SettoreDrawType; descr: string }[] = [
    { id: "rectangle", descr: "Rettangolo" },
    { id: "polygon", descr: "Mano libera" },
];

const AddSettorePanel = ({
    handleConfirm,
    handleCancel,
    hasDrawn,
    drawType,
    setDrawType,
    idLivelloPadre,
    isSaving,
}: AddSettorePanelProps) => {
    const { data: livelli } = useCruxRequest(listLevels, { cacheKey: "livelli" });

    const selectableLevels = useMemo(() => {
        if (livelli && idLivelloPadre) {
            return livelli?.filter((value: any) => value.id > idLivelloPadre);
        }
        return [];
    }, [livelli, idLivelloPadre]);

    return (
        <div>
            <Formik
                initialValues={settoreInitialValues}
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers) => {
                    handleConfirm(values);
                    formikHelpers.resetForm();
                }}
                onReset={handleCancel}
            >
                {({ handleSubmit, handleReset, isValid }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <ul className='space-y-8'>
                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span>Disegno</span>
                                    <SelectInput
                                        name='drawType'
                                        size='xs'
                                        searchable={false}
                                        cleanable={false}
                                        data={drawTypes || []}
                                        block
                                        placeholder='Tipo disegno'
                                        valueKey='id'
                                        labelKey='descr'
                                        menuClassName='z-20'
                                        className='flex-1'
                                        value={drawType}
                                        onChange={(value) => {
                                            setDrawType(value as SettoreDrawType);
                                        }}
                                    />
                                </li>

                                {hasDrawn && (
                                    <>
                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                            <span>Tipo</span>
                                            <SelectInput
                                                name='idLivello'
                                                size='xs'
                                                cleanable={false}
                                                searchable={false}
                                                data={selectableLevels || []}
                                                block
                                                placeholder='Seleziona tipo'
                                                valueKey='id'
                                                labelKey='descr'
                                                menuClassName='z-20'
                                                className='flex-1'
                                            />
                                        </li>

                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <FontAwesomeIcon icon={faHashtag} />
                                            <span>Codice</span>
                                            <FormInput
                                                size='xs'
                                                name='codice'
                                                minLength={0}
                                                maxLength={100}
                                                disabled={selectableLevels.length === 0}
                                            />
                                        </li>

                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <FontAwesomeIcon icon={faTextSize} />
                                            <span>Descrizione</span>
                                            <FormInput
                                                size='xs'
                                                name='descr'
                                                minLength={0}
                                                maxLength={100}
                                                disabled={selectableLevels.length === 0}
                                            />
                                        </li>

                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <span>Visualizza descrizione nella struttura</span>
                                            <ToggleInput
                                                size='sm'
                                                name='visualizzaDescr'
                                                disabled={selectableLevels.length === 0}
                                            />
                                        </li>
                                    </>
                                )}
                                {!hasDrawn && (
                                    <li className='cursor-pointer space-x-12 flex items-center'>
                                        <span>Disegna il settore sulla mappa</span>
                                    </li>
                                )}
                                <hr />
                                <li className='cursor-pointer space-x-12 flex justify-end w-full items-center'>
                                    <Button type='button' appearance='ghost' size='sm' onClick={handleReset}>
                                        <FontAwesomeIcon icon={faEraser} className='mr-1' />
                                        Cancella disegno
                                    </Button>

                                    {hasDrawn && (
                                        <Button
                                            type='submit'
                                            appearance='primary'
                                            size='sm'
                                            disabled={!isValid || !hasDrawn}
                                            loading={isSaving}
                                        >
                                            <FontAwesomeIcon icon={faCheck} className='mr-1' />
                                            Crea settore
                                        </Button>
                                    )}
                                </li>
                            </ul>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default AddSettorePanel;
