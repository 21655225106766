import { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
    actionSidenavTreeState,
    idCimiteroAttivoPersistState,
    idCimiteroAttivoState,
    idCimiteroTrasferimentoState,
} from "@stores/municipality";

import { CruxRoute } from "@models/Pathname";

export const useCruxNavigate = () => {
    const { pathname, search } = useLocation();
    const { idSettore, idTomba } = useParams();

    const navigate = useNavigate();

    const [idCimiteroAttivo, setIdCimiteroAttivo] = useRecoilState(idCimiteroAttivoState);
    const [idCimiteroTrasferimento, setIdCimiteroTrasferimento] = useRecoilState(idCimiteroTrasferimentoState);

    const idCimiteroAttivoPersist = useRecoilValue(idCimiteroAttivoPersistState);

    const setActionSidenavTree = useSetRecoilState(actionSidenavTreeState);

    const numberRouteKey = useMemo(() => {
        if (pathname && pathname.includes(CruxRoute.RICERCHE)) return "6";
        if (pathname && pathname.includes(CruxRoute.CIMITERO)) return "1";
        if (pathname && pathname.includes(CruxRoute.CONTRATTI)) return "2";
        if (pathname && pathname.includes(CruxRoute.CANONI)) return "3";
        if (pathname && pathname.includes(CruxRoute.TABELLE)) return "4";
        if (pathname && pathname.includes(CruxRoute.FATTURE)) return "5";

        return null;
    }, [pathname]);

    const isTabelle = useMemo(() => {
        return pathname.includes(CruxRoute.TABELLE);
    }, [pathname]);

    const isCimitero = useMemo(() => {
        return pathname.includes(CruxRoute.CIMITERO);
    }, [pathname]);

    const isTomba = useMemo(() => {
        return pathname.includes(CruxRoute.TOMBA);
    }, [pathname]);

    const isRicerche = useMemo(() => {
        return pathname.includes(CruxRoute.RICERCHE);
    }, [pathname]);

    const isGrafica = useMemo(() => {
        return (
            (pathname.includes(CruxRoute.CIMITERO) || pathname.includes(CruxRoute.SETTORE)) &&
            !pathname.includes(CruxRoute.TOMBA)
        );
    }, [pathname]);

    const useQuery = useMemo(() => new URLSearchParams(search), [search]);

    const cambioComune = () => {
        if (idCimiteroAttivo) navigate(`/cimitero/${idCimiteroAttivo}`);
        setActionSidenavTree(undefined);
    };

    const selezioneCimitero = (searchMode?: boolean) => {
        if (!searchMode && idCimiteroAttivo && !idSettore && !idTomba) navigate(`/cimitero/${idCimiteroAttivo}`);
        if (!searchMode && idCimiteroAttivo && idSettore && !idTomba)
            navigate(`/cimitero/${idCimiteroAttivo}/settore/${idSettore}`);
        if (!searchMode && idCimiteroAttivo && idSettore && idTomba)
            navigate(`/cimitero/${idCimiteroAttivo}/settore/${idSettore}/tomba/${idTomba}`);
        setActionSidenavTree(undefined);
    };

    const cimiteroVuoto = () => {
        navigate("/cimitero");
    };

    const navigazioneCimitero = (id: number) => {
        navigate(`/cimitero/${id}`);
    };

    const goBack = () => {
        navigate(-1);
    };

    return {
        numberRouteKey,
        isTabelle,
        isCimitero,
        isTomba,
        isRicerche,
        isGrafica,
        cambioComune,
        selezioneCimitero,
        idCimiteroAttivo,
        idCimiteroAttivoPersist,
        setIdCimiteroAttivo,
        cimiteroVuoto,
        navigazioneCimitero,
        goBack,
        useQuery,
        setIdCimiteroTrasferimento,
        idCimiteroTrasferimento,
    };
};
