import { ValueType } from "rsuite/esm/Checkbox";

import { ColoreGrafica } from "@models/Grafica";

import { Tomba as ITomba, PathItem } from "./Tomba";

interface ColoriConfig {
    tomba: TombaColoriConfig[];
    settore: ColoreGrafica;
    retino: ColoreGrafica;
    mappa: ColoreGrafica;
    aggiornaEsistente: boolean;
}

interface TombaColoriConfig {
    statoTomba: "O" | "L" | "P";
    conContratto: boolean;
    colore: ColoreGrafica;
}

interface VisibilityConfig {
    layers: ELayerToShow[] | ValueType[];
    datiTomba: EDatiTomba;
    iconeTomba: boolean;
    showSmallText: boolean;
    overflow: boolean;
}

enum ELayerToShow {
    Mappa = "mappa",
    Grid = "grid",
    Settore = "settore",
    Tomba = "tomba",
    Testo = "testo",
    Retino = "retino",
}

enum EDatiTomba {
    Numero = "numero",
    Defunto = "defunto",
    Contratto = "contratto",
}

enum EIconeDraw {
    LineString = "LineString",
    Polygon = "Polygon",
    Circle = "Circle",
    Rectangle = "Rectangle",
    Text = "Text",
    Point = "Point",
    Settore = "Settore",
    Tomba = "Tomba",
    GrigliaTombe = "GrigliaTombe",
    Seleziona = "Seleziona",
    Regular = "Regular",
    GeometryCollection = "GeometryCollection",
    Modifica = "Modifica",
    Vertice = "Vertice",
    Colore = "Colore",
    Elimina = "Elimina",
}

enum EButtonAction {
    SaveSettori = "SaveSettori",
    SaveTombe = "SaveTombe",
    SaveRetini = "SaveRetini",
    DeleteFeature = "DeleteFeature",
}

interface FromTomba {
    tomba: ITomba;
    colorConfig: ColoriConfig;
    visibilityConfig: VisibilityConfig;
    livelloRicerca?: PathItem;
    idTomba?: number;
}

export type { ColoriConfig, VisibilityConfig, TombaColoriConfig, FromTomba };

export { ELayerToShow, EDatiTomba, EIconeDraw, EButtonAction };
