import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
import { getRecoil, setRecoil } from "recoil-nexus";

import { refreshDirittiState } from "@stores/diritti";
import { showHistoricalDataState } from "@stores/globalUserSettings";

import { AuthUser } from "@models/AuthUser";
import { BaseResponse } from "@models/Responses/BaseResponse";

import history from "../HistoryService";
import {
    deleteAccessToken,
    getAccessToken,
    getCodiceTenant,
    getComuneId,
    getRefreshToken,
    setAccessToken,
    setComuneId,
    setRefreshToken,
} from "../auth/AccessTokenService";

interface RefreshToken {
    accessToken: string | null;
    refreshToken: string | null;
    idComune: number;
    codiceTenant: string | null;
}

export const apiRefreshToken = async ({ accessToken, refreshToken, idComune, codiceTenant }: RefreshToken) => {
    const { data: rs } = await axios.post<BaseResponse<AuthUser>>(
        `${process.env.REACT_APP_AUTH_BACKEND_URL}/auth/refresh`,
        {
            accessToken,
            refreshToken,
            idComune,
        },
        {
            headers: {
                CodiceTenant: codiceTenant ?? "",
            },
        }
    );
    if (rs.data.accessToken) setRecoil(refreshDirittiState, rs.data.accessToken);

    return rs;
};

const tryRefreshToken = async (axiosInstance: AxiosInstance, originalConfig: any) => {
    originalConfig._retry = true;

    try {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();
        const idComune = getComuneId();
        const codiceTenant = getCodiceTenant();

        const { data } = await apiRefreshToken({ accessToken, refreshToken, idComune, codiceTenant });

        setAccessToken(data.accessToken);
        setRefreshToken(data.refreshToken);
        setComuneId(data.comuneAttivo.id);

        return await axiosInstance(originalConfig);
    } catch (_error: any) {
        deleteAccessToken();
        history.replace({ pathname: "/login", search: "?refreshToken=expired" });
        return Promise.reject(_error);
    }
};

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = getAccessToken();
    const showHistoricalData = getRecoil<boolean>(showHistoricalDataState);

    if (config.headers) {
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        config.headers.DatiStorici = showHistoricalData;
    }

    config.paramsSerializer = (params) =>
        qs.stringify(params, {
            // encode: false,
            arrayFormat: "comma",
            skipNulls: true,
        });

    return config;
};

const onRequestError = (error: AxiosError, axiosInstance: AxiosInstance): Promise<AxiosError> => {
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse, axiosInstance: AxiosInstance): any => {
    /*const originalConfig: any = resultsCount.config;
    if (resultsCount.request.responseURL === "https://main-crux-staging.azurewebsites.net/api/tabelle/livelli" && !originalConfig._retry) {
        return tryRefreshToken(axiosInstance, originalConfig)
    }*/
    return response;
};

const onResponseError = async (error: any, axiosInstance: AxiosInstance) => {
    const originalConfig = error.config;
    if (error.response) {
        // Access Token was expired
        if (error.response.status === 401 && !originalConfig._retry && !!getAccessToken() && !!getRefreshToken()) {
            return tryRefreshToken(axiosInstance, originalConfig);
        }
    }
    return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
    axiosInstance.interceptors.request.use(onRequest, (error) => onRequestError(error, axiosInstance));
    axiosInstance.interceptors.response.use(
        (response) => onResponse(response, axiosInstance),
        (error) => onResponseError(error, axiosInstance)
    );
    return axiosInstance;
};
