import { atom } from "recoil";

import { persistRecoil } from "@stores/effects/persistRecoil";

import { EIconeDraw } from "@models/Configs";
import { IGrafica } from "@models/Grafica";

import { defaultModify } from "@openLayersMap/Helpers/EditMappingHelpers";
import { ITransformAndModify } from "@openLayersMap/Models/EditMapping";

const { persistAtom } = persistRecoil({ key: "recoil-persist-ol" });

const typeDrawState = atom<EIconeDraw | null>({
    key: "TypeDrawState",
    default: EIconeDraw.Seleziona,
});

const transformAndModifyState = atom<ITransformAndModify>({
    key: "transformAndModifyState",
    default: defaultModify,
});

const featuresEditState = atom<{
    [key: string]: IGrafica;
}>({
    key: "featuresEditState",
    default: undefined,
});

const editModeState = atom<boolean>({
    key: "editModeState",
    default: false,
});

const levelsModeState = atom<boolean>({
    key: "levelsModeState",
    default: false,
});

const retinoLatiState = atom<number>({
    key: "retinoLatiState",
    default: 4,
});

const mappaEsternaState = atom<boolean>({
    key: "mappaEsternaState",
    default: false,
});

export {
    typeDrawState,
    featuresEditState,
    editModeState,
    levelsModeState,
    retinoLatiState,
    transformAndModifyState,
    mappaEsternaState,
};
