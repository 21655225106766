import { api } from "@services/api/api";

import { ComuneGestito } from "@models/Comune";
import { BaseResponse } from "@models/Responses/BaseResponse";

const BASE_PATH = "/tabelle/comuni-gestiti";

const listComuniGestiti = async () => {
    return api.get<BaseResponse<ComuneGestito[]>>(BASE_PATH).then((res) => res.data);
};

const addComuneGestito = async (level: Partial<ComuneGestito>) => {
    return api.post<BaseResponse<ComuneGestito>>(BASE_PATH, level).then((res) => res.data?.data);
};

const updateComuneGestito = async (level: Partial<ComuneGestito>) => {
    return api
        .put<BaseResponse<ComuneGestito>>(`${BASE_PATH}/${level.id}`, {
            ...level,
        })
        .then((res) => res.data?.data);
};

const deleteComuneGestito = async (id: number) => {
    return api.delete(`${BASE_PATH}/${id}`).then((res) => res.data);
};

const addStemmaComuneGestito = async (id: number, file: File) => {
    return api
        .putForm(`${BASE_PATH}/${id}/stemma`, {
            file,
        })
        .then((res) => res.data);
};

export { addComuneGestito, listComuniGestiti, updateComuneGestito, deleteComuneGestito, addStemmaComuneGestito };
