import { Feature } from "ol";
import OLMap from "ol/Map";
import { Geometry } from "ol/geom";
import VectorSource from "ol/source/Vector";
import { Style } from "ol/style";
import { useSetRecoilState } from "recoil";
import { getRecoil } from "recoil-nexus";

import { cimiteroMapVisibilityConfigState } from "@stores/graveyard";

import { EDatiTomba } from "@models/Configs";
import { ELayer } from "@models/Grafica";

import { getDimensioneTestoTomba, getIcone, tombaText } from "@openLayersMap/Helpers/MappingHelpers";
import { IText } from "@openLayersMap/Models/EditMapping";
import { featuresEditState } from "@openLayersMap/Stores/editControls";

import {
    featureElement,
    featureLayer,
    featureTesto,
    findDrawFeature,
    mapFeature,
    saveNewPropertiesIntoFeature,
} from "../Helpers/EditMappingHelpers";
import { getBorderStyle, getFillStyle, getTextStyle } from "../Helpers/StyleHelper";

interface DrawHookProps {
    source?: VectorSource;
    map?: OLMap | null;
}

const useEditMappingHook = ({ source, map }: DrawHookProps) => {
    const setFeaturesEdit = useSetRecoilState(featuresEditState);

    const removeFeatures = (features: Feature<Geometry>[]): void => {
        features.forEach((feature) => {
            source?.removeFeature(feature);
        });
    };

    const updateFeature = ({
        feature,
        oldSource,
        fill,
        stroke,
        text,
    }: {
        feature: Feature<Geometry>;
        oldSource: VectorSource;
        fill?: string;
        stroke?: string;
        text?: IText;
    }): void => {
        const propertiesElement = featureElement(feature);
        const layer = featureLayer(feature);
        const getFeature = findDrawFeature({ feature, source: oldSource });
        const resolution = feature.get("resolution");
        const visibilityConfig = getRecoil(cimiteroMapVisibilityConfigState);

        if (getFeature && layer !== ELayer.Tomba && layer !== ELayer.Testo) {
            getFeature.setStyle((f: any, resolutionS: any) => {
                return new Style({
                    fill: fill ? getFillStyle(fill) : getFillStyle(propertiesElement?.colore?.sfondo),
                    stroke: stroke ? getBorderStyle(stroke, 1) : getBorderStyle(propertiesElement?.colore?.bordo, 1),
                    text: getTextStyle({
                        text: propertiesElement?.codice ? [propertiesElement.codice, ""] : [],
                        color: [0, 0, 0, 1],
                        align: "center",
                        resolution,
                        size: 30,
                        showOnlyLargerFont: !visibilityConfig.showSmallText,
                        rotation: 0,
                    }),
                });
            });

            getFeature.setProperties(saveNewPropertiesIntoFeature({ feature: getFeature, fill, stroke, text, layer }));
        }

        if (getFeature && layer === ELayer.Tomba) {
            getFeature.setStyle((f: any, resolutionS: any) => {
                const geometry = f.getGeometry();
                const geometries = geometry.getGeometries();

                const tombaStyle = new Style({
                    geometry: geometries,
                    fill: getFillStyle(propertiesElement?.colore?.sfondo),
                    stroke: getBorderStyle(propertiesElement?.colore?.bordo, 1),
                    text: getTextStyle({
                        text: [text?.val ?? "", "title"],
                        color: "black",
                        align: "center",
                        resolution,
                        size: getDimensioneTestoTomba(f) * (visibilityConfig.datiTomba === EDatiTomba.Numero ? 2 : 1),
                        showOnlyLargerFont: !visibilityConfig.showSmallText,
                        rotation: text?.rotation ?? 0,
                    }),
                });

                return [tombaStyle];
            });

            getFeature.setProperties(saveNewPropertiesIntoFeature({ feature: getFeature, text, layer: ELayer.Tomba }));
        }

        const mapF = mapFeature(getFeature);

        setFeaturesEdit((prev) => {
            return {
                ...prev,
                ...(getFeature && getFeature.get("editId") && { [getFeature.get("editId")]: mapF }),
                ...(getFeature && getFeature.get("id") && { [getFeature.get("id")]: mapF }),
            };
        });
    };

    const setNewFeature = (feature: Feature<Geometry>): void => {
        if (source) updateFeature({ feature, oldSource: source });
    };

    const setNewFill = (feature: Feature<Geometry>, color: string): void => {
        if (source) updateFeature({ feature, oldSource: source, fill: color });
    };

    const setNewStroke = (feature: Feature<Geometry>, color: string): void => {
        if (source) updateFeature({ feature, oldSource: source, stroke: color });
    };

    const setNewText = (feature: Feature<Geometry>, text: IText): void => {
        if (source) updateFeature({ feature, oldSource: source, text });
    };

    const setTextBox = (feature: Feature<Geometry>, text: IText, fillColor: string, strokeColor: string): void => {
        if (source) updateFeature({ feature, oldSource: source, text, fill: fillColor, stroke: strokeColor });
    };

    const setModify = (feature: Feature<Geometry>, text: IText): void => {
        if (source) updateFeature({ feature, oldSource: source, text });
    };

    const restoreFeature = (feature: Feature<Geometry>): void => {
        if (source) {
            const propertiesElement = featureElement(feature);
            const propertiesTesto = featureTesto(feature);
            const layer = featureLayer(feature);
            const resolution = feature.get("resolution");
            const visibilityConfig = getRecoil(cimiteroMapVisibilityConfigState);

            if (layer === ELayer.Testo) {
                feature.setStyle(
                    new Style({
                        text: getTextStyle({
                            text: [propertiesTesto?.val ?? "", ""],
                            color: propertiesElement?.colore?.sfondo ?? "black",
                            align: "left",
                            resolution,
                            size: propertiesTesto?.size,
                            rotation: propertiesTesto?.rot ?? 0,
                            showOnlyLargerFont: true,
                            limitTextBetweenRange: false,
                        }),
                    })
                );
            }

            if (layer !== ELayer.Tomba && layer !== ELayer.Testo) {
                feature.setStyle(
                    new Style({
                        stroke: getBorderStyle(
                            layer !== ELayer.Testo ? propertiesElement?.colore?.bordo : undefined,
                            1
                        ),
                        fill: getFillStyle(layer !== ELayer.Testo ? propertiesElement?.colore?.sfondo : undefined),
                        text: getTextStyle({
                            text: propertiesElement?.codice
                                ? [propertiesElement.codice, ""]
                                : [propertiesElement?.text?.val ?? "", ""],
                            color: [0, 0, 0, 1],
                            align: "center",
                            resolution,
                            size: 30,
                            showOnlyLargerFont: !visibilityConfig.showSmallText,
                            rotation: 0,
                        }),
                    })
                );
            }

            if (layer === ELayer.Tomba) {
                const value = tombaText(visibilityConfig, propertiesElement);

                feature.setStyle((f: any, resolutionTomba: any) => {
                    const geometry = f.getGeometry();
                    const geometries = geometry.getGeometries();
                    const icone: any[] = getIcone(
                        visibilityConfig,
                        propertiesElement,
                        geometries,
                        resolutionTomba,
                        feature
                    );

                    const tombaStyle = new Style({
                        geometry: geometries[0],
                        fill: getFillStyle(propertiesElement?.colore?.sfondo),
                        stroke: getBorderStyle(propertiesElement?.colore?.bordo, 1),
                        text: getTextStyle({
                            text: value,
                            color: "black",
                            align: "center",
                            resolution,
                            size:
                                getDimensioneTestoTomba(f) * (visibilityConfig.datiTomba === EDatiTomba.Numero ? 2 : 1),
                            showOnlyLargerFont: !visibilityConfig.showSmallText,
                            rotation: propertiesTesto?.rot ?? 0,
                        }),
                    });

                    return [tombaStyle, ...icone];
                });
            }
        }
    };

    return {
        removeFeatures,
        setNewFeature,
        setNewFill,
        setNewStroke,
        setNewText,
        setModify,
        setTextBox,
        restoreFeature,
    };
};

export default useEditMappingHook;
