import axios from "axios";

import { getCodiceTenant } from "@services/auth/AccessTokenService";

import { setupInterceptorsTo } from "./interceptors";

const codiceTenant = getCodiceTenant();

const api = setupInterceptorsTo(
    axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
);

const apiAnagrafeEsterna = setupInterceptorsTo(
    axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-api-key": "crB6zgM59ELXn2JUInR9OQPWOKuQFUYLTtaAuHyI6sM",
            codCat: process.env.REACT_APP_ANAGRAFE_ESTERNA === "true" ? "STG1" : codiceTenant ?? "",
        },
    })
);

export { api, apiAnagrafeEsterna };
