import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Form, Schema, Stack } from "rsuite";

import { useAuth } from "@context/AuthContext";

import FormField from "@components/Form/FormField";

type FormData = {
    username: string;
    password: string;
    codiceTenant?: string;
};

const LoginPage: FC = () => {
    const { login, isLoading } = useAuth();
    const navigate = useNavigate();
    const version = process.env.REACT_APP_VERSION;

    const model = Schema.Model<FormData>({
        username: Schema.Types.StringType().isRequired("Inserisci un nome utente."),
        password: Schema.Types.StringType().isRequired("Inserisci la tua password."),
        codiceTenant: Schema.Types.StringType(),
    });

    const [formValues, setFormValues] = useState<any>({
        username: localStorage.getItem("username") ?? "",
        password: "",
        codiceTenant: "",
    });

    const handleSubmit = () => {
        login(formValues.username, formValues.password, formValues.codiceTenant);
    };

    return (
        <Form
            fluid
            model={model}
            formValue={formValues}
            onChange={setFormValues}
            checkTrigger='blur'
            className='h-full relative block'
        >
            <Avatar className='mb-12 bg-sky-900 w-fit p-4'>Crux 10</Avatar>
            <h3 className='mb-12 text-md'>Accedi a Crux 10</h3>

            <FormField name='username' label='Nome utente' />
            <FormField type='password' name='password' label='Password' />
            {process.env.REACT_APP_CODICETENANT === "true" && (
                <FormField name='codiceTenant' label='Codice tenant del comune da testare' />
            )}
            <Stack alignItems='center' justifyContent='space-between' direction='row' className='w-full' spacing={30}>
                <Button appearance='primary' type='submit' onClick={handleSubmit} loading={isLoading}>
                    Accedi
                </Button>
                <Button appearance='link' onClick={() => navigate("/recupera-password")}>
                    Password dimenticata?
                </Button>
            </Stack>

            <p className='mb-12 text-sm absolute bottom-8 right-8'>v.{version}</p>
        </Form>
    );
};

export default LoginPage;
