import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "rsuite";
import BreadcrumbItem from "rsuite/esm/Breadcrumb/BreadcrumbItem";

import { SettoreCimitero } from "@models/SettoreCimitero";

import { useCruxNavigate } from "@hooks/useCruxNavigate";

interface BreadcrumbSettoriProps {
    settore: SettoreCimitero | undefined;
    idCimitero?: string;
}

const BreadcrumbWrapper: FC<{ children?: ReactNode }> = ({ children }) => {
    return (
        <Breadcrumb
            separator='>'
            className='text-primary-400 text-xs mb-0 flex items-center uppercase font-medium breadcrumb'
        >
            {children}
        </Breadcrumb>
    );
};

const BreadcrumbSettori: FC<BreadcrumbSettoriProps> = ({ settore }) => {
    const { useQuery } = useCruxNavigate();
    const uiCruxWeb = useQuery.get("preview");

    if (!settore) return <></>;

    if (!settore.settoriPadre || settore.settoriPadre.length === 0) {
        return (
            <BreadcrumbWrapper>
                <BreadcrumbItem
                    as={Link}
                    to={`/cimitero/${settore.id}`}
                    className={uiCruxWeb ? "pointer-events-none" : "pointer-events-auto"}
                >
                    {settore.descrCompleta}
                </BreadcrumbItem>
            </BreadcrumbWrapper>
        );
    }

    const [cimitero, ...altriGenitori] = settore.settoriPadre;

    return (
        <>
            <BreadcrumbWrapper>
                {cimitero && (
                    <BreadcrumbItem
                        as={Link}
                        to={`/cimitero/${cimitero.id}`}
                        className={uiCruxWeb ? "pointer-events-none" : "pointer-events-auto"}
                    >
                        {cimitero.descrCompleta}
                    </BreadcrumbItem>
                )}
                {altriGenitori &&
                    altriGenitori.map((genitore) => (
                        <BreadcrumbItem
                            key={genitore.id}
                            as={Link}
                            to={`/cimitero/${cimitero.id}/settore/${genitore.id}`}
                            className={uiCruxWeb ? "pointer-events-none" : "pointer-events-auto"}
                        >
                            {genitore.descrCompleta}
                        </BreadcrumbItem>
                    ))}
                <BreadcrumbItem
                    as={Link}
                    to={`/cimitero/${cimitero.id}/settore/${settore.id}`}
                    className={uiCruxWeb ? "pointer-events-none" : "pointer-events-auto"}
                >
                    {settore.descrCompleta}
                </BreadcrumbItem>
            </BreadcrumbWrapper>
        </>
    );
};

export default BreadcrumbSettori;
