import { api } from "@services/api/api";

import { AuthUser, IChangePassword, IResetPassword, ITeam } from "@models/AuthUser";
import { BaseResponse } from "@models/Responses/BaseResponse";

const BASE_PATH = process.env.REACT_APP_AUTH_BACKEND_URL;

const LOGIN_URL = `${BASE_PATH}/auth/login`;
const LOGOUT_URL = `${BASE_PATH}/auth/logout`;
const USER_URL = `${BASE_PATH}/auth/user`;
const RESET_PASSWORD_URL = `${BASE_PATH}/auth/reset-password`;
const RESET_PASSWORD_TOKEN = `${BASE_PATH}/auth/reset-password-token`;
const RESET_TEST = `${BASE_PATH}/auth/reset-utente`;
const GET_USERS = `${BASE_PATH}/auth/users`;
const UPDATE_USER_AND_DIRITTI = `${BASE_PATH}/auth/diritti`;
const CHANGE_PASSWORD = `${BASE_PATH}/auth/change-password`;

const login = async (username: string, password: string, codiceTenant?: string) => {
    return api
        .post<BaseResponse<AuthUser>>(
            LOGIN_URL,
            {
                username,
                password,
            },
            {
                headers: {
                    CodiceTenant: codiceTenant ?? "",
                },
            }
        )
        .then((res) => res.data);
};

const logout = async () => {
    return api.post(LOGOUT_URL);
};

const authUser = async () => {
    return api.get<BaseResponse<AuthUser>>(USER_URL).then((res) => res.data);
};

const updateUser = async ({ user }: { user: Partial<ITeam> }) => {
    return api
        .put<BaseResponse<AuthUser>>(user.id ? `${BASE_PATH}/auth/user/${user.id}` : `${BASE_PATH}/auth/user`, {
            ...user,
        })
        .then((res) => res.data);
};

const signUp = async (user: Partial<ITeam>) => {
    return api
        .post<BaseResponse<unknown>>(USER_URL, {
            ...user,
        })
        .then((res) => res.data);
};

const resetPasswordUser = async ({ username, token, password, confirmPassword }: IResetPassword) => {
    return api
        .post<BaseResponse<unknown>>(RESET_PASSWORD_URL, {
            username,
            token,
            password,
            confirmPassword,
        })
        .then((res) => res.data);
};

const changePasswordUser = async ({ oldPassword, password, confirmPassword, idUtente }: IChangePassword) => {
    return api
        .put<BaseResponse<unknown>>(idUtente ? `${BASE_PATH}/auth/change-password/${idUtente}` : CHANGE_PASSWORD, {
            oldPassword,
            password,
            confirmPassword,
        })
        .then((res) => res.data);
};

const resetTest = async () => {
    return api.post<BaseResponse<unknown>>(RESET_TEST, {}).then((res) => res.data);
};

const getToken = async ({ username }: { username: string }) => {
    return api.get<BaseResponse<string>>(`${RESET_PASSWORD_TOKEN}?userName=${username}`).then((res) => res.data);
};

const getTest = async () => {
    return api.get(`${BASE_PATH}/test/test3`).then((res) => res.data.data);
};

const getUsers = async () => {
    return api.get<BaseResponse<ITeam[]>>(GET_USERS).then((res) => res.data);
};

const updateUserAndDiritti = async ({ user }: { user: Partial<ITeam> }) => {
    return api.put<BaseResponse<ITeam>>(UPDATE_USER_AND_DIRITTI, { ...user }).then((res) => res.data);
};

const deleteUser = async ({ idUtente }: { idUtente: number }) => {
    return api.delete<BaseResponse<ITeam>>(`${BASE_PATH}/auth/${idUtente}`).then((res) => res.data);
};

export {
    login,
    logout,
    authUser,
    updateUser,
    resetTest,
    signUp,
    resetPasswordUser,
    changePasswordUser,
    getToken,
    getTest,
    getUsers,
    updateUserAndDiritti,
    deleteUser,
};
