import { api } from "@services/api/api";

import { ColoriConfig } from "@models/Configs";
import { BaseResponse } from "@models/Responses/BaseResponse";

const BASE_PATH = "/config";

type PostelConfig = {
    stampaIBAN: boolean;
    intestazioneBollettino: string[];
    provider?: number;
};

const getColoriConfig = async () => {
    return api.get<BaseResponse<ColoriConfig>>(`${BASE_PATH}/colori`).then((res) => res.data?.data);
};

const updateColoriConfig = async (data: ColoriConfig) => {
    return api
        .put<BaseResponse<ColoriConfig>>(
            `${BASE_PATH}/colori?aggiornaEsistente=${data.aggiornaEsistente ?? true}`,
            data
        )
        .then((res) => res.data?.data);
};

const getPostelConfig = async () => {
    return api.get<BaseResponse<PostelConfig>>(`${BASE_PATH}/export/postel`).then((res) => res.data?.data);
};

export type { PostelConfig };
export { getColoriConfig, updateColoriConfig, getPostelConfig };
