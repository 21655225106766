import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { Draw } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import { Style } from "ol/style";
import { useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { colorConfigState } from "@stores/globalUserSettings";

import { EIconeDraw } from "@models/Configs";

import { useMap } from "@openLayersMap/Map/MapContext";
import { featuresEditState, typeDrawState } from "@openLayersMap/Stores/editControls";

import { findDrawFeature, getDrawRetinoProperties, mapFeature } from "../Helpers/EditMappingHelpers";
import { getBorderStyle, getFillStyle } from "../Helpers/StyleHelper";

interface DrawHookProps {
    source: VectorSource;
    idStruttura: number;
    layer: number;
}

const shouldReactWith = [EIconeDraw.LineString, EIconeDraw.Polygon, EIconeDraw.Circle];

const useDrawRetinoHook = ({ source, idStruttura, layer }: DrawHookProps) => {
    const { map } = useMap();
    const colorConfig = useRecoilValue(colorConfigState);
    const typeDraw = useRecoilValue(typeDrawState);
    const setFeaturesEdit = useSetRecoilState(featuresEditState);
    const [finishDrawRetino, setFinishDrawRetino] = useState<Feature<Geometry> | null>(null);

    const featureStyle = useMemo(() => {
        return new Style({
            fill: getFillStyle(colorConfig?.retino?.sfondo),
            stroke: getBorderStyle(colorConfig?.retino?.bordo, 1),
        });
    }, [colorConfig]);

    const drawRetino = new Draw({
        source,
        type: typeDraw && shouldReactWith.includes(typeDraw) ? (typeDraw as any) : EIconeDraw.Polygon,
    });

    const destroyDrawRetino = () => {
        map?.removeInteraction(drawRetino);
    };

    const initDrawRetino = () => {
        if (!map) return;

        if (typeDraw && shouldReactWith.includes(typeDraw)) {
            map.addInteraction(drawRetino);

            drawRetino.on("drawend", (event) => {
                const { feature } = event;

                feature.setStyle(featureStyle);
                feature.setProperties(getDrawRetinoProperties(feature, layer, idStruttura));

                /** update source */
                setTimeout(() => {
                    if (feature && !feature.getId()) {
                        const getFeature = findDrawFeature({ feature, source });
                        setFinishDrawRetino(getFeature);

                        const mapF = mapFeature(getFeature);

                        setFeaturesEdit((prev) => {
                            return {
                                ...prev,
                                ...(getFeature && getFeature.get("editId") && { [getFeature.get("editId")]: mapF }),
                            };
                        });
                    }
                }, 250);
            });
        }
    };

    return {
        initDrawRetino,
        destroyDrawRetino,
        drawRetino,
        finishDrawRetino,
    };
};

export default useDrawRetinoHook;
