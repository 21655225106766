import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const trasportiSettingsRoutes: RouteObject[] = [
    {
        path: "luoghi",
        index: true,
        element: lazyLoadRoutes("luoghiIndex"),
    },
];

export default trasportiSettingsRoutes;
