import { ETipo } from "@models/TipoTomba";
import { EStato, IPostoTomba, IPostoTombaExtended, Tomba } from "@models/Tomba";

const postiLiberi = (posti: IPostoTomba[], postoTomba: Partial<IPostoTombaExtended> | null): IPostoTomba[] => {
    return posti
        .filter((value) => {
            return (
                value.stato === EStato.Libero ||
                (value.anagrafica?.id === postoTomba?.anagrafica?.id && value.stato === EStato.Prenotato)
            );
        })
        .map((p) => {
            return {
                ...p,
                postoTomba,
            };
        });
};

const tombaPostiLiberi = (tomba: Tomba, postoTomba: Partial<IPostoTombaExtended> | null): IPostoTomba[] => {
    return postiLiberi(tomba.posti, postoTomba);
};

const postiSalma = (tomba: Tomba | undefined): IPostoTomba[] => {
    return tomba ? tomba?.posti.filter((value) => value.tipo === ETipo.Salma && !value.storico) : [];
};

const postiResto = (tomba: Tomba | undefined): IPostoTomba[] => {
    return tomba ? tomba?.posti.filter((value) => value.tipo === ETipo.Resto && !value.storico) : [];
};

const postoOrigine = (postoTombaSelected: Partial<IPostoTombaExtended> | null): number | undefined => {
    if (postoTombaSelected?.provenienza && !postoTombaSelected?.destinazione)
        return postoTombaSelected.provenienza.posto.id;
    if (postoTombaSelected?.destinazione && !postoTombaSelected?.provenienza) return postoTombaSelected.id;
    if (postoTombaSelected?.provenienza && postoTombaSelected?.destinazione) return postoTombaSelected.id;

    return undefined;
};

const tombaOrigine = (
    postoTombaSelected: Partial<IPostoTombaExtended> | null,
    idTomba: string | undefined
): number | undefined => {
    if (postoTombaSelected?.provenienza && !postoTombaSelected?.destinazione)
        return postoTombaSelected.provenienza.tomba.id;
    if (postoTombaSelected?.destinazione && !postoTombaSelected?.provenienza && idTomba) return +idTomba;
    if (postoTombaSelected?.provenienza && postoTombaSelected?.destinazione)
        return postoTombaSelected.provenienza.tomba.id;

    return undefined;
};

const ordinaPosti = (tomba: Tomba | undefined, historicalData: boolean) => {
    return tomba?.posti
        .filter((value) => (historicalData ? value : !value.storico))
        .sort((a, b) => Number(a.storico) - Number(b.storico));
};

export { postiLiberi, tombaPostiLiberi, postiSalma, postiResto, ordinaPosti, postoOrigine, tombaOrigine };
