/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowPointer, faPalette, faPenToSquare, faTrash, faVectorPolygon } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EIconeDraw } from "@models/Configs";

interface IconsPros {
    handleDrawControl: (id: EIconeDraw) => void;
    selectedButton: { id: string | null };
}

const EditIcons = ({ handleDrawControl, selectedButton }: IconsPros) => {
    return (
        <div className='grid grid-cols-3 gap-8'>
            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Seleziona ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Seleziona)}
            >
                <FontAwesomeIcon icon={faArrowPointer as IconProp} size='xl' title='seleziona' cursor='pointer' />
                <p className='text-primary-500 text-center'>Seleziona</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Modifica ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Modifica)}
            >
                <FontAwesomeIcon icon={faPenToSquare as IconProp} size='xl' title='modifica' cursor='pointer' />
                <p className='text-primary-500 text-center'>Modifica</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Vertice ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Vertice)}
            >
                <FontAwesomeIcon icon={faVectorPolygon as IconProp} size='xl' title='vertice' cursor='pointer' />
                <p className='text-primary-500 text-center'>Vertice</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Colore ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Colore)}
            >
                <FontAwesomeIcon icon={faPalette as IconProp} size='xl' title='colore' cursor='pointer' />
                <p className='text-primary-500 text-center'>Colore</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Elimina ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Elimina)}
            >
                <FontAwesomeIcon icon={faTrash as IconProp} size='xl' title='elimina' cursor='pointer' />
                <p className='text-primary-500 text-center'>Elimina</p>
            </div>
        </div>
    );
};
export default EditIcons;
