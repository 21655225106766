import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFileContract } from "@fortawesome/pro-light-svg-icons";
import { faFileSignature, faFileXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compareAsc } from "date-fns";
import React, { useMemo } from "react";
import { IconButton, Whisper } from "rsuite";

import { IContrattoTombaRow } from "@modules/cimiteri/pages/Models/tombaCard";

import { useCruxNavigate } from "@hooks/useCruxNavigate";
import { useExternalTab } from "@hooks/useExternalTab";

import AddressFormat from "@components/Display/AddressFormat";
import DateFormat from "@components/Display/DateFormat";

import ContrattoBadge from "./ContrattoBadge";
import ContrattoCollegato from "./ContrattoCollegato";

const ContrattoTombaRow = ({ contrattoTomba, searchMode, buttonAction }: IContrattoTombaRow) => {
    const { renderSubmenu } = useExternalTab({ deps: contrattoTomba, to: `/contratti/${contrattoTomba?.id}` });
    const { useQuery } = useCruxNavigate();

    const uiPreview = useQuery.get("preview");
    const checkScadenza = contrattoTomba?.dataScadenza
        ? compareAsc(new Date(contrattoTomba.dataScadenza), new Date()) < 0
        : undefined;

    const fontIconValue = useMemo(() => {
        if (contrattoTomba?.storico && !contrattoTomba?.dataAnnullamento)
            return { text: "Contratto storico", icon: faFileXmark };
        if (contrattoTomba?.dataAnnullamento) return { text: "Contratto annullato", icon: faFileXmark };
        if (checkScadenza && !contrattoTomba?.dataAnnullamento) return { text: "Contratto scaduto", icon: faFileXmark };
        return { text: "Contratto attivo", icon: faFileSignature };
    }, [contrattoTomba]);

    return (
        <div className='flex w-full gap-x-2 items-start p-2 border'>
            <div className='mt-0'>
                <FontAwesomeIcon
                    size='lg'
                    className='text-primary-600'
                    title={fontIconValue.text}
                    icon={fontIconValue.icon}
                />
            </div>
            <div className='flex w-full flex-col space-y-4'>
                <ContrattoBadge contract={contrattoTomba} />

                <div className='space-x-4 flex'>
                    <span className='font-bold text-primary-400'>
                        {contrattoTomba.numero}/{contrattoTomba.anno}
                    </span>
                    {contrattoTomba.data && (
                        <span>
                            del <DateFormat value={contrattoTomba.data} />
                        </span>
                    )}
                    {contrattoTomba.dataInizio && (
                        <span>
                            - <span className='text-xs'>Dal</span>{" "}
                            {contrattoTomba.dataInizio && (
                                <span className='font-bold'>
                                    <DateFormat value={contrattoTomba.dataInizio} />
                                </span>
                            )}
                        </span>
                    )}

                    {contrattoTomba.dataScadenza && (
                        <span>
                            <span className='text-xs'>al</span>{" "}
                            {contrattoTomba.dataScadenza && (
                                <span className='font-bold'>
                                    <DateFormat value={contrattoTomba.dataScadenza} />
                                </span>
                            )}
                        </span>
                    )}
                </div>

                {contrattoTomba.richiedenti?.map((richiedente) => (
                    <ul key={richiedente.anagrafica.cognome} className='list-disc list-inside w-full'>
                        <li className='w-full'>
                            {richiedente.anagrafica.cognome} {richiedente.anagrafica.nome}{" "}
                            {richiedente.anagrafica.codFisc && (
                                <span className='uppercase'>({richiedente.anagrafica.codFisc})</span>
                            )}
                            {richiedente.anagrafica.residenza && (
                                <AddressFormat indirizzo={richiedente.anagrafica.residenza} prefix='-' />
                            )}
                        </li>
                    </ul>
                ))}

                {contrattoTomba.dataScadenza && (
                    <p className='text-xs font-bold'>
                        Data scadenza: <DateFormat value={contrattoTomba.dataScadenza} />
                    </p>
                )}

                {contrattoTomba.dataAnnullamento && (
                    <p className='text-xs font-bold'>
                        Data annullamento: <DateFormat value={contrattoTomba.dataAnnullamento} />
                    </p>
                )}

                {contrattoTomba?.precedente && (
                    <div className='text-xs font-medium w-full space-x-8'>
                        Precedente{" "}
                        <b>
                            {contrattoTomba?.precedente?.numero}/{contrattoTomba?.precedente?.anno}
                        </b>
                        {contrattoTomba?.precedente?.data && (
                            <>
                                {" "}
                                del{" "}
                                <span className='font-bold'>
                                    <DateFormat value={contrattoTomba?.precedente?.data} />
                                </span>
                            </>
                        )}
                        {!searchMode && buttonAction && contrattoTomba?.precedente?.id && (
                            <ContrattoCollegato idContrattoCollegato={contrattoTomba?.precedente?.id} />
                        )}
                    </div>
                )}

                {contrattoTomba?.successivo && (
                    <div className='text-xs font-medium w-full space-x-8'>
                        Successivo{" "}
                        <b>
                            {contrattoTomba?.successivo?.numero}/{contrattoTomba?.successivo?.anno}
                        </b>
                        {contrattoTomba?.successivo?.data && (
                            <>
                                {" "}
                                del{" "}
                                <span className='font-bold'>
                                    <DateFormat value={contrattoTomba?.successivo?.data} />
                                </span>
                            </>
                        )}
                        {!searchMode && buttonAction && contrattoTomba?.successivo?.id && (
                            <ContrattoCollegato idContrattoCollegato={contrattoTomba?.successivo?.id} />
                        )}
                    </div>
                )}
            </div>

            {!searchMode && buttonAction && !uiPreview && (
                <Whisper placement='autoVerticalEnd' trigger='click' speaker={renderSubmenu}>
                    <IconButton
                        icon={<FontAwesomeIcon icon={faFileContract as IconProp} />}
                        title='Apri contratto'
                        size='xs'
                        appearance='ghost'
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                </Whisper>
            )}
        </div>
    );
};

export default ContrattoTombaRow;
