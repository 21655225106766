import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const canoniRoutes: RouteObject = {
    element: <Outlet />,
    children: [
        {
            path: "",
            element: lazyLoadRoutes("canoniContainer"),
            children: [
                {
                    path: "",
                    index: true,
                    element: lazyLoadRoutes("canoniIndex"),
                },
                {
                    path: ":id",
                    element: lazyLoadRoutes("canoniShow"),
                },
            ],
        },
        {
            path: "massive-edit",
            element: lazyLoadRoutes("canoniMassiveEdit"),
        },
        {
            path: "massive-copy",
            element: lazyLoadRoutes("canoniMassiveCopy"),
        },
    ],
};

export default canoniRoutes;
