import { useRequest } from "ahooks";
import { unionBy } from "lodash";
import { Feature } from "ol";
import { Extent } from "ol/extent";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { deleteGrafica, getGrafica } from "@services/CimiteriService";

import { comuneAttivoState } from "@stores/geographicLists";
import { colorConfigState } from "@stores/globalUserSettings";
import { cimiteroMapVisibilityConfigState } from "@stores/graveyard";

import { EButtonAction, ELayerToShow } from "@models/Configs";
import { IGrafica } from "@models/Grafica";
import { SettoreCimitero } from "@models/SettoreCimitero";
import { Tomba } from "@models/Tomba";

import { useCruxRequest } from "@hooks/useCruxRequest";

import DrawerFiles from "@components/Drawers/Files/DrawerFiles";
import ConfirmModal from "@components/Modal/ConfirmModal";

import { Controls } from "@openLayersMap/Controls";
import BreadcrumbControl from "@openLayersMap/Controls/BreadcrumbControl";
import DrawPanels from "@openLayersMap/Controls/DrawPanels";
import VisibilityConfigControl from "@openLayersMap/Controls/VisibilityConfigControl";
import { featureTesto } from "@openLayersMap/Helpers/EditMappingHelpers";
import { getDimensioneTestoTomba } from "@openLayersMap/Helpers/MappingHelpers";
import { printMap, saveMap } from "@openLayersMap/Helpers/PrintHelpers";
import useClickHook from "@openLayersMap/Hooks/ClickHook";
import useFeatureMapping from "@openLayersMap/Hooks/MappingHook";
import { TileLayer } from "@openLayersMap/Layers";
import EditLayer from "@openLayersMap/Layers/EditLayer";
import GraticuleLayer from "@openLayersMap/Layers/GraticuleLayer";
import Layers from "@openLayersMap/Layers/Layers";
import VectorLayers from "@openLayersMap/Layers/VectorLayers";
import Map, { RefMap } from "@openLayersMap/Map/Map";
import { useMap } from "@openLayersMap/Map/MapContext";
import { FontSize, IModal } from "@openLayersMap/Models/EditMapping";
import { osm, vector } from "@openLayersMap/Source";
import { editModeState, featuresEditState } from "@openLayersMap/Stores/editControls";

import { SettoreSubViewProps } from "../Models/settori";

const GraphicView: FC<SettoreSubViewProps> = ({
    settore,
    runSettore,
    tombe,
    grafiche,
    loadingSettore,
    loadingTombe,
    loadingRetini,
    onSelectSettore,
    onSelectTomba,
    cruxWebMap = false,
    trasferimentoMap = false,
    graficaMap = false,
    livelloRicerca,
    idTomba,
    handleReload,
    loadingMap,
}) => {
    const { loading: loadingGrafiche, run: runGrafiche } = useRequest(getGrafica, { manual: true });
    const [innerSettori, setInnerSettori] = useState<SettoreCimitero[]>([]);
    const [innerTombe, setInnerTombe] = useState<Tomba[]>([]);
    const [innerRetini, setInnerRetini] = useState<IGrafica[]>([]);
    const handleReloadDelete = () => {
        setInnerTombe([]);
        setInnerSettori([]);
        runGrafiche(settore.id, [3, 4]);
        if (runSettore) runSettore(settore.id, true);
    };

    const { loading: loadingDelete, run: runDeleteGrafiche } = useCruxRequest(deleteGrafica, {
        manual: true,
        onSuccess: () => handleReloadDelete(),
    });

    const mapRef = useRef<RefMap>(null);
    const { idCimitero } = useParams();
    const { map } = useMap();
    const { pathname } = useLocation();

    const colorConfig = useRecoilValue(colorConfigState);
    const comuneAttivo = useRecoilValue(comuneAttivoState);

    const [editMode, setEditMode] = useRecoilState(editModeState);
    const setFeaturesEdit = useSetRecoilState(featuresEditState);
    const [visibilityConfig, setVisibilityConfig] = useRecoilState(cimiteroMapVisibilityConfigState);
    const [stateAction, setStateAction] = useState<EButtonAction | null>(null);
    const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);
    const [transformFeature, setTransformFeature] = useState<Feature | null>(null);
    const [fontSize, setFontSize] = useState<FontSize>({
        size: undefined,
        equal: false,
        max: true,
    });
    const [modal, setModal] = useState<IModal>({
        visible: false,
        message: "",
    });
    const [isManageFile, setIsManageFile] = useState(false);
    const [updateFiles, setUpdateFiles] = useState(false);

    const initialViewExtent = useMemo(() => {
        const latestExtent = JSON.parse(localStorage.getItem("latestExtent") ?? "{}");

        return latestExtent?.settore === settore.id ? latestExtent.extent : undefined;
    }, [settore]);

    useEffect(() => {
        setInnerSettori(settore.settori ?? []);
    }, [settore]);

    useEffect(() => {
        setInnerTombe(tombe ?? []);
    }, [tombe]);

    useEffect(() => {
        if (grafiche) setInnerRetini(grafiche);
    }, [grafiche]);

    useEffect(() => {
        setEditMode(false);
    }, [pathname]);

    const isLoading = loadingGrafiche || loadingSettore || loadingTombe || loadingRetini || loadingDelete;
    const fullMap = !cruxWebMap && !trasferimentoMap;

    const { featuresSettori, featuresTombe, featuresLinee, featuresTesti, featuresEdit, extent, navigationSource } =
        useFeatureMapping({
            tombe: innerTombe,
            settori: innerSettori,
            grafiche: innerRetini,
            colorConfig,
            visibilityConfig,
            isEditMode: editMode,
            livelloRicerca,
            idTomba,
            idStruttura: settore.id,
        });

    const source = useMemo(() => {
        return vector(featuresEdit);
    }, [featuresEdit]);

    const { initClick, destroyClick, clickCoordinates, setClickCoordinates } = useClickHook();

    const handleForceFit = () => {
        mapRef?.current?.forceFit();
    };

    const handleOnMoved = (ext: Extent) => {
        localStorage.setItem("latestExtent", JSON.stringify({ extent: ext, settore: settore.id }));
    };

    const handleOnSettoreAdded = (settoreAggiunto: SettoreCimitero) => {
        setInnerSettori((prev) => [...prev, settoreAggiunto]);
        setStateAction(EButtonAction.SaveSettori);
    };

    const handleOnTombaAdded = (tombeAggiunte: Tomba[]) => {
        setInnerTombe((prev) => unionBy(tombeAggiunte, prev, "id"));
        setStateAction(EButtonAction.SaveTombe);
    };

    const handleOnRetinoCreated = (retiniAggiunti: IGrafica[]) => {
        runGrafiche(settore.id);
        setInnerRetini((prev) => unionBy(retiniAggiunti, prev, "id"));
        setStateAction(EButtonAction.SaveRetini);
    };

    const handleOnReloadMap = () => {
        handleReloadDelete();
    };

    const handleDeleteGrafica = (idFeatures: number[]) => {
        runDeleteGrafiche({ idSettore: settore.id, idGrafiche: idFeatures });
        setStateAction(EButtonAction.DeleteFeature);
    };

    const handleChangePage = (): void => {
        setEditMode(!editMode);
        setFeaturesEdit({});
        setModal({
            visible: false,
            message: "",
        });

        setVisibilityConfig({
            ...visibilityConfig,
            layers: !editMode
                ? [ELayerToShow.Settore, ELayerToShow.Tomba, ELayerToShow.Testo, ELayerToShow.Retino, ELayerToShow.Grid]
                : [ELayerToShow.Settore, ELayerToShow.Tomba, ELayerToShow.Testo, ELayerToShow.Retino],
        });

        if (handleReload) handleReload();
    };

    const handleClose = (): void => {
        setModal({
            visible: false,
            message: "",
        });
    };

    const handlePrint = () => {
        const canvasMap = document.querySelector("canvas");
        if (canvasMap) {
            printMap(canvasMap, cruxWebMap);
        }
    };

    const handleSave = () => {
        const canvasMap = document.querySelector("canvas");
        if (canvasMap) {
            saveMap(canvasMap, comuneAttivo, cruxWebMap);
        }
    };

    const getFontSize = () => {
        if (map) {
            setFontSize((prev) => ({ ...prev, size: undefined, equal: false }));
            let arraySize: number[] = [];

            map.getAllLayers().forEach((layer) => {
                const sourceL = layer.getSource();
                (sourceL as any)?.getFeatures().forEach((f: any) => {
                    const properties = featureTesto(f);
                    const geometry = f.getGeometry();
                    if (!properties.size && properties?.val && geometry) {
                        /* const extentTest = geometry.getExtent();
                        const width = extentTest[2] - extentTest[0];
                        const height = extentTest[3] - extentTest[1]; 
                        Math.sqrt(width * width + height * height); */
                        const featureSize = getDimensioneTestoTomba(f);
                        arraySize.push(featureSize);
                    } else {
                        arraySize.push(properties.size ?? undefined);
                    }
                });
            });

            const filteredArray = arraySize.filter((value) => value).map((size) => (size >= 200 ? 50 : size));
            if (filteredArray.length > 0) {
                const equal = filteredArray.every((x) => x === filteredArray[0]);
                const maxValue = fontSize.max ? Math.max(...filteredArray) : Math.min(...filteredArray);
                const parseString = parseInt(maxValue.toString(), 10);
                setFontSize((prev) => {
                    return {
                        ...prev,
                        size: parseString !== 0 ? parseString : 12,
                        equal,
                    };
                });
                arraySize = [];
            }
        }
    };

    useEffect(() => {
        getFontSize();
    }, [map, fontSize.max]);

    useEffect(() => {
        if (!cruxWebMap) initClick();
        if (editMode) destroyClick();
        return () => {
            destroyClick();
        };
    }, [map, editMode]);

    useEffect(() => {
        if (cruxWebMap || trasferimentoMap)
            setTimeout(() => {
                handleForceFit();
            }, 500);

        if (map && !cruxWebMap) handleForceFit();
    }, [map, cruxWebMap, trasferimentoMap]);

    useEffect(() => {
        if (loadingMap) loadingMap({ loading: isLoading, idSettore: settore.id });
    }, [isLoading, settore]);

    return (
        <>
            <ConfirmModal
                isOpen={modal.visible}
                message={modal.message}
                handleConfirm={handleChangePage}
                handleClose={handleClose}
            />

            {!isLoading && (
                <Map
                    ref={mapRef}
                    extent={extent}
                    initialViewExtent={initialViewExtent}
                    onMoved={handleOnMoved}
                    cruxWebMap={cruxWebMap}
                    graficaMap={graficaMap}
                >
                    <Layers>
                        {visibilityConfig.layers.includes(ELayerToShow.Mappa) && <TileLayer source={osm()} />}

                        {visibilityConfig.layers.includes(ELayerToShow.Grid) && <GraticuleLayer />}

                        {editMode ? (
                            <EditLayer
                                source={source}
                                settore={settore}
                                deleteGrafica={handleDeleteGrafica}
                                setSelectedFeature={setSelectedFeature}
                                setTransformFeature={setTransformFeature}
                            />
                        ) : (
                            <VectorLayers
                                featuresLinee={featuresLinee}
                                featuresTesti={featuresTesti}
                                featuresSettori={featuresSettori}
                                featuresTombe={featuresTombe}
                                onSelectSettore={onSelectSettore}
                                onSelectTomba={onSelectTomba}
                                navigationSource={navigationSource}
                                pixel={clickCoordinates}
                                setCoordinate={setClickCoordinates}
                            />
                        )}
                    </Layers>

                    <Controls>
                        {fullMap && <VisibilityConfigControl hasTombe={innerTombe.length > 0} />}
                        {idCimitero && fullMap && <BreadcrumbControl settore={settore} idCimitero={idCimitero} />}
                        {(fullMap || graficaMap) && (
                            <DrawPanels
                                idSettore={settore.id}
                                onRetinoCreated={handleOnRetinoCreated}
                                onReloadMap={handleOnReloadMap}
                                setModal={setModal}
                                onForceFit={handleForceFit}
                                onSettoreAdded={handleOnSettoreAdded}
                                onTombeAdded={handleOnTombaAdded}
                                settore={settore}
                                selectedFeature={selectedFeature}
                                transformFeature={transformFeature}
                                setSelectedFeature={setSelectedFeature}
                                handleFile={() => setIsManageFile(true)}
                                updateFiles={updateFiles}
                                setUpdateFiles={setUpdateFiles}
                                handlePrint={handlePrint}
                                handleSave={handleSave}
                                source={source}
                                graficaMap={graficaMap}
                                fontSize={fontSize}
                                setFontSize={setFontSize}
                            />
                        )}
                    </Controls>
                </Map>
            )}

            {isManageFile && (
                <DrawerFiles
                    entityId={settore.id ?? 0}
                    entityType='L'
                    onClose={() => setIsManageFile(false)}
                    onFileUpdate={() => setUpdateFiles(true)}
                />
            )}
        </>
    );
};

export default GraphicView;
