import React, { FC, useEffect, useState } from "react";
import { List, Panel } from "rsuite";

import { UpdateFileRequest, deleteFile, updateFile } from "@services/FileService";

import File from "@models/File";

import { useCruxToaster } from "@hooks/useCruxToaster";

import FileListItem from "@components/Drawers/Files/_components/FileListItem";
import ConfirmModal from "@components/Modal/ConfirmModal";
import ConfirmUpdateFile from "@components/Modal/ConfirmUpdateFile";

interface FileListProps {
    items: File[];
    title?: string;
    onFileUpdate?: () => void;
}

const FileList: FC<FileListProps> = ({ items, title, onFileUpdate }) => {
    const [fileList, setFileList] = useState<File[]>([]);
    const { handleApiError } = useCruxToaster();

    useEffect(() => setFileList(items), [items]);

    const [deletingFile, setDeletingFile] = useState<File | null>(null);
    const [updatingFile, setUpdatingFile] = useState<File | null>(null);

    const handleConfirmDelete = () => {
        if (deletingFile) {
            const file = deletingFile;
            deleteFile(file?.id)
                .then(() => {
                    setFileList((old) => old.filter((f) => f.id !== file.id));
                    if (onFileUpdate) onFileUpdate();
                })
                .catch((_) => {
                    handleApiError({ message: "Errore nella cancellazione del file" });
                });
            setDeletingFile(null);
        }
    };

    const handleConfirmUpdate = (data: Partial<UpdateFileRequest>, id: number) => {
        const { file, fileDescr } = data;
        const payload: UpdateFileRequest = {
            id,
            file: file ?? null,
            fileDescr: fileDescr ?? "",
        };

        updateFile(payload)
            .then((resp) => {
                setFileList((old) => old.map((f) => (f.id === id ? resp : f)));
                if (onFileUpdate) onFileUpdate();
            })
            .catch((_) => {
                handleApiError({ message: "Errore nella modifica del file" });
            });
        setUpdatingFile(null);
    };

    const handleDelete = (file: File) => {
        setDeletingFile(file);
    };

    const handleUpdate = (file: File) => {
        setUpdatingFile(file);
    };

    if (!fileList || fileList.length === 0) return <></>;

    return (
        <>
            <Panel header={title} bordered className='bg-athens-200 mt-3'>
                <List hover>
                    {fileList.map((file) => (
                        <FileListItem
                            key={file.id}
                            item={file}
                            onDelete={() => handleDelete(file)}
                            onUpdate={() => handleUpdate(file)}
                        />
                    ))}
                </List>
            </Panel>
            <ConfirmModal
                handleConfirm={handleConfirmDelete}
                handleClose={() => setDeletingFile(null)}
                isOpen={!!deletingFile}
                message='Sei sicuro di voler eliminare questo file?'
            />
            <ConfirmUpdateFile
                handleConfirmUpdate={handleConfirmUpdate}
                handleClose={() => setUpdatingFile(null)}
                isOpen={!!updatingFile}
                fileId={updatingFile?.id ?? 0}
                file={updatingFile}
            />
        </>
    );
};

export default FileList;
