import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const fattureRoutes: RouteObject = {
    element: <Outlet />,
    children: [
        {
            path: "",
            element: lazyLoadRoutes("fattureContainer"),
            children: [
                {
                    path: "",
                    index: true,
                    element: lazyLoadRoutes("fattureIndex"),
                },
                {
                    path: ":id",
                    element: lazyLoadRoutes("fattureShow"),
                },
            ],
        },
        {
            path: "massive-create",
            element: lazyLoadRoutes("fattureMassiveCreate"),
        },

        {
            path: "massive-edit",
            element: lazyLoadRoutes("fattureMassiveEdit"),
        },
    ],
};

export default fattureRoutes;
