import React, { FC } from "react";
import { Outlet } from "react-router-dom";

const RicercheContainer: FC = () => {
    return (
        <>
            <Outlet />
        </>
    );
};

export default RicercheContainer;
