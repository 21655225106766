import React from "react";
import ReactDOM from "react-dom/client";
import {CustomProvider} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./configs/YupGlobalConfig";
import locale from "rsuite/locales/it_IT";
import * as Sentry from "@sentry/react";

import {configResponsive} from "ahooks";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/app-base.css";
import '@fortawesome/fontawesome-pro/css/all.min.css';
import config from "../package.json";

configResponsive({
    small: 640,
    middle: 768,
    large: 1024,
    xLarge: 1400,
    xxLarge: 1536
});

if (process.env.REACT_APP_MOCK_ENABLED === "true") {
    const {worker} = require("./@mocks/browser");
    worker?.start();
}
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://bcf2bf70b113c9f3ae64c2dd07df693e@o112664.ingest.sentry.io/4506547716292608",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["crux10.cloud", /^\//],
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        release: config.version,
        environment: process.env.NODE_ENV,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

root.render(
    // <React.StrictMode>
    <CustomProvider locale={locale}>
        <App/>
    </CustomProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
