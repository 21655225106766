import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFileContract, faFileSignature } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { Badge, IconButton, Whisper } from "rsuite";

import { CanoneSmall } from "@models/Canone";

import { useCruxNavigate } from "@hooks/useCruxNavigate";
import { useExternalTab } from "@hooks/useExternalTab";

import DateFormat from "@components/Display/DateFormat";
import EuroFormat from "@components/Display/EuroFormat";

const CanoniTombaRow: FC<{ canone: CanoneSmall; searchMode?: boolean; buttonAction?: boolean }> = ({
    canone,
    searchMode,
    buttonAction,
}) => {
    const { renderSubmenu } = useExternalTab({ deps: canone, to: `/canoni/${canone?.id}` });
    const { useQuery } = useCruxNavigate();

    const uiPreview = useQuery.get("preview");

    return (
        <div className='flex w-full gap-x-2 items-start bg-athens-100 p-2 border'>
            <div className='mt-0'>
                <FontAwesomeIcon className='text-primary-600' icon={faFileSignature as IconProp} />
            </div>
            <div className='flex w-full flex-col space-y-4'>
                {canone?.dataDisattivazione && (
                    <Badge content='Canone disattivato' className='w-fit bg-athens-500 text-primary-500 font-bold' />
                )}
                <div className='flex w-full justify-between'>
                    <div>
                        <div className='text-primary-400'>
                            {canone.tipo}{" "}
                            <span className='font-bold'>
                                {canone.numero}/{canone.anno}
                            </span>{" "}
                            -{" "}
                            <span className='font-bold'>
                                <EuroFormat value={canone.importo} />
                            </span>{" "}
                            - {canone?.utente && <span className='uppercase font-bold'>{canone.utente}</span>}
                        </div>

                        {canone?.fattura && (
                            <div className='text-primary-400'>
                                Fattura{" "}
                                <b>
                                    {canone.fattura.numero}/{canone.fattura.anno}
                                </b>{" "}
                                del&nbsp;
                                <b>
                                    <DateFormat value={canone.fattura.data} />
                                </b>{" "}
                                -{" "}
                                <b>
                                    <EuroFormat value={canone.fattura.importo} />
                                </b>{" "}
                                - Pag. il&nbsp;
                                <b>
                                    <DateFormat value={canone.fattura.data} />
                                </b>
                            </div>
                        )}
                    </div>

                    <div className='flex items-center gap-20'>
                        {canone?.dataDisattivazione && (
                            <div className='text-warning'>
                                Disattivata in data{" "}
                                <b className='text-errorDark'>
                                    <DateFormat value={canone.dataDisattivazione} />
                                </b>
                            </div>
                        )}

                        {!searchMode && buttonAction && !uiPreview && (
                            <Whisper placement='autoVerticalEnd' trigger='click' speaker={renderSubmenu}>
                                <IconButton
                                    icon={<FontAwesomeIcon icon={faFileContract as IconProp} />}
                                    title='Apri canone'
                                    size='xs'
                                    appearance='ghost'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </Whisper>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CanoniTombaRow;
