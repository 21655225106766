/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGridRound, faLayerGroup, faTombstone } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EIconeDraw } from "@models/Configs";

interface IconsPros {
    handleDrawControl: (id: EIconeDraw) => void;
    selectedButton: { id: string | null };
}

const AreaIcons = ({ handleDrawControl, selectedButton }: IconsPros) => {
    return (
        <div className='grid grid-cols-3 gap-8'>
            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Settore ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Settore)}
            >
                <FontAwesomeIcon icon={faLayerGroup as IconProp} size='xl' title='disegna settore' cursor='pointer' />
                <p className='text-primary-500 text-center'>Settore</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Tomba ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Tomba)}
            >
                <FontAwesomeIcon icon={faTombstone as IconProp} size='xl' title='disegna tomba' cursor='pointer' />
                <p className='text-primary-500 text-center'>Tomba</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.GrigliaTombe ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.GrigliaTombe)}
            >
                <FontAwesomeIcon icon={faGridRound as IconProp} size='xl' title='disegna griglia' cursor='pointer' />
                <p className='text-primary-500 text-center'>Griglia</p>
            </div>
        </div>
    );
};
export default AreaIcons;
