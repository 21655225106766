//import icons from "@fortawesome/fontawesome-pro/metadata/icon-families.json";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField } from "formik";
import React, { ReactNode, useMemo } from "react";
import { SelectPicker as RsSelectPicker, SelectPickerProps as RsSelectPickerProps } from "rsuite";
import { ItemDataType } from "rsuite/cjs/@types/common";

import { iconsFontAwesome } from "@helpers/FileHelper";

interface FontAwesomePickerPros<T> extends Omit<RsSelectPickerProps<T>, "form" | "data"> {
    name: string;
}

const fontAwesomeMenuItem = (icon: ReactNode, item: ItemDataType<string | number>) => {
    return (
        <div className='space-x-12'>
            <FontAwesomeIcon icon={item.icon as IconProp} className='w-[1.5rem]' />
            <span>{item.label}</span>
        </div>
    );
};

const FontAwesomePicker = <T extends string>(props: FontAwesomePickerPros<T>) => {
    const [field, meta, helpers] = useField(props.name);

    const value = useMemo(() => field.value?.[props.valueKey ?? "value"] ?? field.value, [field.value, props.valueKey]);

    const handleOnChange = (selectedValue: T | null, event: any) => {
        helpers.setValue(selectedValue);
        props.onChange?.(selectedValue, event);
    };

    const handleOnClean = () => {
        helpers.setValue(null);
    };

    return (
        <RsSelectPicker
            {...field}
            value={value}
            onClean={handleOnClean}
            onOpen={() => helpers.setTouched(true)}
            block
            placement='autoVertical'
            {...props}
            virtualized
            data={iconsFontAwesome ?? []}
            onChange={handleOnChange}
            renderMenuItem={fontAwesomeMenuItem}
        />
    );
};

export default FontAwesomePicker;
