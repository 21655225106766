import React, { FC } from "react";
import { Table } from "rsuite";

const { Cell } = Table;
const RadioCell: FC<any> = ({ rowData, dataKey, onChange, handleChange, checkedKey, ...props }) => {
    return (
        <Cell {...props}>
            <div className='flex items-center gap-8'>
                <input
                    type='radio'
                    value={rowData?.key ?? rowData?.id}
                    checked={checkedKey && (rowData?.key === checkedKey || rowData?.id === checkedKey)}
                    onClick={() => handleChange(rowData?.key)}
                    className='border-b border-athens-200 ml-0 p-0'
                    id={rowData?.key ?? rowData?.id}
                    readOnly
                />
                <label htmlFor={rowData?.key ?? rowData?.id}>{rowData[dataKey]}</label>
            </div>
        </Cell>
    );
};

export default RadioCell;
