import { Formik } from "formik";
import React from "react";
import { Button, Drawer } from "rsuite";

import RightDrawer from "@components/Drawers/RightDrawer";

interface FormDrawerProps<T> {
    title: string;
    size?: "full" | "lg" | "md" | "sm" | "xs";
    formData: Partial<T>;
    onActionPerformed: (data: Partial<T>) => Promise<void | Partial<T>> | void;
    onClose: () => void;
    children?: React.ReactNode;
    validationSchema?: any;
    primaryActionButtonText?: string;
    cancelActionButtonText?: string;
    onCancel?: () => void;
    showExtraButton?: boolean;
    hideCancelButton?: boolean;
    hideActionButton?: boolean;
    loading?: boolean;
    to?: string;
}

const RightFormDrawer: <T extends object>(p: FormDrawerProps<T>) => React.ReactElement<FormDrawerProps<T>> = (
    props
) => {
    const {
        title,
        onActionPerformed,
        onClose,
        onCancel,
        children,
        size = "md",
        formData,
        validationSchema,
        primaryActionButtonText = "Salva",
        cancelActionButtonText = "Annulla",
        showExtraButton = false,
        hideCancelButton = false,
        hideActionButton = false,
        loading = false,
        to = "",
    } = props;

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        } else {
            onClose();
        }
    };

    return (
        <div>
            <RightDrawer size={size} onClose={onClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={formData}
                    enableReinitialize
                    validateOnChange
                    validateOnBlur
                    validateOnMount
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(true);
                        onActionPerformed(values)
                            ?.then((rd) => {})
                            .catch((error) => {
                                actions.setErrors(error?.response?.data?.meta?.errori);
                            })
                            .finally(() => {
                                actions.setSubmitting(false);
                            });
                    }}
                >
                    {({ isValid, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Drawer.Header>
                                <Drawer.Title>{title}</Drawer.Title>
                                <Drawer.Actions>
                                    {showExtraButton && (
                                        <Button
                                            size='sm'
                                            onClick={(e) => window.open(`${to}?preview=true`, "_blank", "noreferrer")}
                                        >
                                            Visualizza fattura
                                        </Button>
                                    )}
                                    {!hideCancelButton && (
                                        <Button onClick={handleOnCancel}>{cancelActionButtonText}</Button>
                                    )}
                                    {!hideActionButton && (
                                        <Button
                                            type='button'
                                            appearance='primary'
                                            color='red'
                                            disabled={!isValid}
                                            loading={loading}
                                            onClick={() => handleSubmit()}
                                        >
                                            {primaryActionButtonText}
                                        </Button>
                                    )}
                                </Drawer.Actions>
                            </Drawer.Header>
                            <Drawer.Body className='p-3 bg-gray-200 h-drawer'>{children}</Drawer.Body>
                        </form>
                    )}
                </Formik>
            </RightDrawer>
        </div>
    );
};

export default RightFormDrawer;
