import { atom } from "recoil";

import { Provincia, Regione, Stato } from "@models/Comune";

const statiListState = atom<Stato[]>({
    key: "statiListState",
    default: [],
});

const provinceListState = atom<Provincia[]>({
    key: "provinceListState",
    default: [],
});

const regioniListState = atom<Regione[]>({
    key: "regioniListState",
    default: [],
});

const comuneAttivoState = atom<string>({
    key: "comuneAttivoState",
    default: "",
});

const anagrafeEsternaState = atom<boolean>({
    key: "anagrafeEsternaState",
    default: false,
});

const anagrafeEsternaTipoConfrontoState = atom<{ supportaContiene: boolean; supportaInizia: boolean }>({
    key: "anagrafeEsternaTipoConfrontoState",
    default: {
        supportaContiene: false,
        supportaInizia: false,
    },
});

export {
    statiListState,
    provinceListState,
    regioniListState,
    comuneAttivoState,
    anagrafeEsternaState,
    anagrafeEsternaTipoConfrontoState,
};
