import { uniqueId } from "lodash";
import { Geometry } from "ol/geom";
import { Draw } from "ol/interaction";
import { createBox } from "ol/interaction/Draw";
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import React, { FC, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import { addTomba } from "@services/CimiteriService";

import { EIconeDraw } from "@models/Configs";
import { IAddTomba } from "@models/StrutturaLivelli";
import { Tomba } from "@models/Tomba";

import { useCruxToaster } from "@hooks/useCruxToaster";

import AddTombaPanel from "@openLayersMap/Components/Panels/AddTombaPanel";
import { getVectorStyle } from "@openLayersMap/Helpers/StyleHelper";
import { useMap } from "@openLayersMap/Map/MapContext";
import { wkt } from "@openLayersMap/Source";
import { typeDrawState } from "@openLayersMap/Stores/editControls";

interface TombaHookProps {
    onTombaCreated: (e: Tomba) => void;
    idStruttura: number;
}

const vector = new VectorLayer({
    source: new VectorSource({ wrapX: false }),
    className: "tomba-vector-layer",
    style: getVectorStyle,
});

const useTombaDrawHook = ({ onTombaCreated, idStruttura }: TombaHookProps) => {
    const { map, startSnap, stopSnap } = useMap();
    const { handleApiError } = useCruxToaster();
    const setTypeDraw = useSetRecoilState(typeDrawState);

    const [hasDrawn, setHasDrawn] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const source = vector.getSource() as VectorSource;

    const draw = new Draw({
        source,
        type: "Circle",
        geometryFunction: createBox(),
    });

    // const snap = useMemo(() => {
    //     if (map) {
    //         const gridLayer = map
    //             .getLayers()
    //             .getArray()
    //             .find((l) => l.get("name") === "graticule");
    //
    //         console.log("gridLayer", gridLayer);
    //         if (gridLayer) {
    //             const gridSource = (gridLayer as Graticule).getSource();
    //             if (gridSource) {
    //                 return new Snap({
    //                     source: gridSource,
    //                     pixelTolerance: 10,
    //                     edge: true,
    //                 });
    //             }
    //         }
    //     }
    //     return new Snap({
    //         source,
    //     });
    // }, [map, source]);

    const getTempFeature = () => source.getFeatures().find((f) => f.getProperties().isTemp);

    const removeTempFeature = () => {
        const tmpFeature = getTempFeature();
        if (tmpFeature) source.removeFeature(tmpFeature);
    };

    draw.on("drawstart", () => {
        removeTempFeature();
    });

    draw.on("drawend", (e: any) => {
        const { feature } = e;
        feature.setProperties({ id: uniqueId(), isTemp: true });
        setHasDrawn(true);
    });

    useEffect(() => {
        if (map) {
            map.addLayer(vector);
            map.addInteraction(draw);
            // map.addInteraction(snap);
            startSnap();
        }

        return () => {
            removeTempFeature();
            map?.removeLayer(vector);
            map?.removeInteraction(draw);
            // map?.removeInteraction(snap);
            stopSnap();
        };
    }, [map]);

    const handleCancelCreateTomba = () => {
        removeTempFeature();

        map?.addInteraction(draw);
        setHasDrawn(false);
    };

    const handleSaveTomba = (data: Partial<IAddTomba>) => {
        const createdFeatureTomba = getTempFeature();
        if (!createdFeatureTomba) return;
        setIsSaving(true);

        const payload: IAddTomba = {
            idTipoTomba: data.idTipoTomba ?? 0,
            numero: data.numero ?? "",
            geom: wkt.writeGeometry(createdFeatureTomba.getGeometry() as Geometry),
        };

        removeTempFeature();
        addTomba({ idSettore: idStruttura, payload: [payload] })
            .then((res) => {
                onTombaCreated(res[0]);
                setTypeDraw(EIconeDraw.Seleziona);
            })
            .catch((apiError) => {
                handleApiError({ error: apiError.response.data });
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    return {
        handleCancelCreateTomba,
        handleSaveTomba,
        hasDrawn,
        isSaving,
    };
};

const DrawTombaComponent: FC<TombaHookProps> = ({ onTombaCreated, idStruttura }) => {
    const hook = useTombaDrawHook({ onTombaCreated, idStruttura });

    return (
        <AddTombaPanel
            isSaving={hook.isSaving}
            hasDrawn={hook.hasDrawn}
            handleConfirm={hook.handleSaveTomba}
            handleCancel={hook.handleCancelCreateTomba}
        />
    );
};

export { DrawTombaComponent };
