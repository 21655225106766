/* eslint-disable prettier/prettier */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useFormikContext } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Loader, Placeholder } from "rsuite";
import * as Yup from "yup";

import { listLevels } from "@services/settings/cimiteri/LivelliService";

import { ICimiteroState, cimiteroEventState } from "@stores/graveyard";

import Livello from "@models/Livello";
import { IStrutturaLivelli } from "@models/StrutturaLivelli";

import { useCruxRequest } from "@hooks/useCruxRequest";

import FormDrawerStack from "@components/Drawers/FormDrawerStack";
import FormGroup from "@components/Form/FormGroup";
import FormInput from "@components/Form/Input/FormInput";
import SelectInput from "@components/Form/Input/SelectInput";
import ToggleInput from "@components/Form/Input/ToggleInput";

import { getCodiceLabel, getDescrLabel, getLivelli, isAdd, isEdit } from "@helpers/Levels";

interface FormProp {
    idSettore: number;
    idLivelloPadre: number | undefined;
    isCimiteriEditMode: ICimiteroState | undefined;
}

export interface IGetLevels extends Partial<FormProp> {
    livelli: Livello[] | undefined;
}

export const LivelloSchema = Yup.object().shape({
    idLivello: Yup.number().required(),
    codice: Yup.string().min(0).max(50).required(),
    descr: Yup.string().min(0).max(50),
    visualizzaDescr: Yup.boolean(),
});

const LivelloForm: FC<FormProp> = ({ idSettore, idLivelloPadre, isCimiteriEditMode }) => {
    const { data: livelli, loading, mutate } = useCruxRequest(listLevels);
    const { values, setFieldValue } = useFormikContext<IStrutturaLivelli>();
    const [levelFilter, setLevelFilter] = useState<Livello[] | undefined>(undefined);
    const setActualEvent = useSetRecoilState(cimiteroEventState);
    const navigate = useNavigate();
    const [customLoading, setCustomLoading] = useState(true);

    const emptyLevelFilter = levelFilter && levelFilter.length === 0;

    const handleNavigate = (): void => {
        navigate("/tabelle/cimiteri/livelli");
        setActualEvent(undefined);
    };

    useEffect(() => {
        if (livelli) {
            const levels = getLivelli({ livelli, idLivelloPadre, isCimiteriEditMode });
            setLevelFilter(levels);
        }
        // eslint-disable-next-line
    }, [livelli, idLivelloPadre]);

    useEffect(() => {
        if (isAdd(isCimiteriEditMode) && !!levelFilter && levelFilter.length > 0)
            setFieldValue("idLivello", levelFilter[0]?.id ?? 1);

        if (levelFilter && (levelFilter.length > 0 || levelFilter.length === 0))
            setTimeout(() => setCustomLoading(false), 1000);
        // eslint-disable-next-line
    }, [levelFilter]);

    return (
        <FormDrawerStack>
            {!loading && !customLoading && !!livelli && !!levelFilter && livelli.length > 0 && (
                <>
                    <div className='flex items-center space-x-20'>
                        <FormGroup name='idLivello' className='mt-0 flex-1'>
                            <p>Tipo</p>
                            <SelectInput
                                name='idLivello'
                                data={levelFilter}
                                valueKey='id'
                                labelKey='descr'
                                cleanable={false}
                                searchable={false}
                                disabled={
                                    (idSettore === 0 && isAdd(isCimiteriEditMode)) ||
                                    isEdit(isCimiteriEditMode) ||
                                    emptyLevelFilter
                                }
                                placeholder='Seleziona tipo'
                            />
                        </FormGroup>

                        <FormGroup name='codice' label={getCodiceLabel({ isCimiteriEditMode })} className='mt-0 flex-1'>
                            <FormInput name='codice' minLength={0} maxLength={100} disabled={emptyLevelFilter} />
                        </FormGroup>

                        <FormGroup name='descr' label={getDescrLabel({ isCimiteriEditMode })} className='mt-0 flex-1'>
                            <FormInput name='descr' minLength={0} maxLength={100} disabled={emptyLevelFilter} />
                        </FormGroup>
                    </div>

                    <div className='flex-1 flex justify-end mt-8'>
                        <FormGroup
                            name='visualizzaDescr'
                            label='Visualizza descrizione nella struttura'
                            className='mt-0 flex space-x-8'
                        >
                            <ToggleInput name='visualizzaDescr' disabled={emptyLevelFilter} size='md' />
                        </FormGroup>
                    </div>

                    {emptyLevelFilter && (
                        <h2 className='text-errorDark'>
                            Crea un nuovo settore nella&nbsp;
                            <b className='hover:underline' onClick={handleNavigate} role='button' tabIndex={0}>
                                tabella livelli
                            </b>
                            , per poter procedere con l'inserimento
                        </h2>
                    )}
                </>
            )}

            {((emptyLevelFilter && loading) || loading || customLoading) && (
                <div className='relative block px-24'>
                    <Placeholder.Paragraph rows={5} />
                    <Loader center />
                </div>
            )}
        </FormDrawerStack>
    );
};

export default LivelloForm;
