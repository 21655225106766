import React, { createContext, useMemo } from "react";

import { getMenuItems } from "@services/settings/SettingsService";

import { SettingMenuItem } from "@models/Settings/SettingMenuItem";

import { useCruxRequest } from "@hooks/useCruxRequest";

const TablesContext = createContext<{ menuItems: SettingMenuItem[]; loading: boolean }>({
    menuItems: [],
    loading: false,
});

const TablesProvider = ({ children }: { children: React.ReactNode }) => {
    const { data: menuItems, loading } = useCruxRequest(getMenuItems);

    const values = useMemo(
        () => ({
            menuItems: menuItems ?? [],
            loading,
        }),
        [menuItems, loading]
    );

    return <TablesContext.Provider value={values}>{children}</TablesContext.Provider>;
};

function useTablesContext() {
    const context = React.useContext(TablesContext);
    if (context === undefined) {
        throw new Error("useTablesContext must be used within a TablesProvider");
    }
    return context;
}

export { TablesProvider, useTablesContext };
