/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Nav } from "rsuite";

import { EIconeDraw } from "@models/Configs";
import { ELayer } from "@models/Grafica";
import { Tomba } from "@models/Tomba";

import AreaIcons from "@openLayersMap/Components/AreaIcons";
import DrawIcons from "@openLayersMap/Components/DrawIcons";
import EditIcons from "@openLayersMap/Components/EditIcons";
import RegularRetinoToolset from "@openLayersMap/Components/RegularRetinoToolset";
import SelectedFeatureColorToolset from "@openLayersMap/Components/SelectedFeatureColorToolset";
import SelectedFeatureToolset from "@openLayersMap/Components/SelectedFeatureToolset";
import { featureLayer } from "@openLayersMap/Helpers/EditMappingHelpers";
import { DrawGrigliaTombeComponent } from "@openLayersMap/Hooks/DrawGrigliaTombeHook";
import { DrawSettoreComponent } from "@openLayersMap/Hooks/DrawSettoreHook";
import { DrawTestoComponent } from "@openLayersMap/Hooks/DrawTestoHook";
import { DrawTombaComponent } from "@openLayersMap/Hooks/DrawTombaHook";
import { IEditControlsProps } from "@openLayersMap/Models/EditMapping";
import { retinoLatiState, typeDrawState } from "@openLayersMap/Stores/editControls";

const EditControls = ({
    source,
    onTombeAdded,
    onSettoreAdded,
    settore,
    selectedFeature,
    transformFeature,
    setSelectedFeature,
}: IEditControlsProps) => {
    const [active, setActive] = useState("modifica");
    const [typeDraw, setTypeDraw] = useRecoilState(typeDrawState);
    const [retinoLati, setRetinoLati] = useRecoilState(retinoLatiState);
    const [selectedButton, setSelectedButton] = useState<{ id: string | null }>({ id: EIconeDraw.Seleziona });
    const layer = transformFeature ? featureLayer(transformFeature) : undefined;

    const handleDrawControl = (id: EIconeDraw) => {
        setSelectedButton({ id });
        setTypeDraw(id);
        setSelectedFeature(null);
    };

    const handleSetActive = (nav: string) => {
        setActive(nav);
        setTypeDraw(EIconeDraw.Seleziona);
        setSelectedFeature(null);
    };

    const handleSaveLatiRetino = (lati: number): void => {
        setRetinoLati(lati);
    };

    useEffect(() => {
        setSelectedButton({ id: typeDraw });
    }, [typeDraw]);

    return (
        <section className='h-full max-h-[49.5rem]'>
            <Nav appearance='subtle' classPrefix='grafica-navbar' onSelect={handleSetActive} activeKey={active}>
                <Nav.Item eventKey='modifica'>MODIFICA</Nav.Item>
                <Nav.Item eventKey='disegna'>DISEGNA</Nav.Item>
                <Nav.Item eventKey='aggiungi'>AGGIUNGI</Nav.Item>
            </Nav>

            <div className='bg-athens-200 p-20 rounded shadow-sm mt-20'>
                {active === "modifica" && (
                    <EditIcons handleDrawControl={handleDrawControl} selectedButton={selectedButton} />
                )}
                {active === "disegna" && (
                    <DrawIcons handleDrawControl={handleDrawControl} selectedButton={selectedButton} />
                )}
                {active === "aggiungi" && (
                    <AreaIcons handleDrawControl={handleDrawControl} selectedButton={selectedButton} />
                )}
            </div>

            <div className='bg-athens-200 rounded shadow-sm mt-20'>
                {typeDraw === EIconeDraw.Regular && (
                    <div className='p-20'>
                        <RegularRetinoToolset retinoLati={retinoLati} handleSaveLatiRetino={handleSaveLatiRetino} />
                    </div>
                )}

                {(typeDraw === EIconeDraw.Text ||
                    (typeDraw === EIconeDraw.Modifica && transformFeature && layer && layer === ELayer.Testo)) && (
                    <div className='p-20'>
                        <DrawTestoComponent
                            inEditFeature={transformFeature}
                            source={source}
                            settoreCimitero={settore}
                        />
                    </div>
                )}

                {typeDraw === EIconeDraw.Settore && (
                    <div className='p-20'>
                        <DrawSettoreComponent onSettoreCreated={onSettoreAdded} settoreCimitero={settore} />
                    </div>
                )}

                {typeDraw === EIconeDraw.GrigliaTombe && (
                    <div className='p-20'>
                        <DrawGrigliaTombeComponent
                            onGrigliaTombeCreated={(tombe: Tomba[]) => {
                                onTombeAdded?.(tombe);
                            }}
                            idStruttura={settore?.id}
                        />
                    </div>
                )}

                {typeDraw === EIconeDraw.Tomba && (
                    <div className='p-20'>
                        <DrawTombaComponent
                            onTombaCreated={(tomba: Tomba) => {
                                onTombeAdded?.([tomba]);
                            }}
                            idStruttura={settore?.id}
                        />
                    </div>
                )}

                {selectedFeature && typeDraw === EIconeDraw.Seleziona && (
                    <div className='p-20'>
                        <SelectedFeatureToolset selectedFeature={selectedFeature} />
                    </div>
                )}

                {selectedFeature && typeDraw === EIconeDraw.Colore && (
                    <div className='p-20'>
                        <SelectedFeatureColorToolset selectedFeature={selectedFeature} source={source} />
                    </div>
                )}
            </div>
            {/*{typeDraw === EIconeDraw.Modifica && transformFeature && (*/}
            {/*    <TextEdit transformFeature={transformFeature} source={source} />*/}
            {/*)}*/}
        </section>
    );
};

export default EditControls;
