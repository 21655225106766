import { Modify, Select } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import { useState } from "react";

import { ELayer } from "@models/Grafica";

import {
    featureElement,
    featureLayer,
    featureTesto,
    getDimensioneTestoFeature,
} from "@openLayersMap/Helpers/EditMappingHelpers";
import { useMap } from "@openLayersMap/Map/MapContext";
import { IText } from "@openLayersMap/Models/EditMapping";

import useEditMappingHook from "./EditMappingHook";

interface ModifyHookProps {
    source: VectorSource;
}

const useModifyHook = ({ source }: ModifyHookProps) => {
    const { map } = useMap();
    const [pointModify, setPointModify] = useState<Modify | null>(null);

    const selectModify = new Select();

    const modify = new Modify({
        features: selectModify.getFeatures(),
    });

    const { setModify } = useEditMappingHook({
        source,
    });

    const initModify = (): void => {
        if (!map) return;

        map.addInteraction(selectModify);
        map.addInteraction(modify);

        selectModify.on("select", (e) => {
            if (e.selected.length > 0) {
                modify.setActive(true);
                setPointModify(modify);
            } else {
                modify.setActive(false);
                setPointModify(modify);
            }
        });

        modify.on("modifyend", (e) => {
            if (e.features) {
                const f = e.features.getArray()[0];

                const propertiesElement = featureElement(f);
                const propertiesTesto = featureTesto(f);
                const layer = featureLayer(f);
                const fontSize = getDimensioneTestoFeature(f);

                const text: IText = {
                    val: layer === ELayer.Settore ? propertiesElement.codice : propertiesTesto?.val,
                    rotation: propertiesTesto?.rot,
                    size: fontSize,
                };

                setModify(f, text);
            }
        });
    };

    const destroyModify = () => {
        map?.removeInteraction(modify);
        map?.removeInteraction(selectModify);
    };

    return {
        initModify,
        destroyModify,
        pointModify,
    };
};

export default useModifyHook;
