/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Toggle } from "rsuite";
import * as Yup from "yup";

import { listTipiTomba } from "@services/settings/cimiteri/TipiTombaService";

import { addGraveState, incrementGraveState, lastGraveState } from "@stores/graveyard";

import { ITombe } from "@models/StrutturaLivelli";

import { useCruxRequest } from "@hooks/useCruxRequest";

import FormDrawerStack from "@components/Drawers/FormDrawerStack";
import FormGroup from "@components/Form/FormGroup";
import FormInput from "@components/Form/Input/FormInput";
import NumberInput from "@components/Form/Input/NumberInput";
import SelectInput from "@components/Form/Input/SelectInput";
import ToggleInput from "@components/Form/Input/ToggleInput";

export const TombaSchema = Yup.object().shape({
    idTipoTomba: Yup.number(),
    tombeDaAggiungere: Yup.number().min(1).required(),
    prefisso: Yup.string().min(0).max(50),
    suffisso: Yup.string().min(0).max(50),
    incremento: Yup.number().min(1),
    tombaIniziale: Yup.string().required(),
    tombaFinale: Yup.number().min(1),
});

const TombaForm = () => {
    const { data: tombe } = useCruxRequest(listTipiTomba);
    const { values, setFieldValue } = useFormikContext<ITombe>();
    const { tombaIniziale, tombeDaAggiungere, incremento, tombaFinale, toggleAddTomba, toggleEndTomba } = values;
    const [isOnlyNumber, setIsOnlyNumber] = useState(false);
    const [checked, setChecked] = useState(false);

    const lastGrave = useRecoilValue(lastGraveState);
    const setAddGraveState = useSetRecoilState(addGraveState);
    const setIncrementGraveState = useSetRecoilState(incrementGraveState);

    const addGrave = useRecoilValue(addGraveState);
    const incrementGrave = useRecoilValue(incrementGraveState);

    const greaterThanOneAdd = tombeDaAggiungere && +tombeDaAggiungere > 1;
    const greaterThanOneInc = incremento && +incremento > 1;

    const addGravesFunc = (): void => {
        if (greaterThanOneAdd && !greaterThanOneInc) {
            const summ = +(tombeDaAggiungere - 1) + +(tombaIniziale || 0);
            setFieldValue("tombaFinale", summ);
        }

        if (!greaterThanOneAdd && tombaIniziale && !greaterThanOneInc) {
            setFieldValue("tombaFinale", tombaIniziale);
        }
    };

    const tombaFinaleTrigger = (): void => {
        const summ = +(tombaFinale || 1) - +(tombaIniziale || 1) + 1;
        setFieldValue("tombeDaAggiungere", summ);
    };

    const increaseFunc = (): void => {
        if (!!tombeDaAggiungere && greaterThanOneInc && !!tombaFinale) {
            const summ = tombeDaAggiungere >= (addGrave || 1) ? +tombaFinale + +incremento : +tombaFinale - +incremento;

            setFieldValue("tombaFinale", summ > 0 ? summ : tombaIniziale);
        }
    };

    const incrementoTrigger = (): void => {
        if (greaterThanOneAdd && !!incremento && !!tombaFinale) {
            const summ =
                incremento <= (incrementGrave || 1)
                    ? +tombaFinale - +(tombeDaAggiungere - 1)
                    : +tombaFinale + +(tombeDaAggiungere - 1);

            setFieldValue("tombaFinale", summ > 0 ? summ : tombaIniziale);
        }
    };

    const filterTipiTomba = useMemo(() => {
        return checked ? tombe : tombe?.filter((t) => !t.storico);
    }, [checked, tombe]);

    const findStorico = tombe?.find((t) => t.storico);

    const mapTombe = useMemo(() => {
        if (filterTipiTomba && filterTipiTomba?.length > 0) {
            return filterTipiTomba.map((tomba) => {
                return {
                    ...tomba,
                    descr: `${tomba.descr} (${tomba.postiSalma > 0 ? `${tomba.postiSalma} P.S.` : ""}${
                        tomba.postiSalma > 0 && tomba.postiResto ? " - " : ""
                    }${tomba.postiResto > 0 ? `${tomba.postiResto} P.R.` : ""})`,
                };
            });
        }
        return [];
    }, [filterTipiTomba]);

    useEffect(() => {
        if (tombe) setFieldValue("idTipoTomba", tombe[0]?.id ?? undefined);
        setFieldValue("tombaIniziale", +(lastGrave || 0) + 1 ?? 0);
        setFieldValue("tombaFinale", +(lastGrave || 0) + 1 ?? 0);
        // eslint-disable-next-line
    }, [tombe, lastGrave]);

    useEffect(() => {
        const regexIsOnlyNumber = /^\d+$/.test(tombaIniziale || "");
        setIsOnlyNumber(regexIsOnlyNumber);
        setAddGraveState(tombeDaAggiungere);

        if (toggleAddTomba && isOnlyNumber) addGravesFunc();
        if (toggleAddTomba && isOnlyNumber) increaseFunc();
        // eslint-disable-next-line
    }, [tombaIniziale, tombeDaAggiungere]);

    useEffect(() => {
        setIncrementGraveState(incremento);

        if (toggleAddTomba && isOnlyNumber) incrementoTrigger();
        // eslint-disable-next-line
    }, [incremento]);

    useEffect(() => {
        if (toggleEndTomba && isOnlyNumber) tombaFinaleTrigger();
        // eslint-disable-next-line
    }, [tombaFinale]);

    useEffect(() => {
        if (!toggleAddTomba) setFieldValue("toggleEndTomba", true);
        if (toggleAddTomba) {
            setFieldValue("toggleEndTomba", false);
            //incrementoTrigger();
        }
    }, [toggleAddTomba, setFieldValue]);

    useEffect(() => {
        if (!toggleEndTomba) setFieldValue("toggleAddTomba", true);
        if (toggleEndTomba) {
            setFieldValue("incremento", 1);
            setFieldValue("toggleAddTomba", false);
        }
    }, [toggleEndTomba, setFieldValue]);

    useEffect(() => {
        if (!isOnlyNumber) {
            setFieldValue("tombaFinale", tombaIniziale || "");
            setFieldValue("incremento", 1);
            setFieldValue("tombeDaAggiungere", 1);
        }
        if (isOnlyNumber && tombaIniziale) setFieldValue("tombaFinale", +tombaIniziale || 1);
    }, [isOnlyNumber, tombaIniziale, setFieldValue]);

    return (
        <FormDrawerStack>
            <div className='flex items-center space-x-20'>
                <FormGroup name='idTipoTomba' label='Tipo di tomba' className='mt-0'>
                    <SelectInput
                        name='idTipoTomba'
                        data={mapTombe}
                        valueKey='id'
                        labelKey='descr'
                        cleanable={false}
                        searchable
                        placeholder='Seleziona tipo di tomba'
                    />
                </FormGroup>
                {findStorico && (
                    <Toggle
                        size='md'
                        checkedChildren='Disabilita storici'
                        unCheckedChildren='Visualizza storici'
                        checked={checked}
                        onChange={(c) => setChecked((prev) => !prev)}
                    />
                )}
            </div>

            <div className='flex items-center space-x-20'>
                <FormGroup name='tombaIniziale' label='Tomba iniziale' className='mt-0 w-fit'>
                    <FormInput name='tombaIniziale' min={0} />
                </FormGroup>

                <FormGroup name='prefisso' label='Prefisso' className='mt-0 flex-1'>
                    <FormInput name='prefisso' disabled={!isOnlyNumber} />
                </FormGroup>

                <FormGroup name='suffisso' label='Suffisso' className='mt-0 flex-1'>
                    <FormInput name='suffisso' disabled={!isOnlyNumber} />
                </FormGroup>
            </div>

            <div className='flex items-center space-x-20'>
                <FormGroup name='tombeDaAggiungere' label='Tombe da aggiungere' className='mt-0 w-fit'>
                    <NumberInput name='tombeDaAggiungere' min={1} disabled={toggleEndTomba || !isOnlyNumber} />
                </FormGroup>

                <FormGroup name='toggleAddTomba' className='flex-1'>
                    <ToggleInput name='toggleAddTomba' disabled={!isOnlyNumber} />
                </FormGroup>
            </div>

            <div className='flex items-center space-x-20'>
                <FormGroup name='tombaFinale' label='Tomba finale' className='mt-0 w-fit'>
                    <NumberInput name='tombaFinale' disabled={toggleAddTomba || !isOnlyNumber} />
                </FormGroup>

                <FormGroup name='toggleEndTomba' className='flex-1'>
                    <ToggleInput name='toggleEndTomba' disabled={!isOnlyNumber} />
                </FormGroup>
            </div>

            <FormGroup name='incremento' label='Incremento numerazione' className='mt-0 w-fit'>
                <NumberInput name='incremento' min={1} disabled={toggleEndTomba || !isOnlyNumber} />
            </FormGroup>
        </FormDrawerStack>
    );
};

export default TombaForm;
