import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileDownload from "js-file-download";
import CimiteriTypeViewSelector from "layouts/CimiteriTypeViewSelector";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Nav, Navbar } from "rsuite";

import { downloadMacro } from "@services/FileService";
import { listComuniGestiti } from "@services/settings/comuni/ComuniGestitiService";

import { useAuth } from "@context/AuthContext";

import { isAdminState, isSysAdminState, permissionsGraficaState, permissionsRicercheState } from "@stores/diritti";
import { cimiteriViewTypeState } from "@stores/graveyard";
import { cimiteriListState, comuniState } from "@stores/municipality";

import { StatoDiritti } from "@models/Diritti";

import { useCruxNavigate } from "@hooks/useCruxNavigate";
import { useCruxRequest } from "@hooks/useCruxRequest";
import { useCruxToaster } from "@hooks/useCruxToaster";
import { useDiritti } from "@hooks/useDiritti";

import { getFilenameFromResponse } from "@helpers/FileHelper";

import Municipality from "./Municipality";

const CruxNavbar = ({ showFull }: { showFull: boolean }) => {
    const permissionsGrafica = useRecoilValue(permissionsGraficaState);
    const permissionsRicerche = useRecoilValue(permissionsRicercheState);

    const setCimiteriViewType = useSetRecoilState(cimiteriViewTypeState);
    const comuniStore = useRecoilValue(comuniState);
    const cimiteriList = useRecoilValue(cimiteriListState);
    const version = process.env.REACT_APP_VERSION;

    const [activeKey, setActiveKey] = useState<string | null>(null);
    const { user, logout } = useAuth();
    const { numberRouteKey, isTabelle, isGrafica, useQuery } = useCruxNavigate();
    const { handleApiError } = useCruxToaster();

    const { data: comuni } = useCruxRequest(listComuniGestiti);
    const uiPreview = useQuery.get("preview");

    const formData = useMemo(() => {
        if (comuniStore && comuniStore?.length > 0)
            return comuniStore.map((value) => {
                return {
                    id: value.id,
                    descr: value.comune,
                };
            });

        if (comuni && comuni?.length > 0)
            return comuni?.map((value) => {
                return {
                    id: value.id,
                    descr: value.comune,
                };
            });

        return [];
    }, [comuniStore, comuni]);

    const isAdmin = useRecoilValue(isAdminState);
    const isSysAdmin = useRecoilValue(isSysAdminState);

    const handleDownloadMacro = () => {
        downloadMacro("macro")
            .then((resp) => {
                const filename = getFilenameFromResponse(resp, "Documento_Macro.docx");
                FileDownload(resp.data, filename);
            })
            .catch((_) => {
                handleApiError({ message: "Errore nel download del file" });
            });
    };

    const handleOpenChangelog = () => {
        window.open("https://supporto.crux10.cloud/portal/it/home", "_blank", "noreferrer");
    };

    useDiritti();

    useEffect(() => {
        setActiveKey(numberRouteKey);
    }, [numberRouteKey]);

    useEffect(() => {
        if (permissionsGrafica === StatoDiritti.NESSUNO) setCimiteriViewType("data");
    }, [permissionsGrafica]);

    if (uiPreview) return null;

    return (
        <Navbar appearance='subtle' classPrefix='main-navbar'>
            <Navbar.Brand as={Link} to={cimiteriList ? `/cimitero/${cimiteriList[0]?.id}` : "/"}>
                <p>
                    CRUX 10 <span className='text-xs'>(v.{version})</span>
                </p>
            </Navbar.Brand>
            {showFull && (
                <Nav onSelect={setActiveKey} activeKey={activeKey}>
                    <Nav.Item as={Link} eventKey='1' to={cimiteriList ? `/cimitero/${cimiteriList[0]?.id}` : "/"}>
                        Gestione Cimitero
                    </Nav.Item>
                    <Nav.Item as={Link} eventKey='2' to='/contratti'>
                        Gestione Contratti
                    </Nav.Item>

                    {permissionsRicerche === StatoDiritti.LETTURA && (
                        <Nav.Menu title='Ricerche' eventKey='6'>
                            <Nav.Item as={Link} to='/ricerche/defunti'>
                                Ricerche defunti
                            </Nav.Item>
                            <Nav.Item as={Link} to='/ricerche/tombe'>
                                Ricerche tombe
                            </Nav.Item>
                            <Nav.Item as={Link} to='/ricerche/contratti'>
                                Ricerche contratti
                            </Nav.Item>
                        </Nav.Menu>
                    )}

                    <Nav.Menu title='Gestione Canoni' eventKey='3'>
                        <Nav.Item as={Link} to='/canoni'>
                            Lista canoni
                        </Nav.Item>
                        <Nav.Item as={Link} to='/canoni/massive-edit'>
                            Modifica canoni con criteri
                        </Nav.Item>
                        <Nav.Item as={Link} to='/canoni/massive-copy'>
                            Copia canoni
                        </Nav.Item>
                        <Nav.Menu title='Gestione fatture'>
                            <Nav.Item as={Link} to='/fatture'>
                                Lista fatture
                            </Nav.Item>
                            <Nav.Item as={Link} to='/fatture/massive-create'>
                                Crea fatture
                            </Nav.Item>
                            <Nav.Item as={Link} to='/fatture/massive-edit'>
                                Modifica fatture con criteri
                            </Nav.Item>
                        </Nav.Menu>
                    </Nav.Menu>
                </Nav>
            )}

            <Nav pullRight>
                {isGrafica && permissionsGrafica !== StatoDiritti.NESSUNO && (
                    <Nav.Item className='w-[15rem]'>
                        <CimiteriTypeViewSelector />
                    </Nav.Item>
                )}

                {!isTabelle && !!formData && formData?.length > 0 && (
                    <Nav.Item className='w-[15rem]'>
                        <Municipality data={formData} />
                    </Nav.Item>
                )}

                <Nav.Menu
                    title='Impostazioni'
                    placement='bottomEnd'
                    icon={<FontAwesomeIcon icon={faGear} color='white' />}
                >
                    <Nav.Item as={Link} to='/impostazioni/generali/colori'>
                        Colori grafica
                    </Nav.Item>
                    {(isAdmin || isSysAdmin) && (
                        <>
                            <Nav.Item as={Link} to='/impostazioni/gestione-utenti'>
                                Gestione utenti
                            </Nav.Item>
                            <Nav.Item as={Link} to='#' onClick={handleDownloadMacro}>
                                Scarica macro per salvataggio documenti
                            </Nav.Item>
                        </>
                    )}
                    {isSysAdmin && (
                        <Nav.Item as={Link} to='/manager'>
                            Manager
                        </Nav.Item>
                    )}
                    <Nav.Item as={Link} to='/tabelle'>
                        Tabelle
                    </Nav.Item>
                    {/* <Nav.Item onClick={handleOpenChangelog}>Cronologia versioni</Nav.Item> */}
                    <Nav.Item as={Link} to='/cronologia-versioni'>
                        Cronologia versioni
                    </Nav.Item>
                    <Nav.Item as={Link} to='#' onClick={handleOpenChangelog}>
                        Supporto
                    </Nav.Item>
                </Nav.Menu>

                <Nav.Menu title={user?.userName} className='user-nav-menu w-fit px-12' placement='bottomEnd'>
                    <Nav.Item as={Link} to='/impostazioni/profilo' className='w-full'>
                        Profilo
                    </Nav.Item>
                    <Nav.Item as={Link} to='/impostazioni/cambio-password' className='w-full'>
                        Cambio password
                    </Nav.Item>

                    {/*
                        <Nav.Item as={Link} to='/impostazioni/configurazione-cruxweb' className='w-full'>
                            Configurazione CruxWeb
                        </Nav.Item>
                    )} */}

                    <Nav.Item onClick={() => logout()} className='w-full'>
                        Logout
                    </Nav.Item>
                </Nav.Menu>
            </Nav>
        </Navbar>
    );
};

export default CruxNavbar;
