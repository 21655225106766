import { useFormikContext } from "formik";
import { Panel } from "rsuite";
import * as Yup from "yup";

import { IAddPostiTomba } from "@models/SettoreCimitero";

import FormDrawerStack from "@components/Drawers/FormDrawerStack";
import FormGroup from "@components/Form/FormGroup";
import NumberInput from "@components/Form/Input/NumberInput";

export const AggiuntaPostiTombaSchema = Yup.object().shape({
    postiSalma: Yup.number(),
    postiResto: Yup.number(),
});

const AggiuntaPostiTombaForm = ({
    oldPostiResto = 0,
    oldPostiSalma = 0,
}: {
    oldPostiSalma?: number;
    oldPostiResto?: number;
}) => {
    const { values, setFieldValue } = useFormikContext<IAddPostiTomba>();
    const { postiResto, postiSalma } = values;

    const getPostiResto = postiResto ? +postiResto : 0;
    const getPostiSalma = postiSalma ? +postiSalma : 0;

    return (
        <FormDrawerStack>
            <div className='flex flex-row justify-start space-x-12 w-full my-3'>
                <div className='border w-fit border-primary-400 bg-primary-20 text-primary-700 p-3'>
                    Posti Salma totali:&nbsp;
                    <span className='font-bold text-md'>{oldPostiSalma + getPostiSalma}</span>
                </div>
                <div className='border w-fit bg-primary-20  border-primary-400 text-primary-700 p-3'>
                    Posti Resto totali:&nbsp;
                    <span className='font-bold text-md'>{oldPostiResto + getPostiResto}</span>
                </div>
            </div>
            <Panel bordered className='bg-athens-200'>
                <FormGroup name='postiSalma' label='Posti salma' className='mt-0 w-full'>
                    <NumberInput name='postiSalma' min={0} />
                </FormGroup>

                <FormGroup name='postiResto' label='Posti resto' className='mt-0 w-full'>
                    <NumberInput name='postiResto' min={0} />
                </FormGroup>
            </Panel>
        </FormDrawerStack>
    );
};

export default AggiuntaPostiTombaForm;
