import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import ColorPicker from "react-best-gradient-color-picker";
import { IconButton } from "rsuite";

import { ColoreGrafica } from "@models/Grafica";

import PreviewColor from "@modules/settings/sections/colors/_components/PreviewColor";

interface ColorsSettingsRowProps {
    title: string;
    colorSettings: ColoreGrafica;
    onChangeColor: (color: ColoreGrafica) => void;
    onClickColor: (key: string) => void;
    open: { [key: string]: boolean };
}

const ColorButton = ({
    color,
    onChange,
    disabledChromePicker,
    onClickColor,
    open,
}: {
    color: string | undefined;
    onChange: (color: string) => void;
    disabledChromePicker?: boolean;
    onClickColor?: () => void;
    open: boolean;
}) => {
    const handleOnChange = (newColor: string) => {
        onChange(newColor);
    };

    return (
        <div className='relative block'>
            <button
                type='button'
                className={`bg-white inline-block shadow-md rounded-sm border p-1 ${
                    !disabledChromePicker ? "cursor-pointer" : "cursor-auto"
                }`}
                onClick={() => {
                    if (onClickColor) onClickColor();
                }}
            >
                <div
                    className='h-16 w-40'
                    style={{
                        background: color,
                    }}
                />
            </button>
            {open && !disabledChromePicker ? (
                <div className='absolute z-20 bg-primary-300 p-8 rounded left-1/2 -translate-x-1/2'>
                    <div className='flex justify-end mb-8'>
                        <IconButton
                            onClick={onClickColor}
                            icon={<FontAwesomeIcon icon={faClose as IconProp} />}
                            title='download file'
                            appearance='ghost'
                            size='sm'
                        />
                    </div>
                    <ColorPicker
                        value={color}
                        onChange={handleOnChange}
                        hideAdvancedSliders
                        hideColorTypeBtns
                        hideControls
                        hideEyeDrop
                        hideColorGuide
                        hideGradientAngle
                        hideGradientControls
                        hideGradientStop
                        hideGradientType
                        hideInputType
                        hidePresets
                        height={150}
                        width={280}
                    />
                </div>
            ) : null}
        </div>
    );
};

const ColorsSettingsRow: FC<ColorsSettingsRowProps> = ({ title, colorSettings, onChangeColor, onClickColor, open }) => {
    return (
        <div className='grid grid-cols-4 my-2'>
            <div>{title.replaceAll("_", " ")}</div>
            <div className='flex justify-center items-center'>
                <ColorButton
                    key={title}
                    color={colorSettings?.bordo ?? "#000"}
                    onChange={(colorHex) => onChangeColor({ ...colorSettings, bordo: colorHex })}
                    onClickColor={() => {
                        onClickColor(`${title}_bordo`);
                    }}
                    open={open[`${title}_bordo`]}
                />
            </div>
            <div className='flex justify-center items-center'>
                <ColorButton
                    key={title}
                    color={colorSettings?.sfondo ?? "#000"}
                    onChange={(colorHex) => onChangeColor({ ...colorSettings, sfondo: colorHex })}
                    onClickColor={() => {
                        onClickColor(`${title}_sfondo`);
                    }}
                    open={open[`${title}_sfondo`]}
                />
            </div>
            <div className='flex justify-center items-center'>
                <PreviewColor border={colorSettings?.bordo ?? "#000"} fill={colorSettings?.sfondo ?? "#000"} />
            </div>
        </div>
    );
};

export { ColorsSettingsRow, ColorButton };
