import { useField } from "formik";
import * as React from "react";
import { FC } from "react";
import { Input as RsInput, InputProps as RsInputProps } from "rsuite";

interface TextAreaInputPros extends Omit<RsInputProps, "form" | "as"> {
    name: string;
    transformValue?: (value: string) => any;
}

const TextAreaInput: FC<TextAreaInputPros> = ({ transformValue, ...props }) => {
    const [field, meta, helpers] = useField(props.name);

    const handleOnChange = (value: string, event: any) => {
        let newValue = value;
        if (transformValue) {
            newValue = transformValue(value);
        }
        helpers.setValue(newValue);
        props.onChange?.(newValue, event);
    };

    return <RsInput {...field} value={field.value ?? ""} onChange={handleOnChange} {...props} as='textarea' />;
};

export default TextAreaInput;
