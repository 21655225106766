import React from "react";
import { useRecoilValue } from "recoil";
import { ItemDataType } from "rsuite/cjs/@types/common";

import { isCimiteriEditModeState } from "@stores/graveyard";

import CimiteroEditDropdown from "@modules/cimiteri/_components/Sidebar/CimiteroEditDropdown";

const TreeNodeRender = (node: ItemDataType) => {
    const isEditMode = useRecoilValue(isCimiteriEditModeState);
    return (
        <div className='w-full flex justify-between text-xs'>
            <span className='text-xs'>{node.label}</span>
            {isEditMode && (
                <CimiteroEditDropdown idSettore={parseFloat(node.value as string)} idPadri={node.parentIds} />
            )}
        </div>
    );
};

export default TreeNodeRender;
