import { useSetState } from "ahooks";
import { uniqueId } from "lodash";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { Draw } from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { EIconeDraw } from "@models/Configs";
import { ColoreGrafica, ELayer, IGrafica, TestoGrafica } from "@models/Grafica";
import { SettoreCimitero } from "@models/SettoreCimitero";

import EditTestoPanel from "@openLayersMap/Components/Panels/EditTestoPanel";
import { featureElement, featureLayer, findDrawFeature } from "@openLayersMap/Helpers/EditMappingHelpers";
import { getBorderStyle, getFillStyle, getTextStyle, getVectorStyle } from "@openLayersMap/Helpers/StyleHelper";
import { useMap } from "@openLayersMap/Map/MapContext";
import { wkt } from "@openLayersMap/Source";
import { featuresEditState, typeDrawState } from "@openLayersMap/Stores/editControls";

export type TestoDrawType = "rectangle" | "polygon";

interface TestoHookProps {
    source: VectorSource;
    inEditFeature: Feature<Geometry> | null;
    onTestoCreated?: (e: IGrafica) => void;
    settoreCimitero: SettoreCimitero;
}

const vector = new VectorLayer({
    source: new VectorSource({ wrapX: false }),
    className: "testo-vector-layer",
    style: getVectorStyle,
});

export interface TestoSettings {
    testo: TestoGrafica;
    colore: ColoreGrafica;
}

const initialValues: TestoSettings = {
    testo: {
        val: "Testo da inserire",
        rot: 0,
        size: 12,
    },
    colore: {
        bordo: "#ffffff",
        sfondo: "#000000",
    },
};

const useTestoDrawHook = ({ source, inEditFeature, onTestoCreated, settoreCimitero }: TestoHookProps) => {
    const { map } = useMap();

    const setFeaturesEdit = useSetRecoilState(featuresEditState);
    const typeDraw = useRecoilValue(typeDrawState);
    const isModify = typeDraw === EIconeDraw.Modifica;
    const initTextSettings = useMemo(() => {
        if (!inEditFeature) return initialValues;
        const layer = featureLayer(inEditFeature);
        const properties = featureElement(inEditFeature);
        if (layer !== ELayer.Testo) return initialValues;

        return {
            testo: {
                val: properties?.testo?.val,
                rot: properties?.testo?.rot,
                size: properties?.testo?.size,
            },
            colore: {
                bordo: properties?.colore?.bordo,
                sfondo: properties?.colore?.sfondo,
            },
        };
    }, [inEditFeature]);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [textSettings, setTextSettings] = useSetState<TestoSettings>(initTextSettings);
    const [tmpLocation, setTmpLocation] = useState<any>(null);
    const hasDrawn = useMemo(() => (!isModify ? true : !!tmpLocation), [tmpLocation]);

    const draw = new Draw({
        source,
        type: "Point",
    });

    const getTempFeature = useCallback(() => source.getFeatures().find((f) => f.getProperties().isTemp), [source]);
    const getFeature =
        isModify && inEditFeature ? findDrawFeature({ feature: inEditFeature, source }) : getTempFeature();

    const removeTempFeature = () => {
        const tmpFeature = getTempFeature();
        if (tmpFeature) source.removeFeature(tmpFeature);
    };

    const handleCancelCreateTesto = () => {
        removeTempFeature();

        map?.addInteraction(draw);
        setTmpLocation(null);
        setTextSettings(initTextSettings);
    };

    const handleSaveTesto = (data: TestoSettings) => {
        const createdFeatureTesto = getFeature;
        if (!createdFeatureTesto) return;

        setIsSaving(true);

        const id = createdFeatureTesto.getProperties().id ?? uniqueId();
        const grafica: IGrafica = {
            id: inEditFeature ? id : undefined,
            idStruttura: settoreCimitero.id,
            colore: data.colore,
            testo: data.testo,
            layer: ELayer.Testo,
            geom: wkt.writeGeometry(createdFeatureTesto?.getGeometry() as Geometry),
        };
        setFeaturesEdit((prev) => {
            return {
                ...prev,
                ...{ [id]: grafica },
            };
        });

        if (isModify) {
            setTextSettings({
                colore: {
                    sfondo: data.colore.sfondo ?? featureElement(createdFeatureTesto)?.colore?.sfondo,
                    bordo: data.colore.bordo ?? featureElement(createdFeatureTesto)?.colore?.bordo,
                },
                testo: {
                    val: data.testo.val ?? featureElement(createdFeatureTesto)?.testo?.val,
                    size: data.testo.size ?? featureElement(createdFeatureTesto)?.testo?.size,
                    rot: data.testo.rot ?? featureElement(createdFeatureTesto)?.testo?.rot,
                },
            });
        } else createdFeatureTesto.setProperties({ isTemp: false });

        onTestoCreated?.(grafica);
        // setTypeDraw(EIconeDraw.Seleziona);
        setIsSaving(false);
        if (!isModify) handleCancelCreateTesto();
    };

    useEffect(() => {
        if (inEditFeature) {
            const layer = featureLayer(inEditFeature);

            if (layer === ELayer.Testo) {
                inEditFeature.setProperties({ isTemp: !isModify });
                setTmpLocation((inEditFeature as any).getGeometry().getCoordinates());
                setTextSettings({
                    colore: {
                        sfondo: featureElement(inEditFeature)?.colore?.sfondo,
                        bordo: featureElement(inEditFeature)?.colore?.bordo,
                    },
                    testo: {
                        val: featureElement(inEditFeature)?.testo?.val,
                        size: featureElement(inEditFeature)?.testo?.size,
                        rot: featureElement(inEditFeature)?.testo?.rot,
                    },
                });
            }
        }

        return () => {
            removeTempFeature();
        };
    }, [inEditFeature]);

    useEffect(() => {
        const tmpFeature = getFeature;
        if (!tmpFeature) return;

        tmpFeature.setStyle((f: any, resolution: any) => {
            const text = getTextStyle({
                text: textSettings?.testo?.val ? [textSettings?.testo?.val, ""] : [],
                color: textSettings.colore.sfondo ?? "#000000",
                align: "left",
                resolution,
                size: textSettings.testo.size,
                rotation: textSettings?.testo?.rot ?? 0,
                showOnlyLargerFont: true,
                shouldCalculateSize: false,
                limitTextBetweenRange: false,
            });

            if (!isModify) text.setOffsetX(10);
            f.set("resolution", resolution);
            const image = new CircleStyle({
                radius: 5,
                fill: getFillStyle("rgba(0,159,213,0.29)"),
                stroke: getBorderStyle("#000000", 1),
            });
            return new Style({
                image: isModify || tmpFeature.getProperties().isTemp ? image : undefined,
                text,
            });
        });
    }, [tmpLocation, textSettings, getTempFeature, typeDraw]);

    draw.on("drawstart", () => {
        removeTempFeature();
    });

    draw.on("drawend", (e: any) => {
        const { feature } = e;
        feature.setProperties({ id: uniqueId(), isTemp: true, properties: { layer: 3 } });
        setTmpLocation(feature.getGeometry().getCoordinates());
    });

    useEffect(() => {
        if (map && !isModify) {
            map.addLayer(vector);
            map.addInteraction(draw);
        }

        return () => {
            removeTempFeature();
            map?.removeLayer(vector);
            map?.removeInteraction(draw);
        };
    }, [map, inEditFeature]);

    return {
        handleCancelCreateTesto,
        handleSaveTesto,
        textSettings,
        setTextSettings,
        hasDrawn,
        isSaving,
        isModify,
    };
};

const DrawTestoComponent: FC<TestoHookProps> = ({ source, inEditFeature, onTestoCreated, settoreCimitero }) => {
    const hook = useTestoDrawHook({ source, inEditFeature, onTestoCreated, settoreCimitero });

    return (
        <EditTestoPanel
            isSaving={hook.isSaving}
            isModify={hook.isModify}
            hasDrawn={hook.hasDrawn}
            textSettings={hook.textSettings}
            setTextSettings={hook.setTextSettings}
            handleConfirm={hook.handleSaveTesto}
            handleCancel={hook.handleCancelCreateTesto}
        />
    );
};

export { DrawTestoComponent };
