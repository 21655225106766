import FileDownload from "js-file-download";
import React, { FC, useEffect, useState } from "react";
import { Button, Drawer } from "rsuite";

import { listFile } from "@services/FileService";
import { setDocumentoBase } from "@services/stampa-unione/StampaUnioneService";

import { ContrattoTomba } from "@models/Contratto";
import File from "@models/File";

import { useCruxRequest } from "@hooks/useCruxRequest";
import { useCruxToaster } from "@hooks/useCruxToaster";

import AddFileForm from "@components/Drawers/Files/_components/AddFileForm";
import FileList from "@components/Drawers/Files/_components/FileList";
import RightDrawer from "@components/Drawers/RightDrawer";
import StampaUnioneModal, { StampaUnioneConfirm } from "@components/Modal/StampaUnioneModal";

import { getFilenameFromResponse } from "@helpers/FileHelper";

export type FileEntityType = "T" | "P" | "L" | "C" | "A" | "R";

interface DrawerFilesProps {
    entityType: FileEntityType;
    entityId: number;
    onClose: () => void;
    onFileUpdate?: () => void;
    title?: string;
    stampaUnione?: boolean;
    tombe?: ContrattoTomba[];
}

const DrawerFiles: FC<DrawerFilesProps> = ({
    entityType,
    entityId,
    onClose,
    title = "Files",
    stampaUnione,
    onFileUpdate,
    tombe,
}) => {
    const {
        data: files,
        run,
        error,
    } = useCruxRequest(listFile, {
        defaultParams: [entityType, entityId],
        manual: true,
    });
    const { handleApiError } = useCruxToaster();

    const [loading, setLoading] = useState(false);
    const [newAddedFiles, setNewAddedFiles] = useState<File[]>([]);
    const [addFile, setAddFile] = useState(!stampaUnione);
    const [generaDocumento, setGeneraDocumento] = useState(false);

    const onAddFileHandle = (newFile: File) => {
        setNewAddedFiles((old) => [...old, newFile]);
        if (onFileUpdate) onFileUpdate();
    };

    const handleConfirm = ({ checkedKey, allExportMap }: StampaUnioneConfirm) => {
        if (checkedKey && entityId) {
            setLoading(true);
            setDocumentoBase(checkedKey, entityId, allExportMap)
                .then((resp) => {
                    const filename = getFilenameFromResponse(resp, "Documento.docx");
                    FileDownload(resp.data, filename);

                    run(entityType, entityId);
                    if (entityType === "C" && onFileUpdate) onFileUpdate();
                    setGeneraDocumento(false);
                })
                .catch(async (apiError) => {
                    const errorBlob = JSON.parse(await apiError?.response?.data.text());
                    handleApiError({
                        message:
                            errorBlob?.meta?.errori.map((err: string) => err) ??
                            "Errore nella generazione del documento",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (entityType && entityId) run(entityType, entityId);
    }, [entityType, entityId]);

    useEffect(() => {
        if (error)
            handleApiError({
                message: (error as any)?.response?.data?.meta?.errori[0] ?? "Errore nel recupero dei documenti",
            });
    }, [error]);

    return (
        <>
            <StampaUnioneModal
                isOpen={generaDocumento}
                isLoading={loading}
                handleClose={() => setGeneraDocumento(false)}
                handleConfirm={handleConfirm}
                tombe={tombe}
                {...(entityType === "C" ? { isContratti: true } : {})}
                {...(entityType === "T" ? { isTomba: true } : {})}
            />
            <RightDrawer size='sm' onClose={onClose}>
                <Drawer.Header>
                    <Drawer.Title className='flex justify-between items-center'>
                        <div>{title}</div>
                        <Drawer.Actions>
                            <div className='w-full flex justify-end space-x-12'>
                                {!addFile && stampaUnione && (
                                    <Button
                                        type='button'
                                        appearance='primary'
                                        onClick={() => {
                                            setGeneraDocumento(true);
                                        }}
                                    >
                                        Genera documento
                                    </Button>
                                )}
                                <Button type='button' appearance='primary' onClick={() => setAddFile((prev) => !prev)}>
                                    {!addFile
                                        ? `Collega ${title !== "Files" ? "documento" : "file"}`
                                        : `Chiudi collega ${title !== "Files" ? "documento" : "file"}`}
                                </Button>
                            </div>
                        </Drawer.Actions>
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className='p-3 h-drawer bg-athens-100'>
                    {addFile && (
                        <AddFileForm
                            onAdded={onAddFileHandle}
                            entityId={entityId}
                            entityType={entityType}
                            title={title}
                        />
                    )}
                    <FileList
                        items={newAddedFiles ?? []}
                        title={title !== "Files" ? "Documenti appena aggiunti" : "File appena aggiunti"}
                        onFileUpdate={onFileUpdate}
                    />
                    <FileList
                        items={files ?? []}
                        title={title !== "Files" ? "Lista dei documenti" : "Lista di file"}
                        onFileUpdate={onFileUpdate}
                    />
                </Drawer.Body>
            </RightDrawer>
        </>
    );
};
export default DrawerFiles;
