import { useRequest } from "ahooks";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import {
    addSettore,
    addTomba,
    deleteSettore,
    getCimiteri,
    getSettoreCimitero,
    updateSettore,
} from "@services/CimiteriService";

import { PageSidebar } from "@context/PageDataContext";

import { cimiteroEventState } from "@stores/graveyard";
import { addLivelloState, deleteLivelloState, updateLivelloState } from "@stores/livello";
import { cimiteriListState, reloadSidenavTreeState } from "@stores/municipality";

import { CimiteroEvent } from "@models/Events/Cimiteri";
import { SettoreCimitero } from "@models/SettoreCimitero";
import { IAddTomba, IStrutturaLivelli, ITombe } from "@models/StrutturaLivelli";

import { useCruxToaster } from "@hooks/useCruxToaster";

import RightFormDrawer from "@components/Drawers/RightFormDrawer";
import ConfirmModal from "@components/Modal/ConfirmModal";

import { getTitle, isAdd, isAddTomba, isDelete, isEdit } from "@helpers/Levels";

import GraveyardsSidebar from "./_components/Sidebar/GraveyardsSidebar";
import LivelloForm, { LivelloSchema } from "./_forms/LivelloForm";
import TombaForm, { TombaSchema } from "./_forms/TombaForm";

const GraveyardsContainer = () => {
    const { handleApiError, handleApiSuccess } = useCruxToaster();
    const isCimiteriEditMode = useRecoilValue(cimiteroEventState);
    const setActualEventState = useSetRecoilState(cimiteroEventState);
    const setAddLivelloState = useSetRecoilState(addLivelloState);
    const setUpdateLivelloState = useSetRecoilState(updateLivelloState);
    const setDeleteLivelloState = useSetRecoilState(deleteLivelloState);
    const setCimiteriList = useSetRecoilState(cimiteriListState);
    const setReloadSidenavTree = useSetRecoilState(reloadSidenavTreeState);

    const { data: cimiteriList, run } = useRequest(getCimiteri, {
        manual: true,
        onSuccess: setCimiteriList,
    });

    const isSettore = isEdit(isCimiteriEditMode) || isAdd(isCimiteriEditMode);
    const navigate = useNavigate();

    const defaultInitialValuesLivello = {
        idLivello: undefined,
        codice: "",
        descr: "",
        visualizzaDescr: false,
        visualizzaSuWeb: false,
    };

    const defaultInitialValuesTombe: ITombe = {
        idTipoTomba: undefined,
        idSettore: 0,
        prefisso: "",
        suffisso: "",
        tombeDaAggiungere: 1,
        tombaIniziale: undefined,
        incremento: 1,
        tombaFinale: undefined,
        toggleAddTomba: true,
        toggleEndTomba: false,
    };

    const [initialValues, setInitialValues] = useState<Partial<IStrutturaLivelli> | ITombe | undefined>(undefined);
    const [idLivelloPadre, setIdLivelloPadre] = useState<number | undefined>(undefined);

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
        setActualEventState(undefined);
    };

    const redirect = ({ resp }: { resp?: SettoreCimitero }): void => {
        if (isCimiteriEditMode?.evento === CimiteroEvent.ADD_SUB_SETTORE && resp && resp.livello.id === 1) {
            navigate(`/cimitero/${resp.id}`);
        }

        if (isCimiteriEditMode?.evento === CimiteroEvent.ADD_SUB_SETTORE && resp && resp.livello.id > 1) {
            navigate(
                `/cimitero/${
                    isCimiteriEditMode.idPadri && isCimiteriEditMode.idPadri.length > 0
                        ? isCimiteriEditMode.idPadri[0]
                        : isCimiteriEditMode.idSettore
                }/settore/${resp.id}`
            );
        }

        if (
            isCimiteriEditMode?.evento === CimiteroEvent.DELETE_SETTORE &&
            isCimiteriEditMode?.idPadri &&
            isCimiteriEditMode?.idPadri?.length > 0
        ) {
            navigate(`/cimitero/${isCimiteriEditMode?.idPadri[0]}`);
        }

        if (
            isCimiteriEditMode?.evento === CimiteroEvent.DELETE_SETTORE &&
            isCimiteriEditMode?.idPadri &&
            isCimiteriEditMode?.idPadri?.length === 0
        ) {
            navigate("/cimitero");
        }

        setTimeout(() => {
            setReloadSidenavTree(false);
        }, 500);
    };

    const handleConfirmDelete = () => {
        handleClose();

        if (isCimiteriEditMode) {
            setReloadSidenavTree(true);

            deleteSettore({ idSettore: isCimiteriEditMode?.idSettore })
                .then((idLivello) => {
                    run();
                    handleApiSuccess({ message: "Settore eliminato" });
                    setDeleteLivelloState({ idLivello });
                    setActualEventState(undefined);
                    redirect({});
                })
                .catch((apiError) => {
                    handleApiError({ error: apiError.response.data });
                });
        }
    };

    const handleOnCreateSettore = (payload: Partial<IStrutturaLivelli>) => {
        return addSettore({ payload })
            .then((resp) => {
                run();
                handleApiSuccess({ message: `Aggiunto nuovo settore ${resp.descrCompleta}` });
                setAddLivelloState({ idLivello: resp.id, descrCompleta: resp.descrCompleta });
                setActualEventState(undefined);
                setInitialValues(defaultInitialValuesLivello);
                redirect({ resp });
            })
            .catch((apiError) => {
                handleApiError({ error: apiError.response.data });
            });
    };

    const handleOnUpdate = (payload: Partial<IStrutturaLivelli>) => {
        return updateSettore({ payload })
            .then((resp) => {
                run();
                handleApiSuccess({ message: "Settore aggiornato" });
                setUpdateLivelloState({ idLivello: resp.id, descrCompleta: resp.descrCompleta });
                setActualEventState(undefined);
                redirect({ resp });
            })
            .catch((apiError) => {
                handleApiError({ error: apiError.response.data });
            });
    };

    const handleOnCreateTomba = (payload: ITombe) => {
        const tombeToAdd: IAddTomba[] = [];

        if (payload.tombeDaAggiungere === 1) {
            const tomba: IAddTomba = {
                idTipoTomba: payload.idTipoTomba ?? 0,
                geom: null,
                numero: payload.prefisso + (payload.tombaIniziale?.toString() ?? "") + payload.suffisso, // todo -> se ho tombaIniziale a numero, uso anche prefisso e suffisso
            };

            tombeToAdd.push(tomba);
        } else if (payload.tombaIniziale && payload.tombeDaAggiungere && payload.incremento) {
            const tombaFinale = +payload.tombaIniziale + (payload.tombeDaAggiungere - 1) * (payload.incremento ?? 1);
            for (let i = +payload.tombaIniziale ?? 1; i <= tombaFinale; i += payload.incremento ?? 1) {
                const tomba: IAddTomba = {
                    idTipoTomba: payload.idTipoTomba ?? 0,
                    geom: null,
                    numero: payload.prefisso + i.toString() + payload.suffisso,
                };

                tombeToAdd.push(tomba);
            }
        }

        return addTomba({ idSettore: payload.idSettore, payload: tombeToAdd })
            .then((resp) => {
                handleApiSuccess({
                    message: +resp > 1 ? `Aggiunte ${resp} nuove tombe` : "Aggiunta nuova tomba",
                });
                setActualEventState(undefined);
                setInitialValues(defaultInitialValuesTombe);
            })
            .catch((apiError) => {
                handleApiError({ error: apiError.response.data });
            });
    };

    const handleOnSave = (data: Partial<IStrutturaLivelli> | ITombe): Promise<void | any> => {
        if ("idLivello" in data && isSettore) {
            const payload: Partial<IStrutturaLivelli> = {
                idPadri: isCimiteriEditMode?.idPadri,
                idLivello: data.idLivello || 0,
                idSettore: isCimiteriEditMode?.idSettore,
                codice: data.codice || "",
                descr: data.descr || "",
                visualizzaDescr: data.visualizzaDescr,
                visualizzaSuWeb: true,
            };

            if (isEdit(isCimiteriEditMode)) return handleOnUpdate(payload);

            if (isAdd(isCimiteriEditMode)) return handleOnCreateSettore(payload);
        }

        if ("idTipoTomba" in data && isAddTomba(isCimiteriEditMode)) {
            const payload: ITombe = {
                idTipoTomba: data.idTipoTomba,
                idSettore: isCimiteriEditMode?.idSettore || 0,
                tombaIniziale: data.tombaIniziale,
                prefisso: data.prefisso || "",
                suffisso: data.suffisso || "",
                tombeDaAggiungere: (data.tombeDaAggiungere && +data.tombeDaAggiungere) || 0,
                incremento: (data.incremento && +data.incremento) || 0,
                tombaFinale: undefined,
            };

            return handleOnCreateTomba(payload);
        }

        return Promise.reject();
    };

    useEffect(() => {
        if (isCimiteriEditMode) {
            getSettoreCimitero(isCimiteriEditMode.idSettore)
                .then((res) => {
                    if (isEdit(isCimiteriEditMode)) {
                        setInitialValues({
                            idLivello: res.livello?.id,
                            codice: res.codice ?? "",
                            descr: res.descr ?? "",
                            visualizzaDescr: res.visualizzaDescr,
                        });
                    }

                    if (isAdd(isCimiteriEditMode)) {
                        setInitialValues(defaultInitialValuesLivello);
                        setIdLivelloPadre(res?.livello?.id ?? undefined);
                    }
                })
                .catch((apiError) => {
                    handleApiError({ error: apiError.response?.data });
                });
        }

        if (isDelete(isCimiteriEditMode)) setOpen(true);
        // eslint-disable-next-line
    }, [isCimiteriEditMode]);

    useEffect(() => {
        if (isSettore) setInitialValues(defaultInitialValuesLivello);
        if (!isSettore) setInitialValues(defaultInitialValuesTombe);
        // eslint-disable-next-line
    }, [isSettore]);

    return (
        <>
            <PageSidebar>
                <GraveyardsSidebar />
            </PageSidebar>

            {isCimiteriEditMode && isCimiteriEditMode.evento !== CimiteroEvent.DELETE_SETTORE && !!initialValues && (
                <RightFormDrawer
                    size='lg'
                    title={getTitle({ isCimiteriEditMode })}
                    formData={initialValues}
                    validationSchema={isSettore ? LivelloSchema : TombaSchema}
                    onClose={() => setActualEventState(undefined)}
                    onActionPerformed={handleOnSave}
                >
                    {isCimiteriEditMode.evento !== CimiteroEvent.ADD_TOMBA && (
                        <LivelloForm
                            idSettore={isCimiteriEditMode.idSettore}
                            idLivelloPadre={idLivelloPadre}
                            isCimiteriEditMode={isCimiteriEditMode}
                        />
                    )}

                    {isCimiteriEditMode.evento === CimiteroEvent.ADD_TOMBA && <TombaForm />}
                </RightFormDrawer>
            )}

            <ConfirmModal
                handleConfirm={handleConfirmDelete}
                handleClose={handleClose}
                isOpen={open}
                message='Sei sicuro di voler cancellare questo settore e tutti i suoi sottolivelli?'
            />
            <Outlet />
        </>
    );
};

export default GraveyardsContainer;
