/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { faArrowUp19 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Input } from "rsuite";

interface RegularRetinoToolsetProps {
    retinoLati: number;
    handleSaveLatiRetino: (lati: number) => void;
}

const RegularRetinoToolset = ({ retinoLati, handleSaveLatiRetino }: RegularRetinoToolsetProps) => {
    const [newRetino, setNewRetino] = useState(retinoLati ?? 4);

    return (
        <div>
            <ul className='space-y-8'>
                <li className='cursor-pointer space-x-12 flex items-center'>
                    <div className='w-2/3 space-x-8'>
                        <FontAwesomeIcon icon={faArrowUp19} />
                        <span>Numero lati:</span>
                    </div>
                    <Input
                        size='xs'
                        type='number'
                        min={4}
                        max={10}
                        value={newRetino}
                        onChange={(value) => setNewRetino(+value)}
                        className='w-1/3'
                    />
                </li>
            </ul>

            <div className='flex justify-end mt-20'>
                <Button
                    type='button'
                    appearance='primary'
                    disabled={retinoLati === newRetino}
                    onClick={() => handleSaveLatiRetino(newRetino)}
                >
                    conferma
                </Button>
            </div>
        </div>
    );
};

export default RegularRetinoToolset;
