import { uniqueId } from "lodash";
import { Feature } from "ol";
import { getHeight, getWidth } from "ol/extent";
import { Geometry } from "ol/geom";
import VectorSource from "ol/source/Vector";

import { EIconeDraw } from "@models/Configs";
import { ELayer, IGrafica } from "@models/Grafica";

import { getFeatureFillColor, getFeatureStrokeColor } from "@openLayersMap/Helpers/StyleHelper";
import { IText, ITransformAndModify } from "@openLayersMap/Models/EditMapping";
import { wkt } from "@openLayersMap/Source";

const getType = (feature: Feature<Geometry>): string | undefined => {
    const geometry = feature?.getGeometry();
    const type = geometry?.getType();
    return type === EIconeDraw.Circle ? EIconeDraw.Point.toUpperCase() : type?.toUpperCase();
};

const getGeometry = (feature: Feature<Geometry>): Geometry | undefined => {
    return feature?.getGeometry();
};

const featureProperties = (feature: Feature<Geometry>) => feature.getProperties();
const featureElement = (feature: Feature<Geometry>) => feature.getProperties()?.properties?.element;
const featureLayer = (feature: Feature<Geometry>) =>
    feature.getProperties()?.layer ?? feature.getProperties()?.properties?.layer;

const getSize = (feature: Feature<Geometry>): number => {
    if (featureElement(feature)?.grafiche) return featureElement(feature)?.grafiche[0]?.testo?.size;
    if (featureElement(feature)?.grafica) return featureElement(feature)?.grafica?.testo?.size;
    return featureElement(feature)?.testo?.size;
};

const featureTesto = (feature: Feature<Geometry>): any => {
    return {
        val: featureElement(feature)?.testo?.val ?? featureProperties(feature)?.name,
        size: getSize(feature),
        rot: featureElement(feature)?.testo?.rot,
    };
};

const defaultModify: ITransformAndModify = {
    enableRotatedTransform: false,
    translateFeature: false,
    scale: false,
    rotate: false,
    keepRectangle: false,
    translate: false,
    stretch: false,
    selection: false,
    pin: false,
};

const getDimensioneTestoFeature = (f: Feature<Geometry>): number => {
    if (f) {
        const geometry = f.getGeometry();
        const extentF = (geometry as any)?.getExtent();

        const width = getWidth(extentF);
        const height = getHeight(extentF);

        return Math.sqrt((width * height) / 2);
    }

    return 12;
};

const getCircleCoordinates = (geometry: Geometry | undefined): string => {
    const coordinates = (geometry as any).getCenter().toString();
    return `${EIconeDraw.Point.toUpperCase()}(${coordinates.replace(",", " ")})`;
};

const getPolygonCoordinates = (geometry: Geometry | undefined): string => {
    const geometries = (geometry as any).getGeometries();
    return wkt.writeGeometry(geometries[0]);
};

const getFeatureCoordinates = (geometry: Geometry | undefined): string => {
    return geometry ? wkt.writeGeometry(geometry) : "";
};

const getCoordinates = (feature: Feature<Geometry>): string => {
    const type = getType(feature);
    const layer = featureLayer(feature);

    if (type === EIconeDraw.Point.toUpperCase() && layer === ELayer.Testo)
        return getFeatureCoordinates(feature.getGeometry());
    if (type === EIconeDraw.Point.toUpperCase() && layer !== ELayer.Testo)
        return getCircleCoordinates(feature.getGeometry());
    if (type === EIconeDraw.GeometryCollection.toUpperCase()) return getPolygonCoordinates(feature.getGeometry());

    return wkt.writeGeometry(feature.getGeometry() ?? new Geometry());
};

const getRaggio = (feature: Feature<Geometry>) => {
    if (!feature) return undefined;

    const type = getType(feature);
    const layer = featureLayer(feature);

    if (type === EIconeDraw.Point.toUpperCase() && layer !== ELayer.Testo)
        return (getGeometry(feature) as any)?.getRadius();

    return undefined;
};

const mapFeature = (feature: Feature<Geometry> | null): IGrafica | null => {
    if (!feature) return null;

    return {
        id: featureProperties(feature).id,
        layer: featureLayer(feature),
        idStruttura: featureElement(feature)?.idStruttura,
        geom: getCoordinates(feature),
        raggio: getRaggio(feature),
        colore: {
            sfondo: featureElement(feature)?.colore?.sfondo,
            bordo: featureElement(feature)?.colore?.bordo,
        },
        testo: {
            val: featureElement(feature)?.testo?.val ?? featureElement(feature)?.text?.val,
            size: featureElement(feature)?.testo?.size ?? featureElement(feature)?.text?.size,
            rot: featureElement(feature)?.testo?.rot ?? featureElement(feature)?.text?.rot,
        },
    };
};

const getDrawRetinoProperties = (feature: Feature<Geometry>, layer: number, idStruttura: number): any => {
    return {
        editId: +uniqueId(),
        properties: {
            layer,
            element: {
                idStruttura,
                geom: getCoordinates(feature),
                raggio: getRaggio(feature),
                colore: {
                    sfondo: getFeatureFillColor(feature) || "#000",
                    bordo: getFeatureStrokeColor(feature) || "#000",
                },
                layer,
            },
        },
    };
};

const saveNewPropertiesIntoFeature = ({
    feature,
    fill,
    stroke,
    text,
    layer,
}: {
    feature: Feature<Geometry>;
    fill?: string;
    stroke?: string;
    text?: IText;
    layer?: number;
}): any => {
    const propertiesElement = featureElement(feature);
    const propertiesFeature = featureProperties(feature);
    const propertiesLayer = featureLayer(feature);
    const { editId } = propertiesFeature;

    return {
        ...propertiesFeature,
        ...(editId && editId),
        angle: text?.rotation ?? 0,
        properties: {
            ...propertiesLayer,
            ...(layer && { layer }),
            element: {
                ...propertiesElement,
                codice: text?.val,
                colore: {
                    sfondo: fill ?? propertiesElement?.colore?.sfondo,
                    bordo: stroke ?? propertiesElement?.colore?.bordo,
                },
                ...(text && {
                    testo: {
                        val: text?.val ?? "",
                        rot: text?.rotation ?? 0,
                        size: text?.size,
                    },
                }),
            },
        },
    };
};

const findDrawFeature = ({
    feature,
    source,
}: {
    feature: Feature<Geometry>;
    source: VectorSource;
}): Feature<Geometry> | null => {
    const type = getType(feature);
    const layer = featureLayer(feature);

    const isPolygon = type?.toLowerCase() === EIconeDraw.Polygon.toLowerCase();
    const isLinestring = type?.toLowerCase() === EIconeDraw.LineString.toLowerCase();
    const isPoint = type?.toLowerCase() === EIconeDraw.Point.toLowerCase();
    const isGeometryCollection = type?.toLowerCase() === EIconeDraw.GeometryCollection.toLowerCase();

    if (isGeometryCollection) {
        const geometry = feature.getGeometry();
        const geometries = (geometry as any).getGeometries();

        const coordinates = geometries[0]?.getFlatCoordinates();
        const features = source.getClosestFeatureToCoordinate(coordinates);
        return features;
    }

    if (isPolygon || isLinestring || (isPoint && layer === ELayer.Testo)) {
        const coordinates = (feature.getGeometry() as any)?.getFlatCoordinates();
        const features = source.getClosestFeatureToCoordinate(coordinates);
        return features;
    }

    if (isPoint && layer !== ELayer.Testo) {
        const coord = (feature.getGeometry() as any)?.getCenter();
        const center = (feature.getGeometry() as any)?.getCoordinates();
        return source.getClosestFeatureToCoordinate(center ?? coord);
    }

    return null;
};

export {
    getType,
    getCircleCoordinates,
    getCoordinates,
    getDimensioneTestoFeature,
    mapFeature,
    getDrawRetinoProperties,
    featureProperties,
    featureLayer,
    featureElement,
    featureTesto,
    defaultModify,
    findDrawFeature,
    saveNewPropertiesIntoFeature,
};
