import { click } from "ol/events/condition";
import { Select } from "ol/interaction";
import { useState } from "react";
import { useRecoilValue } from "recoil";

import { permissionsTombeState } from "@stores/diritti";

import { StatoDiritti } from "@models/Diritti";

import { Coordinate } from "@modules/cimiteri/pages/Models/settori";

import { useCruxToaster } from "@hooks/useCruxToaster";

import { featureLayer } from "@openLayersMap/Helpers/EditMappingHelpers";
import { useMap } from "@openLayersMap/Map/MapContext";

import { clickStyle } from "../Helpers/StyleHelper";

const useClickHook = () => {
    const { map } = useMap();

    const [clickCoordinates, setClickCoordinates] = useState<Coordinate | undefined>(undefined);
    const permissionsTombe = useRecoilValue(permissionsTombeState);
    const { handleApiError } = useCruxToaster();

    const selectFeatures = new Select({
        style: clickStyle,
        condition: (mapBrowserEvent) => {
            return click(mapBrowserEvent);
        },
    });

    const initClick = () => {
        if (!map) return;

        map.addInteraction(selectFeatures);

        selectFeatures.on("select", (event) => {
            const { selected } = event;

            if (permissionsTombe === StatoDiritti.NESSUNO) handleApiError({ message: "Permesso non disponibile" });
            if (selected.length > 0) {
                const oldLayer = featureLayer(selected[0]);
                if (permissionsTombe !== StatoDiritti.NESSUNO)
                    setClickCoordinates({ pixel: event.mapBrowserEvent?.pixel ?? [], oldLayer, feature: selected[0] });
            }
        });
    };

    const destroyClick = () => {
        map?.removeInteraction(selectFeatures);
    };

    return {
        initClick,
        destroyClick,
        clickCoordinates,
        setClickCoordinates,
    };
};

export default useClickHook;
