import * as Sentry from "@sentry/browser";
import * as React from "react";
import { Dispatch, FC, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Loader } from "rsuite";

import { getColoriConfig } from "@services/ConfigService";
import history from "@services/HistoryService";
import {
    deleteAccessToken,
    setAccessToken,
    setCodiceTenant,
    setComuneId,
    setRefreshToken,
    setResetPasswordToken,
    setResetPasswordUsername,
    setUsername,
} from "@services/auth/AccessTokenService";
import { authUser, login } from "@services/auth/AuthService";
import { getAllProvinceList, getAllStatiList } from "@services/tools/ToolsService";

import { dirittiState } from "@stores/diritti";
import { comuneAttivoState, provinceListState, statiListState } from "@stores/geographicLists";
import { colorConfigState } from "@stores/globalUserSettings";

import { AuthUser, IResetPassword } from "@models/AuthUser";
import { Provincia, Stato } from "@models/Comune";
import { ColoriConfig } from "@models/Configs";

import { useCruxToaster } from "@hooks/useCruxToaster";

interface AuthContextProps {
    isAuthenticated: boolean | undefined;
    user: AuthUser | null;
    resetPassword: IResetPassword | undefined;
    setResetPassword: Dispatch<SetStateAction<IResetPassword | undefined>>;
    setUpdateUser: Dispatch<SetStateAction<Partial<AuthUser> | null>>;
    isLoading: boolean;
    login: Function;
    logout: Function;
}

const initialData: AuthContextProps = {
    isAuthenticated: undefined,
    user: null,
    resetPassword: {
        username: undefined,
        token: undefined,
    },
    setResetPassword: () => null,
    setUpdateUser: () => null,
    isLoading: false,
    login: () => null,
    logout: () => null,
};

const AuthContext = createContext<AuthContextProps>(initialData);

interface AuthProviderProps {
    children?: React.ReactNode;
}

const isResetPassword = ({ error }: { error: string[] }): boolean => {
    return error.length === 2 && error[0] === "Reset password";
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
    const [resetPassword, setResetPassword] = useState<IResetPassword | undefined>(undefined);

    const [user, setUser] = useState<AuthUser | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [updateUser, setUpdateUser] = useState<Partial<AuthUser> | null>(null);

    const { handleApiError } = useCruxToaster();

    const setStatiList = useSetRecoilState(statiListState);
    const setProvinceList = useSetRecoilState(provinceListState);
    const setColorConfig = useSetRecoilState(colorConfigState);
    const setDirittiState = useSetRecoilState(dirittiState);
    const setComuneAttivoStateState = useSetRecoilState(comuneAttivoState);

    const getAuthUser = useCallback(
        (shouldRedirect: boolean) => {
            authUser()
                .then((apiSuccess) => {
                    const { data } = apiSuccess;
                    setUser(data);

                    const promises = [getAllProvinceList(), getAllStatiList(), getColoriConfig()];

                    Promise.allSettled(promises).then((results) => {
                        results.forEach((result, index) => {
                            if (result.status === "fulfilled" && index === 0) {
                                setProvinceList(result.value as Provincia[]);
                            }

                            if (result.status === "fulfilled" && index === 1) {
                                setStatiList(result.value as Stato[]);
                            }

                            if (result.status === "fulfilled" && index === 2) {
                                setColorConfig(result.value as ColoriConfig);
                            }
                        });
                    });

                    setIsAuthenticated(true);
                    if (shouldRedirect) {
                        history.replace({ pathname: "/cimitero" });
                        Sentry.setUser({
                            email: data.eMail,
                            id: data.idUtente,
                            username: data.userName,
                        });

                        (window as any).clarity(
                            "identify",
                            data?.idUtente?.toString(),
                            "custom-session-id",
                            "custom-page-id",
                            data?.eMail
                        );

                        (window as any).clarity("set", "idUtente", data?.idUtente?.toString());
                        (window as any).clarity("set", "email", data?.eMail);

                        const comuneAttivo = data?.comuneAttivo?.descr ?? "";
                        setComuneAttivoStateState(comuneAttivo);
                        document.title = comuneAttivo;
                    }
                })
                .catch((apiError) => {
                    const { data } = apiError.response;
                    setUser(null);
                    setIsAuthenticated(false);

                    if (data) handleApiError({ error: data });
                });
        },
        // eslint-disable-next-line
        []
    );

    const doLogin = useMemo(
        () => (username: string, password: string, codiceTenant?: string) => {
            setIsLoading(true);
            login(username, password, codiceTenant)
                .then((apiSuccess) => {
                    const { data } = apiSuccess;
                    setUsername(username);
                    setAccessToken(data.accessToken ?? "");
                    setDirittiState(data.accessToken ?? "");
                    setRefreshToken(data.refreshToken ?? "");
                    setComuneId(data.comuneAttivo?.id ?? "");
                    setCodiceTenant(codiceTenant ?? "");
                    getAuthUser(true);
                })
                .catch((apiError) => {
                    const error = apiError.response.data.meta.errori;

                    if (isResetPassword({ error })) {
                        setResetPasswordToken(error[1] ?? "");
                        setResetPasswordUsername(username ?? "");

                        setResetPassword({
                            username,
                            token: error[1],
                        });

                        history.replace({ pathname: "/recupera-password" });
                    }

                    if (!isResetPassword({ error })) handleApiError({ error: apiError.response.data });
                })
                .finally(() => setIsLoading(false));
        },
        [getAuthUser, handleApiError]
    );

    const doLogout = async () => {
        deleteAccessToken();
        setUser(null);
        setIsAuthenticated(false);
    };

    useEffect(() => {
        getAuthUser(false);
    }, [getAuthUser]);

    useEffect(() => {
        const updateUserContext: any = {
            ...user,
            nome: updateUser?.nome ?? "",
            cognome: updateUser?.cognome ?? "",
            eMail: updateUser?.eMail ?? "",
        };
        setUser(updateUserContext);
        // eslint-disable-next-line
    }, [updateUser]);

    const contextValue: AuthContextProps = useMemo(
        () => ({
            isAuthenticated,
            user,
            resetPassword,
            setResetPassword,
            setUpdateUser,
            isLoading,
            login: doLogin,
            logout: doLogout,
        }),
        [isAuthenticated, resetPassword, setResetPassword, user, setUpdateUser, isLoading, doLogin]
    );

    return isAuthenticated === undefined ? (
        <Loader content='Caricamento in corso...' center size='md' />
    ) : (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

function useAuth() {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
}

export { AuthProvider, useAuth };
