/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { faArrowsLeftRightToLine, faCancel, faCheck, faColumns, faRows } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { Button, InputGroup } from "rsuite";
import * as Yup from "yup";

import { listTipiTomba } from "@services/settings/cimiteri/TipiTombaService";

import { useCruxRequest } from "@hooks/useCruxRequest";

import FormInput from "@components/Form/Input/FormInput";
import NumberInput from "@components/Form/Input/NumberInput";
import SelectInput from "@components/Form/Input/SelectInput";

import FormikValuesHelper from "@helpers/FormikValuesHelper";

import { FirstTombaPosition, GridSettings } from "@openLayersMap/Hooks/DrawGrigliaTombeHook";

interface TomberGridPanelProps {
    hasDrawn: boolean;
    gridSettings: GridSettings;
    setGridSettings: (values: GridSettings) => void;
    handleConfirmGrid?: (values: GridSettings) => void;
    handleCancelGrid?: () => void;
    isSaving: boolean;
}

const possibleDirection: { label: string; value: "vertical" | "horizontal" }[] = [
    { label: "Verticale", value: "vertical" },
    { label: "Orizzontale", value: "horizontal" },
];

const possiblePlotType: { label: string; value: "straight" | "alternate" }[] = [
    { label: "Continua", value: "straight" },
    { label: "Alternata", value: "alternate" },
];

const possibleFirstTombaPosition: { label: string; value: FirstTombaPosition }[] = [
    { label: "Alto Sinistra", value: "top-left" },
    { label: "Alto Destra", value: "top-right" },
    { label: "Basso Sinistra", value: "bottom-left" },
    { label: "Basso Destra", value: "bottom-right" },
];

const validationSchema = Yup.object().shape({
    rows: Yup.number().nullable().required(),
    cols: Yup.number().nullable().required(),
    space: Yup.number().nullable().required(),
    direction: Yup.string().nullable().required(),
    firstTombaPosition: Yup.string().nullable().required(),
    idTipoTomba: Yup.number().nullable().required(),
    tombaIniziale: Yup.number().nullable().required(),
    prefisso: Yup.string().nullable(),
    suffisso: Yup.string().nullable(),
    incremento: Yup.number().nullable().required(),
});

const AddTombeGridPanel = ({
    gridSettings,
    setGridSettings,
    handleConfirmGrid,
    handleCancelGrid,
    hasDrawn,
    isSaving,
}: TomberGridPanelProps) => {
    const handleValuesChanged = useCallback(
        (values: GridSettings) => {
            setGridSettings(values);
        },
        [setGridSettings]
    );

    const { data: tipiTombe, loading, mutate } = useCruxRequest(listTipiTomba, { cacheKey: "tipi-tombe" });

    return (
        <div id='grid-toolset' className='ol-popup bg-white p-12 rounded shadow-sm space-y-8 z-10'>
            <Formik
                initialValues={gridSettings}
                enableReinitialize
                validateOnChange
                validateOnMount
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers) => {
                    handleConfirmGrid?.(values);
                    formikHelpers.resetForm();
                }}
            >
                {({ isValid, handleReset, handleSubmit }) => (
                    <>
                        <form onSubmit={handleSubmit}>
                            <ul className='space-y-8'>
                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faRows} />
                                    <span>Righe</span>
                                    <NumberInput size='xs' type='number' min={1} name='rows' />
                                </li>
                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faColumns} />
                                    <span>Colonne</span>
                                    <NumberInput size='xs' type='number' min={1} name='cols' />
                                </li>
                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span>Spazio</span>
                                    <InputGroup size='xs'>
                                        <InputGroup.Addon>%</InputGroup.Addon>
                                        <NumberInput size='xs' type='number' min={1} max={100} name='space' />
                                    </InputGroup>
                                </li>

                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span className='grow'>Numero iniziale</span>
                                    <NumberInput size='xs' type='number' min={1} name='tombaIniziale' />
                                </li>

                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span>Incremento</span>
                                    <NumberInput size='xs' type='number' min={1} name='incremento' />
                                </li>

                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span>Prefisso</span>
                                    <FormInput size='xs' name='prefisso' />
                                </li>

                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span>Suffisso</span>
                                    <FormInput size='xs' name='suffisso' />
                                </li>

                                <hr />

                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span>Direzione Numerazione</span>
                                    <SelectInput
                                        name='direction'
                                        searchable={false}
                                        size='xs'
                                        cleanable={false}
                                        data={possibleDirection}
                                        block
                                        menuClassName='z-20'
                                        className='flex-1'
                                    />
                                </li>

                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span>Posizione prima tomba</span>
                                    <SelectInput
                                        name='firstTombaPosition'
                                        searchable={false}
                                        size='xs'
                                        cleanable={false}
                                        data={possibleFirstTombaPosition}
                                        block
                                        menuClassName='z-20'
                                        className='flex-1'
                                    />
                                </li>

                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span>Tipo numerazione</span>
                                    <SelectInput
                                        name='plotType'
                                        searchable={false}
                                        size='xs'
                                        cleanable={false}
                                        data={possiblePlotType}
                                        block
                                        menuClassName='z-20'
                                        className='flex-1'
                                    />
                                </li>

                                <hr />
                                <li className='cursor-pointer space-x-12 flex items-center'>
                                    <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                    <span>Tipo di tomba</span>
                                    <SelectInput
                                        name='idTipoTomba'
                                        size='xs'
                                        cleanable={false}
                                        data={tipiTombe || []}
                                        block
                                        placeholder='Seleziona tipo di tomba'
                                        valueKey='id'
                                        labelKey='descr'
                                        menuClassName='z-20'
                                        className='flex-1'
                                    />
                                </li>

                                <hr />
                                <li className='cursor-pointer space-x-12 flex w-full justify-end items-center'>
                                    <Button type='button' appearance='ghost' size='sm' onClick={handleCancelGrid}>
                                        <FontAwesomeIcon icon={faCancel} className='mr-1' />
                                        Annulla
                                    </Button>

                                    {hasDrawn && (
                                        <Button
                                            type='submit'
                                            appearance='primary'
                                            size='sm'
                                            disabled={!isValid || !hasDrawn || isSaving}
                                            loading={isSaving}
                                        >
                                            <FontAwesomeIcon icon={faCheck} className='mr-1' />
                                            Crea tombe
                                        </Button>
                                    )}
                                </li>
                            </ul>
                            <FormikValuesHelper onValuesChanged={handleValuesChanged} />
                        </form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default AddTombeGridPanel;
