import { useDrop } from "ahooks";
import { useField } from "formik";
import { FC, useRef, useState } from "react";
import { InputGroup, Input as RsInput, InputProps as RsInputProps } from "rsuite";

interface FileInputPros extends Omit<RsInputProps, "form" | "onChange"> {
    name: string;
    onFileSelected?: (file: File, event: any) => void;
    title?: string;
}

const FileInput: FC<FileInputPros> = ({ onFileSelected, name, title, ...props }) => {
    const [field, meta, helpers] = useField(name);

    const [isHovering, setIsHovering] = useState(false);
    const dropRef = useRef(null);

    const setFileValue = (file: File, event: any) => {
        helpers.setValue(file);
        onFileSelected?.(file, event);
    };

    useDrop(dropRef, {
        onFiles: (files, e) => {
            setFileValue(files[0], e);
            setIsHovering(false);
        },
        onDragEnter: () => setIsHovering(true),
        onDragLeave: () => setIsHovering(false),
    });

    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const handleClick = () => {
        hiddenFileInput?.current?.click();
    };

    const handleOnChange = (event: any) => {
        const value = event.target.files[0];

        setFileValue(value, event);
    };

    return (
        <div ref={dropRef}>
            <InputGroup inside className='w-full cursor-pointer' onClick={handleClick}>
                <RsInput
                    {...props}
                    readOnly
                    value={isHovering ? "Lascia per selezionare" : field.value?.name ?? ""}
                    className='cursor-pointer'
                />
                <input
                    type='file'
                    ref={hiddenFileInput}
                    onChange={handleOnChange}
                    style={{ display: "none" }}
                    accept={props.accept}
                />
                <InputGroup.Button tabIndex={-1}>
                    {title !== "Files" ? "Seleziona documento" : "Seleziona file"}
                </InputGroup.Button>
            </InputGroup>
        </div>
    );
};

export default FileInput;
