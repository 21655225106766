import { atom, selector } from "recoil";

import { IDiritti } from "@models/Diritti";

import {
    getAnagraficheKey,
    getCanoniKey,
    getContrattiKey,
    getCremazioniKey,
    getFattureKey,
    getGraficaKey,
    getRicercheKey,
    getStrutturaKey,
    getTombeKey,
    getTrasportiKey,
} from "@helpers/DirittiHelper";

import { persistRecoil } from "./effects/persistRecoil";

const { persistAtom } = persistRecoil();

const dirittiState = atom<IDiritti | {}>({
    key: "dirittiState",
    default: {},
    effects: [persistAtom],
});

const refreshDirittiState = atom<string>({
    key: "refreshDirittiState",
    default: "",
});

const adminState = atom<boolean>({
    key: "adminState",
    default: false,
    effects: [persistAtom],
});

const sysAdminState = atom<boolean>({
    key: "sysAdminState",
    default: false,
    effects: [persistAtom],
});

const isAdminState = selector({
    key: "isAdminState",
    get: ({ get }) => {
        const isAdmin = get(adminState) as any;
        return isAdmin;
    },
});

const isSysAdminState = selector({
    key: "isSysAdminState",
    get: ({ get }) => {
        const isSysAdmin = get(sysAdminState) as any;
        return isSysAdmin;
    },
});

const permissionsGraficaState = selector({
    key: "permissionsGraficaState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getGraficaKey;
        return diritti[key];
    },
});

const permissionsAnagraficheState = selector({
    key: "permissionsAnagraficheState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getAnagraficheKey;
        return diritti[key];
    },
});

const permissionsStrutturaState = selector({
    key: "permissionsStrutturaState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getStrutturaKey;
        return diritti[key];
    },
});

const permissionsTombeState = selector({
    key: "permissionsTombeState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getTombeKey;
        return diritti[key];
    },
});

const permissionsCanoniState = selector({
    key: "permissionsCanoniState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getCanoniKey;
        return diritti[key];
    },
});

const permissionsFattureState = selector({
    key: "permissionsFattureState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getFattureKey;
        return diritti[key];
    },
});

const permissionsContrattiState = selector({
    key: "permissionsContrattiState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getContrattiKey;
        return diritti[key];
    },
});

const permissionsCremazioniState = selector({
    key: "permissionsCremazioniState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getCremazioniKey;
        return diritti[key];
    },
});

const permissionsTrasportiState = selector({
    key: "permissionsTrasportiState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getTrasportiKey;
        return diritti[key];
    },
});

const permissionsRicercheState = selector({
    key: "permissionsRicercheState",
    get: ({ get }) => {
        const diritti = get(dirittiState) as any;
        const key = getRicercheKey;
        return diritti[key];
    },
});

export {
    dirittiState,
    refreshDirittiState,
    adminState,
    sysAdminState,
    isAdminState,
    isSysAdminState,
    permissionsGraficaState,
    permissionsAnagraficheState,
    permissionsStrutturaState,
    permissionsTombeState,
    permissionsCanoniState,
    permissionsFattureState,
    permissionsContrattiState,
    permissionsCremazioniState,
    permissionsTrasportiState,
    permissionsRicercheState,
};
