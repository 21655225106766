import { useField } from "formik";
import { round } from "lodash";
import * as React from "react";
import { FC, useMemo } from "react";
import { Slider, SliderProps } from "rsuite";

interface NumberInputPros extends Omit<SliderProps, "form"> {
    name: string;
}

const AngleInput: FC<NumberInputPros> = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const gradValue = useMemo(() => round(field.value * 57.2958, 0), [field.value]);

    const handleOnChange = (newValue: number, event: React.SyntheticEvent) => {
        const radValue = round(newValue * 0.0174533, 3);
        helpers.setValue(radValue);
        props.onChange?.(radValue, event);
    };

    return (
        <>
            <Slider
                {...field}
                progress
                min={0}
                max={360}
                graduated
                renderTooltip={(value) => `${value?.toFixed(0)}°`}
                value={gradValue}
                onChange={handleOnChange}
                {...props}
            />
        </>
    );
};

export default AngleInput;
