import { Formik } from "formik";
import React, { FC } from "react";

import { IMunicipality } from "@models/Comune";

import MunicipalityForm from "../_forms/MunicipalityForm";

const initialValues: IMunicipality = {
    municipality: undefined,
};

const Municipality: FC<{ data: any }> = ({ data }) => {
    return (
        <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className='w-full'>
                    <MunicipalityForm data={data} />
                </form>
            )}
        </Formik>
    );
};

export default Municipality;
