import React from "react";
import { Loader } from "rsuite";

interface WithLoadingProps {
    isLoading: boolean;
}

const withLoading =
    <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithLoadingProps> =>
    ({ isLoading, ...props }: WithLoadingProps) => {
        if (isLoading) {
            return (
                <div className='relative h-full w-full'>
                    <Loader center size='md' />
                </div>
            );
        }

        return <Component {...(props as P)} />;
    };
export default withLoading;
