import React from "react";
import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

import ContractsContainer from "./ContractsContainer";

const contractsRoutes: RouteObject = {
    element: <ContractsContainer />,
    children: [
        {
            path: "",
            index: true,
            element: lazyLoadRoutes("contrattiIndex"),
        },
        {
            path: ":id",
            element: lazyLoadRoutes("contrattiShow"),
        },
    ],
};

export default contractsRoutes;
