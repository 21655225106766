import { faArrowsLeftRightToLine, faCheck, faEraser, faHashtag } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React from "react";
import { Button } from "rsuite";
import * as Yup from "yup";

import { listTipiTomba } from "@services/settings/cimiteri/TipiTombaService";

import { IAddTomba } from "@models/StrutturaLivelli";

import { useCruxRequest } from "@hooks/useCruxRequest";

import FormInput from "@components/Form/Input/FormInput";
import SelectInput from "@components/Form/Input/SelectInput";

interface AddTombaPanelProps {
    hasDrawn: boolean;
    handleCancel: () => void;
    handleConfirm: (date: Partial<IAddTomba>) => void;
    isSaving: boolean;
}

export const tombaInitialValues = {
    idTipoTomba: undefined,
    numero: undefined,
};

const validationSchema = Yup.object().shape({
    idTipoTomba: Yup.number().required(),
    numero: Yup.string().min(0).max(50).required(),
});

const TombeGridPanel = ({ handleConfirm, handleCancel, hasDrawn, isSaving }: AddTombaPanelProps) => {
    const { data: tipiTombe } = useCruxRequest(listTipiTomba);

    return (
        <div>
            <Formik
                initialValues={tombaInitialValues}
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers) => {
                    handleConfirm(values);
                    formikHelpers.resetForm();
                }}
                onReset={handleCancel}
            >
                {({ handleSubmit, handleReset, isValid }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <ul className='space-y-8'>
                                {hasDrawn && (
                                    <>
                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <FontAwesomeIcon icon={faArrowsLeftRightToLine} />
                                            <span>Tipo di tomba</span>
                                            <SelectInput
                                                name='idTipoTomba'
                                                size='xs'
                                                cleanable={false}
                                                data={tipiTombe || []}
                                                block
                                                placeholder='Seleziona tipo'
                                                valueKey='id'
                                                labelKey='descr'
                                                menuClassName='z-20'
                                                className='flex-1'
                                            />
                                        </li>

                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <FontAwesomeIcon icon={faHashtag} />
                                            <span>Numero tomba</span>
                                            <FormInput size='xs' name='numero' minLength={0} maxLength={100} />
                                        </li>
                                    </>
                                )}
                                {!hasDrawn && (
                                    <li className='cursor-pointer space-x-12 flex items-center'>
                                        <span>Disegna la tomba sulla mappa</span>
                                    </li>
                                )}
                                <hr />
                                <li className='cursor-pointer space-x-12 flex w-full justify-end items-center'>
                                    <Button type='button' appearance='ghost' size='sm' onClick={handleReset}>
                                        <FontAwesomeIcon icon={faEraser} className='mr-1' />
                                        Cancella disegno
                                    </Button>

                                    {hasDrawn && (
                                        <Button
                                            type='submit'
                                            appearance='primary'
                                            size='sm'
                                            disabled={!isValid || !hasDrawn}
                                            loading={isSaving}
                                        >
                                            <FontAwesomeIcon icon={faCheck} className='mr-1' />
                                            Crea tomba
                                        </Button>
                                    )}
                                </li>
                            </ul>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default TombeGridPanel;
