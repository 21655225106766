import { ICimiteroState } from "@stores/graveyard";

import { CimiteroEvent } from "@models/Events/Cimiteri";
import Livello from "@models/Livello";

import { IGetLevels } from "@modules/cimiteri/_forms/LivelloForm";

const isEdit = (isCimiteriEditMode: ICimiteroState | undefined): boolean =>
    !!isCimiteriEditMode && isCimiteriEditMode.evento === CimiteroEvent.EDIT_SETTORE;

const isAdd = (isCimiteriEditMode: ICimiteroState | undefined): boolean =>
    !!isCimiteriEditMode && isCimiteriEditMode.evento === CimiteroEvent.ADD_SUB_SETTORE;

const isDelete = (isCimiteriEditMode: ICimiteroState | undefined): boolean =>
    !!isCimiteriEditMode && isCimiteriEditMode.evento === CimiteroEvent.DELETE_SETTORE;

const isAddTomba = (isCimiteriEditMode: ICimiteroState | undefined): boolean =>
    !!isCimiteriEditMode && isCimiteriEditMode.evento === CimiteroEvent.ADD_TOMBA;

const updateSettore = ({ isCimiteriEditMode }: { isCimiteriEditMode: ICimiteroState | undefined }): boolean =>
    !!isCimiteriEditMode &&
    !!isCimiteriEditMode.idPadri &&
    isCimiteriEditMode.idPadri?.length > 0 &&
    isCimiteriEditMode?.evento === CimiteroEvent.EDIT_SETTORE;

const updateCimitero = ({ isCimiteriEditMode }: { isCimiteriEditMode: ICimiteroState | undefined }): boolean =>
    !!isCimiteriEditMode &&
    isCimiteriEditMode.idPadri?.length === 0 &&
    isCimiteriEditMode?.evento === CimiteroEvent.EDIT_SETTORE;

const addSettore = ({ isCimiteriEditMode }: { isCimiteriEditMode: ICimiteroState | undefined }): boolean =>
    !!isCimiteriEditMode &&
    isCimiteriEditMode.idSettore !== 0 &&
    isCimiteriEditMode?.evento === CimiteroEvent.ADD_SUB_SETTORE;

const addCimitero = ({ isCimiteriEditMode }: { isCimiteriEditMode: ICimiteroState | undefined }): boolean =>
    !!isCimiteriEditMode &&
    isCimiteriEditMode.idSettore === 0 &&
    isCimiteriEditMode?.evento === CimiteroEvent.ADD_SUB_SETTORE;

const addTomba = ({ isCimiteriEditMode }: { isCimiteriEditMode: ICimiteroState | undefined }): boolean =>
    !!isCimiteriEditMode && isCimiteriEditMode?.evento === CimiteroEvent.ADD_TOMBA;

const getLivelli = ({ livelli, idLivelloPadre, isCimiteriEditMode }: IGetLevels): Livello[] => {
    if (!idLivelloPadre && isCimiteriEditMode?.idSettore === 0 && livelli && isAdd(isCimiteriEditMode)) return livelli;

    if (idLivelloPadre && isCimiteriEditMode?.idSettore !== 0 && livelli && isAdd(isCimiteriEditMode))
        return livelli?.filter((value: any) => value.id > idLivelloPadre);

    return livelli || [];
};

const getTitle = ({ isCimiteriEditMode }: { isCimiteriEditMode: ICimiteroState | undefined }): string => {
    if (updateSettore({ isCimiteriEditMode })) return "Modifica settore";
    if (updateCimitero({ isCimiteriEditMode })) return "Modifica cimitero";

    if (addSettore({ isCimiteriEditMode })) return "Inserimento settore";
    if (addCimitero({ isCimiteriEditMode })) return "Inserimento cimitero";

    if (addTomba({ isCimiteriEditMode })) return "Inserimento tombe";

    return "";
};

const getCodiceLabel = ({ isCimiteriEditMode }: { isCimiteriEditMode: ICimiteroState | undefined }): string => {
    if (updateSettore({ isCimiteriEditMode })) return "Codice settore";
    if (updateCimitero({ isCimiteriEditMode })) return "Codice cimitero";

    if (addSettore({ isCimiteriEditMode })) return "Codice settore";
    if (addCimitero({ isCimiteriEditMode })) return "Codice cimitero";

    return "";
};

const getDescrLabel = ({ isCimiteriEditMode }: { isCimiteriEditMode: ICimiteroState | undefined }): string => {
    if (updateSettore({ isCimiteriEditMode })) return "Descrizione settore";
    if (updateCimitero({ isCimiteriEditMode })) return "Descrizione cimitero";

    if (addSettore({ isCimiteriEditMode })) return "Descrizione settore";
    if (addCimitero({ isCimiteriEditMode })) return "Descrizione cimitero";

    return "";
};

export { isEdit, isAdd, isAddTomba, isDelete, getLivelli, getTitle, getCodiceLabel, getDescrLabel };
