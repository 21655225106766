import ProtectedLayout from "@layouts/protectedLayout";
import PublicLayout from "@layouts/publicLayout";
import { Suspense } from "react";
import { RouteObject } from "react-router-dom";
import { Loader } from "rsuite";

import { PageDataProvider } from "@context/PageDataContext";

import ProtectedRoute from "./protected-route/ProtectedRoute";
import commonRoutes from "./routes/commonRoutes";
import protectedRoutes from "./routes/privateRoutes";
import publicRoutes from "./routes/publicRoutes";

const routes: RouteObject[] = [
    {
        path: "/",
        element: (
            <Suspense fallback={<Loader content='Caricamento in corso...' center size='md' />}>
                <PublicLayout />
            </Suspense>
        ),
        children: publicRoutes,
    },
    {
        path: "/",
        element: (
            <Suspense fallback={<Loader content='Caricamento in corso...' center size='md' />}>
                <ProtectedRoute>
                    <PageDataProvider>
                        <ProtectedLayout />
                    </PageDataProvider>
                </ProtectedRoute>
            </Suspense>
        ),
        children: protectedRoutes,
    },
    ...commonRoutes,
];

export default routes;
