import OLMap from "ol/Map";
import { Snap } from "ol/interaction";
import { Graticule } from "ol/layer";
import * as React from "react";
import { FC, createContext, useMemo, useState } from "react";

interface MapContextProps {
    map: OLMap | null;
    setMap: (m: OLMap) => void;
    startSnap: () => void;
    stopSnap: () => void;
}

const initialValues: MapContextProps = {
    map: null,
    setMap: () => null,
    startSnap: () => null,
    stopSnap: () => null,
};

const MapContext = createContext<MapContextProps>(initialValues);

interface MapProviderProps {
    children?: React.ReactNode;
}

const MapProvider: FC<MapProviderProps> = ({ children }) => {
    const [map, setMap] = useState<OLMap | null>(null);

    const [snap, setSnap] = useState<Snap | null>(null);

    const startSnap = () => {
        if (map) {
            const gridLayer = map
                .getLayers()
                .getArray()
                .find((l) => l.get("name") === "graticule");

            if (gridLayer) {
                const gridSource = (gridLayer as Graticule).getSource();
                if (gridSource) {
                    const x = new Snap({
                        source: gridSource,
                        pixelTolerance: 10,
                        edge: true,
                    });
                    map.addInteraction(x);
                    setSnap(x);
                }
            }
        }
    };

    const stopSnap = () => {
        if (snap && map) {
            map.removeInteraction(snap);
        }
    };

    const contextValue: MapContextProps = useMemo(
        () => ({
            map,
            setMap,
            startSnap,
            stopSnap,
        }),
        [map, startSnap, stopSnap]
    );

    return <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>;
};

function useMap() {
    const context = React.useContext(MapContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a MapProvider");
    }
    return context;
}

export { MapProvider, useMap };
