import { useField } from "formik";
import { useMemo } from "react";
import { SelectPicker as RsSelectPicker, SelectPickerProps as RsSelectPickerProps } from "rsuite";
import { ItemDataType } from "rsuite/cjs/@types/common";

interface SelectInputPros<T> extends Omit<RsSelectPickerProps<T>, "form"> {
    name: string;
    returnEnum?: boolean;
}

const SelectInput = <T extends string>({ returnEnum, ...props }: SelectInputPros<T>) => {
    const [field, meta, helpers] = useField(props.name);

    const value = useMemo(() => field.value?.[props.valueKey ?? "value"] ?? field.value, [field.value, props.valueKey]);

    const handleOnChange = (selectedValue: T | null, event: any) => {
        let selectedValueEdit: ItemDataType<T> | null = selectedValue;
        if (returnEnum) {
            selectedValueEdit = props.data.find((ct) => ct[props.valueKey ?? "value"] === selectedValue) ?? null;
        }
        if (selectedValue !== value) helpers.setValue(selectedValueEdit);
        if (selectedValue !== value) props.onChange?.(selectedValue, event);
    };

    const handleOnClean = () => {
        helpers.setValue(null);
    };

    return (
        <RsSelectPicker
            {...field}
            value={value}
            onClean={handleOnClean}
            onOpen={() => helpers.setTouched(true)}
            block
            placement='autoVertical'
            {...props}
            onChange={handleOnChange}
        />
    );
};

export default SelectInput;
