import {
    faBell,
    faCircleExclamation,
    faFlagCheckered,
    faFlagPennant,
    faLightbulbOn,
    faSheetPlastic,
    faTombstone,
    faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { AxiosResponse } from "axios";
import { ItemDataType } from "rsuite/cjs/@types/common";

const getFilenameFromResponse = (response: AxiosResponse, def?: string): string => {
    const filename = response.headers["content-disposition"]
        ?.split("filename=")[1]
        ?.split(";")[0]
        ?.replaceAll('"', "")
        ?.trim();

    return filename ?? def;
};

const iconsFontAwesome: ItemDataType<any>[] = [
    {
        value: faLightbulbOn.icon[3],
        label: "Lampada",
        icon: faLightbulbOn,
        key: faLightbulbOn.iconName,
    },
    {
        value: faTombstone.icon[3],
        label: "Tomba",
        icon: faTombstone,
        key: faTombstone.iconName,
    },
    {
        value: faTriangleExclamation.icon[3],
        label: "Pericolo",
        icon: faTriangleExclamation,
        key: faTriangleExclamation.iconName,
    },
    {
        value: faCircleExclamation.icon[3],
        label: "Divieto",
        icon: faCircleExclamation,
        key: faCircleExclamation.iconName,
    },
    {
        value: faSheetPlastic.icon[3],
        label: "Foglio",
        icon: faSheetPlastic,
        key: faSheetPlastic.iconName,
    },
    {
        value: faFlagCheckered.icon[3],
        label: "Segnalino 1",
        icon: faFlagCheckered,
        key: faFlagCheckered.iconName,
    },
    {
        value: faFlagPennant.icon[3],
        label: "Segnalino 2",
        icon: faFlagPennant,
        key: faFlagPennant.iconName,
    },
    {
        value: faBell.icon[3],
        label: "Segnalino 3",
        icon: faBell,
        key: faBell.iconName,
    },
];

export { getFilenameFromResponse, iconsFontAwesome };
