import React from "react";
import { Tooltip, Whisper } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";

interface WithTooltipProps {
    message: string | React.ReactNode;
    placement?: TypeAttributes.Placement;
}

const withTooltip =
    <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithTooltipProps> =>
    ({ message, placement = "top", ...props }: WithTooltipProps) => {
        const tooltip = <Tooltip>{message}</Tooltip>;
        return (
            <Whisper placement={placement} trigger='hover' speaker={tooltip}>
                <Component {...(props as P)} />
            </Whisper>
        );
    };
export default withTooltip;
