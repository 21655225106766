import { Control } from "ol/control";
import { useEffect, useRef } from "react";

import { SettoreCimitero } from "@models/SettoreCimitero";

import BreadcrumbSettori from "@modules/cimiteri/pages/_components/BreadcrumbSettori";

import { useMap } from "@openLayersMap/Map/MapContext";

const BreadcrumbControl = ({ settore, idCimitero }: { settore: SettoreCimitero; idCimitero: string }): JSX.Element => {
    const { map } = useMap();

    const breadcrumbRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!map || !breadcrumbRef.current) return undefined;

        const visibilityControl = new Control({
            element: breadcrumbRef.current,
        });

        map.addControl(visibilityControl);

        return () => {
            if (map && visibilityControl && breadcrumbRef) map.removeControl(visibilityControl);
        };
    }, [map]);

    return (
        <section className='absolute left-12 bottom-12' ref={breadcrumbRef}>
            <BreadcrumbSettori settore={settore} idCimitero={idCimitero} />
            <p className='breadcrumb-descr italic'>{settore?.descr}</p>
        </section>
    );
};

export default BreadcrumbControl;
