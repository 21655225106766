import React, { FC } from "react";

const PreviewColor: FC<{ border: string | undefined; fill: string | undefined }> = ({ border, fill }) => {
    return (
        <div
            className='w-24 h-20 relative block border-2'
            style={{ background: fill ?? "transparent", borderColor: border ?? "transparent" }}
        />
    );
};

export default PreviewColor;
