import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control } from "ol/control";
import React, { useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Checkbox, CheckboxGroup, Form, Radio, RadioGroup, Toggle } from "rsuite";

import { cimiteroMapVisibilityConfigState } from "@stores/graveyard";

import { EDatiTomba, ELayerToShow } from "@models/Configs";

import { editModeState, levelsModeState } from "@openLayersMap/Stores/editControls";

import { useMap } from "../Map/MapContext";

const possibleLayers = [
    //{ label: "Mappa", value: ELayerToShow.Mappa },
    { label: "Griglia", value: ELayerToShow.Grid },
    //{ label: "Settori", value: ELayerToShow.Settore },
    //{ label: "Tombe", value: ELayerToShow.Tomba },
    { label: "Testi", value: ELayerToShow.Testo },
    { label: "Altri elementi", value: ELayerToShow.Retino },
];

const possibleGraveData = [
    { label: "Solo numero", value: EDatiTomba.Numero },
    { label: "Dati defunto", value: EDatiTomba.Defunto },
    { label: "Dati contratto", value: EDatiTomba.Contratto },
];

const VisibilityConfigControl = ({ hasTombe }: { hasTombe: boolean }) => {
    const { map } = useMap();
    const [visibilityConfig, setVisibilityConfig] = useRecoilState(cimiteroMapVisibilityConfigState);
    const editMode = useRecoilValue(editModeState);
    const [levelsMode, setLevelsMode] = useRecoilState(levelsModeState);

    const visibilityControlRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!map || !visibilityControlRef.current) return undefined;

        const visibilityControl = new Control({
            element: visibilityControlRef.current,
        });

        map.addControl(visibilityControl);

        return () => {
            if (map && visibilityControl && visibilityControlRef) {
                map.removeControl(visibilityControl);
            }
        };
    }, [map]);

    const isLivelsVisible = useMemo(() => {
        if (!levelsMode && !editMode) return true;
        if (editMode) return true;
        return false;
    }, [editMode, levelsMode]);

    return (
        <section
            className={`${
                isLivelsVisible ? "hidden" : "rounded shadow-lg w-fit absolute left-12 top-12 bg-white p-12 pr-24 "
            }`}
            ref={visibilityControlRef}
        >
            <div className='flex justify-end w-full'>
                <FontAwesomeIcon
                    icon={faClose}
                    size='lg'
                    className='cursor-pointer'
                    onClick={() => setLevelsMode(false)}
                />
            </div>
            <CheckboxGroup
                name='layerList'
                value={visibilityConfig.layers}
                onChange={(val) => {
                    setVisibilityConfig({ ...visibilityConfig, layers: val });
                }}
            >
                <p className='font-bold'>Livelli:</p>
                {possibleLayers.map((l) => (
                    <Checkbox value={l.value} key={l.value}>
                        {l.label}
                    </Checkbox>
                ))}
            </CheckboxGroup>

            {hasTombe && (
                <>
                    <RadioGroup
                        name='tombaList'
                        value={visibilityConfig.datiTomba}
                        onChange={(v) => {
                            setVisibilityConfig({
                                ...visibilityConfig,
                                datiTomba: (v as EDatiTomba) ?? EDatiTomba.Numero,
                            });
                        }}
                    >
                        <p className='font-bold mt-8'>Tomba:</p>
                        {possibleGraveData.map((l) => (
                            <Radio value={l.value} key={l.value}>
                                {l.label}
                            </Radio>
                        ))}
                    </RadioGroup>
                </>
            )}
            <CheckboxGroup name='iconeList'>
                <p className='font-bold mt-8'>Impostazioni:</p>
                <Form>
                    <Form.Group>
                        <Form.ControlLabel className='mt-2'>
                            <Toggle
                                size='md'
                                checked={visibilityConfig.iconeTomba}
                                onChange={(newValue, event) => {
                                    setVisibilityConfig({ ...visibilityConfig, iconeTomba: newValue });
                                }}
                            />
                            <span className='ml-2'>Mostra icone</span>
                        </Form.ControlLabel>

                        <Form.ControlLabel className='mt-2'>
                            <Toggle
                                size='md'
                                checked={visibilityConfig.overflow}
                                onChange={(newValue, event) => {
                                    setVisibilityConfig({ ...visibilityConfig, overflow: newValue });
                                }}
                            />
                            <span className='ml-2'>Strabordamento testo</span>
                        </Form.ControlLabel>
                    </Form.Group>
                </Form>
            </CheckboxGroup>
        </section>
    );
};

export default VisibilityConfigControl;
