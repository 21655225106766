import { format, parseISO } from "date-fns";
import { Feature } from "ol";
import { FeatureLike } from "ol/Feature";
import { getHeight, getWidth } from "ol/extent";
import { Circle, Geometry, GeometryCollection, Point } from "ol/geom";
import { Style, Text } from "ol/style";

import { Anagrafica } from "@models/Anagrafica";
import { ColoriConfig, EDatiTomba, FromTomba, VisibilityConfig } from "@models/Configs";
import { ColoreGrafica, ELayer, IGrafica } from "@models/Grafica";
import { SettoreCimitero } from "@models/SettoreCimitero";
import { EStato, IPostoTomba, PathItem, Tomba, TombaContratto } from "@models/Tomba";

import { GraveCanvas } from "@openLayersMap/Helpers/GraveCanvasHelpers";
import { getBorderStyle, getFillStyle, getTextIconStyle, getTextStyle } from "@openLayersMap/Helpers/StyleHelper";
import { wkt } from "@openLayersMap/Source";

export function getDefaultColorConfig(): ColoriConfig {
    return {
        settore: {},
        retino: {},
        mappa: {},
        tomba: [
            {
                statoTomba: "L",
                conContratto: false,
                colore: {},
            },
        ],
        aggiornaEsistente: true,
    };
}

const getColorStatoTomba = (tomba: Tomba, config: ColoriConfig): ColoreGrafica | undefined => {
    const contratti = tomba.contratti.filter((c) => !c.storico);
    return config?.tomba.find((ct) => ct.statoTomba === tomba.stato && ct.conContratto === contratti.length > 0)
        ?.colore;
};

export const getFillColorTomba = (tomba: Tomba, config: ColoriConfig, isStatusBadge?: boolean): any | null => {
    const color = getColorStatoTomba(tomba, config);

    const hasPrenotazione = tomba.posti.some((p) => p.stato === EStato.Prenotato);

    const graveCanvas = new GraveCanvas();
    graveCanvas.addBackground(color?.sfondo);

    if (hasPrenotazione) {
        graveCanvas.addStripes(1, 5, "red");
    }

    if (isStatusBadge) return color?.sfondo;

    // graveCanvas.addImage(lampadina, 0, 0, 100, 100);
    return hasPrenotazione ? graveCanvas.getCanvas() : color?.sfondo;
};

export const getStrokeColorTomba = (tomba: Tomba, config: ColoriConfig): string | undefined => {
    const color = getColorStatoTomba(tomba, config);

    return color?.bordo;
};

export const getPrimoDefunto = (defunti: IPostoTomba[]): Anagrafica | undefined => {
    return defunti && defunti.length > 0 ? defunti.find((d) => !d.storico)?.anagrafica : undefined;
};

export const getDefunti = (defunti: IPostoTomba[]): IPostoTomba[] | undefined => {
    return defunti?.filter((d) => !d.storico);
};

export const getPrimoContratto = (contratti: TombaContratto[]): TombaContratto | undefined => {
    return contratti && contratti.length > 0 ? contratti.find((d) => !d.storico) : undefined;
};

const iconPositions: { textAlign: CanvasTextAlign; textBaseline: CanvasTextBaseline }[] = [
    { textAlign: "left", textBaseline: "bottom" },
    { textAlign: "right", textBaseline: "bottom" },
    { textAlign: "right", textBaseline: "top" },
    { textAlign: "left", textBaseline: "top" },
];

function createFeature(
    id: number | undefined,
    name: string | null | undefined,
    geom: string | null | undefined,
    properties: { element: any; layer: ELayer }
): Feature {
    if (!geom) {
        return new Feature({
            id,
            name: name ?? "",
            properties,
        });
    }

    return new Feature({
        id,
        geometry: wkt.readGeometry(geom),
        name: name ?? "",
        properties,
    });
}

function createFeatureWithPoint(
    id: number,
    name: string | null | undefined,
    geom: string | string[],
    properties: { element: any; layer: ELayer }
): Feature {
    if (!geom) {
        return new Feature({
            id,
            name: name ?? "",
            properties,
        });
    }

    const geometry = wkt.readGeometry(geom);

    const type = geometry.getType();

    if (type !== "Polygon") {
        return new Feature({
            id,
            name: name ?? "",
            properties,
        });
    }

    // @ts-ignore
    const getVertici = geometry.getCoordinates()[0];
    const points = getVertici.map((v: any) => new Point(v));

    return new Feature({
        id,
        geometry: new GeometryCollection([geometry, ...points]),
        name: name ?? "",
        properties,
    });
}

function createCircleFeature(
    id: number | undefined,
    name: string | null | undefined,
    geom: string | null | undefined,
    radius: number,
    properties: { element: any; layer: ELayer }
): Feature {
    if (!geom) {
        return new Feature({
            id,
            name: name ?? "",
            properties,
        });
    }

    // @ts-ignore
    const centerPoint = wkt.readGeometry(geom)?.getCoordinates();
    const circle = new Circle(centerPoint, radius);

    return new Feature({
        id,
        geometry: circle,
        name: name ?? "",
        properties,
    });
}

const getDiagonale = (f: Feature<Geometry>): number => {
    if (f) {
        const geometry = f.getGeometry();
        const extentF = (geometry as any)?.getExtent();

        const width = getWidth(extentF);
        const height = getHeight(extentF);

        const diagonale = Math.sqrt((extentF[3] - extentF[1]) ** 2 + (extentF[2] - extentF[0]) ** 2);

        return diagonale;
    }

    return 12;
};

const getDimensioneTestoTomba = (f: Feature<Geometry>): number => {
    if (f) {
        const geometry = f.getGeometry();
        const extentF = (geometry as any)?.getExtent();

        const width = getWidth(extentF);
        const height = getHeight(extentF);

        const ratio = width / height;

        if (ratio >= 0.5) {
            return Math.sqrt((extentF[3] - extentF[1]) ** 2 + (extentF[2] - extentF[0]) ** 2);
        }

        // how to calulate the size of the text to fit inside the box
        return Math.sqrt((width * height) / 2);
    }

    return 12;
};

const getColorSearchMode = (settoreCimitero: SettoreCimitero | Tomba, livelloRicerca?: number, color?: string) => {
    if (livelloRicerca && settoreCimitero?.id === livelloRicerca) return "red";
    if (!livelloRicerca && color) return color;

    return "#e5e5e5";
};

/* const getTextSearchMode = (settoreCimitero: Tomba, text: string[], livelloRicerca?: number) => {
    if (livelloRicerca && settoreCimitero?.id === livelloRicerca) return text;
    if (!livelloRicerca && text) return text;

    return text;
}; */

const fromSettore = (
    settoreCimitero: SettoreCimitero,
    idStruttura: number,
    livelloRicerca: PathItem | undefined,
    visibilityConfig: VisibilityConfig
): Feature[] => {
    return (
        settoreCimitero.grafiche?.map((grafica) => {
            // todo -> attenzione può essere un punto: fare in modo che l'area sia un po' maggiore (10%)
            // se è un punto, provare a stampare come se fosse un punto.

            const feature = createFeature(grafica.id, (grafica.id ?? 0).toString(), grafica.geom, {
                element: {
                    ...settoreCimitero,
                    idStruttura,
                    colore: {
                        sfondo: getFillStyle(grafica?.colore?.sfondo ?? [0, 0, 0, 0]).getColor(),
                        bordo: getBorderStyle(grafica?.colore?.bordo).getColor(),
                    },
                    text: {
                        val: settoreCimitero.codice ? settoreCimitero.codice : "",
                    },
                },
                layer: ELayer.Settore,
            });

            // const isPoint = feature.getGeometry()?.getType() === "Point";

            feature.setId(grafica.id);
            feature.setStyle((f: FeatureLike, resolution: any) => {
                const fl = f as Feature;

                const diagonale = getDiagonale(fl);

                fl.set("resolution", resolution);
                fl.set("sizeDiagonale", diagonale);
                // const size = isPoint ? 130 : 30;

                return new Style({
                    stroke: getBorderStyle(
                        getColorSearchMode(settoreCimitero, livelloRicerca?.id, grafica?.colore?.bordo)
                    ),
                    fill: getFillStyle(
                        getColorSearchMode(settoreCimitero, livelloRicerca?.id, grafica?.colore?.sfondo)
                    ),
                    text: getTextStyle({
                        text: settoreCimitero.codice ? [settoreCimitero.codice, ""] : [],
                        color: [0, 0, 0, 1],
                        align: "center",
                        resolution,
                        size: grafica?.testo?.size ?? getDimensioneTestoTomba(fl),
                        showOnlyLargerFont: !grafica?.testo?.size,
                        shouldCalculateSize: !grafica?.testo?.size,
                        rotation: 0,
                    }),
                    zIndex: 2,
                });
            });

            return feature;
        }) ?? []
    );
};

const tombaText = (visibilityConfig: VisibilityConfig, tomba: Tomba): string[] => {
    let value = [tomba.numero, "title"];

    if (visibilityConfig.datiTomba === EDatiTomba.Defunto) {
        const primoDefunto = getPrimoDefunto(tomba.posti);

        if (primoDefunto) {
            value = [...value, ...["\n", "", primoDefunto.cognome, ""]];
            value = [...value, ...["\n", "", primoDefunto.nome, ""]];
            value = [...value, ...["\n", "", primoDefunto.dataMorte ?? "", ""]];
        }
    }

    if (visibilityConfig.datiTomba === EDatiTomba.Contratto) {
        const primoContratto = getPrimoContratto(tomba.contratti);

        if (primoContratto) {
            value = [...value, ...["\n", "", `${primoContratto?.numero}/${primoContratto?.anno}`, ""]];
            if (primoContratto?.dataScadenza) {
                value = [...value, ...["\n", "", format(parseISO(primoContratto?.dataScadenza), "dd/MM/yyyy"), ""]];
            }
        }
    }

    return value;
};

const getIcone = (
    visibilityConfig: VisibilityConfig,
    tomba: Tomba,
    geometries: any,
    resolution: number,
    feature: any
) => {
    // tomba.icone = ["0x0031", "0x0032", "0x0033", "0x0034"];
    let icone: any = [];
    if (visibilityConfig.iconeTomba) {
        icone = tomba.icone
            ? tomba.icone.map((icona, index) => {
                  if (!icona) {
                      return new Style();
                  }

                  const iconeDellaTomba = icona
                      .trimEnd()
                      .split(" ")
                      .map((c) => String.fromCharCode(Number(c)))
                      .join(" ");

                  return new Style({
                      geometry: geometries[index + 1],
                      text: new Text({
                          ...getTextIconStyle({
                              text: iconeDellaTomba,
                              // text: icona,
                              color: "black",
                              ...iconPositions[index],
                              resolution,
                              size:
                                  getDimensioneTestoTomba(feature) *
                                  (visibilityConfig.datiTomba === EDatiTomba.Numero ? 2 : 1),
                              fontLimit: true,
                          }),
                      }),
                      zIndex: 3,
                  });
              })
            : [];
    }
    return icone;
};

const fromTomba = ({ tomba, colorConfig, visibilityConfig, idTomba }: FromTomba) => {
    if (tomba.grafica === null) {
        return new Feature();
    }

    const value = tombaText(visibilityConfig, tomba);

    const feature = createFeatureWithPoint(tomba.grafica?.id ?? 0, tomba.numero, tomba.grafica?.geom ?? "", {
        element: {
            ...tomba,
            colore: {
                sfondo: getFillStyle(getFillColorTomba(tomba, colorConfig)).getColor(),
                bordo: getBorderStyle(getStrokeColorTomba(tomba, colorConfig), 1).getColor(),
            },
            text: {
                val: tomba.numero,
            },
        },
        layer: ELayer.Tomba,
    });

    feature.setId(tomba.id);
    feature.setStyle((f: any, resolution: any) => {
        f.set("resolution", resolution);
        f.set("sizeDiagonale", getDiagonale(f));

        const geometry = f.getGeometry();
        const geometries = geometry.getGeometries();
        const icone = getIcone(visibilityConfig, tomba, geometries, resolution, f);

        const tombaStyle = new Style({
            geometry: geometries[0],
            fill: getFillStyle(getColorSearchMode(tomba, idTomba, getFillColorTomba(tomba, colorConfig))),
            stroke: getBorderStyle(getColorSearchMode(tomba, idTomba, getStrokeColorTomba(tomba, colorConfig)), 1),
            text: getTextStyle({
                text: value,
                color: "black",
                align: "center",
                resolution,
                size:
                    tomba?.grafica?.testo?.size ??
                    getDimensioneTestoTomba(f) * (visibilityConfig.datiTomba === EDatiTomba.Numero ? 2 : 1),
                showOnlyLargerFont: !tomba?.grafica?.testo?.size,
                shouldCalculateSize: !tomba?.grafica?.testo?.size,
                overflow: visibilityConfig.overflow,
            }),
            zIndex: 3,
        });

        return [tombaStyle, ...icone];
    });

    return feature;
};

const fromTesto = (graficaTesto: IGrafica) => {
    const feature = createFeature(graficaTesto.id, graficaTesto?.testo?.val, graficaTesto?.geom, {
        element: graficaTesto,
        layer: ELayer.Testo,
    });
    feature.setId(graficaTesto.id);
    feature.setStyle((f: any, resolution: any) => {
        const diagonale = getDiagonale(f);
        // const size = graficaTesto.testo?.size ?? 1;

        f.set("resolution", resolution);
        f.set("sizeDiagonale", getDiagonale(f));

        return new Style({
            text: getTextStyle({
                text: graficaTesto?.testo?.val ? [graficaTesto?.testo?.val, ""] : [],
                color: graficaTesto.colore.sfondo ?? "#000000",
                align: diagonale === 0 ? "left" : "center",
                resolution,
                // size: diagonale === 0 ? 12 : diagonale,
                size: graficaTesto.testo?.size,
                rotation: graficaTesto?.testo?.rot ?? 0,
                showOnlyLargerFont: true,
                shouldCalculateSize: false,
                limitTextBetweenRange: false,
            }),
            zIndex: 4,
        });
    });
    return feature;
};

const fromRetino = (graficaRetino: IGrafica) => {
    const props = {
        element: graficaRetino,
        layer: ELayer.Retino,
    };

    const feature =
        graficaRetino.raggio && graficaRetino.raggio > 0
            ? createCircleFeature(
                  graficaRetino.id,
                  graficaRetino?.testo?.val,
                  graficaRetino?.geom,
                  graficaRetino.raggio,
                  props
              )
            : createFeature(graficaRetino.id, graficaRetino?.testo?.val, graficaRetino?.geom, props);
    feature.setId(graficaRetino.id);
    feature.setStyle((f: any, resolution: any) => {
        f.set("resolution", resolution);
        f.set("sizeDiagonale", getDiagonale(f));

        return new Style({
            stroke: getBorderStyle(graficaRetino.colore.bordo),
            fill: getFillStyle(graficaRetino.colore.sfondo ?? [0, 0, 0, 0]),
            text: getTextStyle({
                text: [graficaRetino.testo?.val ?? "", ""],
                color: "black",
                align: "center",
                resolution,
                size: getDiagonale(f),
                showOnlyLargerFont: true,
            }),
            zIndex: 1,
        });
    });
    return feature;
};

export { fromSettore, fromTomba, fromTesto, fromRetino, tombaText, getDiagonale, getIcone, getDimensioneTestoTomba };
