import React from "react";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const ProtectedContrattiRoute = () => {
    const isArenzano = window.location.href.includes("arenzano");
    return !isArenzano ? lazyLoadRoutes("cruxWebContratti") : <Navigate to='/ricerca/defunti' replace />;
};

const cruxWebRoutes: RouteObject = {
    element: <Outlet />,
    children: [
        {
            path: "",
            index: true,
            element: lazyLoadRoutes("cruxWebContainer"),
        },
        {
            path: "totem",
            element: lazyLoadRoutes("cruxWebTotemIndex"),
        },
        {
            path: "totem/:id",
            element: lazyLoadRoutes("cruxWebTotemShow"),
        },
        {
            path: "defunti",
            element: lazyLoadRoutes("cruxWebDefunti"),
        },
        {
            path: "contratti",
            element: <ProtectedContrattiRoute />,
        },
    ],
};

export default cruxWebRoutes;
