import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Button, Modal } from "rsuite";

import { versionBEState } from "@stores/globalUserSettings";

type ModalProps = {
    handleClose: () => void;
    isOpen: boolean;
};

const RilascioNuovaVersione: FC<ModalProps> = (props) => {
    const { handleClose, isOpen } = props;
    const storedBEVersion = useRecoilValue(versionBEState);
    const navigate = useNavigate();

    const navigateToChangelog = () => {
        navigate("/cronologia-versioni");
        handleClose();
    };

    return (
        <Modal backdrop='static' role='alertdialog' open={isOpen} onClose={handleClose} size='md'>
            <Modal.Body className='px-8'>
                E' stata rilasciata la versione <b>{storedBEVersion}</b> di Crux10, accedi al changelog per scoprire
                tutte le novità.
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={navigateToChangelog} appearance='ghost'>
                    Vai al changelog
                </Button>
                <Button onClick={handleClose} appearance='primary'>
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RilascioNuovaVersione;
