import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const cremazioniSettingsRoutes: RouteObject[] = [
    {
        path: "forni-crematori",
        index: true,
        element: lazyLoadRoutes("forniIndex"),
    },
];

export default cremazioniSettingsRoutes;
