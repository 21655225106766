import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const changelogRoutes: RouteObject = {
    element: <Outlet />,
    children: [
        {
            index: true,
            path: "",
            element: lazyLoadRoutes("changelogIndex"),
        },
    ],
};

export default changelogRoutes;
