import { atom, selector } from "recoil";

import { ComuneGestito } from "@models/Comune";
import { SettoreCimitero } from "@models/SettoreCimitero";

import { persistRecoil } from "./effects/persistRecoil";

enum ActionSidenavTree {
    CambioComune = "CambioComune",
    SelezioneCimitero = "SelezioneCimitero",
    SelezioneSettore = "SelezioneCimitero",
}

const idMunicipalityState = atom<number | undefined>({
    key: "idMunicipality",
    default: undefined,
});

const { persistAtom } = persistRecoil();

const cimiteriListState = atom<SettoreCimitero[] | undefined>({
    key: "cimiteriListState",
    default: undefined,
});

const comuniState = atom<ComuneGestito[] | undefined>({
    key: "comuniState",
    default: undefined,
});

const graveyardSectionState = atom<SettoreCimitero[]>({
    key: "graveyardSectionState",
    default: [],
});

const actionSidenavTreeState = atom<ActionSidenavTree | undefined>({
    key: "actionSidenavTreeState",
    default: undefined,
});

const loadingSidenavTreeState = atom<boolean>({
    key: "loadingSidenavTreeState",
    default: false,
});

const idCimiteroAttivoState = atom<number | null>({
    key: "idCimiteroAttivoState",
    default: null,
    effects: [persistAtom],
});

const idCimiteroTrasferimentoState = atom<number | null>({
    key: "idCimiteroTrasferimentoState",
    default: null,
});

const idCimiteroAttivoPersistState = selector({
    key: "idCimiteroAttivoPersistState",
    get: ({ get }) => {
        const idCimitero = get(idCimiteroAttivoState) as any;
        return idCimitero;
    },
});

const reloadSidenavTreeState = atom<boolean>({
    key: "reloadSidenavTreeState",
    default: false,
});

export {
    idMunicipalityState,
    cimiteriListState,
    comuniState,
    graveyardSectionState,
    actionSidenavTreeState,
    loadingSidenavTreeState,
    idCimiteroAttivoState,
    idCimiteroAttivoPersistState,
    reloadSidenavTreeState,
    idCimiteroTrasferimentoState,
};
export { ActionSidenavTree };
