/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faCircle,
    faDrawPolygon,
    faHexagon,
    faHorizontalRule,
    faRectangle,
    faText,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EIconeDraw } from "@models/Configs";

interface IconsPros {
    handleDrawControl: (id: EIconeDraw) => void;
    selectedButton: { id: string | null };
}

const DrawIcons = ({ handleDrawControl, selectedButton }: IconsPros) => {
    return (
        <div className='grid grid-cols-3 gap-8'>
            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Circle ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Circle)}
            >
                <FontAwesomeIcon icon={faCircle as IconProp} size='xl' title='disegna cerchio' cursor='pointer' />
                <p className='text-primary-500 text-center'>Cerchio</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Rectangle ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Rectangle)}
            >
                <FontAwesomeIcon icon={faRectangle as IconProp} size='xl' title='disegna rettangolo' cursor='pointer' />
                <p className='text-primary-500 text-center'>Rettangolo</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.LineString ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.LineString)}
            >
                <FontAwesomeIcon icon={faHorizontalRule as IconProp} size='xl' title='disegna linea' cursor='pointer' />
                <p className='text-primary-500 text-center'>Linea</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Polygon ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Polygon)}
            >
                <FontAwesomeIcon
                    icon={faDrawPolygon as IconProp}
                    size='xl'
                    title='disegna poligono libero'
                    cursor='pointer'
                />
                <p className='text-primary-500 text-center'>Poligono libero</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Regular ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Regular)}
            >
                <FontAwesomeIcon
                    icon={faHexagon as IconProp}
                    size='xl'
                    title='disegna poligono regolare'
                    cursor='pointer'
                />
                <p className='text-primary-500 text-center'>Poligono</p>
            </div>

            <div
                className={`p-8 rounded flex justify-center flex-col space-y-4 w-[5rem] ${
                    selectedButton.id === EIconeDraw.Text ? "bg-primary-50 " : "bg-athens-200"
                }`}
                onClick={() => handleDrawControl(EIconeDraw.Text)}
            >
                <FontAwesomeIcon icon={faText as IconProp} size='xl' title='disegna testo' cursor='pointer' />
                <p className='text-primary-500 text-center'>Testo</p>
            </div>
        </div>
    );
};
export default DrawIcons;
