import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Popover } from "rsuite";

export const useExternalTab = ({ deps, to }: { deps: unknown; to?: string }) => {
    const navigate = useNavigate();

    const renderSubmenu = useCallback(
        (props: any, ref: any) => {
            const { className, left, top, onClose } = props;
            return (
                <Popover ref={ref} className={className} style={{ left, top }} full>
                    <Dropdown.Menu onSelect={onClose} className='flex flex-col items-start space-y-8'>
                        <Button
                            appearance='link'
                            onClick={() => {
                                if (to) {
                                    navigate(to);
                                    onClose();
                                }
                            }}
                        >
                            Visualizza dettaglio
                        </Button>
                        <Button
                            appearance='link'
                            onClick={() => {
                                if (to) {
                                    window.open(`${to}?preview=true`, "_blank", "noreferrer");
                                    onClose();
                                }
                            }}
                        >
                            Visualizza dettaglio in una nuova finestra
                        </Button>
                    </Dropdown.Menu>
                </Popover>
            );
        },
        [deps]
    );

    return {
        renderSubmenu,
    };
};
