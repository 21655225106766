import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { ItemDataType } from "rsuite/esm/@types/common";

import { getAllSettori, getFigliSettoreCimitero } from "@services/CimiteriService";

import { isCimiteriEditModeState } from "@stores/graveyard";
import { addLivelloState, deleteLivelloState, updateLivelloState } from "@stores/livello";
import { cimiteriListState, graveyardSectionState, idMunicipalityState } from "@stores/municipality";

import { useCruxNavigate } from "@hooks/useCruxNavigate";
import { useCruxToaster } from "@hooks/useCruxToaster";

import SidenavTree from "@components/SidenavTree/SidenavTree";

import { findTreeChild, mapSettoriToTree, paramsToObject } from "@helpers/SearchTree";

const GraveyardsSidebar = () => {
    const navigate = useNavigate();

    const { idCimitero, idSettore } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const params: any = useMemo(() => paramsToObject(searchParams), [searchParams]);

    const { handleApiError } = useCruxToaster();
    const { isCimitero, idCimiteroAttivo } = useCruxNavigate();

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [graveyardSections, setGraveyardSections] = useRecoilState(graveyardSectionState);
    const [expandedItemValues, setExpandedItemValues] = useState<ItemDataType<string | number>[]>([]);
    const [isExpandTree, setIsExpandTree] = useState(true);
    const [itemValues, setItemValues] = useState<any[]>([]);

    const idMunicipality = useRecoilValue(idMunicipalityState);
    const isEditMode = useRecoilValue(isCimiteriEditModeState);
    const addLivello = useRecoilValue(addLivelloState);
    const updateLivello = useRecoilValue(updateLivelloState);
    const deleteLivello = useRecoilValue(deleteLivelloState);
    const cimiteriList = useRecoilValue(cimiteriListState);

    const treeData = useMemo(
        () => mapSettoriToTree(graveyardSections),
        // eslint-disable-next-line
        [graveyardSections, searchQuery]
    );

    const currentParentIds: string[] = useMemo(
        () => (idSettore ? findTreeChild(idSettore, treeData)?.parentIds ?? [] : []),
        [idSettore, treeData]
    );

    const loadFigliCimitero = () => {
        if (idCimiteroAttivo) {
            getFigliSettoreCimitero(idCimiteroAttivo)
                .then((res) => setGraveyardSections(res))
                .catch((apiError) => {
                    handleApiError({ error: apiError.response.data });
                })
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        if (!isEditMode && isCimitero && cimiteriList && cimiteriList?.length > 0) {
            setLoading(true);
            loadFigliCimitero();
        } else {
            setGraveyardSections([]);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [idCimiteroAttivo, params.ns, cimiteriList, isEditMode]);

    useEffect(() => {
        if (isEditMode && isCimitero) {
            setLoading(true);
            setIsExpandTree(true);

            getAllSettori()
                .then((res) => setGraveyardSections(res))
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line
    }, [isEditMode, addLivello, updateLivello, deleteLivello, idMunicipality]);

    useEffect(() => {
        if (isExpandTree) setItemValues([...currentParentIds, ...expandedItemValues]);
        if (!isExpandTree) setItemValues([...expandedItemValues]);
    }, [expandedItemValues, currentParentIds, isExpandTree]);

    const handleSelectSettore = (e: any) => {
        if (!isEditMode) {
            navigate(`/cimitero/${idCimitero}/settore/${e.value}`);
        } else {
            const idRootCimitero = e.parentIds[0];
            if (idRootCimitero) {
                navigate(`/cimitero/${idRootCimitero}/settore/${e.value}`);
            } else {
                navigate(`/cimitero/${e.value}`);
            }
        }
    };

    return (
        <div className='h-fit w-[24rem] bg-[#2D3D60]'>
            <SidenavTree
                graveyardSections={graveyardSections}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                idCimitero={idCimitero}
                idSettore={idSettore}
                treeData={treeData}
                itemValues={itemValues}
                setIsExpandTree={setIsExpandTree}
                setExpandedItemValues={setExpandedItemValues}
                handleSelectSettore={handleSelectSettore}
                loading={loading}
            />
        </div>
    );
};
export default GraveyardsSidebar;
