import React, { FC } from "react";

import { IndirizzoAnagrafica } from "@models/Anagrafica";

const AddressFormat: FC<{ indirizzo: IndirizzoAnagrafica; prefix?: string }> = ({ indirizzo, prefix }) => {
    if (!indirizzo?.indirizzo) {
        return <></>;
    }
    return (
        <span>
            {prefix && <> {prefix} </>}
            {indirizzo.indirizzo} {indirizzo.civico}
            {indirizzo.comune && (indirizzo.indirizzo || indirizzo.civico) && <>, </>}
            {indirizzo.comune && <>{indirizzo.comune?.comune}</>}
        </span>
    );
};

export default AddressFormat;
