import { useCallback, useEffect, useMemo, useState } from "react";
import { ItemDataType } from "rsuite/esm/@types/common";

import { getFigliSettoreCimitero } from "@services/CimiteriService";

import { SettoreCimitero } from "@models/SettoreCimitero";

import { useCruxNavigate } from "@hooks/useCruxNavigate";
import { useCruxToaster } from "@hooks/useCruxToaster";

import SidenavTree from "@components/SidenavTree/SidenavTree";

import { findTreeChild, mapSettoriToTree } from "@helpers/SearchTree";

const SelezionaSettore = ({
    onSettoreSelected,
    onCimiteroIdSelected,
    selectedIdSettore,
    className,
}: {
    onSettoreSelected: (sc: SettoreCimitero) => void;
    selectedIdSettore?: number;
    onCimiteroIdSelected?: (idCimitero: number) => void;
    className?: string;
}): JSX.Element => {
    const { idCimiteroAttivo } = useCruxNavigate();
    const { handleApiError } = useCruxToaster();

    const [loading, setLoading] = useState(false);

    const [graveyardSections, setGraveyardSections] = useState<SettoreCimitero[]>([]);
    const [itemValues, setItemValues] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState("");

    const [idCimitero, setIdCimitero] = useState<number>(idCimiteroAttivo ?? 0);
    const [idSettore, setIdSettore] = useState<number | undefined>(undefined);

    const [expandedItemValues, setExpandedItemValues] = useState<ItemDataType<string | number>[]>([]);
    const [isExpandTree, setIsExpandTree] = useState(true);

    const handleSelectSettore = (settoreCimitero: SettoreCimitero): void => {
        setIdSettore(settoreCimitero.id);
        onSettoreSelected(settoreCimitero);
    };

    useEffect(() => {
        // setIdSettore(idCimitero);
        onCimiteroIdSelected?.(idCimitero);
        // eslint-disable-next-line
    }, [idCimitero]);

    useEffect(() => {
        if (selectedIdSettore) {
            setIdSettore(selectedIdSettore);
        }
    }, [selectedIdSettore]);

    const treeData = useMemo(
        () => mapSettoriToTree(graveyardSections),
        // eslint-disable-next-line
        [graveyardSections, searchQuery]
    );

    const currentParentIds: string[] = useMemo(
        () => (idSettore ? findTreeChild(idSettore.toString(), treeData)?.parentIds ?? [] : []),
        [idSettore, treeData]
    );

    useEffect(() => {
        setItemValues([...currentParentIds, ...expandedItemValues]);
    }, [expandedItemValues, currentParentIds, isExpandTree]);

    /* TODO sostituire con api ricerca */
    const loadFigliCimitero = useCallback(() => {
        if (idCimitero) {
            getFigliSettoreCimitero(idCimitero)
                .then((res) => {
                    setGraveyardSections(res);
                    setItemValues([]);
                    //run(idSettore);
                })
                .catch((apiError) => {
                    handleApiError({ error: apiError.response.data });
                })
                .finally(() => setLoading(false));
        } else {
            setItemValues([]);
            setGraveyardSections([]);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [idCimitero]);

    useEffect(() => {
        setLoading(true);
        loadFigliCimitero();
    }, [loadFigliCimitero]);

    return (
        <SidenavTree
            graveyardSections={graveyardSections}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            idCimitero={idCimitero.toString()}
            idSettore={idSettore?.toString()}
            treeData={treeData}
            itemValues={itemValues}
            setIsExpandTree={setIsExpandTree}
            setExpandedItemValues={setExpandedItemValues}
            handleSelectSettore={handleSelectSettore}
            loading={loading}
            searchMode
            onIdCimiteroChange={setIdCimitero}
            className={className}
        />
    );
};

export default SelezionaSettore;
