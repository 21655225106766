import { Feature } from "ol";
import Transform from "ol-ext/interaction/Transform";
import { Geometry } from "ol/geom";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import { useState } from "react";

import { ELayer } from "@models/Grafica";

import { useMap } from "@openLayersMap/Map/MapContext";

import { defaultModify, featureLayer, featureTesto, findDrawFeature } from "../Helpers/EditMappingHelpers";
import { circleStyle, selezioneStyle } from "../Helpers/StyleHelper";
import useEditMappingHook from "./EditMappingHook";

interface TransformHookProps {
    source: VectorSource;
}

const useTransformHook = ({ source }: TransformHookProps) => {
    const { map } = useMap();
    const { restoreFeature, setNewFeature } = useEditMappingHook({
        source,
    });
    const [transformFeature, setTransformFeature] = useState<Feature | null>(null);

    const interaction = new Transform(defaultModify);

    let startAngle = 0;
    let newAngle = 0;

    interaction.setStyle(
        "rotate",
        new Style({
            text: new Text({
                text: "\uf0e2",
                font: "16px Fontawesome",
                textAlign: "left",
                fill: new Fill({ color: "red" }),
            }),
            image: circleStyle,
        })
    );

    // Center of rotation
    interaction.setStyle(
        "rotate0",
        new Style({
            text: new Text({
                text: "\uf0e2",
                font: "20px Fontawesome",
                fill: new Fill({ color: [255, 255, 255, 0.8] }),
                stroke: new Stroke({ width: 2, color: "red" }),
            }),
        })
    );

    // Style the move handle
    interaction.setStyle(
        "translate",
        new Style({
            text: new Text({
                text: "\uf047",
                font: "20px Fontawesome",
                fill: new Fill({ color: [255, 255, 255, 0.8] }),
                stroke: new Stroke({ width: 2, color: "red" }),
            }),
        })
    );

    const toggleInteraction = (): void => {
        map?.removeInteraction(interaction);
        map?.addInteraction(interaction);
    };

    const getSelectedFeature = (feature: Feature<Geometry>): void => {
        if (feature && feature.getId()) {
            const getFeature = source.getFeatureById(feature.getId() as number);
            setTransformFeature(getFeature);
        }

        if (feature && !feature.getId()) {
            const getFeature = findDrawFeature({ feature, source });
            setTransformFeature(getFeature);
        }
    };

    const initTransform = (keys: string[]): void => {
        if (!map) return;

        //const keys = ["selection", "scale", "rotate", "translate"];

        keys.forEach((key) => {
            interaction.setProperties({ [key]: true });
        });
        map.addInteraction(interaction);
    };

    const destroyTransform = () => {
        interaction.setProperties({});
        map?.removeInteraction(interaction);
    };

    interaction.on("select", (evt) => {
        setTransformFeature(null);
        if (evt.feature) {
            const layer = featureLayer(evt.feature);
            evt.feature.setStyle(selezioneStyle);
            if (layer === ELayer.Testo) {
                initTransform(["selection", "translate"]);
            } else initTransform(["selection", "scale", "rotate", "translate"]);

            setTimeout(() => {
                getSelectedFeature(evt.feature);
            }, 500);
        }
    });

    interaction.on(["scaleend", "translateend"], (evt) => {
        const feature = (evt as any)?.feature;

        setNewFeature(feature);
        restoreFeature(feature);
        toggleInteraction();
    });

    interaction.on(["rotatestart", "rotating", "rotateend"], (evt) => {
        const feature = (evt as any)?.feature;
        const angle = (evt as any)?.angle;
        const propertiesTesto = featureTesto(feature);
        const textAngle = propertiesTesto?.rot;

        if (evt.type === "rotatestart" && feature) {
            const sA = textAngle ?? 0;
            startAngle = sA;
        }

        if (evt.type === "rotating" && feature) {
            newAngle = startAngle - angle;
            feature.set("angle", newAngle);
        }

        if (evt.type === "rotateend" && feature) {
            setNewFeature(feature);
            restoreFeature(feature);
            toggleInteraction();
        }
    });

    return {
        initTransform,
        destroyTransform,
        transformFeature,
    };
};

export default useTransformHook;
