import { atom, selector } from "recoil";

import { persistRecoil } from "@stores/effects/persistRecoil";

import { EDatiTomba, ELayerToShow, VisibilityConfig } from "@models/Configs";
import { CimiteroEvent } from "@models/Events/Cimiteri";
import { SettoreCimitero } from "@models/SettoreCimitero";

export interface ICimiteroState {
    idSettore: number;
    idPadri?: number[];
    evento: CimiteroEvent;
}

const { persistAtom } = persistRecoil();

const cimiteriState = atom<SettoreCimitero[] | undefined>({
    key: "cimiteriState",
    default: [],
});

const cimiteriViewTypeState = atom<"map" | "data">({
    key: "cimiteriViewTypeState",
    default: "data",
    effects: [persistAtom],
});

const isCimiteriViewTypeMap = selector({
    key: "isCimiteriViewTypeMap",
    get: ({ get }) => {
        return get(cimiteriViewTypeState) === "map";
    },
});

const isCimiteriEditModeState = atom<boolean>({
    key: "isCimiteriEditModeState",
    default: false,
});

const cimiteroEventState = atom<ICimiteroState | undefined>({
    key: "cimiteroEventState",
    default: undefined,
});

const lastGraveState = atom<number | string | undefined>({
    key: "lastGraveState",
    default: undefined,
});

const addGraveState = atom<number | undefined>({
    key: "addGraveState",
    default: undefined,
});

const incrementGraveState = atom<number | undefined>({
    key: "incrementGraveState",
    default: undefined,
});

const cimiteroMapVisibilityConfigState = atom<VisibilityConfig>({
    key: "cimiteroMapVisibilityConfigState",
    default: {
        layers: [ELayerToShow.Settore, ELayerToShow.Tomba, ELayerToShow.Testo, ELayerToShow.Retino],
        datiTomba: EDatiTomba.Numero,
        iconeTomba: true,
        showSmallText: true,
        overflow: true,
    },
    effects: [persistAtom],
});

export {
    cimiteriState,
    cimiteriViewTypeState,
    isCimiteriEditModeState,
    cimiteroEventState,
    lastGraveState,
    addGraveState,
    incrementGraveState,
    isCimiteriViewTypeMap,
    cimiteroMapVisibilityConfigState,
};
