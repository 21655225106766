import { EStato, IPostoTomba } from "@models/Tomba";

const coloriPosto = ({ postoTomba }: { postoTomba: IPostoTomba }): string => {
    if (postoTomba.stato === EStato.Occupato) return "red-600";
    if (postoTomba.stato === EStato.Libero) return "green-500";
    if (postoTomba.stato === EStato.Prenotato) return "primary-200";

    return "primary-500";
};

export { coloriPosto };
