import { uniqueId } from "lodash";
import { Geometry } from "ol/geom";
import { Draw } from "ol/interaction";
import { createBox } from "ol/interaction/Draw";
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { Style } from "ol/style";
import React, { FC, useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { addSettore } from "@services/CimiteriService";

import { colorConfigState } from "@stores/globalUserSettings";

import { EIconeDraw } from "@models/Configs";
import { SettoreCimitero } from "@models/SettoreCimitero";
import { IStrutturaLivelli } from "@models/StrutturaLivelli";

import { useCruxToaster } from "@hooks/useCruxToaster";

import AddSettorePanel from "@openLayersMap/Components/Panels/AddSettorePanel";
import { getBorderStyle, getFillStyle, getVectorStyle } from "@openLayersMap/Helpers/StyleHelper";
import { useMap } from "@openLayersMap/Map/MapContext";
import { wkt } from "@openLayersMap/Source";
import { typeDrawState } from "@openLayersMap/Stores/editControls";

export type SettoreDrawType = "rectangle" | "polygon";

interface SettoreHookProps {
    onSettoreCreated?: (e: SettoreCimitero) => void;
    settoreCimitero: SettoreCimitero;
}

const vector = new VectorLayer({
    source: new VectorSource({ wrapX: false }),
    className: "settore-vector-layer",
    style: getVectorStyle,
});

const useSettoreDrawHook = ({ onSettoreCreated, settoreCimitero }: SettoreHookProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { map, startSnap, stopSnap } = useMap();
    const colorConfig = useRecoilValue(colorConfigState);
    const { handleApiError } = useCruxToaster();
    const setTypeDraw = useSetRecoilState(typeDrawState);

    const [hasDrawn, setHasDrawn] = useState<boolean>(false);
    const [drawType, setDrawType] = useState<SettoreDrawType>("rectangle");
    const [lastDraw, setLastDraw] = useState<any>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const source = vector.getSource() as VectorSource;

    const draw = useMemo(() => {
        if (drawType === "polygon") {
            return new Draw({
                source,
                type: "Polygon",
            });
        }
        return new Draw({
            source,
            type: "Circle",
            geometryFunction: createBox(),
        });
    }, [drawType, source]);

    const getTempFeature = () => source.getFeatures().find((f) => f.getProperties().isTemp);

    const removeTempFeature = () => {
        const tmpFeature = getTempFeature();
        if (tmpFeature) source.removeFeature(tmpFeature);
    };

    draw.on("drawstart", () => {
        removeTempFeature();
    });

    draw.on("drawend", (e: any) => {
        const { feature } = e;
        feature.setProperties({ id: uniqueId(), isTemp: true });
        feature.setStyle(
            new Style({
                stroke: getBorderStyle(colorConfig.settore.bordo, 1),
                fill: getFillStyle(colorConfig.settore.sfondo ?? [0, 0, 0, 0]),
            })
        );
        setHasDrawn(true);
    });

    const switchInteraction = () => {
        if (map) {
            map.removeInteraction(lastDraw);
            map.addInteraction(draw);
        }
    };

    useEffect(() => {
        switchInteraction();
        setLastDraw(draw);
    }, [draw]);

    useEffect(() => {
        if (map) {
            map.addLayer(vector);
            startSnap();
        }

        return () => {
            removeTempFeature();
            map?.removeLayer(vector);
            map?.removeInteraction(draw);
            stopSnap();
        };
    }, [map]);

    const handleCancelCreateSettore = () => {
        removeTempFeature();
        map?.addInteraction(draw);

        setHasDrawn(false);
    };

    const handleSaveSettore = (data: Partial<IStrutturaLivelli>) => {
        const createdFeatureSettore = getTempFeature();
        if (!createdFeatureSettore) return;

        setIsSaving(true);

        const payload: Partial<IStrutturaLivelli> = {
            ...data,
            idSettore: settoreCimitero.id,
            visualizzaSuWeb: false,
            geom: wkt.writeGeometry(createdFeatureSettore?.getGeometry() as Geometry),
            colore: {
                sfondo: colorConfig.settore.sfondo,
                bordo: colorConfig.settore.bordo,
            },
        };

        removeTempFeature();

        addSettore({ payload })
            .then((res) => {
                onSettoreCreated?.(res);
                setTypeDraw(EIconeDraw.Seleziona);
                setSearchParams(createSearchParams({ ns: res.id.toString() }));
            })
            .catch((apiError) => {
                handleApiError({ error: apiError.response.data });
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    return {
        handleCancelCreateSettore,
        handleSaveSettore,
        hasDrawn,
        drawType,
        setDrawType,
        isSaving,
    };
};

const DrawSettoreComponent: FC<SettoreHookProps> = ({ onSettoreCreated, settoreCimitero }) => {
    const hook = useSettoreDrawHook({ onSettoreCreated, settoreCimitero });

    return (
        <AddSettorePanel
            isSaving={hook.isSaving}
            idLivelloPadre={settoreCimitero.livello.id}
            drawType={hook.drawType}
            setDrawType={hook.setDrawType}
            hasDrawn={hook.hasDrawn}
            handleConfirm={hook.handleSaveSettore}
            handleCancel={hook.handleCancelCreateSettore}
        />
    );
};

export { DrawSettoreComponent };
