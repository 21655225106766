/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Feature } from "ol";
import VectorSource from "ol/source/Vector";
import React, { useEffect, useState } from "react";

import { ColoreGrafica, ELayer } from "@models/Grafica";

import { ColorButton } from "@modules/settings/sections/colors/_components/ColorsSettingsRow";
import PreviewColor from "@modules/settings/sections/colors/_components/PreviewColor";

import { featureElement, featureLayer } from "@openLayersMap/Helpers/EditMappingHelpers";
import useEditMappingHook from "@openLayersMap/Hooks/EditMappingHook";

interface SelectedFeatureColorToolsetProps {
    selectedFeature: Feature;
    source: VectorSource;
}

const SelectedFeatureColorToolset = ({ selectedFeature, source }: SelectedFeatureColorToolsetProps) => {
    const propertiesElement = featureElement(selectedFeature);
    const layer = featureLayer(selectedFeature);
    const [colorConfig, setColorConfig] = useState<ColoreGrafica | undefined>(undefined);
    const { restoreFeature, setNewFill, setNewStroke } = useEditMappingHook({
        source,
    });

    const onChangeColor = ({ bordo, sfondo }: { bordo?: string; sfondo?: string }) => {
        setColorConfig((prev) => {
            return { ...prev, ...(bordo && { bordo }), ...(sfondo && { sfondo }) };
        });
        if (selectedFeature && sfondo) setNewFill(selectedFeature, sfondo);
        if (selectedFeature && bordo) setNewStroke(selectedFeature, bordo);
    };

    useEffect(() => {
        if (selectedFeature)
            setColorConfig({
                bordo: propertiesElement?.colore?.bordo ?? "#000",
                sfondo: propertiesElement?.colore?.sfondo ?? "#000",
            });
    }, [selectedFeature]);

    useEffect(() => {
        return () => {
            setColorConfig(undefined);
            restoreFeature(selectedFeature);
        };
    }, []);

    const [openColorButtons, setOpenColorButtons] = useState<{ [key: string]: boolean }>({});

    const handleColorButtonClick = (key: string) => {
        setOpenColorButtons((prevOpenColorButtons) => ({
            ...prevOpenColorButtons,
            [key]: !prevOpenColorButtons[key],
        }));
    };

    return (
        <div className='space-y-8'>
            <p className='font-bold text-md'>Colori:</p>
            <div className='flex space-x-12'>
                <span>Bordo:</span>
                <ColorButton
                    key='bordo'
                    color={colorConfig?.bordo ?? "#000"}
                    onChange={(colorHex) => onChangeColor({ bordo: colorHex })}
                    disabledChromePicker={layer === ELayer.Testo || layer === ELayer.Tomba}
                    open={openColorButtons.bordo}
                    onClickColor={() => handleColorButtonClick("bordo")}
                />
            </div>
            <div className='flex space-x-12'>
                <span>Sfondo:</span>
                <ColorButton
                    key='sfondo'
                    color={colorConfig?.sfondo ?? "#000"}
                    onChange={(colorHex) => onChangeColor({ sfondo: colorHex })}
                    disabledChromePicker={layer === ELayer.Testo || layer === ELayer.Tomba}
                    open={openColorButtons.sfondo}
                    onClickColor={() => handleColorButtonClick("sfondo")}
                />
            </div>
            <div className='flex space-x-12'>
                <span>Anteprima:</span>
                <PreviewColor border={colorConfig?.bordo ?? "#000"} fill={colorConfig?.sfondo ?? "#000"} />
            </div>
        </div>
    );
};

export default SelectedFeatureColorToolset;
