import { api } from "@services/api/api";

import { BaseResponse } from "@models/Responses/BaseResponse";
import { TipoTomba } from "@models/TipoTomba";

const BASE_PATH = "/tabelle/tipi-tomba";

const listTipiTomba = async () => {
    return api.get<BaseResponse<TipoTomba[]>>(BASE_PATH).then((res) => res.data);
};

const addTipoTomba = async (tipoTomba: Partial<TipoTomba>) => {
    return api.post<BaseResponse<TipoTomba>>(BASE_PATH, tipoTomba).then((res) => res.data?.data);
};

const updateTipoTomba = async (tipoTomba: Partial<TipoTomba>) => {
    return api
        .put<BaseResponse<TipoTomba>>(`${BASE_PATH}/${tipoTomba.id}`, {
            ...tipoTomba,
        })
        .then((res) => res.data?.data);
};

const deleteTipoTomba = async (id: number) => {
    return api.delete(`${BASE_PATH}/${id}`).then((res) => res.data);
};

const tipiTombaCsv = async () => {
    return api.get<any>(`${BASE_PATH}/csv`, { responseType: "blob" }).then((res) => res);
};

export { listTipiTomba, addTipoTomba, updateTipoTomba, deleteTipoTomba, tipiTombaCsv };
