import { Control } from "ol/control";
import React, { useMemo } from "react";

import { useMap } from "@openLayersMap/Map/MapContext";

interface EditControlsHookProps {
    editControlsRef: React.MutableRefObject<null>;
    regularRetinoToolsetRef: React.MutableRefObject<null>;
}

const useEditControlsHook = ({ editControlsRef, regularRetinoToolsetRef }: EditControlsHookProps) => {
    const { map } = useMap();

    const tControl = useMemo(() => {
        if (editControlsRef.current) {
            return new Control({
                element: editControlsRef.current,
            });
        }
        if (regularRetinoToolsetRef.current) {
            return new Control({
                element: regularRetinoToolsetRef.current,
            });
        }
        return null;
    }, [editControlsRef.current, regularRetinoToolsetRef.current]);

    const initEditControls = () => {
        if (!map) return;

        if (tControl) {
            map.addControl(tControl);
        }
    };

    const destroyEditControls = () => {
        if (tControl) map?.removeControl(tControl);
    };

    return {
        initEditControls,
        destroyEditControls,
    };
};

export default useEditControlsHook;
