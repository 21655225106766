import React from "react";
import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const cimiteriSettingsRoutes: RouteObject[] = [
    {
        path: "livelli",
        index: true,
        element: lazyLoadRoutes("livelliIndex"),
    },
    {
        path: "tipi-tomba",
        element: <>{lazyLoadRoutes("tipiTombaIndex")}</>,
    },
    {
        path: "struttura",
        element: (
            <>
                <div>Struttura cimitero</div>
            </>
        ),
    },
];

export default cimiteriSettingsRoutes;
