import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFileContract } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IconButton, Whisper } from "rsuite";

import { useExternalTab } from "@hooks/useExternalTab";

const ContrattoCollegato = ({ idContrattoCollegato }: { idContrattoCollegato: number }) => {
    const { renderSubmenu } = useExternalTab({ deps: idContrattoCollegato, to: `/contratti/${idContrattoCollegato}` });

    return (
        <Whisper placement='autoVerticalEnd' trigger='click' speaker={renderSubmenu}>
            <IconButton
                icon={<FontAwesomeIcon icon={faFileContract as IconProp} />}
                title='Apri contratto'
                size='xs'
                appearance='ghost'
            />
        </Whisper>
    );
};

export default ContrattoCollegato;
