import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button, ButtonGroup } from "rsuite";

import { cimiteriViewTypeState, isCimiteriEditModeState } from "@stores/graveyard";

const CimiteriTypeViewSelector = () => {
    const [cimiteriViewType, setCimiteriViewType] = useRecoilState(cimiteriViewTypeState);
    const isEditMode = useRecoilValue(isCimiteriEditModeState);

    return (
        <ButtonGroup className='bg-athens-200 overflow-hidden rounded-2xl view-data' justified>
            <Button
                onClick={() => setCimiteriViewType("map")}
                active={cimiteriViewType === "map"}
                className='text-primary-700 rounded-2xl'
                disabled={isEditMode}
            >
                Grafica
            </Button>
            <Button
                onClick={() => setCimiteriViewType("data")}
                active={cimiteriViewType === "data"}
                className='text-primary-700 rounded-2xl'
                disabled={isEditMode}
            >
                Dati
            </Button>
        </ButtonGroup>
    );
};

export default CimiteriTypeViewSelector;
