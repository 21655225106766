import { ItemDataType } from "rsuite/cjs/@types/common";

import { SettoreCimitero } from "@models/SettoreCimitero";

const mapSettoriToTree: (settori: SettoreCimitero[], parentIds?: string[]) => ItemDataType[] = (
    settori: SettoreCimitero[],
    parentIds: string[] = []
) => {
    return settori.map((s) => {
        const data = {
            parentIds,
            ...s,
            value: s.id.toString(),
            label: s.descrCompleta,
        };
        if (s.settori && s.settori.length > 0) {
            return {
                ...data,
                children: mapSettoriToTree(s.settori, [...parentIds, data.value]),
            };
        }
        return data;
    });
};

const findTreeChild: (settoreId: string, treeList: ItemDataType[]) => ItemDataType | null = (
    settoreId: string,
    treeList: ItemDataType[]
) => {
    let result: ItemDataType | null = null;

    function iter(a: ItemDataType) {
        if (a.value === settoreId) {
            result = a;
            return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
    }

    treeList.some(iter);
    return result;
};

const paramsToObject = (searchParams: URLSearchParams) => {
    const params: any = {};
    searchParams.forEach((value, key) => {
        params[key] = value;
    });
    return params;
};

export { mapSettoriToTree, findTreeChild, paramsToObject };
