import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const comuniSettingsRoutes: RouteObject[] = [
    {
        path: "comuni",
        index: true,
        element: lazyLoadRoutes("comuniIndex"),
    },
];

export default comuniSettingsRoutes;
