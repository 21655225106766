import { faCheck, faEraser, faRotate, faText, faTextSize } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import React, { useCallback, useState } from "react";
import { Button } from "rsuite";
import * as Yup from "yup";

import { ColorButton } from "@modules/settings/sections/colors/_components/ColorsSettingsRow";

import AngleInput from "@components/Form/Input/AngleInput";
import FormInput from "@components/Form/Input/FormInput";
import NumberInput from "@components/Form/Input/NumberInput";

import FormikValuesHelper from "@helpers/FormikValuesHelper";

import { TestoSettings } from "@openLayersMap/Hooks/DrawTestoHook";

interface EditTestoPanelProps {
    hasDrawn: boolean;
    handleCancel: () => void;
    handleConfirm: (date: TestoSettings) => void;
    isSaving: boolean;
    isModify: boolean;
    textSettings: TestoSettings;
    setTextSettings: (values: TestoSettings) => void;
}

const validationSchema = Yup.object().shape({
    testo: Yup.object().shape({
        val: Yup.string().min(0).max(50).required(),
        size: Yup.number().min(0).max(200).required(),
        rot: Yup.number().min(0).max(360).required(),
    }),
    colore: Yup.object().shape({
        sfondo: Yup.string().required(),
    }),
});

const EditTestoPanel = ({
    handleConfirm,
    handleCancel,
    hasDrawn,
    isSaving,
    isModify,
    textSettings,
    setTextSettings,
}: EditTestoPanelProps) => {
    const handleValuesChanged = useCallback(
        (values: TestoSettings) => {
            setTextSettings(values);
        },
        [setTextSettings]
    );

    const [openColorButtons, setOpenColorButtons] = useState<{ [key: string]: boolean }>({});

    const handleColorButtonClick = (key: string) => {
        setOpenColorButtons((prevOpenColorButtons) => ({
            ...prevOpenColorButtons,
            [key]: !prevOpenColorButtons[key],
        }));
    };

    return (
        <div>
            <Formik
                initialValues={textSettings}
                validationSchema={validationSchema}
                onSubmit={(values, formikHelpers) => {
                    handleConfirm(values);
                    if (!isModify) formikHelpers.resetForm();
                }}
                onReset={handleCancel}
            >
                {({ handleSubmit, handleReset, isValid, values, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <ul className='space-y-8'>
                                {hasDrawn && (
                                    <>
                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <FontAwesomeIcon icon={faText} />
                                            <span>Testo</span>
                                            <FormInput size='xs' name='testo.val' />
                                        </li>

                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <FontAwesomeIcon icon={faTextSize} />
                                            <span>Dimensione</span>
                                            <NumberInput size='xs' min={0} max={200} name='testo.size' />
                                        </li>

                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <FontAwesomeIcon icon={faRotate} />
                                            <span>Rotazione</span>
                                            <div className='w-full'>
                                                <AngleInput name='testo.rot' step={45} />
                                            </div>
                                        </li>

                                        <li className='cursor-pointer space-x-12 flex items-center'>
                                            <FontAwesomeIcon icon={faRotate} />
                                            <span>Colore</span>
                                            <ColorButton
                                                key='testo'
                                                color={values.colore?.sfondo ?? "#000"}
                                                onChange={(colorHex) => setFieldValue("colore.sfondo", colorHex)}
                                                open={openColorButtons?.testo}
                                                onClickColor={() => handleColorButtonClick("testo")}
                                            />
                                        </li>
                                    </>
                                )}
                                {!hasDrawn && (
                                    <li className='cursor-pointer space-x-12 flex items-center'>
                                        <span>Trova un punto dove inserire il testo</span>
                                    </li>
                                )}
                                <hr />
                                <li className='cursor-pointer space-x-12 flex justify-end items-center'>
                                    {!isModify && (
                                        <Button type='button' appearance='ghost' size='sm' onClick={handleReset}>
                                            <FontAwesomeIcon icon={faEraser} className='mr-1' />
                                            Cancella testo
                                        </Button>
                                    )}

                                    {hasDrawn && (
                                        <Button
                                            type='submit'
                                            appearance='primary'
                                            size='sm'
                                            disabled={!isValid || !hasDrawn}
                                            loading={isSaving}
                                        >
                                            <FontAwesomeIcon icon={faCheck} className='mr-1' />
                                            {isModify ? "Salva modifica" : "Inserisci testo"}
                                        </Button>
                                    )}
                                </li>
                            </ul>
                            <FormikValuesHelper onValuesChanged={handleValuesChanged} />
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default EditTestoPanel;
