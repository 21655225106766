import { Suspense, lazy } from "react";
import { Loader } from "rsuite";

const routePathCruxWeb: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    cruxWebContainer: lazy(() => import("@modules/crux-web/CruxWebContainer")),
    cruxWebContratti: lazy(() => import("@modules/crux-web/pages/contratti/ContrattiIndex")),
    cruxWebTotemIndex: lazy(() => import("@modules/crux-web/pages/totem/index/TotemIndex")),
    cruxWebTotemShow: lazy(() => import("@modules/crux-web/pages/totem/show/TotemShow")),
    cruxWebDefunti: lazy(() => import("@modules/crux-web/pages/defunti/DefuntiIndex")),
};

const routePathStruttura: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    dashBoardCimitero: lazy(() => import("@modules/cimiteri/pages/Dashboard")),
    settoreShow: lazy(() => import("@modules/cimiteri/pages/settori")),
    tombaShow: lazy(() => import("@modules/cimiteri/pages/TombaShow")),
};

const routePathContratti: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    contrattiIndex: lazy(() => import("@modules/contracts/pages/index/ContractsIndex")),
    contrattiShow: lazy(() => import("@modules/contracts/pages/show/ContractShow")),
};

const routePathCanoni: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    canoniContainer: lazy(() => import("@modules/canoni/CanoniContainer")),
    canoniIndex: lazy(() => import("@modules/canoni/pages/index/CanoniIndex")),
    canoniShow: lazy(() => import("@modules/canoni/pages/show/CanoneShow")),
    canoniMassiveCopy: lazy(() => import("@modules/canoni/pages/copy-massive/CanoniMassiveCopy")),
    canoniMassiveEdit: lazy(() => import("@modules/canoni/pages/edit-massive/CanoniMassiveEdit")),
};

const routePathFatture: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    fattureContainer: lazy(() => import("@modules/fatture/FattureContainer")),
    fattureIndex: lazy(() => import("@modules/fatture/pages/index/FattureIndex")),
    fattureShow: lazy(() => import("@modules/fatture/pages/show/FatturaShow")),
    fattureMassiveCreate: lazy(() => import("@modules/fatture/pages/create-massive/FattureMassiveCreate")),
    fattureMassiveEdit: lazy(() => import("@modules/fatture/pages/edit-massive/FattureMassiveEdit")),
};

const routePathManager: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    managerIndex: lazy(() => import("@modules/manager/sections/ManagerIndex")),
};

const routePathSearch: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    searchDefunti: lazy(() => import("@modules/ricerche/pages/defunti/RicercaDefunti")),
    searchContratti: lazy(() => import("@modules/ricerche/pages/contratti/RicercaContratti")),
    searchTombe: lazy(() => import("@modules/ricerche/pages/tombe/RicercaTombe")),
};

const routePathSettings: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    colorsIndex: lazy(() => import("@modules/settings/sections/colors/ColorsIndex")),
    profileIndex: lazy(() => import("@modules/settings/sections/profile/ProfileIndex")),
    changePasswordIndex: lazy(() => import("@modules/settings/sections/change-password/ChangePasswordIndex")),
    teamIndex: lazy(() => import("@modules/settings/sections/team/TeamIndex")),
};

const routePathTabelle: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    tabelleContainer: lazy(() => import("@modules/tables/TablesContainer")),
    tabelleDashboard: lazy(() => import("@modules/tables/pages/TablesDashboard")),
    livelliIndex: lazy(() => import("@modules/tables/sections/cimiteri/LivelliIndex")),
    tipiTombaIndex: lazy(() => import("@modules/tables/sections/cimiteri/TipiTombaIndex")),
    personeIndex: lazy(() => import("@modules/tables/sections/anagrafiche/PersoneIndex")),
    professioniIndex: lazy(() => import("@modules/tables/sections/anagrafiche/ProfessioniIndex")),
    gradiParentelaIndex: lazy(() => import("@modules/tables/sections/anagrafiche/GradiParentelaIndex")),
    impreseIndex: lazy(() => import("@modules/tables/sections/anagrafiche/ImpreseIndex")),
    comuniIndex: lazy(() => import("@modules/tables/sections/comuni/ComuniGestitiIndex")),
    concessioniIndex: lazy(() => import("@modules/tables/sections/contratti/ConcessioniIndex")),
    altriImportiIndex: lazy(() => import("@modules/tables/sections/contratti/AltriImportiIndex")),
    tipiCanoniIndex: lazy(() => import("@modules/tables/sections/canoni/TipiCanoneIndex")),
    importiIndex: lazy(() => import("@modules/tables/sections/canoni/ImportiIndex")),
    metodiDiPagamentoIndex: lazy(() => import("@modules/tables/sections/canoni/MetodiPagamentoIndex")),
    luoghiIndex: lazy(() => import("@modules/tables/sections/trasporti/LuoghiIndex")),
    forniIndex: lazy(() => import("@modules/tables/sections/cremazioni/ForniIndex")),
    stampaUnioneIndex: lazy(() => import("@modules/tables/sections/stampa-unione/StampaUnioneIndex")),
};

const routePathChangelog: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    changelogIndex: lazy(() => import("@modules/changelog/ChangelogContainer")),
};

const routePath: { [key: string]: React.LazyExoticComponent<React.FC> } = {
    reset: lazy(() => import("@modules/auth/ResetPassword")),
    ...routePathCanoni,
    ...routePathCruxWeb,
    ...routePathStruttura,
    ...routePathContratti,
    ...routePathFatture,
    ...routePathManager,
    ...routePathSearch,
    ...routePathSettings,
    ...routePathTabelle,
    ...routePathChangelog,
};

export function lazyLoadRoutes(key: string) {
    const LazyElement = routePath[key];

    return (
        <Suspense fallback={<Loader content='Caricamento in corso...' center size='md' />}>
            <LazyElement />
        </Suspense>
    );
}
