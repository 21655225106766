/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Feature } from "ol";
import React from "react";

import { ELayer } from "@models/Grafica";

import { featureElement, featureLayer, featureTesto } from "@openLayersMap/Helpers/EditMappingHelpers";
import { IText } from "@openLayersMap/Models/EditMapping";

interface SelectedFeatureToolsetProps {
    selectedFeature: Feature;
}

const SelectedFeatureToolset = ({ selectedFeature }: SelectedFeatureToolsetProps) => {
    const propertiesElement = featureElement(selectedFeature);
    const propertiesTesto = featureTesto(selectedFeature);
    const layer = featureLayer(selectedFeature);

    const text: IText = {
        val: layer === ELayer.Settore ? propertiesElement.codice : propertiesTesto?.val,
        rotation: propertiesTesto?.rot,
        size: propertiesTesto?.size,
    };

    return (
        <div className='space-y-8'>
            <p className='font-bold text-md'>Riepilogo</p>
            <p>Tipo di livello: {ELayer[layer]}</p>
            <p>Testo: {text?.val || "-"}</p>
            <p>Rotazione: {text?.rotation ?? 0}</p>
            <p>Grandezza: {text?.size ?? 0}</p>
        </div>
    );
};

export default SelectedFeatureToolset;
