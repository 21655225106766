enum DocumentiBaseTipo {
    Contratto = 0,
    Fatture = 1,
    Trasporti = 2,
    Cremazioni = 3,
    RicercaContratti = 4,
    RicercaTombe = 5,
    RicercaDefunti = 6,
    Canoni = 7,
    ListaContratti = 8,
    Tomba = 9,
}
interface DocumentiBase {
    file?: File;
    id: number;
    tipo: DocumentiBaseTipo;
    nomeDoc: string;
    descr: string;
    salvaDoc: string;
    salvaImmagini: boolean;
    tipoImmagine: number;
    tipoOrigineDati: number;
    numRipetizioni: number;
    generaQRCode: boolean;
    salvaDocSingoli: boolean;
    includiRecordTotali: boolean;
    inviaPDF: boolean;
    formatoHTML: boolean;
    isCreate?: boolean;
    estensione?: string;
    key?: number;
}

export { DocumentiBaseTipo };
export type { DocumentiBase };
