import { Navigate } from "react-router-dom";

import canoniRoutes from "@modules/canoni/routes";
import changelogRoutes from "@modules/changelog/routes";
import graveyardsRoutes from "@modules/cimiteri/routes";
import contractsRoutes from "@modules/contracts/routes";
import fattureRoutes from "@modules/fatture/routes";
import managerRoutes from "@modules/manager/routes";
import searchRoutes from "@modules/ricerche/routes";
import settingsRoutes from "@modules/settings/routes";
import tablesRoutes from "@modules/tables/routes";

const protectedRoutes = [
    { index: true, element: <Navigate to='/cimitero' /> },
    { path: "cimitero/*", ...graveyardsRoutes },
    { path: "contratti/*", ...contractsRoutes },
    { path: "canoni/*", ...canoniRoutes },
    { path: "fatture/*", ...fattureRoutes },
    { path: "tabelle/*", ...tablesRoutes },
    { path: "impostazioni/*", ...settingsRoutes },
    { path: "ricerche/*", ...searchRoutes },
    { path: "manager/*", ...managerRoutes },
    { path: "cronologia-versioni/*", ...changelogRoutes },
];

export default protectedRoutes;
