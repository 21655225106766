import React from "react";
import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

import { TablesProvider } from "@modules/tables/context/TablesContext";
import canoniSettingsRoutes from "@modules/tables/sections/canoni/routes";

import anagraficheSettingsRoutes from "./sections/anagrafiche/routes";
import cimiteriSettingsRoutes from "./sections/cimiteri/routes";
import comuniSettingsRoutes from "./sections/comuni/routes";
import contrattiSettingsRoutes from "./sections/contratti/routes";
import cremazioniSettingsRoutes from "./sections/cremazioni/routes";
import stampaUnioneSettingsRoutes from "./sections/stampa-unione/routes";
import trasportiSettingsRoutes from "./sections/trasporti/routes";

const tablesRoutes: RouteObject = {
    element: <TablesProvider>{lazyLoadRoutes("tabelleContainer")}</TablesProvider>,
    children: [
        {
            path: "",
            index: true,
            element: lazyLoadRoutes("tabelleDashboard"),
        },
        {
            path: "cimiteri/*",
            children: cimiteriSettingsRoutes,
        },
        {
            path: "anagrafiche/*",
            children: anagraficheSettingsRoutes,
        },
        {
            path: "comuni/*",
            children: comuniSettingsRoutes,
        },
        {
            path: "contratti/*",
            children: contrattiSettingsRoutes,
        },
        {
            path: "canoni/*",
            children: canoniSettingsRoutes,
        },
        {
            path: "trasporti/*",
            children: trasportiSettingsRoutes,
        },
        {
            path: "cremazioni/*",
            children: cremazioniSettingsRoutes,
        },
        {
            path: "documenti/*",
            children: stampaUnioneSettingsRoutes,
        },
    ],
};

export default tablesRoutes;
