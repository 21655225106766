import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFileExport, faRoute, faTombstone } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Breadcrumb, Checkbox, IconButton, Panel, PanelGroup, Radio } from "rsuite";
import BreadcrumbItem from "rsuite/esm/Breadcrumb/BreadcrumbItem";

import { colorConfigState } from "@stores/globalUserSettings";
import { cimiteriViewTypeState } from "@stores/graveyard";

import { TombaContratto } from "@models/Tomba";

import IconaNoteTomba from "@modules/cimiteri/_components/Sidebar/IconaNoteTomba";

import Card from "@components/Card/Card";
import CanoniTombaRow from "@components/Tombe/CanoniTombaRow";
import ContrattoTombaRow from "@components/Tombe/ContrattoTombaRow";
import PostoTombaRow from "@components/Tombe/PostoTombaRow";
import PostoTombaStatusBadge from "@components/Tombe/PostoTombaStatusBadge";

import WithTooltip from "@hoc/withTooltip";

import { postiResto, postiSalma } from "@helpers/TombaHelper";

import { getFillColorTomba, getStrokeColorTomba } from "@openLayersMap/Helpers/MappingHelpers";

import { ISottoSettoreCardProps, ITombaCardData } from "../Models/tombaCard";

const ButtonWithTooltip = WithTooltip(IconButton);

const TombaCardData = ({
    tomba,
    getPostiSalma,
    getPostiResti,
    handleTomba,
    idCimitero,
    showPath,
    list,
    searchMode,
    buttonAction,
    historicalData,
    defaultExpanded,
    setDefaultExpanded,
}: ITombaCardData): JSX.Element => {
    const setCimiteriViewType = useSetRecoilState(cimiteriViewTypeState);

    const contrattiAttivi: TombaContratto[] | undefined = useMemo(() => {
        if (tomba && tomba?.contratti.length > 0) return tomba?.contratti.filter((con) => !con.storico);
        return undefined;
    }, [tomba]);

    const contrattiStorici = useMemo(() => {
        if (historicalData && tomba && tomba?.contratti.length > 0)
            return tomba?.contratti.filter((con) => con.storico);
        return [];
    }, [tomba, historicalData]);

    const handleNavigateToTombaMap = () => {
        if (tomba?.id) {
            setCimiteriViewType("map");
            window.open(
                `/cimitero/${tomba?.idCimitero}/settore/${tomba?.idStruttura}?preview=true&idTomba=${tomba?.id}`,
                "_blank",
                "noreferrer"
            );
        }
    };

    if (!tomba) return <></>;

    return (
        <section className='flex flex-col gap-8'>
            <section className='flex gap-20'>
                <div className='w-100 flex justify-start items-center space-x-12 bg-athens-300 border border-athens-600 px-8 py-4'>
                    <IconButton
                        icon={<FontAwesomeIcon icon={faTombstone} className='mr-1' />}
                        onClick={() => handleTomba()}
                        appearance='primary'
                        className='text-xs pl-2'
                        size='sm'
                    >
                        {tomba?.tipo?.descr}
                    </IconButton>

                    {tomba && showPath && (
                        <ButtonWithTooltip
                            message='Visualizza dettaglio in una nuova finestra'
                            size='sm'
                            icon={<FontAwesomeIcon icon={faFileExport as IconProp} size='sm' />}
                            appearance='ghost'
                            onClick={() => {
                                handleTomba(true);
                            }}
                        />
                    )}

                    {tomba && showPath && (
                        <ButtonWithTooltip
                            message='Visualizza grafica tomba'
                            size='sm'
                            icon={<FontAwesomeIcon icon={faRoute as IconProp} size='sm' />}
                            appearance='ghost'
                            onClick={() => {
                                handleNavigateToTombaMap();
                            }}
                        />
                    )}

                    {tomba && showPath && (
                        <Breadcrumb separator='>' maxItems={2} className='text-xs mb-0 flex items-center'>
                            {tomba?.path ? (
                                tomba?.path?.map((item, index) => (
                                    <BreadcrumbItem
                                        key={index}
                                        as={Link}
                                        to={`/cimitero/${idCimitero}/settore/${item.id}`}
                                    >
                                        {item.descrCompleta}
                                    </BreadcrumbItem>
                                ))
                            ) : (
                                <BreadcrumbItem as={Link} to='#'>
                                    {tomba.posizione}
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>
                    )}

                    <IconaNoteTomba tomba={tomba} showNote />
                </div>

                <section className='space-y-8 bg-athens-300 border border-athens-600 px-8 py-4'>
                    {getPostiSalma && getPostiSalma.length > 0 && (
                        <div className='w-full gap-8 flex flex-wrap'>
                            {getPostiSalma.map((pt) => (
                                <PostoTombaRow postoTomba={pt} key={pt.id} compactIfFree list={list} />
                            ))}
                        </div>
                    )}

                    {getPostiResti && getPostiResti.length > 0 && (
                        <div className='w-full gap-8 flex flex-wrap'>
                            {getPostiResti.map((pt) => (
                                <PostoTombaRow postoTomba={pt} key={pt.id} compactIfFree list={list} />
                            ))}
                        </div>
                    )}
                </section>
            </section>

            {(tomba?.canoni?.length > 0 ||
                (contrattiAttivi && contrattiAttivi?.length > 0) ||
                contrattiStorici?.length > 0) && (
                <PanelGroup accordion className='flex justify-between gap-8'>
                    {contrattiAttivi && contrattiAttivi.length > 0 && (
                        <Panel
                            className='flex-1 bg-athens-300 border border-athens-600 px-8 py-4 h-fit'
                            header='Contratti'
                            classPrefix='tomba-card'
                            defaultExpanded={
                                defaultExpanded && defaultExpanded[`contratti-${tomba.id}`]
                                    ? defaultExpanded[`contratti-${tomba.id}`]
                                    : false
                            }
                            onClick={() => {
                                if (setDefaultExpanded)
                                    setDefaultExpanded((prev) => {
                                        return {
                                            ...prev,
                                            [`contratti-${tomba.id}`]: !prev[`contratti-${tomba.id}`],
                                        };
                                    });
                            }}
                        >
                            <div className='w-full flex flex-wrap'>
                                {contrattiAttivi.map((ct) => (
                                    <ContrattoTombaRow
                                        contrattoTomba={ct}
                                        key={ct.numero}
                                        searchMode={searchMode}
                                        buttonAction={buttonAction}
                                    />
                                ))}
                            </div>
                        </Panel>
                    )}
                    {contrattiStorici && contrattiStorici.length > 0 && (
                        <Panel
                            className='flex-1 bg-athens-300 border border-athens-600 px-8 py-4 h-fit'
                            header='Contratti storici'
                            classPrefix='tomba-card'
                            defaultExpanded={
                                defaultExpanded && defaultExpanded[`storici-${tomba.id}`]
                                    ? defaultExpanded[`storici-${tomba.id}`]
                                    : false
                            }
                            onClick={() => {
                                if (setDefaultExpanded)
                                    setDefaultExpanded((prev) => {
                                        return {
                                            ...prev,
                                            [`storici-${tomba.id}`]: !prev[`storici-${tomba.id}`],
                                        };
                                    });
                            }}
                        >
                            <div className='w-full flex flex-wrap'>
                                {contrattiStorici.map((ct) => (
                                    <ContrattoTombaRow
                                        contrattoTomba={ct}
                                        key={ct.numero}
                                        searchMode={searchMode}
                                        buttonAction={buttonAction}
                                    />
                                ))}
                            </div>
                        </Panel>
                    )}
                    {tomba.canoni && tomba.canoni.length > 0 && (
                        <Panel
                            className='flex-1 bg-athens-300 border border-athens-600 px-8 py-4 h-fit'
                            header='Canoni'
                            classPrefix='tomba-card'
                            defaultExpanded={
                                defaultExpanded && defaultExpanded[`canoni-${tomba.id}`]
                                    ? defaultExpanded[`canoni-${tomba.id}`]
                                    : false
                            }
                            onClick={() => {
                                if (setDefaultExpanded)
                                    setDefaultExpanded((prev) => {
                                        return { ...prev, [`canoni-${tomba.id}`]: !prev[`canoni-${tomba.id}`] };
                                    });
                            }}
                        >
                            <div className='w-full flex flex-wrap'>
                                {tomba.canoni.map((ct) => (
                                    <CanoniTombaRow
                                        canone={ct}
                                        key={ct.numero}
                                        searchMode={searchMode}
                                        buttonAction={buttonAction}
                                    />
                                ))}
                            </div>
                        </Panel>
                    )}
                </PanelGroup>
            )}
        </section>
    );
};

const TombaCard: FC<ISottoSettoreCardProps> = ({
    tomba,
    showPath = false,
    searchMode = false,
    buttonAction = true,
    setSelectTomba,
    list = false,
    historicalData,
    multipleOptions,
    defaultExpanded,
    setDefaultExpanded,
}) => {
    const colorConfig = useRecoilValue(colorConfigState);
    const { idCimitero } = useParams();
    const sfondoTomba = getFillColorTomba(tomba, colorConfig, true);
    const bordoTomba = getStrokeColorTomba(tomba, colorConfig);
    const getPostiSalma = useMemo(() => postiSalma(tomba), [tomba]);
    const getPostiResti = useMemo(() => postiResto(tomba), [tomba]);

    const handleTomba = (preview?: boolean) => {
        if (setSelectTomba) setSelectTomba(tomba, preview);
    };

    return (
        <div className='flex items-center space-x-8 w-full'>
            {searchMode && !multipleOptions && <Radio value={tomba.id} classPrefix='tomba-card' />}
            {searchMode && multipleOptions && <Checkbox value={tomba.id} classPrefix='tomba-card' />}
            <Card
                left={
                    <PostoTombaStatusBadge
                        numero={tomba.numero}
                        sfondoTomba={sfondoTomba}
                        bordoTomba={bordoTomba}
                        handleTomba={handleTomba}
                        isStart
                        textBg
                    />
                }
                right={
                    <TombaCardData
                        tomba={tomba}
                        getPostiSalma={getPostiSalma}
                        getPostiResti={getPostiResti}
                        handleTomba={handleTomba}
                        idCimitero={idCimitero}
                        showPath={showPath}
                        list={list}
                        searchMode={searchMode}
                        buttonAction={buttonAction}
                        historicalData={historicalData}
                        defaultExpanded={defaultExpanded}
                        setDefaultExpanded={setDefaultExpanded}
                    />
                }
            />
        </div>
    );
};

export default TombaCard;
