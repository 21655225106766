import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGears as faGearsLight } from "@fortawesome/pro-light-svg-icons";
import {
    faEdit,
    faFile,
    faFilePdf,
    faGear,
    faLocationCrosshairs,
    faMinus,
    faPlus,
    faPrint,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebounceFn, useRequest } from "ahooks";
import classNames from "classnames";
import { Control } from "ol/control";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    Badge,
    Button,
    ButtonGroup,
    ButtonToolbar,
    IconButton,
    InputGroup,
    InputNumber,
    Popover,
    Toggle,
    Whisper,
} from "rsuite";

import { dimensioneTestoGrafica, getSettoreCimitero, getTombeSettore, updateGrafica } from "@services/CimiteriService";
import { listFile } from "@services/FileService";

import { permissionsGraficaState } from "@stores/diritti";
import { cimiteroMapVisibilityConfigState } from "@stores/graveyard";

import { ELayerToShow } from "@models/Configs";
import { StatoDiritti } from "@models/Diritti";
import { ELayer } from "@models/Grafica";

import { useCruxNavigate } from "@hooks/useCruxNavigate";
import { useCruxRequest } from "@hooks/useCruxRequest";
import { useCruxToaster } from "@hooks/useCruxToaster";

import { useMap } from "@openLayersMap/Map/MapContext";
import { FontSize, IDrawPanels } from "@openLayersMap/Models/EditMapping";
import { editModeState, featuresEditState, levelsModeState } from "@openLayersMap/Stores/editControls";

import EditControls from "./EditControls";

const DefaultPopover = React.forwardRef(
    (
        {
            setFontSize,
            fontSize,
            ...props
        }: { setFontSize: React.Dispatch<React.SetStateAction<FontSize>>; fontSize: FontSize },
        ref: any
    ) => {
        return (
            <Popover ref={ref} {...props}>
                <p className='max-w-md'>
                    Quando ci sono più poligoni con dimensioni del testo diverse, la grandezza del testo di partenza
                    verrà calcolata partendo dal poligono più grande/più piccolo.
                </p>
                <p className='font-bold mb-8'>N.B: la scelta verrà impostata dalla seguente configurazione</p>
                <Toggle
                    size='md'
                    checkedChildren='Testo grande'
                    unCheckedChildren='Testo piccolo'
                    checked={fontSize.max}
                    onChange={(c) => {
                        setFontSize((prev) => ({ ...prev, max: c }));
                    }}
                />
                <p className='mt-4'>
                    Dimensione base di partenza: <b>{fontSize.size}px</b>
                </p>
            </Popover>
        );
    }
);

const DrawPanels = ({
    source,
    idSettore,
    onRetinoCreated,
    onReloadMap,
    setModal,
    onForceFit,
    settore,
    onSettoreAdded,
    onTombeAdded,
    selectedFeature,
    transformFeature,
    setSelectedFeature,
    updateFiles,
    setUpdateFiles,
    handleFile,
    handlePrint,
    handleSave,
    graficaMap,
    fontSize,
    setFontSize,
}: IDrawPanels): JSX.Element => {
    const { map } = useMap();
    const { useQuery } = useCruxNavigate();
    const [fieldPreview, setFieldPreview] = useState(fontSize.size);
    const uiCruxWeb = useQuery.get("cruxweb");

    const {
        data: grafiche,
        loading: loadingGrafiche,
        run: runGrafiche,
        error,
    } = useCruxRequest(updateGrafica, { manual: true });
    const { data: files, run: runFiles } = useCruxRequest(listFile, {
        manual: true,
    });
    const {
        data: settoreText,
        loading: loadingSettore,
        run: runSettore,
        error: errorText,
    } = useRequest(getSettoreCimitero, { manual: true });

    const { data: tombe, loading: loadingTombe, run: runTombe } = useCruxRequest(getTombeSettore, { manual: true });
    const { handleApiSuccess, handleApiError } = useCruxToaster();
    const { idCimitero } = useParams();

    const [featuresEdit, setFeaturesEdit] = useRecoilState(featuresEditState);
    const [editMode, setEditMode] = useRecoilState(editModeState);
    const [levelsMode, setLevelsMode] = useRecoilState(levelsModeState);
    const [loading, setLoading] = useState(false);

    const permissionsGrafica = useRecoilValue(permissionsGraficaState);
    const [visibilityConfig, setVisibilityConfig] = useRecoilState(cimiteroMapVisibilityConfigState);

    const payload = featuresEdit ? Object.values(featuresEdit) : [];

    const handleSaveEditMode = (evt: SyntheticEvent<Element, Event>): void => {
        if (evt && payload && idSettore) {
            runGrafiche(+idSettore, payload);
        }
    };

    const handleEditMode = (): void => {
        if (payload.length === 0) {
            setEditMode((prev) => !prev);
            setVisibilityConfig({
                ...visibilityConfig,
                layers: !editMode
                    ? [
                          ELayerToShow.Grid,
                          ELayerToShow.Retino,
                          ELayerToShow.Settore,
                          ELayerToShow.Tomba,
                          ELayerToShow.Testo,
                      ]
                    : [ELayerToShow.Retino, ELayerToShow.Settore, ELayerToShow.Tomba, ELayerToShow.Testo],
            });
        }
        if (payload.length > 0 && editMode) {
            setModal({
                visible: true,
                message: "Confermando il cambio di pagina, perderai tutte le modifiche non salvate.",
            });
        }
    };

    const handleLevelsMode = (): void => {
        setLevelsMode((prev) => !prev);
    };

    const handleUpdateText = (): void => {
        if (idCimitero && idSettore) {
            runSettore(idSettore, true);
        }

        if (idCimitero && !idSettore) {
            runSettore(idCimitero, true);
        }

        if (idSettore) {
            runTombe(+idSettore, true);
            runGrafiche(+idSettore, payload);
        }

        onReloadMap();
    };

    const handlePlus = (): void => {
        setLoading(true);
        dimensioneTestoGrafica({
            idSettore,
            dimensione: fontSize.size ? fontSize.size + 1 : 12,
            layers: [ELayer.Testo, ELayer.Settore, ELayer.Tomba],
        })
            .then(() => {
                setFontSize((prev) => {
                    return {
                        ...prev,
                        size: prev.size ? prev.size + 1 : undefined,
                    };
                });
                handleUpdateText();
            })
            .catch(() => handleApiError({ message: "Errore nel salvataggio della dimensione testo" }))
            .finally(() => setTimeout(() => setLoading(false), 500));
    };

    const handleMinus = (): void => {
        setLoading(true);
        dimensioneTestoGrafica({
            idSettore,
            dimensione: fontSize.size ? fontSize.size - 1 : 12,
            layers: [ELayer.Testo, ELayer.Settore, ELayer.Tomba],
        })
            .then(() => {
                setFontSize((prev) => {
                    return {
                        ...prev,
                        size: prev.size ? prev.size - 1 : undefined,
                    };
                });
                handleUpdateText();
            })
            .catch(() => handleApiError({ message: "Errore nel salvataggio della dimensione testo" }))
            .finally(() => setTimeout(() => setLoading(false), 500));
    };

    const { run: handleFontSize } = useDebounceFn(
        (value: number) => {
            setLoading(true);
            dimensioneTestoGrafica({
                idSettore,
                dimensione: value,
                layers: [ELayer.Testo, ELayer.Settore, ELayer.Tomba],
            })
                .then(() => {
                    setFontSize((prev) => {
                        return {
                            ...prev,
                            size: value,
                        };
                    });
                    handleUpdateText();
                })
                .catch(() => handleApiError({ message: "Errore nel salvataggio della dimensione testo" }))
                .finally(() => setTimeout(() => setLoading(false), 500));
        },
        { wait: 1000 }
    );

    const buttonEditRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!map || !buttonEditRef.current) return undefined;

        const visibilityControl = new Control({
            element: buttonEditRef.current,
        });

        map.addControl(visibilityControl);

        return () => {
            if (map && visibilityControl && buttonEditRef) map.removeControl(visibilityControl);
        };
    }, [map]);

    useEffect(() => {
        runFiles("L", settore.id);
    }, []);

    useEffect(() => {
        if (updateFiles) {
            runFiles("L", settore.id);
            setUpdateFiles(false);
        }
    }, [updateFiles]);

    useEffect(() => {
        if (grafiche && grafiche.length > 0) {
            setFeaturesEdit({});
            handleApiSuccess({ message: "Salvataggio completato" });
            onRetinoCreated(grafiche);
        }
    }, [grafiche]);

    useEffect(() => {
        if (error) handleApiError({ message: "Salvataggio non riuscito, per favore riprova!" });
    }, [error]);

    return (
        <section
            className={classNames(
                "absolute right-0 top-0 bg-athens-800 h-full w-fit transition-all px-8 py-16 shadow-[-7px_0px_15px_0px_#00000010]",
                { "bg-opacity-30": !editMode }
            )}
            ref={buttonEditRef}
        >
            <div className='w-full h-full block relative'>
                <ButtonToolbar className='flex flex-col'>
                    <ButtonGroup vertical className='space-y-8'>
                        {(!editMode || graficaMap) && (
                            <IconButton
                                onClick={onForceFit}
                                className='bg-white rounded'
                                icon={<FontAwesomeIcon icon={faLocationCrosshairs} title='centra mappa' />}
                            />
                        )}
                        {permissionsGrafica === StatoDiritti.SCRITTURA && !editMode && !graficaMap && !uiCruxWeb && (
                            <IconButton
                                onClick={handleEditMode}
                                className='bg-white rounded'
                                icon={<FontAwesomeIcon icon={faEdit} title='modifica' />}
                            />
                        )}
                        {(!editMode || graficaMap) && (
                            <IconButton
                                circle
                                onClick={handleLevelsMode}
                                className='bg-white rounded'
                                icon={
                                    <FontAwesomeIcon
                                        icon={(!levelsMode ? faGear : faGearsLight) as IconProp}
                                        title={!levelsMode ? "opzioni mappa" : "nascondi opzioni mappa"}
                                    />
                                }
                            />
                        )}
                        {(!editMode || graficaMap) && !uiCruxWeb && (
                            <Badge content={files?.length ?? 0} color='blue'>
                                <IconButton
                                    circle
                                    onClick={handleFile}
                                    className='bg-white rounded'
                                    icon={<FontAwesomeIcon icon={faFile} title='file' />}
                                />
                            </Badge>
                        )}
                        {(!editMode || graficaMap) && (
                            <IconButton
                                circle
                                onClick={handlePrint}
                                className='bg-white rounded'
                                icon={<FontAwesomeIcon icon={faPrint} title='stampa file' />}
                            />
                        )}
                        {(!editMode || graficaMap) && !uiCruxWeb && (
                            <IconButton
                                circle
                                onClick={handleSave}
                                className='bg-white rounded'
                                icon={<FontAwesomeIcon icon={faFilePdf} title='salva file' />}
                            />
                        )}
                    </ButtonGroup>

                    {!editMode && !graficaMap && !loading && fontSize?.size && !fontSize.equal && (
                        <Whisper
                            trigger='click'
                            placement='leftStart'
                            controlId='control-id'
                            speaker={<DefaultPopover fontSize={fontSize} setFontSize={setFontSize} />}
                            disabled={fontSize.equal}
                        >
                            <Badge
                                content='!'
                                color='orange'
                                className='mt-12 -left-4 cursor-pointer rounded-full max-w-[1.5rem] w-full flex justify-center'
                            />
                        </Whisper>
                    )}

                    {!editMode && !graficaMap && !loading && fontSize?.size && (
                        <InputGroup className='flex flex-col justify-center items-center number-group w-[2.25rem] h-[7rem] gap-4 overflow-hidden border-none focus:border-none'>
                            <IconButton
                                circle
                                onClick={handlePlus}
                                className='bg-white rounded w-[2rem] h-[2rem]'
                                icon={<FontAwesomeIcon icon={faPlus as IconProp} title='aumenta dimensione testo' />}
                                loading={loading}
                            />
                            <InputNumber
                                className='custom-input-number text-center rounded overflow-hidden'
                                value={fieldPreview ?? fontSize.size}
                                onChange={(value: any) => {
                                    setFieldPreview(value);
                                    if (value && +value > 0 && fontSize.size !== +value) {
                                        setFieldPreview(value);
                                        handleFontSize(value);
                                    }
                                }}
                                min={0}
                                max={200}
                                maxLength={3}
                                size='sm'
                            />
                            <IconButton
                                circle
                                onClick={handleMinus}
                                className='bg-white rounded w-[2rem] h-[2rem]'
                                icon={
                                    <FontAwesomeIcon icon={faMinus as IconProp} title='diminuisci dimensione testo' />
                                }
                                loading={loading}
                            />
                        </InputGroup>
                    )}
                </ButtonToolbar>

                {editMode && (
                    <EditControls
                        onTombeAdded={onTombeAdded}
                        onSettoreAdded={onSettoreAdded}
                        settore={settore}
                        selectedFeature={selectedFeature}
                        transformFeature={transformFeature}
                        setSelectedFeature={setSelectedFeature}
                        source={source}
                    />
                )}

                {editMode && (
                    <div className='fidex flex justify-between w-full'>
                        <Button type='button' appearance='link' size='sm' onClick={handleEditMode}>
                            Chiudi modalità modifica
                        </Button>
                        <Button
                            type='button'
                            appearance='primary'
                            size='sm'
                            onClick={handleSaveEditMode}
                            loading={loadingGrafiche}
                        >
                            Invia modifica definitiva
                        </Button>
                    </div>
                )}
            </div>
        </section>
    );
};

export default DrawPanels;
