import { Navigate } from "react-router-dom";
import { Loader } from "rsuite";

const commonRoutes = [
    {
        path: "loading",
        element: <Loader content='Caricamento in corso...' center size='md' />,
    },
    {
        path: "*",
        element: <Navigate to='/' />,
    },
];

export default commonRoutes;
