/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CruxSidenav from "@layouts/protectedLayout/CruxSidenav";
import CruxNavbar from "@layouts/protectedLayout/_components/CruxNavbar";
import { useResponsive } from "ahooks";
import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Outlet } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Container, Content, Header, Sidebar } from "rsuite";

import { getChangelogList } from "@services/tools/ToolsService";

import { usePageData } from "@context/PageDataContext";

import { versionBEState } from "@stores/globalUserSettings";

import { useCruxNavigate } from "@hooks/useCruxNavigate";

import RilascioNuovaVersione from "@components/Modal/RilascioNuovaVersione";

import { editModeState } from "@openLayersMap/Stores/editControls";

const ProtectedLayout = () => {
    const { useQuery } = useCruxNavigate();
    const uiPreview = useQuery.get("preview");

    const [expanded, setExpanded] = useState(!uiPreview);
    const [openModalChangelog, setOpenModalChangelog] = useState(false);
    const { sidebar: PageSidebar } = usePageData();
    const responsive = useResponsive();
    const isEditMode = useRecoilValue(editModeState);
    const storedBEVersion = useRecoilValue(versionBEState);
    const setBEVersion = useSetRecoilState(versionBEState);

    const getChangelog = useCallback(async () => {
        getChangelogList().then((resp) => {
            if (!storedBEVersion || (storedBEVersion && storedBEVersion !== resp[0]?.versione)) {
                setOpenModalChangelog(true);
                setBEVersion(resp[0]?.versione);
            }
        });
    }, [storedBEVersion]);

    useEffect(() => {
        getChangelog();
    }, [getChangelog]);

    return (
        <Container className='max-h-screen overflow-hidden h-screen'>
            {openModalChangelog &&
                createPortal(
                    <RilascioNuovaVersione isOpen handleClose={() => setOpenModalChangelog((prev) => !prev)} />,
                    document.body
                )}
            <Header className='bg-primary-400 border-b-2 border-b-secondary-400'>
                <CruxNavbar showFull={responsive["xLarge"]} />
            </Header>
            <Container className='bg-athens-400'>
                <section className={`${!isEditMode ? "flex" : "hidden"} transition-all`}>
                    <aside className='bg-primary-400 border-r-2  border-r-secondary-400'>
                        {!responsive["xLarge"] && <CruxSidenav />}
                    </aside>
                    {PageSidebar && (
                        <Sidebar
                            className={`bg-primary-400 transition-all h-full block flex-none overflow-hidden ${
                                expanded ? "w-[24rem]" : "w-[3rem]"
                            }`}
                        >
                            <div
                                className={`py-8 px-12 bg-[#2D3D60] flex items-center space-x-8 w-full text-primary-100 cursor-pointer ${
                                    expanded ? "justify-start" : "justify-center"
                                }`}
                                onClick={() => {
                                    if (!uiPreview) setExpanded((prev) => !prev);
                                }}
                            >
                                <FontAwesomeIcon icon={(expanded ? faAngleLeft : faAngleRight) as IconProp} size='lg' />
                                {expanded && <div className='transition-all'>Nascondi sezione</div>}
                            </div>
                            {expanded && PageSidebar}
                        </Sidebar>
                    )}
                </section>

                <Content className='h-layout relative block'>
                    <Outlet />
                </Content>
            </Container>
        </Container>
    );
};
export default ProtectedLayout;
