import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const canoniSettingsRoutes: RouteObject[] = [
    {
        path: "tipi",
        index: true,
        element: lazyLoadRoutes("tipiCanoniIndex"),
    },
    {
        path: "importi",
        index: true,
        element: lazyLoadRoutes("importiIndex"),
    },
    {
        path: "metodi-pagamento",
        index: true,
        element: lazyLoadRoutes("metodiDiPagamentoIndex"),
    },
];

export default canoniSettingsRoutes;
