import { Feature } from "ol";
import { useMemo } from "react";

import { ColoriConfig, ELayerToShow, VisibilityConfig } from "@models/Configs";
import { ELayer, IGrafica } from "@models/Grafica";
import { SettoreCimitero } from "@models/SettoreCimitero";
import { PathItem, Tomba } from "@models/Tomba";

import { vector } from "@openLayersMap/Source";

import * as mappingHelper from "../Helpers/MappingHelpers";

interface UseFeatureMappingProps {
    settori: SettoreCimitero[];
    tombe: Tomba[];
    grafiche: IGrafica[];
    colorConfig: ColoriConfig | undefined;
    visibilityConfig: VisibilityConfig;
    isEditMode?: boolean;
    livelloRicerca?: PathItem;
    idTomba?: number;
    idStruttura: number;
}

const useFeatureMapping = ({
    settori,
    tombe,
    grafiche,
    colorConfig,
    visibilityConfig,
    isEditMode,
    livelloRicerca,
    idTomba,
    idStruttura,
}: UseFeatureMappingProps) => {
    const config = useMemo(() => colorConfig ?? mappingHelper.getDefaultColorConfig(), [colorConfig]);

    const featuresTombe: Feature[] = useMemo<Feature[]>(
        () =>
            tombe.map((tomba: Tomba) =>
                mappingHelper.fromTomba({ tomba, colorConfig: config, visibilityConfig, idTomba })
            ) ?? [],
        [
            tombe,
            colorConfig,
            visibilityConfig.datiTomba,
            visibilityConfig.iconeTomba,
            visibilityConfig.showSmallText,
            visibilityConfig.overflow,
        ]
    );
    const featuresSettori = useMemo<Feature[]>(
        () =>
            settori.reduce(
                (old: Feature[], settore) => [
                    ...old,
                    ...mappingHelper.fromSettore(settore, idStruttura, livelloRicerca, visibilityConfig),
                ],
                []
            ),
        [settori, colorConfig]
    );
    const featuresLinee = useMemo<Feature[]>(
        () => grafiche.filter((g) => g.layer === ELayer.Retino).map(mappingHelper.fromRetino) ?? [],
        [grafiche, colorConfig]
    );
    const featuresTesti = useMemo<Feature[]>(
        () => grafiche.filter((g) => g.layer === ELayer.Testo).map(mappingHelper.fromTesto) ?? [],
        [grafiche, colorConfig]
    );

    const featuresEdit = useMemo(() => {
        return visibilityConfig.layers
            .map((layer) => {
                if (layer === ELayerToShow.Settore) return featuresSettori;
                if (layer === ELayerToShow.Tomba) return featuresTombe;
                if (layer === ELayerToShow.Retino) return featuresLinee;
                if (layer === ELayerToShow.Testo) return featuresTesti;

                return [];
            })
            .flat();
    }, [visibilityConfig, isEditMode, featuresSettori, featuresLinee, featuresTesti, featuresTombe]);

    const navigationSource = useMemo(
        () => vector([...featuresTombe, ...featuresSettori]),
        [featuresTombe, featuresSettori]
    );

    const fullSource = useMemo(
        () => vector([...featuresLinee, ...featuresTombe, ...featuresSettori, ...featuresTesti]),
        [featuresSettori, featuresLinee, featuresTesti, tombe, settori, grafiche]
    );

    const extent = useMemo(() => fullSource.getExtent(), [fullSource]);

    return {
        featuresTombe,
        featuresSettori,
        featuresLinee,
        featuresTesti,
        featuresEdit,
        navigationSource,
        fullSource,
        extent,
    };
};

export default useFeatureMapping;
