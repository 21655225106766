import { getYear } from "date-fns";
import { atom, selector } from "recoil";

import { ContrattiFilterInterface } from "@modules/contracts/context/ContractsContext";

import { persistRecoil } from "./effects/persistRecoil";

export enum ETipoRicercaContratto {
    tutti = "tutti",
    numeroContratto = "numero",
    dataContratto = "data",
    richiedente = "richiedente",
    posizioneTomba = "posizione",
    numeroTomba = "tomba",
    defunto = "defunto",
}

export enum EStatoPagamentoContratto {
    tutti = "tutti",
    pagato = "pagato",
    nonPagato = "non pagato",
}

export const initialSearchValues: ContrattiFilterInterface = {
    anno: getYear(new Date()),
    storico: true,
    pagato: EStatoPagamentoContratto.tutti,
    extra: { tipo: ETipoRicercaContratto.tutti, valore: null },
};

const { persistAtom } = persistRecoil();

const filtriContrattiState = atom<ContrattiFilterInterface>({
    key: "filtriContrattiState",
    default: initialSearchValues,
    effects: [persistAtom],
});

const filtriContratti = selector({
    key: "filtriContratti",
    get: ({ get }) => {
        return get(filtriContrattiState);
    },
});

export { filtriContrattiState, filtriContratti };
