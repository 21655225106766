import React, { FC, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Button, Dropdown, Popover, Whisper } from "rsuite";

import { getLastGrave } from "@services/CimiteriService";

import { permissionsTombeState } from "@stores/diritti";
import { cimiteroEventState, lastGraveState } from "@stores/graveyard";

import { StatoDiritti } from "@models/Diritti";
import { CimiteroEvent } from "@models/Events/Cimiteri";

import { useCruxToaster } from "@hooks/useCruxToaster";

const MenuPopover = React.forwardRef(({ style, onClose, onMenuSelected, ...rest }: any, ref: any) => {
    const { idSettore, ...payload } = rest;
    const { handleApiError } = useCruxToaster();
    const setLastGraveState = useSetRecoilState(lastGraveState);
    const permissionsTombe = useRecoilValue(permissionsTombeState);

    const handleSelect = (eventKey: string | undefined) => {
        if (eventKey) {
            onMenuSelected(eventKey);
            onClose();
        }
    };

    useEffect(() => {
        if (idSettore)
            getLastGrave({ idSettore })
                .then((resp) => {
                    setLastGraveState(resp);
                })
                .catch((apiError) => {
                    handleApiError({ error: apiError.response.data });
                });
        // eslint-disable-next-line
    }, [idSettore]);

    return (
        <Popover {...payload} style={style} ref={ref} full>
            <Dropdown.Menu onSelect={handleSelect}>
                <Dropdown.Item eventKey={CimiteroEvent.ADD_SUB_SETTORE}>Aggiungi settore</Dropdown.Item>
                <Dropdown.Item eventKey={CimiteroEvent.EDIT_SETTORE}>Modifica</Dropdown.Item>
                <Dropdown.Item eventKey={CimiteroEvent.DELETE_SETTORE}>Elimina</Dropdown.Item>

                {permissionsTombe === StatoDiritti.SCRITTURA && (
                    <>
                        <Dropdown.Item divider />
                        <Dropdown.Item eventKey={CimiteroEvent.ADD_TOMBA}>Aggiungi Tombe</Dropdown.Item>
                    </>
                )}
            </Dropdown.Menu>
        </Popover>
    );
});

const CimiteroEditDropdown: FC<{ idSettore: number; idPadri: number[] }> = ({ idSettore, idPadri }) => {
    const setCimiteroEvent = useSetRecoilState(cimiteroEventState);

    const handleOnEvent = (evento: CimiteroEvent) => {
        setCimiteroEvent({ idSettore, idPadri, evento });
    };

    const renderWisper = (props: any, ref: any) => {
        const { className, left, top, onClose } = props;
        return (
            <MenuPopover
                style={{ left, top }}
                onClose={onClose}
                className={className}
                ref={ref}
                onMenuSelected={handleOnEvent}
                idSettore={idSettore}
            />
        );
    };

    return (
        <Whisper placement='autoVerticalStart' trigger='click' speaker={renderWisper}>
            <Button size='xs' appearance='subtle'>
                ...
            </Button>
        </Whisper>
    );
};

export default CimiteroEditDropdown;
