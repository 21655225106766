import { useField } from "formik";
import * as React from "react";
import { FC } from "react";
import { Toggle as RsToggle, ToggleProps as RsToggleProps } from "rsuite";

interface FormInputPros extends Omit<RsToggleProps, "form"> {
    name: string;
    label?: string;
    labelPosition?: "start" | "end";
}

const ToggleInput: FC<FormInputPros> = ({ label, labelPosition = "end", onChange, ...props }) => {
    const [field, meta, helpers] = useField(props.name);

    return (
        <span className='mr-4 mt-2'>
            {label && labelPosition === "start" && <span className='mr-1'>{label}</span>}
            <RsToggle
                {...field}
                checked={field.value ?? false}
                onChange={(newValue, event) => {
                    helpers.setValue(newValue);
                    onChange?.(newValue, event);
                }}
                {...props}
            />
            {label && labelPosition === "end" && <span className='ml-1'>{label}</span>}
        </span>
    );
};

export default ToggleInput;
