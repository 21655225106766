import { api } from "@services/api/api";

import { BaseResponse } from "@models/Responses/BaseResponse";
import { SettingMenuItem } from "@models/Settings/SettingMenuItem";

const BASE_PATH = "/tabelle";

const getMenuItems = async () => {
    return api.get<BaseResponse<SettingMenuItem[]>>(BASE_PATH).then((res) => res.data);
};

export { getMenuItems };
