import FolderFillIcon from "@rsuite/icons/FolderFill";
import { uniqueId } from "lodash";
import React, { FC } from "react";
import { Panel } from "rsuite";

import { SettoreCimitero } from "@models/SettoreCimitero";

interface SottoSettoreCardProps {
    settore: SettoreCimitero[];
    setSettore: (settore: SettoreCimitero) => void;
}

const SottoSettoreCardHeader: FC<{ title: string }> = ({ title }) => {
    return (
        <div className='flex content-center'>
            <FolderFillIcon className='text-md text-primary/20' />
            <p className='text-primary text-xs hover:underline cursor-pointer ml-2'>{title}</p>
        </div>
    );
};

const SottoSettoreCardInfo: FC<{ title: string; value: string | number; colorClass: string }> = ({
    title,
    value,
    colorClass,
}) => {
    return (
        <div className='flex justify-between items-center'>
            <div>{title}</div>
            <div className={`font-bold mr-3 ${colorClass}`}>{value}</div>
        </div>
    );
};

const SottoSettoreCard: FC<SottoSettoreCardProps> = ({ settore, setSettore }) => {
    return (
        <div className='grid 2xl:grid-cols-4 gap-2'>
            {settore.map((sottoSettore) => (
                <Panel
                    bordered
                    header={<SottoSettoreCardHeader title={sottoSettore.descrCompleta} />}
                    className='bg-athens-100 mb-4 h-full'
                    onClick={(event: any) => {
                        if (setSettore) setSettore(sottoSettore);
                        event.preventDefault();
                    }}
                    key={sottoSettore.codice}
                >
                    {sottoSettore?.stat && sottoSettore?.stat?.length > 0 && (
                        <div className='flex flex-col space-y-8 border p-1'>
                            {sottoSettore.stat?.map((value) => (
                                <SottoSettoreCardInfo
                                    title={value.descr}
                                    value={value.valore}
                                    colorClass={`text-${value.colore}`}
                                    key={uniqueId()}
                                />
                            ))}
                        </div>
                    )}
                </Panel>
            ))}
        </div>
    );
};

export default SottoSettoreCard;
