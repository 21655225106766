import { useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { adminState, dirittiState, refreshDirittiState, sysAdminState } from "@stores/diritti";

import { IJwtToken } from "@models/AuthUser";
import { IDiritti } from "@models/Diritti";

import { parseJwt } from "@helpers/Jwt";

export const useDiritti = () => {
    const [jwtToken, setJwtToken] = useState("");
    const { decodedToken } = useJwt(jwtToken);

    const refreshDiritti = useRecoilValue(refreshDirittiState);
    const setDiritti = useSetRecoilState(dirittiState);
    const setAdmin = useSetRecoilState(adminState);
    const setSysAdmin = useSetRecoilState(sysAdminState);

    useEffect(() => {
        if (refreshDiritti) setJwtToken(refreshDiritti);
    }, [refreshDiritti]);

    useEffect(() => {
        if (decodedToken) {
            const token = decodedToken as IJwtToken;

            if (token?.Diritti) {
                const diritti: IDiritti | {} = parseJwt(token);
                if (diritti) setDiritti(diritti);
            }

            if (token?.Admin && token.Admin === "true") {
                setAdmin(true);
            }

            if (!token?.Admin || (token?.Admin && token.Admin === "false")) {
                setAdmin(false);
            }

            if (token?.SysAdmin && token.SysAdmin === "true") {
                setSysAdmin(true);
            }

            if (!token?.SysAdmin || (token?.SysAdmin && token.SysAdmin === "false")) {
                setSysAdmin(false);
            }
        }
    }, [decodedToken]);
};
