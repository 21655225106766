import { toInteger } from "lodash";

const ACCESS_TOKEN_KEY = "access_token";
const USERNAME_KEY = "username";
const REFRESH_TOKEN_KEY = "refresh_token";
const COMUNE_ID_KEY = "comune_id";
const RESET_PASSWORD_TOKEN = "reset_password_token";
const RESET_PASSWORD_USERNAME = "reset_password_username";
const CODICE_TENANT_KEY = "codice_tenant";

export const setAccessToken = (access_token: string | null) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, access_token ?? "");
};

export const setUsername = (token: string | null) => {
    localStorage.setItem(USERNAME_KEY, token ?? "");
};

export const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const deleteAccessToken = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(COMUNE_ID_KEY);
};

export const clearLocalStorage = () => {
    localStorage.clear();
};

export const setCodiceTenant = (codiceTenant: string | null) => {
    localStorage.setItem(CODICE_TENANT_KEY, codiceTenant ?? "");
};

export const getCodiceTenant = () => {
    return localStorage.getItem(CODICE_TENANT_KEY);
};

export const setRefreshToken = (refresh_token: string | null) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token ?? "");
};

export const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const setComuneId = (idComune: number) => {
    localStorage.setItem(COMUNE_ID_KEY, idComune.toString());
};

export const getComuneId = () => {
    return toInteger(localStorage.getItem(COMUNE_ID_KEY) ?? "0");
};

export const setResetPasswordToken = (token: string) => {
    localStorage.setItem(RESET_PASSWORD_TOKEN, token);
};

export const getResetPasswordToken = () => {
    return localStorage.getItem(RESET_PASSWORD_TOKEN) ?? "";
};

export const setResetPasswordUsername = (username: string) => {
    localStorage.setItem(RESET_PASSWORD_USERNAME, username);
};

export const getResetPasswordUsername = (): string => {
    return localStorage.getItem(RESET_PASSWORD_USERNAME) ?? "";
};
