import { format, parseJSON } from "date-fns";
import React, { FC } from "react";

interface DateFormatProps {
    value: string | Date;
    stringFormat?: string;
}

const DateFormat: FC<DateFormatProps> = ({ value, stringFormat }) => {
    if (!value) {
        return <></>;
    }

    const date = parseJSON(value);

    return <span>{format(date, stringFormat ?? "dd/MM/yyyy")}</span>;
};

export default DateFormat;
