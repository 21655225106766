import { Diritti, StatoDiritti } from "@models/Diritti";

const getGraficaKey = Object.values(Diritti)[Diritti.GRAFICA - 1];
const getAnagraficheKey = Object.values(Diritti)[Diritti.ANAGRAFICHE - 1];
const getStrutturaKey = Object.values(Diritti)[Diritti.STRUTTURA - 1];
const getTombeKey = Object.values(Diritti)[Diritti.TOMBE - 1];
const getCanoniKey = Object.values(Diritti)[Diritti.CANONI - 1];
const getFattureKey = Object.values(Diritti)[Diritti.FATTURE - 1];
const getContrattiKey = Object.values(Diritti)[Diritti.CONTRATTI - 1];
const getCremazioniKey = Object.values(Diritti)[Diritti.CREMAZIONI - 1];
const getTabelleKey = Object.values(Diritti)[Diritti.TABELLE - 1];
const getTrasportiKey = Object.values(Diritti)[Diritti.TRASPORTI - 1];
const getRicercheKey = Object.values(Diritti)[Diritti.RICERCHE - 1];

const statoDiritti0 = Object.values(StatoDiritti)[StatoDiritti.NESSUNO];
const statoDiritti1 = Object.values(StatoDiritti)[StatoDiritti.LETTURA];
const statoDiritti2 = Object.values(StatoDiritti)[StatoDiritti.SCRITTURA];

export {
    getGraficaKey,
    getAnagraficheKey,
    getStrutturaKey,
    getTombeKey,
    getCanoniKey,
    getFattureKey,
    getContrattiKey,
    getCremazioniKey,
    getTabelleKey,
    getTrasportiKey,
    getRicercheKey,
    statoDiritti0,
    statoDiritti1,
    statoDiritti2,
};
