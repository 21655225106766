import { Feature } from "ol";
import { click } from "ol/events/condition";
import { Geometry } from "ol/geom";
import { Select } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import { Dispatch, SetStateAction, useState } from "react";
import { useRecoilValue } from "recoil";

import { EIconeDraw } from "@models/Configs";

import { findDrawFeature } from "@openLayersMap/Helpers/EditMappingHelpers";
import { useMap } from "@openLayersMap/Map/MapContext";
import { IModal } from "@openLayersMap/Models/EditMapping";
import { typeDrawState } from "@openLayersMap/Stores/editControls";

import { selezioneStyle } from "../Helpers/StyleHelper";

interface SelectHookProps {
    source: VectorSource;
    setModal: Dispatch<SetStateAction<IModal>>;
}

const useSelectHook = ({ source, setModal }: SelectHookProps) => {
    const { map } = useMap();
    const typeDraw = useRecoilValue(typeDrawState);

    const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);
    const [selectedFeatureDelete, setSelectedFeatureDelete] = useState<Feature | null>(null);
    const shouldReactWith = [EIconeDraw.Seleziona, EIconeDraw.Elimina, EIconeDraw.Colore];

    const selectFeatures = new Select({
        style: (feature, resolution) => {
            return selezioneStyle(feature, resolution);
        },
        condition: (mapBrowserEvent) => {
            return click(mapBrowserEvent);
        },
    });

    const setFeature = (getFeature: Feature<Geometry> | null) => {
        if (typeDraw && typeDraw === EIconeDraw.Elimina) setSelectedFeatureDelete(getFeature);
        if (typeDraw && (typeDraw === EIconeDraw.Seleziona || typeDraw === EIconeDraw.Colore))
            setSelectedFeature(getFeature);
    };

    const getSelectedFeature = (feature: Feature<Geometry>): void => {
        if (feature && feature.getId()) {
            const getFeature = source.getFeatureById(feature.getId() as number);
            setFeature(getFeature);
        }

        if (feature && !feature.getId()) {
            const getFeature = findDrawFeature({ feature, source });
            setFeature(getFeature);
        }
    };

    const initSelectedFeatures = () => {
        if (!map) return;

        if (typeDraw && shouldReactWith.includes(typeDraw)) {
            map.addInteraction(selectFeatures);

            selectFeatures.on("select", (event) => {
                const { selected } = event;

                if (selected.length > 0) {
                    getSelectedFeature(selected[0]);

                    if (typeDraw && typeDraw === EIconeDraw.Elimina)
                        setModal({
                            visible: true,
                            message: "Sei sicuro di voler cancellare queste geometrie?",
                        });
                }

                if (selected.length === 0) setSelectedFeature(null);
            });
        }
    };

    const destroySelectedFeatures = () => {
        map?.removeInteraction(selectFeatures);
    };

    return {
        initSelectedFeatures,
        destroySelectedFeatures,
        selectedFeature,
        selectedFeatureDelete,
    };
};

export default useSelectHook;
