import React from "react";
import { Outlet } from "react-router-dom";

import { ContractsProvider } from "@modules/contracts/context/ContractsContext";

const ContractsContainer = () => {
    return (
        <>
            <ContractsProvider>
                <Outlet />
            </ContractsProvider>
        </>
    );
};

export default ContractsContainer;
