import React, { FC, createContext, useContext, useEffect, useMemo, useState } from "react";

type Props = {
    children: React.ReactNode;
};

export interface PageDataContextModel {
    pageTitle?: string;
    setPageTitle: (_title: string) => void;
    sidebar?: JSX.Element | null;
    setSidebar: (_sidebar: JSX.Element | null) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
    setPageTitle: (_title: string) => null,
    setSidebar: (_sidebar: JSX.Element | null) => null,
});

const PageDataProvider: React.FC<Props> = ({ children }) => {
    const [pageTitle, setPageTitle] = useState<string>("");
    const [sidebar, setSidebar] = useState<JSX.Element | null>(null);

    const value: PageDataContextModel = useMemo(
        () => ({
            pageTitle,
            setPageTitle,
            sidebar,
            setSidebar,
        }),
        [pageTitle, sidebar]
    );

    return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>;
};

function usePageData() {
    return useContext(PageDataContext);
}

const PageTitle: FC<Props> = ({ children }) => {
    const { setPageTitle } = usePageData();
    useEffect(() => {
        if (children) {
            setPageTitle(children.toString());
        }
        return () => {
            setPageTitle("");
        };
    }, [children, setPageTitle]);

    return <></>;
};

const PageSidebar: React.FC<Props> = ({ children }) => {
    const { setSidebar } = usePageData();
    useEffect(() => {
        if (children) {
            // @ts-ignore
            setSidebar(children);
        }
        return () => {
            setSidebar(null);
        };
    }, [children, setSidebar]);

    return <></>;
};

export { PageSidebar, PageTitle, PageDataProvider, usePageData };
