import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Panel, Toggle } from "rsuite";
import * as Yup from "yup";

import { listTipiTomba } from "@services/settings/cimiteri/TipiTombaService";

import { IUpdatePostiTomba } from "@models/SettoreCimitero";
import { IPostoTomba, Tomba } from "@models/Tomba";

import { useCruxRequest } from "@hooks/useCruxRequest";

import FormDrawerStack from "@components/Drawers/FormDrawerStack";
import FormGroup from "@components/Form/FormGroup";
import FormInput from "@components/Form/Input/FormInput";
import SelectInput from "@components/Form/Input/SelectInput";
import FontAwesomePicker from "@components/Form/Input/Special/FontAwesomePicker";
import TextAreaInput from "@components/Form/Input/TextAreaInput";

import IconaNoteTomba from "../_components/Sidebar/IconaNoteTomba";

export const PostiTombaSchema = Yup.object().shape({
    idTipo: Yup.string(),
    numero: Yup.string(),
    note: Yup.string(),
    icona: Yup.string().nullable(),
});

interface IPostiLabel {
    newPostiSalma?: number;
    newPostiResto?: number;
    isPostiResto?: boolean;
    isPostiSalma?: boolean;
    oldPostiSalma?: number;
    oldPostiResto?: number;
}

const getLabel = ({ newPostiResto, newPostiSalma, oldPostiSalma, oldPostiResto }: IPostiLabel): string => {
    if ((!newPostiResto && oldPostiResto) || (oldPostiResto && newPostiResto === oldPostiResto))
        return `attuali: ${oldPostiResto}`;
    if (!!newPostiResto && newPostiResto > 0 && !newPostiSalma) return `da aggiungere: ${newPostiResto}`;
    if (!!newPostiResto && newPostiResto < 0 && !newPostiSalma) return `da eliminare: ${newPostiResto * -1}`;

    if ((!newPostiSalma && oldPostiSalma) || (oldPostiSalma && newPostiSalma === oldPostiSalma))
        return `attuali: ${oldPostiSalma}`;
    if (!!newPostiSalma && newPostiSalma > 0 && !newPostiResto) return `da aggiungere: ${newPostiSalma}`;
    if (!!newPostiSalma && newPostiSalma < 0 && !newPostiResto) return `da eliminare: ${newPostiSalma * -1}`;

    return "";
};

const PostiLabel = ({
    newPostiSalma = 0,
    newPostiResto = 0,
    isPostiResto = false,
    isPostiSalma = false,
    oldPostiSalma,
    oldPostiResto,
}: IPostiLabel): JSX.Element => {
    if (isPostiSalma)
        return (
            <p>
                Posti salma&nbsp;
                <span className='font-bold'>{getLabel({ newPostiSalma, oldPostiSalma })}</span>
            </p>
        );

    if (isPostiResto)
        return (
            <p>
                Posti resto&nbsp;
                <span className='font-bold'>{getLabel({ newPostiResto, oldPostiResto })}</span>
            </p>
        );

    return <></>;
};

const PostiTombaForm = ({
    posti,
    oldPostiResto = 0,
    oldPostiSalma = 0,
    tomba,
}: {
    posti: IPostoTomba[];
    oldPostiResto?: number;
    oldPostiSalma?: number;
    tomba?: Tomba;
}) => {
    const { data: tombe } = useCruxRequest(listTipiTomba);
    const { values, setFieldValue } = useFormikContext<IUpdatePostiTomba>();
    const [postiSalma, setPostiSalma] = useState(0);
    const [postiResto, setPostiResto] = useState(0);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (posti.length > 0 && !!values && values.idTipo) {
            const newPostiTomba = tombe?.find((value) => value.id === values.idTipo);

            const diffSalma = newPostiTomba ? (newPostiTomba?.postiSalma || 0) - oldPostiSalma : oldPostiSalma;
            const diffResto = newPostiTomba ? (newPostiTomba?.postiResto || 0) - oldPostiResto : oldPostiResto;

            setPostiSalma(diffSalma);
            setPostiResto(diffResto);
        }
        // eslint-disable-next-line
    }, [posti, values.idTipo]);

    const filterTipiTomba = useMemo(() => {
        return checked ? tombe : tombe?.filter((t) => !t.storico);
    }, [checked, tombe]);

    const findStorico = tombe?.find((t) => t.storico);

    return (
        <FormDrawerStack>
            <Panel bordered className='bg-athens-200 space-y-8'>
                <div className='flex items-center space-x-20'>
                    <FormGroup name='idTipo' label='Tipo di tomba' className='mt-0'>
                        <SelectInput
                            name='idTipo'
                            data={filterTipiTomba ?? []}
                            valueKey='id'
                            labelKey='descr'
                            cleanable={false}
                            searchable
                            placeholder='Seleziona tipo di tomba'
                        />
                    </FormGroup>
                    {findStorico && (
                        <Toggle
                            size='md'
                            checkedChildren='Disabilita storici'
                            unCheckedChildren='Visualizza storici'
                            checked={checked}
                            onChange={(c) => setChecked((prev) => !prev)}
                        />
                    )}
                </div>

                <FormGroup name='numero' label='Numero tomba' className='w-full'>
                    <FormInput name='numero' />
                </FormGroup>

                <FormGroup name='descr' label='Descrizione' className='w-full'>
                    <FormInput name='descr' />
                </FormGroup>

                <FormGroup name='icona' label='Nome icona' className='flex-1'>
                    <FontAwesomePicker name='icona' />
                </FormGroup>

                <FormGroup name='note' label='Note tomba' className='w-full'>
                    <TextAreaInput name='note' />
                </FormGroup>

                <div className='flex space-x-8 mt-12 mb-12'>
                    <div className='border w-fit bg-primary-100 border-primary-400 text-primary-700 pl-2 pr-2 pt-1 pb-1'>
                        <PostiLabel isPostiSalma newPostiSalma={postiSalma} oldPostiSalma={oldPostiSalma} />
                    </div>
                    <div className='border w-fit bg-primary-100 border-primary-400 text-primary-700 pl-2 pr-2 pt-1 pb-1'>
                        <PostiLabel isPostiResto newPostiResto={postiResto} oldPostiResto={oldPostiResto} />
                    </div>
                    {tomba && <IconaNoteTomba tomba={tomba} showNote={false} />}
                </div>
            </Panel>
        </FormDrawerStack>
    );
};

export default PostiTombaForm;
