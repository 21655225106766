import OLTileLayer from "ol/layer/Tile";
import TileSource from "ol/source/Tile";
import { FC, useEffect } from "react";

import { useMap } from "../Map/MapContext";

interface TileLayerProps {
    source: TileSource;
}

const TileLayer: FC<TileLayerProps> = ({ source }) => {
    const { map } = useMap();

    useEffect(() => {
        if (!map) return undefined;

        const tileLayer = new OLTileLayer({
            source,
        });

        map.addLayer(tileLayer);

        return () => {
            if (map) {
                map.removeLayer(tileLayer);
            }
        };
    }, [map, source]);

    return <></>;
};

export default TileLayer;
