import { Anagrafica } from "@models/Anagrafica";
import { Contratto, ContrattoRichiedente } from "@models/Contratto";
import { GraficaBase } from "@models/Grafica";
import IEnum from "@models/IEnum";

import { CanoneSmall } from "./Canone";
import { ETipoTrasferimento } from "./SettoreCimitero";
import { ETipo, ETipoDefunto } from "./TipoTomba";

interface TombaBase {
    id: number;
    idCimitero?: number;
    idStruttura: number;
    numero: string;
    numeroFiles?: number;
    tipo: IEnum;
    stato: string;
    path: PathItem[];
    posizione: string | null;
}

interface Tomba extends TombaBase {
    posti: IPostoTomba[];
    contratti: TombaContratto[];
    icone: string[];
    icona?: string;
    canoni: CanoneSmall[];
    immaginiPresenti?: boolean;
    postiStoriciPresenti?: boolean;
    lampade?: boolean;
    note?: string;
    grafica?: GraficaBase | null;
    descr?: string;
}

interface PathItem {
    id: number;
    codice: string;
    descrCompleta: string | null;
    livello: string;
}

enum EStato {
    Occupato = "O",
    Libero = "L",
    Prenotato = "P",
}

interface PostoBase {
    id: number;
    storico: boolean;
    tipo: ETipo;
    tipoDefunto: ETipoDefunto;
    numero: number;
    stato: EStato;
}

interface ITrasferimento {
    altroComune?: string;
    dataTrasferimento: Date;
    tipoTrasferimento: {
        descr: string;
        id: ETipoTrasferimento;
    };
    posto: PostoBase;
    tomba: TombaBase;
}

interface IPostoTomba extends PostoBase {
    anagrafica: Anagrafica;
    dataOccupazione: Date;
    oraOccupazione?: string;
    daVerificare: boolean;
    provvisorio: boolean;
    note: string;
    destinazione: ITrasferimento;
    provenienza: ITrasferimento;
}

interface IPostoTombaExtended extends IPostoTomba {
    idCasoParticolare: number | null;
    numPermSep: string | null;
    annoPermSep: number | null;
    dataPermSep: Date | null;
    dataFunerale: Date | null;
    oraFunerale: string | null;
    chiesaFunerale: string | null;
    partenzaFunerale: string | null;
    idImpresa: number;
}

interface TombaContratto {
    id: number;
    anno: number;
    numero: number;
    data: string;
    dataInizio: string;
    dataScadenza: string;
    dataAnnullamento?: string;
    richiedenti: ContrattoRichiedente[];
    storico: boolean;
    precedente?: Partial<Contratto>;
    successivo?: Partial<Contratto>;
}

interface RUpdatePostiTomba {
    id: number;
    numero: string;
    tipo: { id: number; descr: string };
    postiAggiuntiSalma: number;
    postiEliminatiSalma: number;
    postiAggiuntiResto: number;
    postiEliminatiResto: 0;
    descr: string;
}

interface TipiTrasferimento {
    descr: string;
    esumazione: boolean;
    id: number;
    storico: boolean;
}

export { EStato };
export type {
    TombaBase,
    Tomba,
    IPostoTomba,
    TombaContratto,
    RUpdatePostiTomba,
    PathItem,
    IPostoTombaExtended,
    ITrasferimento,
    TipiTrasferimento,
};
