import html2PDF from "jspdf-html2canvas";

const getTextBreadcrumb = () => {
    const lastBreadcrumb = Array.from(document.getElementsByClassName("breadcrumb")).at(-1);

    const textBreadcrumb = lastBreadcrumb?.children
        ? Array.from(lastBreadcrumb?.children)
              .map((item: any) => item.innerHTML)
              .toString()
              .replaceAll("&gt;", ">")
              .replaceAll(",", " ")
        : "";

    return textBreadcrumb;
};

const geTTextDescr = () => {
    const descr = Array.from(document.getElementsByClassName("breadcrumb-descr")).at(-1);

    return descr ? descr.innerHTML : "";
};
const printMap = (map: HTMLCanvasElement | HTMLCanvasElement[], cruxWebMap?: boolean) => {
    const textBreadcrumb = getTextBreadcrumb();
    const textDescr = geTTextDescr();

    html2PDF(map, {
        jsPDF: {
            format: "a4",
            orientation: "landscape",
        },
        margin: { top: 0, right: 5, bottom: 0, left: 5 },
        imageType: "image/png",
        success: (pdf) => {
            const pageHeight = pdf.internal.pageSize.getHeight();
            const textOptions = {
                align: "center" as any,
                baseline: "bottom" as any,
            };
            pdf.text(textBreadcrumb, pdf.internal.pageSize.getWidth() / 2, pageHeight - 60, textOptions);
            pdf.text(textDescr, pdf.internal.pageSize.getWidth() / 2, pageHeight - 20, textOptions);
            const blob = pdf.output("blob");
            window.open(URL.createObjectURL(blob));
        },
    });
};

const saveMap = (map: HTMLCanvasElement, title: string, cruxWebMap?: boolean) => {
    const textBreadcrumb = getTextBreadcrumb();
    const textDescr = geTTextDescr();

    html2PDF(map, {
        jsPDF: {
            format: "a4",
            orientation: "landscape",
        },
        margin: { top: cruxWebMap ? 80 : 0, right: 0, bottom: 0, left: 0 },
        imageType: "image/png",
        success: (pdf) => {
            const pageHeight = pdf.internal.pageSize.getHeight();
            const textOptions = {
                align: "center" as any,
                baseline: "bottom" as any,
            };
            pdf.text(textBreadcrumb, pdf.internal.pageSize.getWidth() / 2, pageHeight - 40, textOptions);
            pdf.text(textDescr, pdf.internal.pageSize.getWidth() / 2, pageHeight - 20, textOptions);
            pdf.save(`${title.replaceAll(" ", "-")}.pdf`);
        },
    });
};

export { printMap, saveMap };
