import { Feature } from "ol";
import { platformModifierKeyOnly } from "ol/events/condition";
import { Geometry } from "ol/geom";
import { DragBox } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import { Dispatch, SetStateAction, useState } from "react";
import { useRecoilValue } from "recoil";

import { EIconeDraw } from "@models/Configs";

import { useMap } from "@openLayersMap/Map/MapContext";
import { IModal } from "@openLayersMap/Models/EditMapping";
import { typeDrawState } from "@openLayersMap/Stores/editControls";

import { selezioneStyle } from "../Helpers/StyleHelper";

interface DragBoxHookProps {
    source: VectorSource;
    setModal: Dispatch<SetStateAction<IModal>>;
}

const useDragBoxHook = ({ source, setModal }: DragBoxHookProps) => {
    const [boxFeatures, setBoxFeatures] = useState<Feature<Geometry>[]>([]);
    const { map } = useMap();
    const typeDraw = useRecoilValue(typeDrawState);
    const shouldReactWith = [EIconeDraw.Elimina];

    const dragBox = new DragBox({
        condition: platformModifierKeyOnly,
        className: "map-drag-box",
    });

    const initDragBox = (): void => {
        if (!map) return;

        if (typeDraw && shouldReactWith.includes(typeDraw)) {
            map.addInteraction(dragBox);

            dragBox.on("boxend", () => {
                const extent = dragBox.getGeometry().getExtent();
                const selectedBoxFeatures = source
                    .getFeaturesInExtent(extent)
                    .filter((boxFeature: Feature<Geometry>) => boxFeature?.getGeometry()?.intersectsExtent(extent));

                if (selectedBoxFeatures.length > 0) {
                    selectedBoxFeatures.forEach((f) => {
                        f.setStyle(selezioneStyle);
                    });

                    setBoxFeatures(selectedBoxFeatures);
                    setModal({
                        visible: true,
                        message: "Sei sicuro di voler cancellare queste geometrie?",
                    });
                }
            });
        }
    };

    const destroyDragBox = () => {
        map?.removeInteraction(dragBox);
    };

    return {
        initDragBox,
        destroyDragBox,
        boxFeatures,
    };
};

export default useDragBoxHook;
