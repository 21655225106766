import { useSetState } from "ahooks";
import { orderBy, uniqueId } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Carousel, Modal, Table } from "rsuite";
import { ModalSize } from "rsuite/esm/Modal/Modal";
import { ValueType } from "rsuite/esm/Radio";

import { listDocumentiBase } from "@services/stampa-unione/StampaUnioneService";

import { ContrattoTomba } from "@models/Contratto";
import { SchemaOrderBy, SchemaSortBy } from "@models/OramaSearch";
import { PaginateBaseRequest } from "@models/Requests/Requests";
import { DocumentiBase, DocumentiBaseTipo } from "@models/StampaUnione";
import { PathItem } from "@models/Tomba";

import { ExportMap, LoadingExportMap } from "@modules/cimiteri/pages/Models/settori";

import { useCruxRequest } from "@hooks/useCruxRequest";
import { OramaSearchFilters } from "@hooks/useOramaSearch";

import MappaEsterna from "@components/Map/MappaEsterna";
import RadioCell from "@components/Table/RadioCell";

export type ConfirmModalAlternatives = {
    value: any;
    label: string;
};

export interface StampaUnioneConfirm {
    checkedKey: number;
    allExportMap?: ExportMap[];
}

type ModalProps = {
    handleConfirm: ({ checkedKey, allExportMap }: StampaUnioneConfirm) => void;
    handleClose: () => void;
    isOpen: boolean;
    isLoading: boolean;
    tombe?: ContrattoTomba[];
    size?: ModalSize | undefined;
    isFatture?: boolean;
    isContratti?: boolean;
    isListaContratti?: boolean;
    isRicercheTombe?: boolean;
    isRicercheDefunti?: boolean;
    isRicercheContratti?: boolean;
    isCanoni?: boolean;
    isTomba?: boolean;
};

const StampaUnioneModal: FC<ModalProps> = (props) => {
    const {
        handleClose,
        handleConfirm,
        isOpen,
        isLoading,
        tombe,
        size = "full",
        isContratti = false,
        isListaContratti = false,
        isFatture = false,
        isRicercheTombe = false,
        isRicercheDefunti = false,
        isRicercheContratti = false,
        isCanoni = false,
        isTomba = false,
    } = props;
    const { data: documentiBase, loading: loadingDocumentiBase } = useCruxRequest(listDocumentiBase);
    const [checkedKey, setCheckedKey] = useState<number | undefined>(undefined);
    const [allExportMap, setAllExportMap] = useState<ExportMap[]>([]);
    const [loadingAllMap, setLoadingAllMap] = useState<LoadingExportMap[]>([]);
    const [tableResults, setTableResults] = useState<DocumentiBase[] | undefined>(undefined);
    const [timeoutTable, setTimeoutTable] = useState(false);

    const navigate = useNavigate();
    const { Column, HeaderCell, Cell } = Table;

    const handleChange = (value: ValueType | undefined) => {
        if (value) setCheckedKey(+value);
    };

    const documentiBaseContratto = useMemo(() => {
        return documentiBase?.filter((db) => db.tipo === DocumentiBaseTipo["Contratto"]);
    }, [documentiBase]);

    const documentiBaseListaContratto = useMemo(() => {
        return documentiBase?.filter((db) => db.tipo === DocumentiBaseTipo["ListaContratti"]);
    }, [documentiBase]);

    const documentiBaseFatture = useMemo(() => {
        return documentiBase?.filter((db) => db.tipo === DocumentiBaseTipo["Fatture"]);
    }, [documentiBase]);

    const documentiBaseCanoni = useMemo(() => {
        return documentiBase?.filter((db) => db.tipo === DocumentiBaseTipo["Canoni"]);
    }, [documentiBase]);

    const documentiBaseRicercheTombe = useMemo(() => {
        return documentiBase?.filter((db) => db.tipo === DocumentiBaseTipo["RicercaTombe"]);
    }, [documentiBase]);

    const documentiBaseRicercheDefunti = useMemo(() => {
        return documentiBase?.filter((db) => db.tipo === DocumentiBaseTipo["RicercaDefunti"]);
    }, [documentiBase]);

    const documentiBaseRicercheContratti = useMemo(() => {
        return documentiBase?.filter((db) => db.tipo === DocumentiBaseTipo["RicercaContratti"]);
    }, [documentiBase]);

    const documentiBaseTomba = useMemo(() => {
        return documentiBase?.filter((db) => db.tipo === DocumentiBaseTipo["Tomba"]);
    }, [documentiBase]);

    const tomba = useMemo(() => {
        return tombe ? tombe[0]?.tomba : undefined;
    }, [tombe]);

    const isExportMap = useMemo(() => {
        return documentiBase?.find((dB) => dB.id === checkedKey)?.salvaImmagini;
    }, [documentiBase, checkedKey]);

    const payloadOrderBy: SchemaOrderBy = {
        orders: "asc",
        key: "tipo",
    };
    const schema = {
        key: "number",
        nomeDoc: "string",
        salvaDoc: "string",
        descr: "string",
        tipo: "number",
        salvaImmagini: "boolean",
        tipoImmagine: "number",
        numRipetizioni: "number",
        generaQRCode: "boolean",
        salvaDocSingoli: "boolean",
        inviaPDF: "boolean",
        formatoHTML: "boolean",
    };
    const stemmerSkipProperties = ["tipo", "key"];
    const properties = ["nomeDoc", "salvaDoc", "descr"];
    const sortBy: SchemaSortBy = {
        property: "tipo",
        order: "ASC",
    };

    const getCanvas = (
        path: PathItem,
        canvas: any,
        mapContext: CanvasRenderingContext2D | null,
        mapCanvas: HTMLCanvasElement
    ) => {
        if (canvas && mapContext) {
            const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
            let matrix;
            const { transform } = canvas.style;
            if (transform) {
                matrix = transform
                    .match(/^matrix\(([^]*)\)$/)[1]
                    .split(",")
                    .map(Number);
            } else {
                matrix = [
                    parseFloat(canvas.style.width) / canvas.width,
                    0,
                    0,
                    parseFloat(canvas.style.height) / canvas.height,
                    0,
                    0,
                ];
            }
            CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
            const { backgroundColor } = canvas.parentNode.style;
            if (backgroundColor) {
                mapContext.fillStyle = backgroundColor;
                mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            mapContext.drawImage(canvas, 0, 0);
        }

        if (mapContext) mapContext.globalAlpha = 1;
        if (mapContext) mapContext.setTransform(1, 0, 0, 1, 0, 0);
        setAllExportMap((prev) => [
            ...prev,
            { idSettore: path.id, mappa: mapCanvas.toDataURL().replace("data:image/png;base64,", "") },
        ]);
    };

    const handleExportMap = () => {
        tomba?.path.forEach((path, index) => {
            const mapCanvas = document.createElement("canvas");
            const canvasSize = [700, 400];
            const [width, height] = canvasSize;
            mapCanvas.width = width;
            mapCanvas.height = height;
            const mapContext = mapCanvas.getContext("2d");

            const canvas: any = document.querySelectorAll(".ol-layer canvas, canvas.ol-layer")[index];
            getCanvas(path, canvas, mapContext, mapCanvas);
        });
    };

    const handleSave = () => {
        if ((isContratti || isListaContratti || isTomba) && checkedKey && isExportMap) {
            setAllExportMap([]);
            handleExportMap();
        }

        if ((isFatture || isCanoni) && checkedKey) handleConfirm({ checkedKey });
        if ((isContratti || isListaContratti || isTomba) && checkedKey && allExportMap.length === 0 && !isExportMap)
            handleConfirm({ checkedKey });
        if ((isRicercheTombe || isRicercheDefunti || isRicercheContratti) && checkedKey) handleConfirm({ checkedKey });
    };

    const isLoadingAllMap = useMemo(() => {
        return loadingAllMap.find((l) => l.loading);
    }, [loadingAllMap]);

    useEffect(() => {
        if (allExportMap.length > 0 && checkedKey) {
            handleConfirm({ checkedKey, allExportMap });
            setAllExportMap([]);
            setLoadingAllMap([]);
        }

        return () => setCheckedKey(undefined);
    }, [allExportMap]);

    const payload = useMemo(() => {
        if (documentiBaseContratto && documentiBaseContratto?.length > 0 && isContratti) {
            return documentiBaseContratto.map((dB) => {
                return { ...dB, key: dB.id };
            });
        }
        if (documentiBaseListaContratto && documentiBaseListaContratto?.length > 0 && isListaContratti) {
            return documentiBaseListaContratto.map((dB) => {
                return { ...dB, key: dB.id };
            });
        }
        if (documentiBaseFatture && documentiBaseFatture?.length > 0 && isFatture) {
            return documentiBaseFatture.map((dB) => {
                return { ...dB, key: dB.id };
            });
        }
        if (documentiBaseCanoni && documentiBaseCanoni?.length > 0 && isCanoni) {
            return documentiBaseCanoni.map((dB) => {
                return { ...dB, key: dB.id };
            });
        }
        if (documentiBaseRicercheTombe && documentiBaseRicercheTombe?.length > 0 && isRicercheTombe) {
            return documentiBaseRicercheTombe.map((dB) => {
                return { ...dB, key: dB.id };
            });
        }
        if (documentiBaseRicercheDefunti && documentiBaseRicercheDefunti?.length > 0 && isRicercheDefunti) {
            return documentiBaseRicercheDefunti.map((dB) => {
                return { ...dB, key: dB.id };
            });
        }
        if (documentiBaseRicercheContratti && documentiBaseRicercheContratti?.length > 0 && isRicercheContratti) {
            return documentiBaseRicercheContratti.map((dB) => {
                return { ...dB, key: dB.id };
            });
        }
        if (documentiBaseTomba && documentiBaseTomba?.length > 0 && isTomba) {
            return documentiBaseTomba.map((dB) => {
                return { ...dB, key: dB.id };
            });
        }
        return [];
    }, [
        documentiBaseContratto,
        documentiBaseCanoni,
        documentiBaseFatture,
        documentiBaseRicercheTombe,
        documentiBaseRicercheDefunti,
        documentiBaseRicercheContratti,
        documentiBaseTomba,
        documentiBaseListaContratto,
    ]);

    const [sortAndPaginate, setSortAndPaginate] = useSetState<PaginateBaseRequest>({
        ordinamento: "nomeDoc",
        ordinamentoDecrescente: false,
        pagina: 1,
        dimensionePagina: 50,
    });
    const params = useMemo(() => ({ ...sortAndPaginate }), [sortAndPaginate]);
    const sortDocumenti = useMemo(() => {
        return orderBy(tableResults, params.ordinamento, params.ordinamentoDecrescente ? "desc" : "asc");
    }, [tableResults, params]);
    const handleOnSortTable = (sortColumn: string, sortType?: "desc" | "asc") => {
        setSortAndPaginate({
            ordinamento: sortColumn,
            ordinamentoDecrescente: sortType === "desc",
        });
    };

    // force reload table
    useEffect(() => {
        setTimeoutTable(true);

        setTimeout(() => {
            setTimeoutTable(false);
        }, 500);
    }, []);

    return (
        <Modal backdrop='static' role='alertdialog' open={isOpen} onClose={handleClose} size={size}>
            <section className='flex justify-between items-center'>
                <div className='font-bold text-md'>Lista documenti</div>
                <Button type='button' appearance='link' onClick={() => navigate("/tabelle/documenti/stampa-unione")}>
                    Vai alla tabella documenti stampa unione
                </Button>
            </section>
            <Modal.Body className='max-h-modal'>
                <section className='mb-12'>
                    <OramaSearchFilters
                        payload={payload}
                        schema={schema}
                        properties={properties}
                        stemmerSkipProperties={stemmerSkipProperties}
                        payloadOrderBy={payloadOrderBy}
                        sortBy={sortBy}
                        inputPlaceholder='Ricerca per nome documento, salva con nome e descrizione'
                        setReturnResults={setTableResults}
                    />
                </section>

                {!timeoutTable && (
                    <Table
                        bordered
                        cellBordered
                        autoHeight={sortDocumenti && sortDocumenti?.length > 5}
                        loading={loadingDocumentiBase}
                        data={sortDocumenti}
                        sortColumn={sortAndPaginate.ordinamento}
                        sortType={sortAndPaginate.ordinamentoDecrescente ? "desc" : "asc"}
                        onSortColumn={handleOnSortTable}
                        className={tableResults?.length === 0 ? "h-[6rem]" : ""}
                        rowHeight={40}
                    >
                        <Column fullText flexGrow={1} sortable>
                            <HeaderCell>Nome documento</HeaderCell>
                            <RadioCell dataKey='nomeDoc' handleChange={handleChange} checkedKey={checkedKey} />
                        </Column>

                        <Column fullText flexGrow={1} sortable>
                            <HeaderCell>Descrizione</HeaderCell>
                            <Cell dataKey='descr' />
                        </Column>

                        <Column fullText flexGrow={1}>
                            <HeaderCell>Salva con nome</HeaderCell>
                            <Cell dataKey='salvaDoc' />
                        </Column>
                    </Table>
                )}

                {isExportMap && tombe && tombe.length > 0 && (
                    <div className='flex justify-center'>
                        <section className='w-[700px] h-[400px]'>
                            <p className='font-bold text-primary-500 mt-20'>Piantine importate nel documento</p>
                            <Carousel
                                key={uniqueId()}
                                placement='bottom'
                                shape='bar'
                                className='custom-slider h-full flex bg-athens-800'
                            >
                                {tomba?.path &&
                                    tomba?.path.length > 0 &&
                                    tomba?.path.map((livello, index) => (
                                        <MappaEsterna
                                            livello={livello}
                                            key={livello.id}
                                            idTomba={tomba?.id}
                                            nextLivello={tomba?.path[index + 1] ?? undefined}
                                            isCruxWeb={false}
                                        />
                                    ))}
                            </Carousel>
                        </section>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className='mt-20'>
                <Button
                    onClick={handleSave}
                    disabled={isLoading || !!isLoadingAllMap || !checkedKey}
                    loading={isLoading}
                    appearance='primary'
                >
                    {isLoadingAllMap ? "Caricamento piantine..." : "Esegui"}
                </Button>
                <Button
                    onClick={() => {
                        handleClose();
                        setAllExportMap([]);
                        setLoadingAllMap([]);
                        setCheckedKey(undefined);
                    }}
                    appearance='subtle'
                >
                    Annulla
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default StampaUnioneModal;
