import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Results, create, insertMultiple, search } from "@orama/orama";
import { isUndefined, orderBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Input, InputGroup, SelectPicker } from "rsuite";

import { IOramaSearchFilters, OramaSearch, SchemaFilter } from "@models/OramaSearch";

import { replaceSpecialCharacter } from "@helpers/Filter";

export const useOramaSearch = ({
    payload,
    filters,
    payloadOrderBy,
    schema,
    stemmerSkipProperties,
    properties,
    sortBy,
    where,
}: OramaSearch) => {
    const [results, setResults] = useState<Results<any>>();
    const [index, setIndex] = useState<any>();

    const mapHitsOrPayload = (hP: any) => {
        return { ...hP };
    };

    const initOramaSearch = async (mapPayload: any[]) => {
        // @ts-ignore
        const clientIndex = await create({
            schema,
            ...(stemmerSkipProperties && {
                components: {
                    tokenizer: {
                        stemming: true,
                        stemmerSkipProperties,
                    },
                },
            }),
        });

        await insertMultiple(clientIndex, mapPayload);
        setIndex(clientIndex);
    };

    useEffect(() => {
        async function searchIntoIndex() {
            if (filters && (filters.terms || !isUndefined(filters.whereKey))) {
                const searchResult = await search(index, {
                    term: filters.terms,
                    properties,
                    ...(!isUndefined(filters.whereKey) && {
                        where,
                    }),
                    sortBy,
                    limit: 100,
                });
                setResults(searchResult);
            } else {
                setResults(undefined);
            }
        }
        if (index) searchIntoIndex();
    }, [filters, index]);

    useEffect(() => {
        if (payload && payload.length > 0) {
            const mapPayload = payload.map((dB) => {
                const { id, ...rest } = dB;
                return mapHitsOrPayload(rest);
            });
            initOramaSearch(mapPayload);
        }
    }, [payload]);

    const tableResults = useMemo(() => {
        if (results)
            return results.hits.map((hT) => {
                return mapHitsOrPayload(hT.document);
            });
        return payloadOrderBy ? orderBy(payload, payloadOrderBy.key, payloadOrderBy.orders) : payload;
    }, [results, payload]);

    return { tableResults };
};

const OramaSearchFilters = ({
    payload,
    schema,
    properties,
    payloadSelect,
    inputPlaceholder,
    setReturnResults,
}: IOramaSearchFilters) => {
    const schemaFilter: SchemaFilter = {
        terms: undefined,
        whereKey: undefined,
    };
    const [filters, setFilters] = useState<SchemaFilter>(schemaFilter);
    const where = {
        tipo: {
            eq: filters.whereKey,
        },
    };

    const replaceFiltersTerms = useMemo(() => {
        if (filters.terms) {
            return {
                ...filters,
                terms: replaceSpecialCharacter(filters.terms),
            };
        }
        return filters;
    }, [filters]);

    const { tableResults } = useOramaSearch({
        payload,
        filters: replaceFiltersTerms,
        schema,
        properties,
        where,
    });

    useEffect(() => {
        setReturnResults(tableResults);
    }, [tableResults]);

    return (
        <div className='border flex justify-between items-end p-3 rounded bg-athens-800 mt-12 space-x-12'>
            <InputGroup>
                <InputGroup.Addon>
                    <FontAwesomeIcon icon={faSearch} className='mr-1' />
                </InputGroup.Addon>
                <Input
                    type='text'
                    id='filter-text'
                    size='sm'
                    placeholder={inputPlaceholder}
                    value={filters.terms ?? ""}
                    className='w-4/5'
                    onChange={(newValue) => {
                        setFilters((prev) => {
                            return { ...prev, terms: newValue };
                        });
                    }}
                />
            </InputGroup>

            {payloadSelect && payloadSelect.length > 0 && (
                <SelectPicker
                    data={payloadSelect}
                    label='Filtra per tipo'
                    valueKey='id'
                    labelKey='descr'
                    searchable={false}
                    placeholder='Seleziona tipo'
                    size='sm'
                    className='w-1/5'
                    onChange={(tipo: number | null) => {
                        setFilters((prev) => {
                            return { ...prev, whereKey: tipo ?? undefined };
                        });
                    }}
                />
            )}
        </div>
    );
};

export { OramaSearchFilters };
