import classNames from "classnames";
import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { VariableSizeList as List } from "react-window";

import { SettoreCimitero } from "@models/SettoreCimitero";
import { Tomba } from "@models/Tomba";

import SottoSettoreCard from "@modules/cimiteri/pages/_components/SottoSettoreCard";

import withLoading from "@hoc/withLoading";

interface DataViewProps {
    tombe?: Tomba[];
    settore: SettoreCimitero | undefined;
    setSettore: (settore: SettoreCimitero) => void;
    isTrasferimento?: boolean;
}

const RowRenderer = ({
    data,
    index,
    setSize,
    setSettore,
}: {
    data: SettoreCimitero[][];
    index: number;
    setSize: any;
    setSettore: any;
}) => {
    const rowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (rowRef.current) setSize(index, ((rowRef.current as any)?.getBoundingClientRect()?.height ?? 50) + 12);
    }, [setSize, index]);

    return (
        <div ref={rowRef}>
            <SottoSettoreCard settore={data[index]} setSettore={setSettore} />
        </div>
    );
};

const SottoSettoriViewWrapper: FC<DataViewProps> = ({ settore, setSettore, tombe, isTrasferimento }) => {
    const listRef = useRef();
    const sizeMap = useRef<{ [key: number]: any }>({});
    const setSize = useCallback((index: any, size: any) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        (listRef.current as any)?.resetAfterIndex(index);
    }, []);

    const getSize = (index: number) => sizeMap.current[index] || 50;

    const chunkArray = (array: SettoreCimitero[] | undefined, chunkSize: number) => {
        if (array) {
            return array.reduce((result: any[], item, index) => {
                const chunkIndex = Math.floor(index / chunkSize);

                if (!result[chunkIndex]) {
                    result[chunkIndex] = [];
                }

                result[chunkIndex].push(item);
                return result;
            }, []);
        }

        return [];
    };

    const chunkedArray = useMemo(() => {
        return settore ? chunkArray(settore.settori, 4) : [];
    }, [settore]);

    if (!settore) return <p className="className='font-bold text-md text-center'">Nessun settore presente!</p>;

    return (
        <List
            ref={listRef as any}
            height={chunkedArray?.length > 0 ? 280 : 0}
            itemCount={chunkedArray?.length}
            itemSize={getSize}
            width='100%'
            itemData={chunkedArray}
            className={classNames(
                {
                    "h-[43rem] mt-12 block overflow-y-auto overflow-x-hidden":
                        chunkedArray?.length > 0 && tombe?.length === 0,
                },
                { "h-virtualizedList": !isTrasferimento },
                { "h-[18.5rem] mt-12 block": chunkedArray?.length > 0 && tombe && tombe?.length > 0 },
                {
                    "h-[44rem] mt-12 block": chunkedArray?.length > 0 && ((tombe && tombe?.length === 0) || !tombe),
                },
                { hidden: chunkedArray?.length === 0 }
            )}
        >
            {({ data, index, style }) => (
                <div style={style}>
                    <RowRenderer data={data} index={index} setSize={setSize} setSettore={setSettore} />
                </div>
            )}
        </List>
    );
};

export default withLoading(SottoSettoriViewWrapper);
