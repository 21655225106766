import * as Yup from "yup";

import { ICrudPostiTomba, IUpdateDataPostoTomba, IUpdatePostiTomba } from "@models/SettoreCimitero";
import { IPostoTomba, IPostoTombaExtended, Tomba } from "@models/Tomba";

enum TombaEvent {
    POSTI = "POSTI",
    CONTRATTI = "CONTRATTI",
    ASSOCIA_CONTRATTO = "ASSOCIA_CONTRATTO",
    CANONI = "CANONI",
    CREMAZIONI = "CREMAZIONI",
    TRASPORTI = "TRASPORTI",
}

enum TrasferimentoEvent {
    ALTRA_TOMBA = "ALTRA_TOMBA",
    STESSA_TOMBA = "STESSA_TOMBA",
    ALTRO_COMUNE = "ALTRO_COMUNE",
}

enum TombaAndPostoEvent {
    UPDATE_TOMBA = "UPDATE_TOMBA",
    DELETE_TOMBA = "DELETE_TOMBA",

    ADD_POSTO = "ADD_POSTO",
    RIEMPI_POSTO = " RIEMPI_POSTO",
    TRASFERIMENTO_POSTO = "TRASFERIMENTO_POSTO",
    ANNULLA_TRASFERIMENTO_POSTO = "ANNULLA_TRASFERIMENTO_POSTO",
    MODIFICA_TRASFERIMENTO_POSTO = "MODIFICA_TRASFERIMENTO_POSTO",
    ELIMINA_POSTO = "ELIMINA_POSTO",
    UPDATE_POSTO = "UPDATE_POSTO",
    LIBERA_POSTO = "LIBERA_POSTO",
    ELIMINA_NOTE_POSTO = "ELIMINA_NOTE_POSTO",
}

interface IActions {
    tomba?: Tomba | undefined;
}

interface IPosto extends IActions {
    postoTomba: IPostoTomba;
    posti: IPostoTomba[] | undefined;
    historicalData?: boolean;
}

interface TombaContextProps {
    tombaEvent: TombaAndPostoEvent | null;
    trasferimentoEvent: TrasferimentoEvent | undefined;
    setTombaEvent: React.Dispatch<React.SetStateAction<TombaAndPostoEvent | null>>;
    setTrasferimentoEvent: React.Dispatch<React.SetStateAction<TrasferimentoEvent | undefined>>;
    tombaForm: TombaFormProps | undefined;
    handleOnSave: (data: Partial<IUpdatePostiTomba>) => Promise<void>;
    setPostoTombaSelected: React.Dispatch<React.SetStateAction<Partial<IPostoTombaExtended> | null>>;
    postoTombaSelected: Partial<IPostoTombaExtended> | null;
    setIsManageFile: React.Dispatch<React.SetStateAction<boolean>>;
    isManageFile: boolean;
    setHistoricalData: React.Dispatch<React.SetStateAction<boolean>>;
    historicalData: boolean;
    oldPostiSalma: number;
    oldPostiResto: number;
    refresh: () => void;
    loading: boolean;
    onSavePosto: (data: Partial<IUpdateDataPostoTomba>) => Promise<void>;
    liberaPostoConfirmed: ({
        shouldDeleteNote,
        reloadTomba,
    }: {
        shouldDeleteNote?: boolean;
        reloadTomba?: boolean;
    }) => Promise<void>;
    tomba: Tomba | undefined;
}

interface TombaFormProps {
    initialValues?: Partial<ICrudPostiTomba>;
    title?: string;
    schema?: Yup.ObjectSchema<any> | undefined;
    onSave?: (data: Partial<IUpdatePostiTomba>) => Promise<void>;
    onExtraAction?: (action?: boolean | undefined) => Promise<void>;
}

export const initialData: TombaContextProps = {
    tombaEvent: null,
    trasferimentoEvent: undefined,
    setTombaEvent: () => null,
    setTrasferimentoEvent: () => null,
    tombaForm: undefined,
    handleOnSave: () => Promise.resolve(),
    setPostoTombaSelected: () => null,
    postoTombaSelected: null,
    setIsManageFile: () => null,
    isManageFile: false,
    setHistoricalData: () => null,
    historicalData: false,
    oldPostiResto: 0,
    oldPostiSalma: 0,
    refresh: () => {},
    loading: false,
    onSavePosto: () => Promise.resolve(),
    liberaPostoConfirmed: () => Promise.resolve(),
    tomba: undefined,
};

export type { IActions, IPosto, TombaContextProps, TombaFormProps };
export { TombaEvent, TrasferimentoEvent, TombaAndPostoEvent };
