import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import { useDebounceFn } from "ahooks";
import { useField } from "formik";
import { FC, useEffect, useState } from "react";
import { InputGroup, Input as RsInput, InputProps as RsInputProps } from "rsuite";

import { getType } from "../../Helpers/FormUtility";

interface FormInputPros extends Omit<RsInputProps, "form"> {
    name: string;
    transformValue?: (value: string) => any;
    suff?: string;
    pre?: string;
    disabledAutoComplete?: boolean;
    forceReset?: boolean;
    onForceReset?: (value: boolean) => void;
    debounce?: boolean;
}

const FormInput: FC<FormInputPros> = ({
    transformValue,
    suff,
    pre,
    disabledAutoComplete,
    forceReset = false,
    onForceReset,
    debounce = false,
    ...props
}) => {
    const { type = "text", ...rest } = props;
    const [field, meta, helpers] = useField(props.name);
    const [visible, setVisible] = useState(false);
    const [fieldPreview, setFieldPreview] = useState(field.value);

    const { run: handleDebounceOnChange } = useDebounceFn(
        (value: string, event) => {
            let newValue = value;
            if (transformValue) {
                newValue = transformValue(value);
            }
            helpers.setValue(newValue);
            props.onChange?.(newValue, event);
        },
        { wait: 500 }
    );

    const handleOnChange = (value: string, event: any) => {
        let newValue = value;
        if (transformValue) {
            newValue = transformValue(value);
        }
        helpers.setValue(newValue);
        props.onChange?.(newValue, event);
    };

    const handleChange = () => {
        setVisible(!visible);
    };

    useEffect(() => {
        if (forceReset) {
            helpers.setValue("");
            setFieldPreview("");

            if (onForceReset) {
                setTimeout(() => {
                    onForceReset(false);
                }, 250);
            }
        }
    }, [forceReset]);

    useEffect(() => {
        setFieldPreview(field.value);
    }, [field.value]);

    return (
        <InputGroup inside className='w-full'>
            {pre && <InputGroup.Addon className='bg-athens-300 mr-8'>{pre}</InputGroup.Addon>}

            <RsInput
                {...field}
                value={fieldPreview ?? ""}
                onChange={(value, event) => {
                    setFieldPreview(value);
                    if (debounce) handleDebounceOnChange(value.trim(), event);
                    if (!debounce) handleOnChange(value.trim(), event);
                }}
                {...rest}
                type={getType({ type, visible })}
                {...(disabledAutoComplete && { autoComplete: props.type === "password" ? "new-password" : "off" })}
            />

            {props.type === "password" ? (
                <InputGroup.Button onClick={handleChange} tabIndex={-1}>
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Button>
            ) : null}

            {suff && <InputGroup.Addon className='bg-athens-300'>{suff}</InputGroup.Addon>}
        </InputGroup>
    );
};

export default FormInput;
