export class GraveCanvas {
    private readonly can: HTMLCanvasElement;

    private ctx: CanvasRenderingContext2D;

    constructor() {
        this.can = document.createElement("canvas");
        this.ctx = this.can.getContext("2d") ?? new CanvasRenderingContext2D();
    }

    addBackground(color: string | undefined) {
        this.ctx.fillStyle = color ?? "transparent";
        this.ctx.fillRect(0, 0, this.can.width, this.can.height);
    }

    addDiagonalStripes(lineWidth: number, spacing: number, slope: number, color: string | undefined) {
        const len = Math.hypot(1, slope);

        this.can.width = 1 / len + spacing + 0.5 ?? 0;
        this.can.height = slope / len + spacing * slope + 0.5 ?? 0;

        this.ctx.strokeStyle = color ?? "black";
        this.ctx.lineWidth = lineWidth;
        this.ctx.beginPath();

        // Line through top left and bottom right corners
        this.ctx.moveTo(0, 0);
        this.ctx.lineTo(this.can.width, this.can.height);
        // Line through top right corner to add missing pixels
        this.ctx.moveTo(0, -this.can.height);
        this.ctx.lineTo(this.can.width * 2, this.can.height);
        // Line through bottom left corner to add missing pixels
        this.ctx.moveTo(-this.can.width, 0);
        this.ctx.lineTo(this.can.width, this.can.height * 2);

        this.ctx.stroke();
    }

    addStripes(lineWidth: number, spacing: number, color: string | undefined) {
        this.ctx.strokeStyle = color ?? "black";
        this.ctx.lineWidth = lineWidth;

        for (let x = 0; x <= this.can.width; x += spacing) {
            this.ctx.beginPath();
            this.ctx.moveTo(x, 0);
            this.ctx.lineTo(x, this.can.height);
            this.ctx.stroke();
        }
    }

    addImage(image: string, top: number, left: number, width: number, height: number) {
        const img = new Image();
        img.src = image;
        this.ctx.drawImage(img, top, left, width, height);
    }

    getCanvas(): CanvasPattern | null {
        return this.ctx?.createPattern(this.can, "repeat") ?? null;
    }
}
