import SearchIcon from "@rsuite/icons/Search";
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { Input, InputGroup, Loader, Placeholder, Tree } from "rsuite";
import { ItemDataType } from "rsuite/esm/@types/common";

import { isCimiteriEditModeState } from "@stores/graveyard";

import { SettoreCimitero } from "@models/SettoreCimitero";

import CimiteroPicker from "@modules/cimiteri/_components/Sidebar/CimiteroPicker";
import TreeNodeRender from "@modules/cimiteri/_components/Sidebar/TreeNodeRender";

interface ISidenaTree {
    graveyardSections: SettoreCimitero[];
    searchQuery: string;
    setSearchQuery: Dispatch<SetStateAction<string>>;
    idSettore?: string | undefined;
    idCimitero?: string | undefined;
    onIdCimiteroChange?: Dispatch<SetStateAction<number>>;
    treeData: ItemDataType<string | number>[];
    itemValues?: any[];
    setIsExpandTree?: Dispatch<SetStateAction<boolean>>;
    setExpandedItemValues?: Dispatch<SetStateAction<ItemDataType<string | number>[]>>;
    handleSelectSettore: (activeNode: any) => void;
    searchMode?: boolean;
    className?: string;
    loading: boolean;
}

const SidenavTree = ({
    graveyardSections,
    searchQuery,
    setSearchQuery,
    idSettore,
    idCimitero,
    treeData,
    itemValues,
    setExpandedItemValues,
    setIsExpandTree,
    onIdCimiteroChange,
    handleSelectSettore,
    searchMode = false,
    className = "tailwind-tree",
    loading,
}: ISidenaTree) => {
    const isEditMode = useRecoilValue(isCimiteriEditModeState);
    const treeRef = useRef(null);

    const scrollToElement = () => {
        const elementToScrollTo = document?.querySelector(".rs-tree-node-active");
        if (elementToScrollTo) {
            elementToScrollTo.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (treeRef && treeRef?.current) {
            setTimeout(() => {
                scrollToElement();
            }, 500);
        }
    }, [treeRef, treeData]);

    return (
        <div className='h-fit w-full flex flex-col'>
            <CimiteroPicker searchMode={searchMode} onIdCimiteroChange={onIdCimiteroChange} />

            <div className='flex-none px-12 mt-24'>
                <InputGroup inside className='w-full mb-3'>
                    <Input className='bg-[#c8d9ff]' value={searchQuery} onChange={setSearchQuery} />
                    <InputGroup.Button>
                        <SearchIcon />
                    </InputGroup.Button>
                </InputGroup>
            </div>
            <div className='bg-athens-300 m-3 mt-12 p-2 rounded-lg grow'>
                {graveyardSections.length > 0 && !loading && (
                    <Tree
                        searchKeyword={searchQuery}
                        value={idSettore ?? idCimitero ?? undefined}
                        data={treeData}
                        expandItemValues={itemValues ?? undefined}
                        onExpand={(event) => {
                            if (setIsExpandTree) setIsExpandTree(false);
                            if (setExpandedItemValues) setExpandedItemValues(event);
                        }}
                        showIndentLine
                        onSelect={(event) => {
                            if (!isEditMode) handleSelectSettore(event);
                        }}
                        className={className}
                        renderTreeNode={TreeNodeRender}
                        ref={treeRef}
                    />
                )}

                {((graveyardSections.length === 0 && loading) || loading) && (
                    <div className='relative block px-24'>
                        <Placeholder.Paragraph rows={5} />
                        <Loader center />
                    </div>
                )}

                {graveyardSections.length === 0 && !loading && (
                    <div className='relative block px-24'>
                        <h2>Struttura non presente</h2>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SidenavTree;
