import React from "react";
import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

import RicercheContainer from "./RicercheContainer";

const searchRoutes: RouteObject = {
    element: <RicercheContainer />,
    children: [
        {
            path: "defunti",
            element: lazyLoadRoutes("searchDefunti"),
        },
        {
            path: "contratti",
            element: lazyLoadRoutes("searchContratti"),
        },
        {
            path: "tombe",
            element: lazyLoadRoutes("searchTombe"),
        },
    ],
};

export default searchRoutes;
