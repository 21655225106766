import { atom } from "recoil";

const addLivelloState = atom<{ idLivello: number; descrCompleta: string } | undefined>({
    key: "addLivello",
    default: undefined,
});

const addTombaState = atom<number>({
    key: "addTombaState",
    default: undefined,
});

const updateLivelloState = atom<{ idLivello: number; descrCompleta: string } | undefined>({
    key: "updateLivello",
    default: undefined,
});

const deleteLivelloState = atom<{ idLivello: number } | undefined>({
    key: "deleteLivello",
    default: undefined,
});

export { addLivelloState, addTombaState, updateLivelloState, deleteLivelloState };
