import { useSetState } from "ahooks";
import { flatten } from "lodash";
import { transpose } from "mathjs";
import { Feature } from "ol";
import { Geometry, Polygon } from "ol/geom";
import ExtentInteraction from "ol/interaction/Extent";
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { Fill, Style, Text } from "ol/style";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import { addTomba } from "@services/CimiteriService";

import { EIconeDraw } from "@models/Configs";
import { IAddTomba } from "@models/StrutturaLivelli";
import { Tomba } from "@models/Tomba";

import { useCruxToaster } from "@hooks/useCruxToaster";

import AddTombeGridPanel from "@openLayersMap/Components/Panels/AddTombeGridPanel";
import { getVectorStyle } from "@openLayersMap/Helpers/StyleHelper";
import { useMap } from "@openLayersMap/Map/MapContext";
import { wkt } from "@openLayersMap/Source";
import { typeDrawState } from "@openLayersMap/Stores/editControls";

interface GrigliaTombeHookProps {
    onGrigliaTombeCreated: (e: Tomba[]) => void;
    idStruttura: number;
}

export type FirstTombaPosition = "top-left" | "top-right" | "bottom-left" | "bottom-right";

export interface GridSettings {
    rows: number;
    cols: number;
    space: number;
    direction: "vertical" | "horizontal";
    firstTombaPosition: FirstTombaPosition;
    plotType: "straight" | "alternate";
    idTipoTomba: number | undefined;
    tombaIniziale: number;
    prefisso: string;
    suffisso: string;
    incremento: number;
}

const extentInteraction = new ExtentInteraction();
const vector = new VectorLayer({
    source: new VectorSource({ wrapX: false }),
    className: "grid-vector-layer",
    style: getVectorStyle,
});

const initialValues: GridSettings = {
    rows: 5,
    cols: 5,
    space: 15,
    direction: "horizontal",
    firstTombaPosition: "top-left",
    plotType: "straight",
    idTipoTomba: undefined,
    tombaIniziale: 1,
    prefisso: "",
    suffisso: "",
    incremento: 1,
};

const calcolaNumerazione = (gridSettings: GridSettings) => {
    const { rows, cols, direction, firstTombaPosition, tombaIniziale, incremento, prefisso, suffisso, plotType } =
        gridSettings;

    const incrementoNumber = +incremento;

    let arr = Array.from({ length: gridSettings.rows }, () => Array.from({ length: gridSettings.cols }, () => 0));

    const [vStart, hStart] = firstTombaPosition.split("-");

    for (let r = 0; r < rows; r += 1) {
        for (let c = 0; c < cols; c += 1) {
            const nTomba =
                direction === "horizontal"
                    ? +tombaIniziale + cols * incrementoNumber * r + incrementoNumber * c
                    : +tombaIniziale + rows * incrementoNumber * c + incrementoNumber * r;

            arr[r][c] = +nTomba;
        }
    }

    if (plotType === "alternate") {
        if (direction === "horizontal") {
            arr = arr.map((riga, index) => (index % 2 === 0 ? riga.slice() : riga.reverse()));
        } else {
            arr = transpose(arr);
            arr = arr.map((riga, index) => (index % 2 === 0 ? riga.slice() : riga.reverse()));
            arr = transpose(arr);
        }
    }

    if (hStart === "right") {
        arr = arr.map((riga) => riga.reverse());
    }

    if (vStart === "bottom") {
        arr = arr.reverse() /*.map((riga) => riga.slice())*/;
    }

    return flatten(arr).map((n) => prefisso + n.toString() + suffisso);
};

const drawGrid = (extent: number[], gridSettings: GridSettings) => {
    vector.getSource()?.clear();

    if (extent === null) return;

    const numeration = calcolaNumerazione(gridSettings);

    const features: Feature<Polygon>[] = [];
    try {
        const [minX, minY, maxX, maxY] = extent;
        const { rows, cols, space } = gridSettings;

        const w = (maxX - minX) / cols;
        const h = (maxY - minY) / rows;
        const spaziox = ((w / 100) * space) / 2;
        const spazioy = ((h / 100) * space) / 2;

        let n = 0;

        for (let r = rows - 1; r >= 0; r -= 1) {
            for (let c = 0; c < cols; c += 1) {
                const coords = [
                    [
                        [minX + c * w + spaziox, minY + r * h + spazioy],
                        [minX + c * w + spaziox, minY + (r + 1) * h - spazioy], //top left
                        [minX + (c + 1) * w - spaziox, minY + (r + 1) * h - spazioy], //top right
                        [minX + (c + 1) * w - spaziox, minY + r * h + spazioy], //bottom right
                        [minX + c * w + spaziox, minY + r * h + spazioy], //bottom left
                    ],
                ];
                const polygonGeom = new Polygon(coords);
                const feature = new Feature({
                    name: numeration[n],
                    geometry: polygonGeom,
                });
                feature.setStyle(
                    new Style({
                        fill: new Fill({
                            color: "blue",
                        }),
                        text: new Text({
                            text: numeration[n],
                            fill: new Fill({
                                color: "white",
                            }),
                        }),
                    })
                );
                features.push(feature);
                n += 1;
            }
        }
    } catch (err) {
        console.log(err);
    }
    vector.getSource()?.addFeatures(features);
};

const useGrigliaTombeDrawHook = ({ onGrigliaTombeCreated, idStruttura }: GrigliaTombeHookProps) => {
    const { map, startSnap, stopSnap } = useMap();
    const { handleApiError } = useCruxToaster();
    const setTypeDraw = useSetRecoilState(typeDrawState);

    const [hasDrawn, setHasDrawn] = useState<boolean>(false);
    const [gridSettings, setGridSettings] = useSetState<GridSettings>(initialValues);

    const innerDraw = useCallback(() => {
        const currentExtent = extentInteraction.getExtentInternal();
        if (currentExtent) {
            drawGrid(extentInteraction.getExtentInternal(), gridSettings);
        }
    }, [gridSettings]);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    extentInteraction.on("extentchanged", innerDraw);
    extentInteraction.once("extentchanged", ({ extent }) => {
        setHasDrawn(extent?.length > 0);
    });

    useEffect(() => innerDraw(), [gridSettings, innerDraw]);

    const attachInteraction = useCallback(() => {
        vector.getSource()?.clear();
        map?.removeLayer(vector);
        map?.addLayer(vector);
        map?.addInteraction(extentInteraction);
        startSnap();
        extentInteraction.setExtent([]);
    }, [map]);

    useEffect(() => {
        if (map) {
            attachInteraction();
        }

        return () => {
            map?.removeInteraction(extentInteraction);
            map?.removeLayer(vector);
            stopSnap();
        };
    }, [attachInteraction]);

    const handleCancelCreateGrigliaTombe = () => {
        vector.getSource()?.clear();
        extentInteraction.setExtent([]);
    };

    const handleSaveGrigliaTombe = useCallback(
        (values: GridSettings) => {
            setIsSaving(true);
            const tombeToAdd: IAddTomba[] = [];

            vector
                .getSource()
                ?.getFeatures()
                ?.forEach((feature) => {
                    tombeToAdd.push({
                        idTipoTomba: values.idTipoTomba ?? 0,
                        numero: feature.getProperties().name ?? "",
                        geom: wkt.writeGeometry(feature.getGeometry() ?? new Geometry()),
                    });
                });

            addTomba({ idSettore: idStruttura, payload: tombeToAdd })
                .then((res) => {
                    onGrigliaTombeCreated(res);
                    attachInteraction();
                    setTypeDraw(EIconeDraw.Seleziona);
                })
                .catch((apiError) => {
                    handleApiError({ error: apiError.response.data });
                })
                .finally(() => {
                    setIsSaving(false);
                });
        },
        [attachInteraction, handleApiError, idStruttura, onGrigliaTombeCreated]
    );

    return {
        handleCancelCreateGrigliaTombe,
        handleSaveGrigliaTombe,
        hasDrawn,
        gridSettings,
        setGridSettings,
        isSaving,
    };
};

const DrawGrigliaTombeComponent: FC<GrigliaTombeHookProps> = ({ onGrigliaTombeCreated, idStruttura }) => {
    const hook = useGrigliaTombeDrawHook({ onGrigliaTombeCreated, idStruttura });

    return (
        <AddTombeGridPanel
            isSaving={hook.isSaving}
            hasDrawn={hook.hasDrawn}
            setGridSettings={hook.setGridSettings}
            gridSettings={hook.gridSettings}
            handleConfirmGrid={hook.handleSaveGrigliaTombe}
            handleCancelGrid={hook.handleCancelCreateGrigliaTombe}
        />
    );
};

export { DrawGrigliaTombeComponent };
