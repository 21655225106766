import { api } from "@services/api/api";

import { BaseResponse } from "@models/Responses/BaseResponse";
import { DocumentiBase, DocumentiBaseTipo } from "@models/StampaUnione";

import { ExportMap } from "@modules/cimiteri/pages/Models/settori";

const BASE_PATH = "/tabelle";
const BASE_PATH_DOCUMENT = "/document";

const listDocumentiBase = async () => {
    return api.get<BaseResponse<DocumentiBase[]>>(`${BASE_PATH}/stampa-unione`).then((res) => res.data);
};

const getDocumentoBase = async (id: number) => {
    return api.get<BaseResponse<DocumentiBase>>(`${BASE_PATH}/stampa-unione/${id}`).then((res) => res.data);
};

const getStrutturaDati = async (tipo: DocumentiBaseTipo) => {
    return api.get(`${BASE_PATH_DOCUMENT}/struttura-dati/${tipo}`, { responseType: "blob" }).then((res) => res);
};

const addDocumentoBase = async (document: Partial<DocumentiBase>) => {
    const {
        file,
        tipo,
        nomeDoc,
        salvaDoc,
        descr,
        salvaImmagini,
        tipoImmagine,
        tipoOrigineDati,
        numRipetizioni,
        generaQRCode,
        salvaDocSingoli,
        inviaPDF,
        formatoHTML,
    } = document;

    return api
        .postForm<BaseResponse<DocumentiBase>>(`${BASE_PATH}/stampa-unione`, {
            file,
            tipo,
            nomeDoc,
            salvaDoc,
            descr,
            salvaImmagini,
            tipoImmagine,
            tipoOrigineDati,
            numRipetizioni,
            generaQRCode,
            salvaDocSingoli,
            inviaPDF,
            formatoHTML,
        })
        .then((res) => res.data?.data);
};

const updateDocumentoBase = async (document: Partial<DocumentiBase>) => {
    const {
        file,
        tipo,
        salvaDoc,
        salvaImmagini,
        tipoImmagine,
        tipoOrigineDati,
        numRipetizioni,
        generaQRCode,
        salvaDocSingoli,
        inviaPDF,
        formatoHTML,
        descr,
    } = document;

    return api
        .putForm<BaseResponse<any>>(`${BASE_PATH}/stampa-unione/${document.id}`, {
            file,
            tipo,
            descr,
            salvaDoc,
            salvaImmagini,
            tipoImmagine,
            tipoOrigineDati,
            numRipetizioni,
            generaQRCode,
            salvaDocSingoli,
            inviaPDF,
            formatoHTML,
        })
        .then((res) => res.data?.data);
};

const deleteDocumentoBase = async (id: number) => {
    return api.delete(`${BASE_PATH}/stampa-unione/${id}`).then((res) => res.data);
};

const setDocumentoBase = async (idDoc: number, id: number, allExportMap?: ExportMap[]) => {
    return api
        .post<Blob>(
            `${BASE_PATH_DOCUMENT}/stampa-unione/${idDoc}/${id}`,
            {
                mappe: allExportMap && allExportMap.length > 0 ? allExportMap : [],
            },
            {
                responseType: "blob",
            }
        )
        .then((res) => res);
};

const setDocumentoBaseFatture = async (idDoc: number, fattura: any) => {
    return api
        .post<Blob>(
            `${BASE_PATH_DOCUMENT}/stampa-unione-fatture/${idDoc}`,
            {
                ...fattura,
            },
            {
                responseType: "blob",
            }
        )
        .then((res) => res);
};

export {
    listDocumentiBase,
    getDocumentoBase,
    getStrutturaDati,
    addDocumentoBase,
    updateDocumentoBase,
    deleteDocumentoBase,
    setDocumentoBase,
    setDocumentoBaseFatture,
};
