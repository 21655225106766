import { Formik } from "formik";
import React, { FC, useState } from "react";
import { Button, Loader, Panel, Progress } from "rsuite";
import * as Yup from "yup";

import { AddFileRequest, addFile } from "@services/FileService";

import File from "@models/File";

import { useCruxToaster } from "@hooks/useCruxToaster";

import FormDrawerStack from "@components/Drawers/FormDrawerStack";
import FormGroup from "@components/Form/FormGroup";
import FileInput from "@components/Form/Input/FileIput";
import FormInput from "@components/Form/Input/FormInput";

const addFileValidationSchema = Yup.object().shape({
    file: Yup.mixed().required(),
    fileDescr: Yup.string().min(0).max(200).nullable(),
});

const initialFormValues: AddFileRequest = {
    file: null,
    fileDescr: "",
};

interface AddFileFormProps {
    onAdded: (data: File) => void;
    entityType: string;
    entityId: number;
    title: string;
}

const AddFileForm: FC<AddFileFormProps> = ({ onAdded, entityId, entityType, title }) => {
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const [forceReset, setForceReset] = useState<boolean>(false);
    const { handleApiError } = useCruxToaster();

    const handleOnProgress = (event: any) => {
        const percentage = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(percentage);
    };

    const handleResetFileDescr = (value: boolean) => {
        setForceReset(value);
    };

    return (
        <Panel
            header={title !== "Files" ? "Aggiungi nuovo documento" : "Aggiungi nuovo file"}
            bordered
            className='bg-athens-200'
        >
            <Formik
                validationSchema={addFileValidationSchema}
                initialValues={initialFormValues}
                enableReinitialize
                validateOnChange
                validateOnBlur
                validateOnMount
                onSubmit={(values, actions) => {
                    actions.setSubmitting(true);
                    addFile(entityType, entityId, values, handleOnProgress)
                        .then((data) => {
                            onAdded(data);
                            handleResetFileDescr(true);
                        })
                        .catch((apiError) => {
                            handleApiError({
                                message:
                                    apiError?.response?.data.meta.errori.map((error: string) => error) ??
                                    "Errore nel salvataggio del documento",
                            });
                        })
                        .finally(() => {
                            actions.setSubmitting(false);
                            setUploadProgress(null);
                            actions.resetForm();
                        });
                }}
            >
                {({ isValid, isSubmitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <FormDrawerStack>
                            <FormGroup name='file' label={title !== "Files" ? "Documento" : "File"} required>
                                <FileInput name='file' title={title} required />
                            </FormGroup>

                            <FormGroup
                                name='fileDescr'
                                label={title !== "Files" ? "Descrizione documento" : "Descrizione file"}
                            >
                                <FormInput
                                    name='fileDescr'
                                    minLength={0}
                                    maxLength={200}
                                    forceReset={forceReset}
                                    onForceReset={handleResetFileDescr}
                                />
                            </FormGroup>

                            {uploadProgress && <Progress.Line percent={uploadProgress} status='active' />}

                            {!uploadProgress && (
                                <Button type='submit' appearance='primary' disabled={!isValid || isSubmitting}>
                                    Aggiungi {title !== "Files" ? "documento" : "file"} {isSubmitting && <Loader />}
                                </Button>
                            )}
                        </FormDrawerStack>
                    </form>
                )}
            </Formik>
        </Panel>
    );
};

export default AddFileForm;
