/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { Panel } from "rsuite";
import * as Yup from "yup";

import { getTipiTrasferimento } from "@services/CimiteriService";

import { TombaAndPostoEvent, TrasferimentoEvent } from "@models/Events/Tomba";
import { ETipoTrasferimento, ICrudPostiTomba } from "@models/SettoreCimitero";
import { ETipo, ETipoDefunto } from "@models/TipoTomba";
import { EStato, IPostoTomba, IPostoTombaExtended, Tomba } from "@models/Tomba";

import { useCruxRequest } from "@hooks/useCruxRequest";

import FormDrawerStack from "@components/Drawers/FormDrawerStack";
import FormGroup from "@components/Form/FormGroup";
import DateInput from "@components/Form/Input/DateInput";
import FormInput from "@components/Form/Input/FormInput";
import SelectInput from "@components/Form/Input/SelectInput";
import TimeInput from "@components/Form/Input/TimeInput";
import SelezionaTomba from "@components/Tombe/SelezionaTomba";

import { tombaPostiLiberi } from "@helpers/TombaHelper";

interface Prop {
    postoTomba: Partial<IPostoTombaExtended> | null;
    tomba: Tomba | undefined;
    tombaEvent: TombaAndPostoEvent | null;
    trasferimentoEvent: TrasferimentoEvent | undefined;
}

const optionsDirezione = [
    {
        id: 1,
        descr: "Uscita",
    },
    {
        id: 2,
        descr: "Entrata",
    },
];

export const TrasferimentoDefuntoSchema = Yup.object().shape({
    direzione: Yup.number().required(),
    idTipoTrasferimento: Yup.number().required(),
    data: Yup.date(),
    idTomba: Yup.number(),
    idPosto: Yup.number().when(["trasferimentoEvent"], {
        is: (trasferimentoEvent: string) =>
            trasferimentoEvent === TrasferimentoEvent.STESSA_TOMBA ||
            trasferimentoEvent === TrasferimentoEvent.ALTRA_TOMBA,
        then: Yup.number().required(),
    }),
    comuneDestinazione: Yup.string()
        .nullable()
        .when(["trasferimentoEvent"], {
            is: (trasferimentoEvent: string) => trasferimentoEvent === TrasferimentoEvent.ALTRO_COMUNE,
            then: Yup.string().required(),
        }),
    tipoDefuntoDestinazione: Yup.string().nullable(),
});

const getFilterPosti = (filterPosti: IPostoTomba[], values: ICrudPostiTomba) => {
    return filterPosti
        .filter((p: any) => {
            if (
                values.idTipoTrasferimento === ETipoTrasferimento.Esumazione &&
                p?.postoTomba?.tipoDefunto !== ETipoDefunto.Ceneri
            ) {
                return p.tipo === ETipo.Resto;
            }

            if (
                values.idTipoTrasferimento === ETipoTrasferimento.Accostamento &&
                p?.postoTomba?.tipoDefunto === ETipoDefunto.Resto
            ) {
                return p.tipo === ETipo.Resto;
            }

            if (
                values.idTipoTrasferimento === ETipoTrasferimento.Accostamento &&
                p?.postoTomba?.tipoDefunto !== ETipoDefunto.Ceneri
            ) {
                return p.tipo === ETipo.Salma;
            }

            if (
                p?.postoTomba?.tipoDefunto === ETipoDefunto.Ceneri &&
                values?.idTipoTrasferimento === ETipoTrasferimento.Accostamento
            ) {
                return p.tipo === ETipo.Resto;
            }
            return false;
        })
        .map((posto) => {
            return {
                id: posto.id,
                descr: `${posto.tipo}${posto.numero} ${
                    posto.stato === EStato.Prenotato
                        ? `(Prenotato per ${posto?.anagrafica?.cognome} ${posto?.anagrafica?.nome})`
                        : ""
                }`,
            };
        });
};

const TrasferimentoDefuntoForm = ({ postoTomba, tomba, tombaEvent, trasferimentoEvent }: Prop) => {
    const [getTomba, setGetTomba] = useState<Tomba | undefined>(undefined);
    const { setFieldValue, values } = useFormikContext<ICrudPostiTomba>();
    const isAltraTomba = trasferimentoEvent === TrasferimentoEvent.ALTRA_TOMBA;
    const isStessaTomba = trasferimentoEvent === TrasferimentoEvent.STESSA_TOMBA;
    const { data: tipiTrasferimento, loading } = useCruxRequest(getTipiTrasferimento);

    const optionsTipoDefuntoDestinazione = [
        {
            id: 1,
            descr: "Ceneri",
        },
        {
            id: 2,
            descr: "Resti",
        },
    ];

    const optionsTipiTrasferimento = useMemo(() => {
        if (tipiTrasferimento && postoTomba?.tipo === ETipo.Resto) {
            return tipiTrasferimento
                .filter((value) => !value.esumazione && !value.storico)
                .map((tT) => {
                    return {
                        id: tT.id,
                        descr: tT.descr,
                    };
                });
        }

        if (tipiTrasferimento && postoTomba?.tipo === ETipo.Salma) {
            return tipiTrasferimento
                .filter((value) => !value.storico)
                .map((tT) => {
                    return {
                        id: tT.id,
                        descr: tT.descr,
                    };
                });
        }

        return [];
    }, [tipiTrasferimento]);

    const postiTomba = useMemo(() => {
        if (getTomba?.posti && getTomba.posti.length > 0 && isAltraTomba) {
            const filterPosti = tombaPostiLiberi(getTomba, postoTomba);

            if (filterPosti.length > 1) {
                return postoTomba?.tipo === ETipo.Resto
                    ? filterPosti
                          .filter((p) => p.tipo === postoTomba?.tipo)
                          .map((posto) => {
                              return {
                                  id: posto.id,
                                  descr: `${posto.tipo}${posto.numero}`,
                              };
                          })
                    : getFilterPosti(filterPosti, values);
            }

            if (filterPosti.length === 1) {
                return getFilterPosti(filterPosti, values);
            }
        }

        if (tomba?.posti && tomba.posti.length > 0 && isStessaTomba) {
            const filterPosti = tombaPostiLiberi(tomba, postoTomba);

            if (filterPosti.length > 1) {
                return postoTomba?.tipo === ETipo.Resto
                    ? filterPosti
                          .filter((p) => p.tipo === postoTomba?.tipo)
                          .map((posto) => {
                              return {
                                  id: posto.id,
                                  descr: `${posto.tipo}${posto.numero}`,
                              };
                          })
                    : getFilterPosti(filterPosti, values);
            }

            if (filterPosti.length === 1) {
                return getFilterPosti(filterPosti, values);
            }
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTomba, tomba, values.idTipoTrasferimento]);

    useEffect(() => {
        if (getTomba) setFieldValue("idTomba", getTomba.id);
    }, [getTomba, setFieldValue]);

    const isEsumazione = useMemo(() => {
        const find = tipiTrasferimento?.find((tT) => tT.id === values.idTipoTrasferimento);
        const enabled = postoTomba?.tipo === ETipo.Salma && find?.esumazione;

        return enabled;
    }, [postoTomba, values.idTipoTrasferimento, tipiTrasferimento]);

    useEffect(() => {
        if (!isEsumazione) setFieldValue("tipoDefuntoDestinazione", undefined);
        if (isEsumazione) setFieldValue("tipoDefuntoDestinazione", "Resti");
    }, [isEsumazione]);

    useEffect(() => {
        if (postiTomba) setFieldValue("idPosto", undefined);
    }, [postiTomba]);

    useEffect(() => {
        if (postiTomba) setFieldValue("idPosto", undefined);
    }, [values.idTipoTrasferimento]);

    return (
        <section className='flex space-x-12 w-full'>
            {isAltraTomba && (
                <div className='w-3/4'>
                    <SelezionaTomba setGetTomba={setGetTomba} />
                </div>
            )}

            <div className={isAltraTomba ? "w-1/4 bg-athens-300 p-3 border rounded" : "w-full"}>
                <FormDrawerStack>
                    <Panel className='bg-athens-200' header='Dati trasferimento' bordered>
                        <div className='flex flex-wrap gap-8 items-end w-full justify-between'>
                            <FormGroup name='tipo' className='flex-1'>
                                <p className='font-bold'>Tipo di trasferimento</p>
                                <SelectInput
                                    name='idTipoTrasferimento'
                                    data={optionsTipiTrasferimento}
                                    valueKey='id'
                                    labelKey='descr'
                                    cleanable={false}
                                    searchable={false}
                                    loading={loading}
                                />
                            </FormGroup>
                            <FormGroup name='data' label='Data trasferimento' className='flex-1 mt-0'>
                                <DateInput name='data' />
                            </FormGroup>
                            <FormGroup name='ora' label='Alle'>
                                <TimeInput name='ora' />
                            </FormGroup>
                        </div>

                        {isEsumazione && (
                            <FormGroup name='tipoDefuntoDestinazione' className='flex-1'>
                                <p className='font-bold'>Tipo defunto</p>
                                <SelectInput
                                    name='tipoDefuntoDestinazione'
                                    data={optionsTipoDefuntoDestinazione}
                                    valueKey='descr'
                                    labelKey='descr'
                                    cleanable={false}
                                    searchable={false}
                                />
                            </FormGroup>
                        )}

                        <FormGroup name='direzione' className='flex-1'>
                            <p className='font-bold'>Trasferimento in</p>
                            <SelectInput
                                name='direzione'
                                data={optionsDirezione || []}
                                disabled={
                                    trasferimentoEvent === TrasferimentoEvent.ALTRO_COMUNE ||
                                    tombaEvent === TombaAndPostoEvent.MODIFICA_TRASFERIMENTO_POSTO
                                }
                                valueKey='id'
                                labelKey='descr'
                                cleanable={false}
                                searchable={false}
                            />
                        </FormGroup>

                        {trasferimentoEvent !== TrasferimentoEvent.ALTRO_COMUNE && (
                            <FormGroup name='direzione' className='flex-1'>
                                <p className='font-bold'>Seleziona posto</p>
                                <SelectInput
                                    name='idPosto'
                                    data={postiTomba}
                                    valueKey='id'
                                    labelKey='descr'
                                    cleanable={false}
                                    searchable={false}
                                    disabled={postiTomba.length === 0}
                                    placeholder='Seleziona un posto'
                                />
                            </FormGroup>
                        )}

                        {postiTomba.length === 0 && trasferimentoEvent !== TrasferimentoEvent.ALTRO_COMUNE && (
                            <p className='text-error text-xs pt-4 font-bold'>Nessun posto presente!</p>
                        )}

                        {trasferimentoEvent === TrasferimentoEvent.ALTRO_COMUNE && (
                            <FormGroup name='comuneDestinazione' className='flex-1' label='Altro comune'>
                                <FormInput name='comuneDestinazione' />
                            </FormGroup>
                        )}
                    </Panel>
                </FormDrawerStack>
            </div>
        </section>
    );
};

export default TrasferimentoDefuntoForm;
