import React from "react";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const settingsRoutes: RouteObject = {
    element: <Outlet />,
    children: [
        {
            path: "generali",
            children: [
                {
                    path: "colori",
                    element: lazyLoadRoutes("colorsIndex"),
                },
                {
                    path: "*",
                    element: <Navigate to='/impostazioni/generali/colori' />,
                },
            ],
        },
        {
            path: "profilo",
            element: lazyLoadRoutes("profileIndex"),
        },
        {
            path: "cambio-password",
            element: lazyLoadRoutes("changePasswordIndex"),
        },
        {
            path: "gestione-utenti",
            element: lazyLoadRoutes("teamIndex"),
        },
        {
            path: "*",
            element: <Navigate to='/impostazioni/profilo' />,
        },
    ],
};

export default settingsRoutes;
