export const wrapData = (data?: any | any[]) => ({
    data,
    meta: {
        statuscode: 200,
        messaggio: "OK",
        errori: null,
    },
    permessi: {},
});

export const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const restUrl = (path: string, bypass = false) =>
    bypass ? path : (process.env.REACT_APP_BACKEND_URL ?? "") + path;
