import { IJwtToken } from "@models/AuthUser";
import { Diritti, IDiritti } from "@models/Diritti";

const parseJwt = (token: IJwtToken): IDiritti | {} => {
    let diritti: IDiritti | {} = {};
    const parseDiritti = JSON.parse(token.Diritti);

    Object.entries(parseDiritti).forEach((key) => {
        diritti = {
            ...diritti,
            [Object.values(Diritti)[+(key as [string, number])[0] - 1]]: +(key as [string, number])[1],
        };
    });

    return diritti;
};

export { parseJwt };
