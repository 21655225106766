import { lazyLoadRoutes } from "routing/LazyLoadRouter";

import LoginPage from "@modules/auth/LoginPage";
import cruxWebRoutes from "@modules/crux-web/routes";

const publicRoutes = [
    { path: "login", element: <LoginPage /> },
    { path: "recupera-password", element: lazyLoadRoutes("reset") },
    { path: "ricerca/*", ...cruxWebRoutes },
];

export default publicRoutes;
