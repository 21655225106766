import { Feature } from "ol";
import { FeatureLike } from "ol/Feature";
import { Color } from "ol/color";
import { ColorLike } from "ol/colorlike";
import { Geometry } from "ol/geom";
import { Fill, RegularShape, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { Options as TextOptions } from "ol/style/Text";

import { ELayer } from "@models/Grafica";

import { featureLayer, featureTesto } from "./EditMappingHelpers";

export interface GetTextStyleOptions {
    text: string[];
    color: Color | ColorLike | undefined;
    align: CanvasTextAlign;
    resolution?: number;
    size?: number;
    showOnlyLargerFont?: boolean;
    rotation?: number;
    shouldCalculateSize?: boolean;
    overflow?: boolean;
    limitTextBetweenRange?: boolean;
}

export interface GetTextIconOptions {
    text: string;
    color: Color | ColorLike | undefined;
    textAlign: CanvasTextAlign;
    textBaseline: CanvasTextBaseline;
    resolution?: number;
    size?: number;
    fontLimit?: boolean;
    rotation?: number;
    shouldCalculateSize?: boolean;
    style?: string;
}

const getBorderStyle = (color: Color | ColorLike | undefined = "transparent", width: number = 1): Stroke => {
    return new Stroke({
        color,
        width,
    });
};

const getFillStyle = (color: Color | ColorLike | undefined = "transparent"): Fill => {
    return new Fill({
        color,
    });
};

const getTextStyle = ({
    text,
    color,
    align,
    resolution = 1,
    size = 1,
    showOnlyLargerFont = false,
    rotation = 0,
    shouldCalculateSize = true,
    overflow = true,
    limitTextBetweenRange = true,
}: GetTextStyleOptions): Text => {
    let textSize = size;
    let showText = true;
    if (shouldCalculateSize) {
        textSize = size / 12 / resolution;

        if (showOnlyLargerFont) {
            showText = textSize > 4;
        }

        if (limitTextBetweenRange) {
            textSize = textSize > 20 ? 20 : textSize;
            textSize = textSize < 8 ? 8 : textSize;
        }
    }

    const fixedText = text.map((v, index) => {
        if (index % 2 === 0) {
            return v;
        }
        if (v === "") return "";
        return v === "title" ? `bold ${textSize * 1.2}px arial` : v;
    });

    return new Text({
        font: `${textSize}px arial`,
        rotation: rotation ?? 0,
        textAlign: align,
        text: showText ? fixedText : "",
        fill: new Fill({
            color,
        }),
        overflow,
    });
};

const getTextIconStyle = ({
    text,
    color,
    textAlign,
    textBaseline,
    resolution = 1,
    size = 1,
    fontLimit = false,
    rotation = 0,
    shouldCalculateSize = true,
    style = "400",
}: GetTextIconOptions): TextOptions => {
    let textSize = size;
    let showText = true;

    if (shouldCalculateSize) {
        textSize = size / 12 / resolution;

        if (fontLimit) {
            showText = textSize > 4;
        }

        textSize = textSize > 20 ? 20 : textSize;
        textSize = textSize < 8 ? 8 : textSize;
    }

    const fontA = `${style} ${textSize}px "Font Awesome 6 Pro"`;

    const offset = 2;

    return {
        font: fontA,
        rotation: rotation ?? 0,
        textAlign,
        textBaseline,
        text: showText ? text : "",
        fill: new Fill({
            color,
        }),
        stroke: new Stroke({ color: "#fff", width: 0.5 }),
        overflow: false,
        offsetY: (offset * (textBaseline === "bottom" ? -1 : 1)) / 30 / resolution,
        offsetX: (offset * (textAlign === "right" ? -1 : 1)) / 30 / resolution,
    };
};

const selezioneStyle = (feature: FeatureLike, resolution: number) => {
    const mapFeature = feature as Feature<Geometry>;
    const layer = featureLayer(mapFeature);
    const propertiesTesto = featureTesto(mapFeature);

    return new Style({
        fill: new Fill({
            color: "rgba(255, 179, 0, 0.6)",
        }),
        stroke: new Stroke({
            color: "#425C93",
            width: 2,
        }),
        ...(layer === ELayer.Testo && {
            image: new CircleStyle({
                radius: 5,
                fill: getFillStyle("rgba(255, 179, 0, 0.6)"),
                stroke: getBorderStyle("#425C93", 2),
            }),
        }),
        text: getTextStyle({
            text: [propertiesTesto?.val ?? "", ""],
            color: "#425C93",
            align: "left",
            resolution,
            size: propertiesTesto.size,
            rotation: propertiesTesto.rot ?? 0,
            showOnlyLargerFont: true,
            shouldCalculateSize: false,
            limitTextBetweenRange: false,
        }),
    });
};

const clickStyle = (feature: FeatureLike, resolution: number) => {
    const mapFeature = feature as Feature<Geometry>;
    const layer = featureLayer(mapFeature);
    const propertiesTesto = featureTesto(mapFeature);

    return new Style({
        fill: new Fill({
            color: "rgba(255, 179, 0, 0.6)",
        }),
        stroke: new Stroke({
            color: "#425C93",
            width: 2,
        }),
        ...(layer === ELayer.Testo && {
            image: new CircleStyle({
                radius: 5,
                fill: getFillStyle("rgba(255, 179, 0, 0.6)"),
                stroke: getBorderStyle("#425C93", 2),
            }),
        }),
        text: getTextStyle({
            text: ["", ""],
            color: "#425C93",
            align: "left",
            resolution,
            size: propertiesTesto.size,
            rotation: propertiesTesto.rot ?? 0,
            showOnlyLargerFont: true,
            shouldCalculateSize: false,
            limitTextBetweenRange: false,
        }),
    });
};

const circleStyle = new RegularShape({
    fill: new Fill({ color: [255, 255, 255, 0.01] }),
    stroke: new Stroke({ width: 1, color: [0, 0, 0, 0.01] }),
    radius: 8,
    points: 10,
});

const getVectorStyle = (feature: any) => {
    return [
        new Style({
            image: new RegularShape({
                fill: new Fill({ color: [0, 0, 255, 0.4] }),
                stroke: new Stroke({ color: [0, 0, 255, 1], width: 1 }),
                radius: 10,
                points: 3,
                angle: feature.get("angle") || 0,
            }),
            fill: new Fill({ color: [0, 0, 255, 0.4] }),
            stroke: new Stroke({ color: [0, 0, 255, 1], width: 1 }),
        }),
    ];
};

const getFeatureFillColor = (feature: Feature<Geometry> | null): string => {
    return (feature && ((feature.getStyle() as any)?.getFill().getColor() as string)) ?? "";
};

const getFeatureStrokeColor = (feature: Feature<Geometry> | null): string => {
    return (feature && ((feature.getStyle() as any)?.getStroke().getColor() as string)) ?? "";
};

export {
    getBorderStyle,
    getFillStyle,
    getTextStyle,
    getTextIconStyle,
    selezioneStyle,
    clickStyle,
    circleStyle,
    getVectorStyle,
    getFeatureFillColor,
    getFeatureStrokeColor,
};
