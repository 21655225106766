import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import { FC, useState } from "react";
import { Form, InputGroup } from "rsuite";

import { getType } from "../Helpers/FormUtility";

type FormFieldProps = {
    name: string;
    message?: string;
    label: string;
    accepter?: any;
    error?: string;
    type?: string;
};

const FormField: FC<FormFieldProps> = (props) => {
    const { name, message, label, accepter, error, type = "text", ...rest } = props;
    const [visible, setVisible] = useState(false);

    const handleChange = () => {
        setVisible(!visible);
    };

    return (
        <Form.Group controlId={name} className={error ? "has-error" : ""}>
            <Form.ControlLabel>{label} </Form.ControlLabel>

            <InputGroup inside className='w-full'>
                <Form.Control
                    name={name}
                    accepter={accepter}
                    type={getType({ type, visible })}
                    errorMessage={error}
                    {...rest}
                />

                {type === "password" ? (
                    <InputGroup.Button onClick={handleChange}>
                        {visible ? <EyeIcon /> : <EyeSlashIcon />}
                    </InputGroup.Button>
                ) : null}
            </InputGroup>
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
};

export default FormField;
