import { setLocale } from "yup";

setLocale({
    mixed: {
        default: "field_invalid",
        required: "Il campo è obbligatorio",
        oneOf: (params) => `Il campo deve essere uno dei seguenti valori ${params.values}`,
    },
    string: {
        max: (params) =>
            `Il campo può contenere al massimo ${params.max} caratteri, invece ne contiene ${params.value?.length}`,
        min: (params) =>
            `Il campo deve contenere almeno ${params.min} caratteri, invece ne contiene ${params.value?.length}`,
        length: (params) =>
            `Il campo deve contenere ${params.length} caratteri, invece ne contiene ${params.value?.length}`,
        email: `Il campo deve essere una email`,
    },
    // use functions to generate an error object that includes the value from the schema
    number: {
        min: ({ min }) => `Il valore massimo consentito per questo campo è ${min}`,
        max: ({ max }) => `Il valore minimo consentito per questo campo è ${max}`,
    },
});
