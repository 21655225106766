enum Diritti {
    CONTRATTI = 1,
    TOMBE = 2,
    GRAFICA = 3,
    CANONI = 4,
    TRASPORTI = 5,
    CREMAZIONI = 6,
    ANAGRAFICHE = 7,
    STRUTTURA = 8,
    TABELLE = 9,
    RICERCHE = 10,
    FATTURE = 11, //aggiungere diritto per le fatture?
}

enum StatoDiritti {
    NESSUNO = 0,
    LETTURA = 1,
    SCRITTURA = 2,
}

type IDiritti = {
    [key in Diritti]: StatoDiritti;
};

export type { IDiritti };
export { Diritti, StatoDiritti };
