import React from "react";
import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

import { TombaProvider } from "@context/TombaContext";

import GraveyardsContainer from "./GraveyardsContainer";

const graveyardsRoutes: RouteObject = {
    element: <GraveyardsContainer />,
    children: [
        {
            path: "",
            index: true,
            element: lazyLoadRoutes("dashBoardCimitero"),
        },
        {
            path: ":idCimitero",
            index: true,
            element: lazyLoadRoutes("settoreShow"),
        },
        {
            path: ":idCimitero/settore/:idSettore",
            index: true,
            element: lazyLoadRoutes("settoreShow"),
        },
        {
            path: ":idCimitero/settore/:idSettore/tomba/:idTomba",
            index: true,
            element: <TombaProvider>{lazyLoadRoutes("tombaShow")}</TombaProvider>,
        },
    ],
};

export default graveyardsRoutes;
