import { api } from "@services/api/api";

import { Contratto, ContrattoSmall, TipoContratto } from "@models/Contratto";
import { BaseResponse } from "@models/Responses/BaseResponse";

const BASE_PATH = "/contratti";

const listTipiContratto = async (anno: number) => {
    return api.get<BaseResponse<TipoContratto[]>>(`tabelle/tipi-contratto`).then((res) => res.data);
};

const listContratti = async (anno: number) => {
    return api
        .get<BaseResponse<ContrattoSmall[]>>(`${BASE_PATH}`, {
            params: {
                anno,
            },
        })
        .then((res) => res.data);
};

const getContratto = async (id: number) => {
    return api.get<BaseResponse<Contratto>>(`${BASE_PATH}/${id}`).then((res) => res.data);
};

const addContratto = async (data: Partial<Contratto>) => {
    return api.post<BaseResponse<Contratto>>(`${BASE_PATH}`, data).then((res) => res.data?.data);
};

const updateContratto = async (id: number, data: Partial<Contratto>) => {
    return api.put<BaseResponse<Contratto>>(`${BASE_PATH}/${id}`, data).then((res) => res.data?.data);
};

const deleteContratto = async (id: number) => {
    return api.delete(`${BASE_PATH}/${id}`).then((res) => res.data);
};

const getNextNumeroContratto = async (anno: number) => {
    return api.get<BaseResponse<string>>(`${BASE_PATH}/ultimo-contratto/${anno}`).then((res) => res.data?.data);
};

const getNextRepertorioContratto = async (anno: number) => {
    return api.get<BaseResponse<string>>(`${BASE_PATH}/ultimo-repertorio/${anno}`).then((res) => res.data?.data);
};

const tipiContrattoCsv = async () => {
    return api.get<any>(`${BASE_PATH}/csv`, { responseType: "blob" }).then((res) => res);
};

export {
    listTipiContratto,
    listContratti,
    getContratto,
    addContratto,
    updateContratto,
    getNextNumeroContratto,
    getNextRepertorioContratto,
    deleteContratto,
    tipiContrattoCsv,
};
