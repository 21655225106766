import { FC, ReactNode, SyntheticEvent } from "react";
import { Button, Modal } from "rsuite";

type ModalProps = {
    handleConfirm: (event: SyntheticEvent<Element, Event>) => void;
    handleClose: (event: SyntheticEvent<Element, Event>) => void;
    isOpen: boolean;
    message?: string | ReactNode;
    confirmLabel?: string;
    cancelLabel?: string;
};

const ConfirmModal: FC<ModalProps> = (props) => {
    const {
        handleClose,
        handleConfirm,
        isOpen,
        message = "Sei sicuro di voler procedere?",
        confirmLabel = "Ok",
        cancelLabel = "Annulla",
    } = props;
    return (
        <Modal backdrop='static' role='alertdialog' open={isOpen} onClose={handleClose} size='xs'>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: message as string }} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleConfirm} appearance='primary'>
                    {confirmLabel}
                </Button>
                <Button onClick={handleClose} appearance='subtle'>
                    {cancelLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
