enum CruxRoute {
    CIMITERO = "cimitero",
    SETTORE = "settore",
    CONTRATTI = "contratti",
    CANONI = "canoni",
    TABELLE = "tabelle",
    FATTURE = "fatture",
    RICERCHE = "ricerche",
    TOMBA = "tomba",
}

export { CruxRoute };
