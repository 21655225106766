import { useRequest } from "ahooks";
import Fetch from "ahooks/lib/useRequest/src/Fetch";
import { Options } from "ahooks/lib/useRequest/src/types";
import { AxiosError } from "axios";
import { isFunction } from "lodash";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import { idMunicipalityState } from "@stores/municipality";

import { BaseResponse, IMeta, IPermessi } from "@models/Responses/BaseResponse";

import { useCruxToaster } from "@hooks/useCruxToaster";

import { useCruxNavigate } from "./useCruxNavigate";

interface ReturnedData<TData, TParams extends any[]> {
    data: TData | undefined;
    permessi: IPermessi;
    meta: IMeta;
    loading: boolean;
    error?: Error;
    // params: TParams | [];
    // cancel: Fetch<TData, TParams>['cancel'];
    refresh: Fetch<TData, TParams>["refresh"];
    // refreshAsync: Fetch<TData, TParams>['refreshAsync'];
    run: Fetch<TData, TParams>["run"];
    // runAsync: Fetch<TData, TParams>['runAsync'];
    mutate: Fetch<TData, TParams>["mutate"];
}

export function useCruxRequest<TData, TParams extends any[]>(
    service: (...args: TParams) => Promise<BaseResponse<TData>>,
    options?: Options<BaseResponse<TData>, TParams>,
    disableRefresh?: boolean
): ReturnedData<TData, TParams> {
    const { isTabelle } = useCruxNavigate();

    const idMunicipality = useRecoilValue(idMunicipalityState);

    const { handleApiError } = useCruxToaster();

    const innerOptions: Options<BaseResponse<TData>, TParams> = {
        ...options,
        onError: (error) => {
            const axiosError = error as AxiosError;
            const errorData = axiosError?.response?.data as BaseResponse<unknown>;
            handleApiError({ error: errorData, message: !errorData ? "Errore generico" : undefined });
        },
    };

    const {
        data: requestResponse,
        mutate: requestMutate,
        loading,
        run,
        error,
        refresh,
    } = useRequest(service, innerOptions);

    const mutate = (data?: TData | ((oldData?: TData) => TData | undefined)) => {
        let targetData: any;
        if (isFunction(data)) {
            targetData = data(requestResponse?.data);
        } else {
            targetData = data;
        }

        requestMutate((oldData) => {
            if (oldData)
                return {
                    ...oldData,
                    data: targetData,
                };
            return oldData;
        });
    };

    useEffect(() => {
        if (isTabelle && !loading && !disableRefresh) {
            refresh();
        }
        // eslint-disable-next-line
    }, [idMunicipality]);

    return {
        data: requestResponse?.data,
        permessi: requestResponse?.permessi ?? { fake: false },
        meta: requestResponse?.meta ?? {
            statuscode: 0,
            messaggio: "",
            errori: [],
        },
        mutate,
        loading,
        run,
        error,
        refresh,
    };
}
