import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const stampaUnioneSettingsRoutes: RouteObject[] = [
    {
        path: "stampa-unione",
        index: true,
        element: lazyLoadRoutes("stampaUnioneIndex"),
    },
];

export default stampaUnioneSettingsRoutes;
