const replaceNumberAndSpecialCharacterIntoDocument = (value?: string) => {
    if (!value) return undefined;

    const replace = value?.split(".")[0].replaceAll(/[^a-zA-Z]/g, "");
    const replaceWithoutSpace = replace.replaceAll(" ", "").toLowerCase();
    return replaceWithoutSpace;
};

const replaceNumberAndSpecialCharacter = (value: string) => {
    if (!value) return undefined;

    const replace = value?.replaceAll(/[^a-zA-Z]/g, "");
    const replaceWithoutSpace = replace.replaceAll(" ", "").toLowerCase();

    return replaceWithoutSpace;
};

const replaceSpecialCharacter = (value: string) => {
    if (!value) return undefined;

    const replace = value?.replaceAll(/[^a-zA-Z0-9]/g, "");
    const replaceWithoutSpace = replace.replaceAll(" ", "").toLowerCase();

    return replaceWithoutSpace;
};

export { replaceNumberAndSpecialCharacterIntoDocument, replaceNumberAndSpecialCharacter, replaceSpecialCharacter };
