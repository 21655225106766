import { api } from "@services/api/api";

import { Changelog } from "@models/Changelog";
import { Comune, Provincia, Regione, Stato } from "@models/Comune";
import { BaseResponse } from "@models/Responses/BaseResponse";

const BASE_PATH = "/tabelle";

const getComuniList = async (filter: string, maxResults: number = 10) => {
    return api
        .get<BaseResponse<Comune[]>>(`${BASE_PATH}/comuni`, {
            params: { descr: filter, maxResults },
        })
        .then((res) => res.data.data);
};

const getProvinceList = async (filter?: string, maxResults: number = 300) => {
    return api
        .get<BaseResponse<Provincia[]>>(`${BASE_PATH}/province`, {
            params: { descr: filter, maxResults },
        })
        .then((res) => res.data.data);
};

const getRegioniList = async (filter: string, maxResults: number = 10) => {
    return api
        .get<BaseResponse<Regione[]>>(`${BASE_PATH}/regioni`, {
            params: { descr: filter, maxResults },
        })
        .then((res) => res.data.data);
};

const getStatiList = async (filter: string, maxResults: number = 10) => {
    return api
        .get<BaseResponse<Stato[]>>(`${BASE_PATH}/stati`, {
            params: { descr: filter, maxResults },
        })
        .then((res) => res.data.data);
};

const getAllProvinceList = async () => {
    return api.get<BaseResponse<Provincia[]>>(`${BASE_PATH}/province`).then((res) => res.data.data);
};

const getAllRegioniList = async () => {
    return api.get<BaseResponse<Regione[]>>(`${BASE_PATH}/regioni`).then((res) => res.data.data);
};

const getAllStatiList = async () => {
    return api.get<BaseResponse<Stato[]>>(`${BASE_PATH}/stati`).then((res) => res.data.data);
};

const getChangelogList = async () => {
    return api.get<BaseResponse<Changelog[]>>(`/changelog`).then((res) => res.data.data);
};

export {
    getComuniList,
    getProvinceList,
    getRegioniList,
    getStatiList,
    getAllStatiList,
    getAllProvinceList,
    getAllRegioniList,
    getChangelogList,
};
