import { useDebounceEffect, useSetState } from "ahooks";
import { isSameDay, isValid, parse, parseISO } from "date-fns";
import { get, orderBy } from "lodash";
import * as React from "react";
import { FC, createContext, useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { listContratti } from "@services/contratti/ContrattiService";

import {
    EStatoPagamentoContratto,
    ETipoRicercaContratto,
    filtriContratti,
    filtriContrattiState,
    initialSearchValues,
} from "@stores/filtri";

import { ContrattoSmall } from "@models/Contratto";
import { OrdinamentoRequest } from "@models/Requests/Requests";

import { useCruxRequest } from "@hooks/useCruxRequest";

export interface ContrattiFilterInterface {
    anno: number;
    storico: boolean;
    pagato: EStatoPagamentoContratto;
    extra: {
        tipo: ETipoRicercaContratto;
        valore: string | null;
    };
}

interface ContractsContextProps {
    contratti: ContrattoSmall[];
    onDeletedItem: (id: number) => void;
    loading: boolean;
    filters: ContrattiFilterInterface;
    setFilters: (values: Partial<ContrattiFilterInterface> | null) => void;
    pageFilters: OrdinamentoRequest;
    setPageFilters: (values: Partial<OrdinamentoRequest> | null) => void;
    error: any | undefined;
}

const initialData: ContractsContextProps = {
    onDeletedItem: () => null,
    contratti: [],
    loading: false,
    filters: initialSearchValues,
    setFilters: () => null,
    pageFilters: {
        ordinamento: undefined,
        ordinamentoDecrescente: undefined,
    },
    setPageFilters: () => null,
    error: undefined,
};

const ContractsContext = createContext<ContractsContextProps>(initialData);

interface ContractsProviderProps {
    children?: React.ReactNode;
}

const applyFilter = (contratto: ContrattoSmall, field: ETipoRicercaContratto, value: string | null) => {
    if (!value) return true;
    if (field === ETipoRicercaContratto.dataContratto) {
        let parsedValue = parseISO(value);
        if (!isValid(parsedValue)) {
            parsedValue = parse(value, "dd/MM/yyyy", new Date());
        }
        return isSameDay(parseISO(contratto[field]), parsedValue);
    }
    return (get(contratto, field) as any)?.toLowerCase()?.includes(value.toLowerCase());
};

const sortContratti = (contratti: ContrattoSmall[], pageFilters: OrdinamentoRequest) => {
    if (pageFilters.ordinamento) {
        return orderBy(contratti, pageFilters.ordinamento, pageFilters.ordinamentoDecrescente ? "desc" : "asc");
    }

    return contratti;
};

const filtroPagamento = (contratti: ContrattoSmall[], filters: ContrattiFilterInterface) => {
    if (filters.pagato === EStatoPagamentoContratto.pagato) {
        return contratti.filter((contratto) => contratto.importoPagato);
    }
    if (filters.pagato === EStatoPagamentoContratto.nonPagato) {
        return contratti.filter((contratto) => !contratto.importoPagato);
    }
    return contratti;
};

const filterContratti = (contratti: ContrattoSmall[], filters: ContrattiFilterInterface) => {
    const getContratti = filtroPagamento(contratti, filters);
    return getContratti.filter((contratto) => {
        if (filters.extra.tipo !== ETipoRicercaContratto.tutti) {
            return applyFilter(contratto, filters.extra.tipo, filters.extra.valore);
        }
        return Object.values(ETipoRicercaContratto)
            .filter((f) => f !== ETipoRicercaContratto.tutti)
            .some((f) => applyFilter(contratto, f, filters.extra.valore));
    });
};

const ContractsProvider: FC<ContractsProviderProps> = ({ children }) => {
    const [contrattiFiltrati, setContrattiFiltrati] = useState<ContrattoSmall[]>([]);

    const setFilters = useSetRecoilState(filtriContrattiState);
    const filters = useRecoilValue(filtriContratti);

    const [pageFilters, setPageFilters] = useSetState<OrdinamentoRequest>({
        ordinamento: undefined,
        ordinamentoDecrescente: undefined,
    });

    const { data: contracts, loading, mutate, run, error } = useCruxRequest(listContratti, { manual: true });

    useDebounceEffect(
        () => {
            setContrattiFiltrati(sortContratti(filterContratti(contracts ?? [], filters), pageFilters));
        },
        [contracts, filters, pageFilters],
        { wait: 500 }
    );

    useEffect(() => {
        run(filters.anno);
    }, [filters.anno, run]);

    const handleChangeFilters = (values: Partial<ContrattiFilterInterface> | null) => {
        if (values) {
            setFilters((old) => ({ ...old, ...values }));
        } else {
            setFilters(initialSearchValues);
        }
    };

    const onDeletedItem = (id: number) => {
        mutate((oldData) => oldData?.filter((c) => c.id !== id));
    };

    const handleChangePageFilters = (values: Partial<OrdinamentoRequest> | null) => {
        if (values) {
            setPageFilters((old) => ({ ...old, ...values }));
        } else {
            setPageFilters({
                ordinamento: undefined,
                ordinamentoDecrescente: undefined,
            });
        }
    };

    const contextValue: ContractsContextProps = useMemo(
        () => ({
            contratti: contrattiFiltrati,
            onDeletedItem,
            loading,
            filters,
            setFilters: handleChangeFilters,
            pageFilters,
            setPageFilters: handleChangePageFilters,
            error,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contrattiFiltrati, filters, mutate, loading]
    );

    return <ContractsContext.Provider value={contextValue}>{children}</ContractsContext.Provider>;
};

function useContractsContext() {
    const context = React.useContext(ContractsContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
}

export { ContractsProvider, useContractsContext };
