import { api } from "@services/api/api";

import Livello from "@models/Livello";
import { BaseResponse } from "@models/Responses/BaseResponse";

const BASE_PATH = "/tabelle/livelli";

const listLevels = async () => {
    return api.get<BaseResponse<Livello[]>>(BASE_PATH).then((res) => res.data);
};

const addLevel = async (level: Partial<Livello>) => {
    return api.post<BaseResponse<Livello>>(BASE_PATH, level).then((res) => res.data?.data);
};

const updateLevel = async (level: Partial<Livello>) => {
    return api.put<BaseResponse<Livello>>(`${BASE_PATH}/${level.id}`, { ...level }).then((res) => res.data?.data);
};

const deleteLevel = async (id: number) => {
    return api.delete(`${BASE_PATH}/${id}`).then((res) => res.data);
};

export { addLevel, listLevels, updateLevel, deleteLevel };
