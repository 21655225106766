import { api } from "@services/api/api";

import File from "@models/File";
import { BaseResponse } from "@models/Responses/BaseResponse";

const BASE_PATH = "/file";

export type AddFileRequest = {
    file: File | null;
    fileDescr: string;
};

export type UpdateFileRequest = {
    id: number | undefined;
    file: File | null;
    fileDescr: string;
};

const listFile = async (tipo: string, idEsterno: number) => {
    return api.get<BaseResponse<File[]>>(`${BASE_PATH}/${tipo}/${idEsterno}`).then((res) => res.data);
};

const addFile = async (tipo: string, idEsterno: number, data: AddFileRequest, onProgress: (event: any) => void) => {
    const config = {
        onUploadProgress: onProgress,
    };
    return api
        .postForm<BaseResponse<File>>(`${BASE_PATH}/${idEsterno}/upload/${tipo}`, data, config)
        .then((res) => res.data?.data);
};

const downloadFile = async (tipo: string, uid: string) => {
    return api
        .get(`${BASE_PATH}/download/${tipo}/${uid}`, {
            responseType: "blob",
        })
        .then((res) => res.data);
};

const downloadMacro = async (tipo: string) => {
    return api
        .get(`${BASE_PATH}/download/${tipo}`, {
            responseType: "blob",
        })
        .then((res) => res);
};

const previewFile = async (tipo: string, uid: string, small: boolean = false) => {
    return api
        .get(`${BASE_PATH}/download/${tipo}/${uid}`, {
            params: {
                anteprima: small,
            },
            responseType: "blob",
        })
        .then((response) => {
            const file = new Blob([response.data], { type: response.headers["content-type"] });
            return URL.createObjectURL(file);
        });
};

const updateFile = async ({ file, fileDescr, id }: UpdateFileRequest) => {
    return api
        .putForm<BaseResponse<any>>(`${BASE_PATH}/${id}`, {
            file,
            fileDescr,
        })
        .then((res) => res.data?.data);
};

const deleteFile = async (id: number) => {
    return api.delete(`${BASE_PATH}/${id}`).then((res) => res.data);
};

export { addFile, listFile, deleteFile, previewFile, downloadFile, downloadMacro, updateFile };
