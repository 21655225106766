import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const contrattiSettingsRoutes: RouteObject[] = [
    {
        path: "concessioni",
        index: true,
        element: lazyLoadRoutes("concessioniIndex"),
    },
    {
        path: "altri-importi",
        index: true,
        element: lazyLoadRoutes("altriImportiIndex"),
    },
];

export default contrattiSettingsRoutes;
