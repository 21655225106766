interface TipoTomba {
    id: number;
    descr: string;
    postiSalma: number;
    postiResto: number;
    ignoraPostiResto: boolean;
    storico: boolean;
    descrPerVS: string;
}

enum ETipo {
    Salma = "S",
    Resto = "R",
}

enum ETipoDefunto {
    Salma = "S",
    Resto = "R",
    Ceneri = "C",
    Prenotazione = "P",
}

export { ETipoDefunto, ETipo };
export type { TipoTomba };
