import { useFormikContext } from "formik";
import React, { useEffect } from "react";

const FormikValuesHelper = ({ onValuesChanged }: { onValuesChanged: (values: any) => void }) => {
    const { values } = useFormikContext();

    useEffect(() => {
        onValuesChanged(values);
    }, [values]);

    return <></>;
};

export default FormikValuesHelper;
