import { atom, selector } from "recoil";

import { ColoriConfig } from "@models/Configs";

import { persistRecoil } from "./effects/persistRecoil";

const { persistAtom } = persistRecoil();

const showHistoricalDataState = atom<boolean>({
    key: "showHistoricalDataState",
    default: false,
});

const colorConfigState = atom<ColoriConfig>({
    key: "colorConfigState",
    default: undefined,
});

const versionState = atom<string>({
    key: "versionState",
    default: undefined,
    effects: [persistAtom],
});

const getVersionState = selector({
    key: "getVersionState",
    get: ({ get }) => {
        const version = get(versionState) as string;
        return version;
    },
});

const versionBEState = atom<string>({
    key: "versionBEState",
    default: undefined,
    effects: [persistAtom],
});

const getVersionBEState = selector({
    key: "getVersionBEState",
    get: ({ get }) => {
        const version = get(versionBEState) as string;
        return version;
    },
});

export { showHistoricalDataState, colorConfigState, versionState, getVersionState, versionBEState, getVersionBEState };
