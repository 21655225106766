import { useField } from "formik";
import * as React from "react";
import { FC } from "react";
import { Input } from "rsuite";
import { InputProps as RsInputProps } from "rsuite/esm/Input/Input";

interface TimeInputProps extends Omit<RsInputProps, "form" | "type"> {
    name: string;
}

const TimeInput: FC<TimeInputProps> = (props) => {
    const [field, meta, helpers] = useField(props.name);

    const handleOnChange = (value: string, event: any) => {
        helpers.setValue(value);
        props.onChange?.(value, event);
    };

    return <Input type='time' {...field} value={field.value ?? ""} onChange={handleOnChange} {...props} />;
};

export default TimeInput;
