import { faCirclePlus, faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Button, SelectPicker } from "rsuite";

import { permissionsStrutturaState } from "@stores/diritti";
import { cimiteroEventState, isCimiteriEditModeState } from "@stores/graveyard";
import {
    ActionSidenavTree,
    actionSidenavTreeState,
    cimiteriListState,
    loadingSidenavTreeState,
    reloadSidenavTreeState,
} from "@stores/municipality";

import { StatoDiritti } from "@models/Diritti";
import { CimiteroEvent } from "@models/Events/Cimiteri";

import { useCruxNavigate } from "@hooks/useCruxNavigate";

import withTooltip from "@hoc/withTooltip";

const CimiteroPicker = ({
    searchMode,
    onIdCimiteroChange,
}: {
    searchMode?: boolean;
    onIdCimiteroChange?: Dispatch<SetStateAction<number>>;
}) => {
    const ButtonWithTooltip = withTooltip(Button);

    const loadingSidenavTree = useRecoilValue(loadingSidenavTreeState);
    const permissionsStruttura = useRecoilValue(permissionsStrutturaState);
    const actualEvent = useRecoilValue(cimiteroEventState);
    const cimiteriList = useRecoilValue(cimiteriListState);

    const [actionSidenavTree, setActionSidenavTree] = useRecoilState(actionSidenavTreeState);
    const [isEditMode, setIsEditMode] = useRecoilState(isCimiteriEditModeState);
    const [reloadSidenavTree, setReloadSidenavTreeState] = useRecoilState(reloadSidenavTreeState);

    const setCimiteroEvent = useSetRecoilState(cimiteroEventState);

    const {
        idCimiteroAttivo,
        idCimiteroAttivoPersist,
        setIdCimiteroAttivo,
        selezioneCimitero,
        cimiteroVuoto,
        navigazioneCimitero,
        setIdCimiteroTrasferimento,
        idCimiteroTrasferimento,
    } = useCruxNavigate();
    const { idCimitero, idSettore } = useParams();

    const handleEditButtonClick = () => {
        setIsEditMode(true);
    };

    useEffect(() => {
        const entries = window.performance.getEntriesByType("navigation");
        if ((entries[0] as any)?.type === "reload") setReloadSidenavTreeState(true);
    }, []);

    useEffect(() => {
        if (cimiteriList && !reloadSidenavTree && !loadingSidenavTree && !idCimitero && !searchMode)
            setIdCimiteroAttivo(idCimiteroAttivoPersist ?? cimiteriList[0]?.id);
        if (cimiteriList?.length === 0) cimiteroVuoto();
    }, [cimiteriList, reloadSidenavTree, loadingSidenavTree, searchMode]);

    useEffect(() => {
        if (
            !searchMode &&
            (((actionSidenavTree === ActionSidenavTree.CambioComune ||
                actionSidenavTree === ActionSidenavTree.SelezioneCimitero) &&
                idCimiteroAttivo) ||
                (!idCimitero && cimiteriList && cimiteriList?.length > 0))
        ) {
            selezioneCimitero();
            setReloadSidenavTreeState(false);
        }

        if (!!onIdCimiteroChange && searchMode && idCimiteroTrasferimento && !loadingSidenavTree) {
            onIdCimiteroChange(idCimiteroTrasferimento);
        }
    }, [idCimiteroAttivo, idCimiteroTrasferimento, cimiteriList]);

    useEffect(() => {
        if (idSettore) setReloadSidenavTreeState(false);
    }, [idSettore]);

    const value = useMemo(() => {
        if (!searchMode && idCimiteroAttivo) return parseFloat(idCimiteroAttivo.toString());
        if (searchMode && !idCimiteroTrasferimento && idCimiteroAttivo) return parseFloat(idCimiteroAttivo.toString());
        if (searchMode && idCimiteroTrasferimento) return parseFloat(idCimiteroTrasferimento.toString());

        return "";
    }, [idCimiteroAttivo, idCimiteroTrasferimento]);

    return (
        <>
            {!isEditMode && (
                <div className='flex space-x-24 px-12 w-full max-w-full items-center justify-between'>
                    <SelectPicker
                        data={cimiteriList ?? []}
                        block
                        className='w-[15rem] flex-1 '
                        valueKey='id'
                        labelKey='descrCompleta'
                        searchable={false}
                        value={value}
                        onSelect={(graveyardId) => {
                            if (!searchMode) navigazioneCimitero(+graveyardId);
                            setActionSidenavTree(ActionSidenavTree.SelezioneCimitero);
                            if (!searchMode) setIdCimiteroAttivo(graveyardId);
                            else setIdCimiteroTrasferimento(graveyardId);
                        }}
                        cleanable={false}
                        loading={loadingSidenavTree}
                    />

                    {!searchMode && permissionsStruttura === StatoDiritti.SCRITTURA && (
                        <ButtonWithTooltip
                            message='Abilita modifica struttura'
                            size='md'
                            appearance='default'
                            className='bg-white'
                            onClick={handleEditButtonClick}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </ButtonWithTooltip>
                    )}
                </div>
            )}

            {isEditMode && (
                <div className='flex w-full px-5 gap-2 items-center justify-between'>
                    <Button
                        appearance='link'
                        onClick={() => setIsEditMode(false)}
                        disabled={actualEvent !== undefined}
                        className='text-white uppercase'
                    >
                        Termina modifica
                    </Button>

                    <ButtonWithTooltip
                        message='Nuovo cimitero'
                        size='lg'
                        appearance='default'
                        className='bg-white'
                        onClick={() =>
                            setCimiteroEvent({
                                idSettore: 0,
                                evento: CimiteroEvent.ADD_SUB_SETTORE,
                            })
                        }
                    >
                        <FontAwesomeIcon icon={faCirclePlus} />
                    </ButtonWithTooltip>
                </div>
            )}
        </>
    );
};

export default CimiteroPicker;
