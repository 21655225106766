import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import * as Yup from "yup";

import { UpdateFileRequest } from "@services/FileService";

import File from "@models/File";

import FormGroup from "@components/Form/FormGroup";
import FileInput from "@components/Form/Input/FileIput";
import FormInput from "@components/Form/Input/FormInput";

type ModalProps = {
    handleConfirmUpdate: (data: Partial<UpdateFileRequest>, id: number) => void;
    handleClose: () => void;
    isOpen: boolean;
    fileId: number;
    file: File | null;
};

const updateFileValidationSchema = Yup.object().shape({
    file: Yup.mixed(),
    fileDescr: Yup.string().min(0).max(200),
});

const initialFormValues: Partial<UpdateFileRequest> = {
    file: null,
    fileDescr: "",
};

const ConfirmUpdateFile: FC<ModalProps> = (props) => {
    const { handleClose, handleConfirmUpdate, file, fileId, isOpen } = props;
    const [formValues, setFormValues] = useState(initialFormValues);

    useEffect(() => {
        if (file) {
            setFormValues((prev) => {
                return { ...prev, fileDescr: file.descr };
            });
        }
    }, [file]);

    return (
        <Modal backdrop='static' role='alertdialog' open={isOpen} onClose={handleClose} size='md'>
            <Formik
                validationSchema={updateFileValidationSchema}
                initialValues={formValues}
                enableReinitialize
                validateOnChange
                validateOnBlur
                validateOnMount
                onSubmit={(values) => {
                    handleConfirmUpdate(values, fileId);
                }}
            >
                {({ handleSubmit }) => (
                    <form>
                        <Modal.Header>
                            <h2 className='font-bold p-0 text-md'>Modifica file</h2>
                        </Modal.Header>
                        <Modal.Body className='px-8'>
                            <FormGroup name='file' label='File'>
                                <FileInput name='file' placeholder={file?.fileName ?? ""} />
                            </FormGroup>
                            <FormGroup name='fileDescr' label='Descrizione file'>
                                <FormInput name='fileDescr' minLength={0} maxLength={200} />
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => handleSubmit()} appearance='primary'>
                                Ok
                            </Button>
                            <Button onClick={handleClose} appearance='subtle'>
                                Annulla
                            </Button>
                        </Modal.Footer>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default ConfirmUpdateFile;
