import { formatISO9075, isValid } from "date-fns";
import { useField } from "formik";
import * as React from "react";
import { FC, useEffect, useMemo } from "react";
import { DatePicker as RsDatePicker, DatePickerProps as RsDatePickerProps } from "rsuite";

interface DateInputProps extends Omit<RsDatePickerProps, "form"> {
    name: string;
}

const DateInput: FC<DateInputProps> = (props) => {
    const [field, meta, helpers] = useField<Date | undefined>(props.name);

    const dateVal = useMemo(() => {
        if (field.value) {
            const date = new Date(field.value);
            return isValid(date) ? date : null;
        }
        return null;
    }, [field.value]);

    useEffect(() => {
        if (field.value) {
            const formatDate = formatISO9075(new Date(field.value), { representation: "date" });
            helpers.setValue(formatDate as any);
        }
    }, [field.value]);

    const handleOnDateChange = (date: Date | null, event: any) => {
        if (date) {
            const formatDate = formatISO9075(new Date(date), { representation: "date" });

            helpers.setValue(formatDate as any);
            props.onChange?.(formatDate as any, event);
        } else {
            helpers.setValue(undefined);
            props.onChange?.(null, event);
        }
    };
    return (
        <RsDatePicker
            {...field}
            format='dd/MM/yyyy'
            placeholder='gg/mm/aaaa'
            block
            oneTap
            value={dateVal}
            onClean={(event) => handleOnDateChange(null, event)}
            onSelect={handleOnDateChange}
            onChange={handleOnDateChange}
            placement='auto'
            {...props}
        />
    );
};

export default DateInput;
